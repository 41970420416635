import _ from "lodash";
import React from "react";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS, Services } from "../../../../../../../constants";
import AppService from "../../../../../../../contexts/AppService";
import { IMultiSelectorModel } from "../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { ITextFieldModel } from "../../../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../../../core/forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";
import { ITagsApi } from "../../../../../../../services/api/v1/tags/ITags.api";

export const getHeatmapFilterFields = (
  organisationId: number,
  currentTags: any[],
  setCurrentTags: (s) => void
): IFormFieldModel<any, any>[] => {
  const tagsProvider = AppService.getService<ITagsApi>(Services.TagsApi);

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => `${e.text}`} />
    ),
    manageLink: `/organisations/${organisationId}/tags/search/`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await tagsProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(sortedTags);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: function (e) {
      if (e === null || typeof e === "undefined") return;
      setCurrentTags(e);
    },
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: currentTags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: false,
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("phrases.heatmapTagsFilterResultsNotFound"),
    searchResultHint: <p className="mb-0 pl-3 pb-1 pt-2">{I18n.t("forms.tagsSearchResultHint")}</p>
  };

  const fields = [];

  if (organisationId) {
    const orgId: Partial<ITextFieldModel> = {
      ...INIT_TEXT_FIELD,
      key: "organisationId",
      inputType: "hidden",
      value: organisationId + "",
      defaultValue: organisationId + ""
    };
    fields.push(orgId);
  }

  fields.push(tags);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
