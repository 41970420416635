import * as React from "react";
import { IHttpProgressModel } from "./HttpProgress_model";
import { PositionedSpinner } from "../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { TopProgressBar } from "../../components/ui/TopProgressBar";

export interface HttpProgressProps {
  model: IHttpProgressModel;
}

const HttpProgress: React.FunctionComponent<HttpProgressProps> = observer(({ model }) => {
  return (
    <div className="http-progress-wrapper">
      {model.isTopProgressBarVisible && <TopProgressBar />}
      {model.isProgressVisible ? <div className="http-progress-wrapper__progress" /> : null}
      {model.isOverlayVisible ? (
        <div className="http-progress-wrapper__overlay">
          <PositionedSpinner />
        </div>
      ) : null}
    </div>
  );
});

export { HttpProgress };
