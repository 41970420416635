import { IHttp } from "../../IHttp";
import { IImpactGroupsApi } from "./IImpactGroupsApi";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";

export class ImpactGroupsApi extends ExportableProjectApi<FP.Entities.IImpactGroup> implements IImpactGroupsApi {
  controller: string = "impact-groups";

  constructor(http: IHttp) {
    super(http, "impact-groups");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getImpactsUnassignedToImpactGroup = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number = 0,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/impacts/unassigned`;

    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  async getImpactGroupStakeholders(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/project-stakeholders`;

    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  }

  async getImpactGroupImpacts(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/impacts`;

    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  }

  async getDetailed(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpactGroup>;
    }
  }

  addImpactsAsync = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    impacts: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/${impactGroupId}/impacts`,
      impacts,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    console.error("API error - Status Code: " + res.status + "\t Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getComments = async (
    organisationId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment[]>> => {
    let res = await this.http.get(this.url + impactGroupId + "/comments", config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment[]>;
    }
    console.error("API error - Status Code: " + res.status + "\t Url: " + this.url + impactGroupId + "/comments");
    console.error("Status: " + res.statusText);
    return null;
  };

  addComment = async (
    organisationId: number,
    impactGroupId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> => {
    let res = await this.http.post(this.url + impactGroupId + "/comments", comment, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment>;
    }
    console.error("API error - Status Code: " + res.status + "\t Url: " + impactGroupId + "/comments");
    console.error("Status: " + res.statusText);
    return null;
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<boolean> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/${impactGroupId}/impacts/${impactId}`,
      config
    );
    if (res && res.status === 200) return true;

    console.error("API error - Status Code: " + res.status + "\t Url: " + impactGroupId + "/delete" + impactId);
    console.error("Status: " + res.statusText);
    return null;
  };

  alreadyExists = async (
    organisationId: number,
    projectId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/already-exists/`,
      { name: name },
      config
    );
    if (res && res.status === 200) return res.data as IFlightPathApiResponse<boolean>;

    console.error("API error - Status Code: " + res.status + "\t Url: /impact-groups/already-exists" + name);
    console.error("Status: " + res.statusText);
    return null;
  };

  async getFilterList(
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/filter-list`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpactGroup[]>;
    }
  }
}
