import { ColDef } from "ag-grid-community";
import moment from "moment";
import { baseDateColumn, baseSelectionColumn } from "../../../../../../../core/grids/BaseColumns";
import {
  baseTextColumn,
  IBaseColumnProps,
} from "../../../../../../../core/grids/BaseColumns";
import { Tooltip } from "../../../../../../../components/ui/Tooltip";
import React from "react";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Icon, IconSymbols } from "../../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../../components/ui/Panel";
import I18n from "../../../../../../../core/localization/I18n";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { StakeholderField } from "../../../../../../../enums";

export const colSelection = (): ColDef => {
  return {
    ...baseSelectionColumn(),
    checkboxSelection: (props) => {
      return (isUploaded(props.data) && props.data.emailAddressValid);
    }
  };
};

export const isUploaded = (data): boolean =>{
  return (data.uploadedOn === undefined || data.uploadedOn === null);
}

export const colFirstName = (saveFn: (id: number, value: string,fieldId: number, fileId: number, showUpdatedRows: boolean)=> void, props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "firstName",
      heading: "phrases.organisationStakeholders_upload_firstName",
      editable: true,
      ...props,
    }),
    valueSetter: (params) => {
      if(!params.newValue || !saveFn) return false;

      saveFn(
        params.data["id"],
        params.newValue,
        StakeholderField.firstName,
        params.data["fileId"],
        params.data["showUploadedRows"]);

      return true;
    }
  };
};

export const colLastName = (saveFn: (id: number, value: string,fieldId: number, fileId: number, showUpdatedRows: boolean)=> void, props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "lastName",
      heading: "phrases.organisationStakeholders_upload_lastName",
      editable: true,
      ...props,
    }),
    valueSetter: (params) => {
      if(!params.newValue || !saveFn) return false;

      saveFn(
        params.data["id"],
        params.newValue,
        StakeholderField.lastName,
        params.data["fileId"],
        params.data["showUploadedRows"]);

      return true;
    }
  };
};

export const colEmailAddress = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "email",
      heading: "phrases.organisationStakeholders_upload_emailAddress",
      editable: false,
      width: 400,
      ...props,
    }),
  };
};

export const colBusinessArea = (saveFn: (id: number, value: string,fieldId: number, fileId: number, showUpdatedRows: boolean)=> void, props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "businessArea",
      heading: "phrases.organisationStakeholders_businessAreas",
      editable: true,
      width: 400,
      ...props,
    }),
    valueSetter: (params) => {
      if(!params.newValue || !saveFn) return false;

      saveFn(
        params.data["id"],
        params.newValue,
        StakeholderField.businessArea,
        params.data["fileId"],
        params.data["showUploadedRows"]);

      return true;
    }
  };
};

export const colRole = (saveFn: (id: number, value: string,fieldId: number, fileId: number, showUpdatedRows: boolean)=> void, props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "role",
      heading: "phrases.organisationStakeholders_role",
      editable: true,
      width: 400,
      ...props,
    }),
    valueSetter: (params) => {
      if(!params.newValue || !saveFn) return false;

      saveFn(
        params.data["id"],
        params.newValue,
        StakeholderField.role,
        params.data["fileId"],
        params.data["showUploadedRows"]);

      return true;
    }
  };
};

export const colUploadedOn = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseDateColumn({
      fieldName: "uploadedOn",
      heading: "pulse.organisationRespondents_uploadedOn",
      editable: false,
      width: 200,
      ...props,
    }),
    valueGetter: (columnProps) =>
    columnProps.data["uploadedOn"]
      ? moment(columnProps.data["uploadedOn"]).format("DD/MM/YYYY HH:mm")
      : "",
  };
};

export const colUploadedBy = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "uploadedBy",
      heading: "pulse.organisationRespondents_uploadedBy",
      editable: false,
      width: 200,
      ...props,
    }),
  };
};

export const colExistingStakeholder = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "isExistingStakeholder",
      heading: "",
      editable: false,
      width: 55,
      ...props,
    }),
    cellStyle:params => {
      return {textAlign: "center", overflow: "visible"};
    },
    cellRenderer: params => {
      let isUploaded: boolean = params.data.uploadedOn !== undefined && params.data.uploadedOn !== null;
      let isExistingStakeholder: boolean = params.data.isExistingStakeholder && isUploaded === false;
      let businessAreaExists: boolean = params.data.businessAreaExists;
      let roleExists: boolean = params.data.roleExists;
      let isEmailAddressValid: boolean = params.data.emailAddressValid;

      let shouldShowWarning: boolean = isExistingStakeholder || businessAreaExists === false || roleExists == false || isEmailAddressValid === false;
      let warningMessage: string = "";

      if(isEmailAddressValid === false)
        warningMessage += I18n.t("phrases.organisationStakeholder_upload_emailAddressInvalid");

      if(isExistingStakeholder)
        warningMessage += I18n.t("phrases.organisationStakeholder_upload_existingStakeholderWarning");

      if(businessAreaExists === false)
        warningMessage += I18n.t("phrases.organisationStakeholder_upload_missingBusinessAreaWarning");

      if(roleExists === false)
        warningMessage += I18n.t("phrases.organisationStakeholder_upload_missingRoleWarning");

      return (
        shouldShowWarning ? (
          <Tooltip
          position={TooltipPositions.RIGHT}
          shownElement={<Icon symbol={IconSymbols.Warning} className="mt-2" size={UiSizes.MD} />}
          triggeredOn="hover"
        >
          <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} hasShadow={true} className="pl-2 pr-2">
            {warningMessage}
          </Panel.Panel>
        </Tooltip>)
        :
        (<></>)
        )} 
    }
  };