import React, { useCallback, useEffect, useState } from "react";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { TreeEditor } from "../../../../../components/widgets/treeEditor/TreeEditor_view";
import { Input } from "../../../../../components/ui/_forms/Input";
import { Observer, observer } from "mobx-react-lite";
import { Enums } from "../../../../../enums";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { Async } from "react-async";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { ProcessSettingsModel } from "./Processes_model";

export interface ProcessProps {
  model?: ProcessSettingsModel;
}

const ProcessSettings: React.FunctionComponent<ProcessProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  // const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => m || new ProcessSettingsModel(appService, organisation));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const load = useCallback(async () => {
    await model.loadProcesses();
  }, [model]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <Observer>
          {() => (
            <div
              className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}
              style={{ height: "100%", minHeight: "100vh" }}
            >
              <div className="d-flex flex-column" style={{ height: "100%", padding: "0 16px" }}>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <h1>
                      {model.organisation.name} | {I18n.t("phrases.manageProcesses")}
                    </h1>
                    <p>{I18n.t("phrases.manageProcessesDescription")}</p>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-end">
                      <LinkButton
                        type={ButtonTypes.OUTLINE_PRIMARY}
                        href={`/organisations/${model.organisation.id}`}
                        className="float-right"
                      >
                        {I18n.t("phrases.closeSettings")}
                      </LinkButton>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <div style={{ flex: "1" }}>
                        <Input
                          onChange={model.performTreeSearch}
                          icon={IconSymbols.Search}
                          iconSize={Enums.UiSizes.SM}
                          placeholder={I18n.t("placeholders.searchProcess")}
                        />
                      </div>
                      <ButtonIcon
                        id={`Add${model.id}Button`}
                        size={Enums.UiSizes.MD}
                        type={ButtonTypes.OUTLINE_PRIMARY}
                        className="ml-2"
                        title={I18n.t("phrases.add")}
                        onClick={model.addParentTreeNode}
                        symbol={IconSymbols.Plus}
                      />
                      <ButtonIcon
                        size={Enums.UiSizes.MD}
                        type={ButtonTypes.OUTLINE_PRIMARY}
                        className="ml-2"
                        title={I18n.t("phrases.expandAll")}
                        onClick={model.expandAll}
                        symbol={IconSymbols.Maximize2}
                      />
                      <ButtonIcon
                        type={ButtonTypes.OUTLINE_PRIMARY}
                        size={Enums.UiSizes.MD}
                        className="ml-2"
                        title={I18n.t("phrases.collapseAll")}
                        onClick={model.collapseAll}
                        symbol={IconSymbols.Minimize2}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4" style={{ flex: 1 }}>
                  <TreeEditor key="process" model={model.treeEditorModel} />
                </div>
              </div>
            </div>
          )}
        </Observer>
      </Async.Resolved>
    </Async>
  );
});

export { ProcessSettings };
