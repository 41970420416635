import React from "react";
import { Link } from "react-router-dom";
import I18n from "../../../../../core/localization/I18n";
import { StakeholderProfilingType, StakeholderType } from "../../../../../enums";

export const StakeholderTypeLinks: React.FC<{
  baseUrl: string;
  stakeholderType: StakeholderType;
  profileType: StakeholderProfilingType;
}> = ({ baseUrl, stakeholderType, profileType }) => {
  return (
    <>
      <Link
        to={`${baseUrl}/${StakeholderType.INDIVIDUAL}/${profileType}`}
        className={stakeholderType === StakeholderType.INDIVIDUAL ? "stakeholder-reporting__toggle--active" : ""}
      >
        {I18n.t("entities.stakeholders")}
      </Link>
      <span> | </span>
      <Link
        to={`${baseUrl}/${StakeholderType.AUDIENCE}/${profileType}`}
        className={stakeholderType === StakeholderType.AUDIENCE ? "stakeholder-reporting__toggle--active" : ""}
      >
        {I18n.t("entities.audiences")}
      </Link>
    </>
  );
};
export const StakeholderProfileLinks: React.FC<{
  baseUrl: string;
  stakeholderType: StakeholderType;
  profileType: StakeholderProfilingType;
}> = ({ baseUrl, stakeholderType, profileType }) => {
  return (
    <>
      <Link
        to={`${baseUrl}/${stakeholderType}/0`}
        className={(profileType as any) === 0 ? "stakeholder-reporting__toggle--active" : ""}
      >
        {I18n.t("phrases.stakeholderAllLabel")}
      </Link>
      <span> | </span>
      <Link
        to={`${baseUrl}/${stakeholderType}/${StakeholderProfilingType.Commitment}`}
        className={profileType === StakeholderProfilingType.Commitment ? "stakeholder-reporting__toggle--active" : ""}
      >
        {I18n.t("phrases.commitment")}
      </Link>
      <span> | </span>
      <Link
        to={`${baseUrl}/${stakeholderType}/${StakeholderProfilingType.Sentiment}`}
        className={profileType === StakeholderProfilingType.Sentiment ? "stakeholder-reporting__toggle--active" : ""}
      >
        {I18n.t("phrases.sentiment")}
      </Link>
      <span> | </span>
      <Link
        to={`${baseUrl}/${stakeholderType}/${StakeholderProfilingType.Receptiveness}`}
        className={
          profileType === StakeholderProfilingType.Receptiveness ? "stakeholder-reporting__toggle--active" : ""
        }
      >
        {I18n.t("phrases.receptiveness")}
      </Link>
    </>
  );
};
