import * as React from "react";
import { AppService } from "strikejs-app-service";
import { BaseModel } from "../../../core/util/BaseModel";
import { observable, action, computed } from "mobx";
import { IUsersApi } from "../../../services/api/v1/users/IUsers.api";
import { Services, DEBOUNCE_DELAY } from "../../../constants";
import { IModalService } from "../../../core/modal/IModalService";
import _ from "lodash";
import { ISearchApi } from "../../../services/api/v1/search/ISearch.api";

export enum ResultType {
  user = "user",
  programme = "programme",
  project = "project",
  organisation = "organisation",
  action = "action",
  impact = "impact",
  impact_group = "impact_group",
  stakeholder = "stakeholder"
}

export interface ISearchFacetOption {
  id: number;
  label: string;
  imageUrl: string;
}

export interface IFilterValue extends Omit<ISearchFacetOption, "imageUrl"> {
  resultType: ResultType;
}

export interface ISearchFacet {
  key: string;
  label: string;
  type: ResultType;
  options: ISearchFacetOption[];
}

export interface ISearchResultItem {
  id: number;
  name: string;
  parentId: number | null;
  resultType: ResultType;
  imageUrl: string | null;
  organisationId: number;
}

export class GlobalSearchModel extends BaseModel {
  appService: AppService;
  modalService: IModalService;
  userProvider: IUsersApi;

  @observable searchInputValue: string = "";
  @observable isSearching: boolean = false;
  @observable searchCountKey = 0;
  @observable.ref users: FP.Entities.IUser[] = [];
  @observable.ref filterValues: IFilterValue[] = [];
  @observable.ref results: ISearchResultItem[] = [];
  @observable.ref facets: ISearchFacet[] = [];
  searchProvider: ISearchApi;

  @computed get organisations(): ISearchResultItem[] {
    return this.results.filter(o => o.resultType === ResultType.organisation);
  }

  @computed get actions(): ISearchResultItem[] {
    return this.results.filter(o => o.resultType === ResultType.action);
  }

  @computed get impacts(): ISearchResultItem[] {
    return this.results.filter(o => o.resultType === ResultType.impact);
  }

  @computed get projects(): ISearchResultItem[] {
    return this.results.filter(o => o.resultType === ResultType.project);
  }

  @computed get programmes(): ISearchResultItem[] {
    return this.results.filter(o => o.resultType === ResultType.programme);
  }

  @computed get impactGroups() {
    return this.results.filter(e => e.resultType === ResultType.impact_group);
  }

  @computed get stakeholders() {
    return this.results.filter(e => e.resultType === ResultType.stakeholder);
  }

  @computed get organisationsFilter() {
    return (this.facets.find(e => e.type === ResultType.organisation) as ISearchFacet) || null;
  }

  @computed get projectsFilter() {
    return (this.facets.find(e => e.type === ResultType.project) as ISearchFacet) || null;
  }

  @computed get programmesFilter() {
    return (this.facets.find(e => e.type === ResultType.programme) as ISearchFacet) || null;
  }

  constructor(appService: AppService) {
    super();
    this.appService = appService;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.userProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.searchProvider = this.appService.getService<ISearchApi>(Services.SearchApi);
  }

  toggleFilterValue = (val: IFilterValue) => {
    const filterValues = this.filterValues.slice(0);
    let itemIndex = filterValues.findIndex(e => `${e.id}_${e.resultType}` === `${val.id}_${val.resultType}`);
    if (itemIndex !== -1) {
      filterValues.splice(itemIndex, 1);
    } else {
      filterValues.push(val);
    }
    this.filterValues = filterValues;
  };

  @action
  handleSearchInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchInputValue = e.currentTarget.value;
    this.debouncedHandleSearch();
  };

  debouncedHandleSearch = _.debounce(() => {
    this.searchCountKey++;
  }, DEBOUNCE_DELAY.NORMAL);

  @action.bound
  async loadFilters() {
    const { payload } = await this.searchProvider.getFacets();
    this.facets = payload;
    return payload as ISearchFacet[];
  }

  @action.bound
  async handleSearch() {
    const data = {
      query: this.searchInputValue,
      filters: this.filterValues.map(fv => ({
        id: fv.id,
        resultType: fv.resultType
      }))
    };

    const result = await this.searchProvider.globalSearch(data);
    this.results = result.payload;
  }

  closeModal = () => {
    this.resetSearch();
    this.modalService.hide();
  };

  @action
  resetSearchResults = () => {
    this.users = [];
  };

  @action
  resetSearch = () => {
    this.searchInputValue = "";
    this.resetSearchResults();
  };
}
