import React, { Dispatch } from 'react'
import { ButtonTypes } from '../../../../../../../../components/ui/Button';
import I18n from '../../../../../../../../core/localization/I18n';
import { IUiAction, UiActionRenderers } from '../../../../../../../../core/uiAction/IUiAction';
import { IActionsApi } from '../../../../../../../../services/api/v1/actions/IActions.api';
import { SingleFormModel } from '../../../../../../../change/forms/singleFormModel/SingleForm_model';
import { getActionImpactFormFields } from './ActionImpactsSidebar_formFields';

interface AddImpactFormProps {
    actionsProvider: IActionsApi;
    organisationId: number;
    action: FP.Entities.IAction;
    reloadFn: (id: number) => void;
    setFormIsShown?: Dispatch<boolean>;
}

export const AddImpactForm: React.FC<AddImpactFormProps> = (props) => {
    const { actionsProvider, action, organisationId, reloadFn, setFormIsShown } = props;
    let formFields = getActionImpactFormFields(actionsProvider, organisationId, action);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
        {
            id: "action1",
            label: I18n.t("phrases.cancel"),
            onAction: ev => {
                setFormIsShown(false);
            },
            componentProps: {
                type: ButtonTypes.LINK,
                className: "ml-auto"
            },
            rendersIn: UiActionRenderers.BUTTON
        },
        {
            id: "action2",
            label: I18n.t("phrases.add"),
            onAction: async () => {
                const formRes = await formModel.submit();
                if (!formRes) return;
                const res = await actionsProvider.addImpactsAsync(
                    organisationId,
                    action.projectId,
                    action.id,
                    formRes.impacts
                );

                if (!res || res.isError) return;

                if (res.payload) {
                    reloadFn(action.id);
                }
            },
            componentProps: {
                className: "ml-2"
            },
            rendersIn: UiActionRenderers.BUTTON
        }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return <div className="container-fluid">{formModel.renderComponent()}</div>
}