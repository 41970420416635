import _ from "lodash";
import { action, observable } from "mobx";
import moment from "moment";
import React from "react";
import { Tooltip } from "react-tippy";
import { AppService } from "strikejs-app-service";
import {
  ITimelineGroup,
  ITimelineItem,
  ITimelineMarker,
  TimeLineModel
} from "../../../../../components/widgets/timeline";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";
import { TimelineItem } from "./components/TimelineItem";

export class DashboardTimelineModel {
  timelineModel: TimeLineModel;
  organisationId: number;
  projectsProvider: IProjectsApi;
  @observable.ref projects = [];

  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    this.organisationId = organisationId;
    this.projectsProvider = appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.timelineModel = new TimeLineModel();
  }

  onMount = async () => {
    await this.loadOrganisation();
  };

  loadOrganisation = async () => {
    const result = await this.projectsProvider.getFiltered(this.organisationId, { page: 1, pageSize: 1000000 });
    if (!result || result.isError) return;
    this.setProjects(result.payload);
  };

  @action
  setProjects = (projects: FP.Entities.IProject[]) => {
    this.projects = projects;
    this.setTimelineDefaultDates(this.projects);
    this.setTimeLineItems(this.projects);
  };

  setTimelineDefaultDates = (projects: FP.Entities.IProject[]) => {
    const proj = projects.map(e => {
      return {
        ...e,
        mStartDate: moment(e.startDate),
        mEndDate: moment(e.actualEndDate)
      };
    });
    const minDate = _.minBy(proj, e => e.mStartDate);
    const maxDate = _.maxBy(proj, e => e.mEndDate);
    const startDate = minDate.mStartDate;
    const endDate = maxDate.mEndDate;
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate.subtract(10, "days"), endDate.add(10, "days"));
  };

  setTimeLineItems = (projects: FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = projects.map(project => {
      return {
        id: project.id,
        group: project.id,
        title: project.name,
        start_time: moment(project.startDate),
        end_time: moment(project.actualEndDate),
        data: project
      };
    });

    let groups: ITimelineGroup[] = projects.map(project => {
      return {
        id: project.id,
        start_time_v: moment(project.startDate).valueOf(),
        title:
          project.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{project.name}</small>}>
              {project.name}
            </Tooltip>
          ) : (
            project.name
          )
      };
    });

    groups = _.sortBy(groups, (e: any) => e.start_time_v);

    let milestones = [];
    const markers: ITimelineMarker[] = milestones.map(milestone => {
      return {
        id: milestone.id,
        date: moment(milestone.deadline),
        title: milestone.name
      };
    });

    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setSidebarWidth(350);
    this.timelineModel.setSideheaderTitle(<strong className="pl-3 pb-1">{I18n.t("entities.projects")}</strong>);
    this.timelineModel.setMarkers(markers);
    // this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = TimelineItem(this.organisationId);
    this.timelineModel.isLoading = false;
  };
}
