import { action, observable, reaction } from "mobx";
import { IFormFieldModel, IFormAction, IFormFieldSaveDelegate } from "./IFormField";
import { FormFieldType } from "./FormFieldTypes";
import { INIT_FORM_FIELD } from "./FormField_init";
import { DisposableModel } from "../../util/DisposableModel";

/**
 * An abstract class that should be implemented by any
 * form control class. Manages the state of the current
 * value, an has methods to be run accross all Form control
 * components
 */
export abstract class FormFieldModel<T, ComponentProps>
  extends DisposableModel
  implements IFormFieldModel<T, ComponentProps> {
  value: T;
  key: string;
  type: FormFieldType;
  action?: IFormAction;
  saveDelegate: IFormFieldSaveDelegate;
  defaultLabel?: string;
  config: any;
  subscribeTo: string[];
  subscribers: FormFieldModel<T, ComponentProps>[] = [];
  hintLabel?: React.ReactNode = null;
  onChannelFieldChanged: (field: IFormFieldModel<any, any>) => void;
  channels: Dictionary<IFormFieldModel<any, any>> = {};
  @observable pending = 0;
  @observable disabled: boolean = false;
  @observable label: string = "";
  @observable className?: string = "";
  @observable isHidden: boolean = false;
  isLabelHidden: boolean = false;
  fieldClassName: string = "";
  validateOnChange: boolean = false;
  defaultValue: T = null;
  @observable isValid?: boolean = true;
  @observable errorMessage: React.ReactNode = null;
  @observable hasChanges: boolean = false;
  onValueChange?: (value: T, model: IFormFieldModel<T, ComponentProps>) => void = null;
  componentProps: ComponentProps;
  tooltipLabel?: React.ReactNode = null;
  manageLink?: string = "";
  lowerTooltipLabel?: string;
  lowerTooltipContent?: React.ReactNode;
  testId?: string;
  ref?: any;

  constructor(initOpts?: IFormFieldModel<T, ComponentProps>) {
    super();
    this.setConfig(initOpts).then(e => {
      this.installReactions();
    });
  }

  /**
   * Set config is executed with promise. Because installReaction would run asynchronously
   * causing the onValueChange callback to be called multiple times in the initial load
   */
  setConfig = async (initOpts?: IFormFieldModel<T, ComponentProps>): Promise<any> => {
    return new Promise(res => {
      if (initOpts) {
        this.key = initOpts.key || INIT_FORM_FIELD.key;
        this.label = (initOpts.label || INIT_FORM_FIELD.label) as any;
        this.config = initOpts.config || INIT_FORM_FIELD.config;
        this.className = initOpts.className || INIT_FORM_FIELD.className;
        this.errorMessage = initOpts.errorMessage || INIT_FORM_FIELD.errorMessage;
        this.action = initOpts.action || INIT_FORM_FIELD.action;
        this.disabled = initOpts.disabled || INIT_FORM_FIELD.disabled;
        this.value = initOpts.value || null;
        this.isHidden = initOpts.isHidden || INIT_FORM_FIELD.isHidden;
        this.isLabelHidden = initOpts.isLabelHidden || INIT_FORM_FIELD.isLabelHidden;
        this.isValid = initOpts.isValid || INIT_FORM_FIELD.isValid;
        this.validateOnChange = initOpts.validateOnChange || INIT_FORM_FIELD.validateOnChange;
        this.subscribeTo = initOpts.subscribeTo || INIT_FORM_FIELD.subscribeTo;
        this.subscribers = [];
        this.hintLabel = initOpts.hintLabel || this.hintLabel;
        this.onChannelFieldChanged = initOpts.onChannelFieldChanged || null;
        this.onValueChange = initOpts.onValueChange || null;
        this.componentProps = initOpts.componentProps || null;
        this.fieldClassName = initOpts.fieldClassName || this.fieldClassName;
        this.tooltipLabel = initOpts.tooltipLabel || this.tooltipLabel;
        this.manageLink = initOpts.manageLink || this.manageLink;
        this.defaultValue = initOpts.defaultValue || this.defaultValue;
        this.lowerTooltipLabel = initOpts.lowerTooltipLabel || this.lowerTooltipLabel;
        this.lowerTooltipContent = initOpts.lowerTooltipContent || this.lowerTooltipContent;
        this.testId = initOpts.testId || null;
        res(true);
      }
    });
  };

  @action
  clearChanges = () => {
    this.hasChanges = false;
  };

  @action
  setHasChanges = (bool: boolean) => {
    this.hasChanges = bool;
  };

  @action
  removeErrorMessage = () => this.errorMessage = null;

  @action.bound
  saveChanges(): void {
    this.hasChanges = false;
    if (this.saveDelegate) {
      this.pending++;
      this.saveDelegate.onSaveField(this.key, this.extractValue()).then(
        action("saved", () => {
          this.pending--;
          this.hasChanges = false;
        }),
        action("nosave", () => {
          this.pending--;
          this.hasChanges = true;
        })
      );
    }
  }

  /**
   * Adds subscribers to this FormField.
   * So later they can be activated
   * @param subs
   */
  registerSubscribers(subs: FormFieldModel<any, any>) {
    this.subscribers.push(subs);
  }

  /**
   * Loops through the list of subscribers and runs
   * the mobx reaction function which trigers an
   * ```onChannelFieldChange``` function everytime
   * the value is of the channel FormFieldModel is changed.
   */
  activateSubscribers = (): void => {
    if (this.subscribers && this.subscribers.length > 0) {
      this.subscribers.forEach(subscriber => {
        if (subscriber.onChannelFieldChanged) {
          const d = reaction(
            () => {
              return this.value;
            },
            (e, reaction) => {
              subscriber.onChannelFieldChanged(this);
            }
          );
          this.addDisposer(d);
        }
      });
    }
  };

  installReactions = () => {
    const d = reaction(
      () => {
        return this.value;
      },
      (e, reaction) => {
        this.setHasChanges(true);
        if (this.onValueChange) {
          this.onValueChange(e, this);
        }
      }, {
      onError: (e) => console.error("Reaction error (FormField_model)", e)
    }
    );
    this.addDisposer(d);
  };

  destroyModel = () => {
    this.dispose();
  };

  abstract validate: (...args: any[]) => any;

  abstract setValue(val: T): void;

  abstract setFieldValue(val: any): void;

  abstract extractValue(): any;

  abstract reset(): void;

  abstract renderComponent: () => React.ReactNode;
}
