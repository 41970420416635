import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CanViewOrgRoute, CanContributeOrgRoute } from "../../core/router/Route";
import { ContainerProps } from ".";
import { SettingsView } from "../../pages/change/organisations/settingsView/SettingsView_view";
import { SettingsView as CustomPropertySettingsView } from "../../pages/change/organisations/customPropertiesSettings/SettingsView_view";
import { SettingsViewModel as CustomPropertySettingsModel } from "../../pages/change/organisations/customPropertiesSettings/SettingsView_model";
import { SettingsViewModel } from "../../pages/change/organisations/settingsView/SettingsView_model";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { StakeholderView } from "../../pages/change/stakeholders/view/View_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { NoOrgAccess } from "../../pages/change/notfound/access/NoOrgAccess_view";
import {
  SetCurrentOrganisationFromRoute,
  useCurrentOrganisationId,
  useOrganisationContext
} from "../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { useRefreshClaims } from "../../core/auth/authorise";
import { ProgrammesContainer } from "./ProgrammesContainer";
import { ProjectsContainer } from "./ProjectsContainer";
import { StakeholdersContainer } from "./StakeholdersContainer";
import { UsersContainer } from "./UsersContainer";
import { NavigationSecondaryBottomContent } from "../../components/widgets/navigation/Navigation_view";
import { CurrentNavigation } from "../../pages/change/navigation/contentNav/nav";
import { CreateCustomProperty } from "../../pages/change/organisations/createCustomProperty/CreateCustomProperty_view";
import { useIsOrganisationAdmin } from "../../contexts/permissions/PermissionHooks";
import { ShowIfOrganisationAdmin } from "../../contexts/permissions/PermissionHelpers";
import { DashboardSunburstContent } from "../../pages/insight/organisations/dashboard/Sunburst/Sunburst_view";
import { DashboardTimeline } from "../../pages/insight/organisations/dashboard/Timeline/DashboardTimeline_view";
import { Heatmap } from "../../pages/insight/organisations/dashboard/Heatmap/Heatmap_view";

export interface IOrganisationsContainer extends ContainerProps {}

export const OrganisationsContainer: React.FunctionComponent<IOrganisationsContainer> = ({ appService }) => {
  const refreshClaims = useRefreshClaims();
  const organisationId = useCurrentOrganisationId();
  const isAdmin = useIsOrganisationAdmin(organisationId);
  return (
    <>
      <Switch>
        <Route path="/organisations/no-access">
          <>
            <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
            <NoOrgAccess />
          </>
        </Route>
        <Route path="/organisations/:organisationId">
          <NavigationSecondaryBottomContent>
            <Route path="/organisations/:organisationId">
              <CurrentNavigation />
            </Route>
          </NavigationSecondaryBottomContent>
          <SetCurrentOrganisationFromRoute />
          <OrganisationSelector>
            <Switch>
              <Route path="/organisations/:organisationId/programmes">
                <ProgrammesContainer appService={appService} />
              </Route>
              <Route path="/organisations/:organisationId/projects">
                <ProjectsContainer appService={appService} />
              </Route>
              <Route path="/organisations/:organisationId/stakeholders">
                <StakeholdersContainer appService={appService} />
              </Route>
              <Route path="/organisations/:organisationId/users">
                <UsersContainer appService={appService} />
              </Route>
              <Route
                path="/organisations/:organisationId/settings/customProperties/create"
                render={props => (
                  <ShowIfOrganisationAdmin>
                    <PageTitle title={`${I18n.t(`phrases.customProperty`)} | ${I18n.t(`phrases.settings`)}`} />
                    <CreateCustomProperty
                      key={`${props.match.params["organisationId"]}-${props.match.params["page"]}`}
                    />
                  </ShowIfOrganisationAdmin>
                )}
              />
              <Route
                path="/organisations/:organisationId/settings/customProperties/:customPropertyId/:key"
                render={props => (
                  <ShowIfOrganisationAdmin>
                    <PageTitle title={`${I18n.t(`phrases.customProperty`)} | ${I18n.t(`phrases.settings`)}`} />
                    <CustomPropertySettingsView
                      key={`${props.match.params["organisationId"]}-${props.match.params["page"]}`}
                      model={
                        new CustomPropertySettingsModel(
                          appService,
                          +props.match.params["organisationId"],
                          props,
                          refreshClaims,
                          isAdmin,
                          parseInt(props.match.params["customPropertyId"])
                        )
                      }
                    />
                  </ShowIfOrganisationAdmin>
                )}
              />
              <Route
                path="/organisations/:organisationId/settings/customProperties/:customPropertyId"
                render={props => (
                  <ShowIfOrganisationAdmin>
                    <PageTitle title={`${I18n.t(`phrases.customProperty`)} | ${I18n.t(`phrases.settings`)}`} />
                    <CustomPropertySettingsView
                      key={`${props.match.params["organisationId"]}-${props.match.params["page"]}`}
                      model={
                        new CustomPropertySettingsModel(
                          appService,
                          +props.match.params["organisationId"],
                          props,
                          refreshClaims,
                          isAdmin,
                          parseInt(props.match.params["customPropertyId"])
                        )
                      }
                    />
                  </ShowIfOrganisationAdmin>
                )}
              />
              <Route
                path="/organisations/:organisationId/settings/:page"
                render={props => (
                  <CanContributeOrgRoute>
                    <PageTitle title={`${I18n.t(`phrases.organisation`)} | ${I18n.t(`phrases.settings`)}`} />
                    <SettingsView
                      key={`${props.match.params["organisationId"]}-${props.match.params["page"]}`}
                      model={
                        new SettingsViewModel(
                          appService,
                          +props.match.params["organisationId"],
                          props,
                          refreshClaims,
                          isAdmin
                        )
                      }
                    />
                  </CanContributeOrgRoute>
                )}
              />
              <Route
                path="/organisations/:organisationId/settings"
                render={props => (
                  <CanContributeOrgRoute>
                    <PageTitle title={`${I18n.t(`phrases.organisation`)} | ${I18n.t(`phrases.settings`)} `} />
                    <SettingsView
                      key={props.match.params["organisationId"]}
                      model={
                        new SettingsViewModel(
                          appService,
                          +props.match.params["organisationId"],
                          props,
                          refreshClaims,
                          isAdmin
                        )
                      }
                    />
                  </CanContributeOrgRoute>
                )}
              />
              <Route path="/organisations/:organisationId/stakeholders/:stakeholderId">
                <CanViewOrgRoute>
                  <PageTitle title={`${I18n.t(`phrases.organisation`)} | ${I18n.t(`phrases.stakeholder`)}`} />
                  <StakeholderView />
                </CanViewOrgRoute>
              </Route>

              <Route path="/organisations/:organisationId/visualisations/sunburst">
                <CanViewOrgRoute>
                  <PageTitle title={I18n.t(`phrases.organisation`)} />
                  <DashboardSunburstContent />
                </CanViewOrgRoute>
              </Route>

              <Route path="/organisations/:organisationId/visualisations/timeline">
                <CanViewOrgRoute>
                  <PageTitle title={I18n.t(`phrases.organisation`)} />
                  <DashboardTimeline />
                </CanViewOrgRoute>
              </Route>

              <Route path="/organisations/:organisationId/visualisations/heatmap/:section">
                <CanViewOrgRoute>
                  <PageTitle title={I18n.t(`phrases.organisation`)} />
                  <Heatmap />
                </CanViewOrgRoute>
              </Route>

              <Route
                path="/organisations/:organisationId"
                render={props => {
                  const orgId = props.match.params.organisationId;
                  return (
                    <CanViewOrgRoute>
                      {/* redirect to route above as organisation default to business heatmap */}
                      <Redirect to={`/organisations/${orgId}/visualisations/heatmap/projects`} />
                    </CanViewOrgRoute>
                  );
                }}
              ></Route>
            </Switch>
          </OrganisationSelector>
        </Route>
        <Route path="*">
          <>
            <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
            <NotFoundIndex />
          </>
        </Route>
      </Switch>
    </>
  );
};

const OrganisationSelector: React.FC = ({ children }) => {
  const organisationContext = useOrganisationContext();
  const { organisationId } = useParams<{ organisationId: string }>();
  useEffect(() => {
    organisationContext.setCurrentOrganisationId(+organisationId);
  }, [organisationId, organisationContext]);

  return children as any;
};
