import * as React from "react";
import { IToasterModel, ToasterModel } from "./Toaster_model";
import { observable, action } from "mobx";
import { ToasterView } from "./Toaster_view";
import { observer } from "mobx-react";
import _ from "lodash";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import I18n from "../localization/I18n";

export interface IToasterService {
  position: "left" | "right";
  toasts: IToasterModel[];
  newToast: () => IToasterModel;
  removeToast: (toast: IToasterModel) => void;
  showErrorToast: (durations?: number, position?: "left" | "right") => IToasterModel;
  showSuccessToast: (duration?: number, position?: "left" | "right", hideClose?: boolean) => IToasterModel;
  showReviewToast: (duration?: number) => IToasterModel;
}

export class ToasterService implements IToasterService {
  @observable toasts: IToasterModel[] = [];
  @observable position: "left" | "right" = "right";

  @action
  newToast = () => {
    let s = new ToasterModel({
      id: Date.now(),
      onDestroy: this.removeToast
    });
    this.toasts.push(s);
    return s;
  };

  @action
  removeToast = (toast: IToasterModel) => {
    _.remove(this.toasts, e => e.config.id === toast.config.id);
    this.position = "right";
  };

  showErrorToast = (duration?: number, position?: "left" | "right"): IToasterModel => {
    this.position = position;
    let s = this.newToast().setPanelProps({
      type: PanelTypes.BORDER_TOP_DANGER
    });
    if (duration) {
      s.startTimer(duration);
    }

    return s;
  };

  showSuccessToast = (duration?: number, position?: "left" | "right", hideClose?: boolean): IToasterModel => {
    this.position = position;
    let s = this.newToast().setPanelProps({
      type: PanelTypes.BORDER_TOP_SECONDARY
    });

    if (duration) {
      s.startTimer(duration);
    }

    if(hideClose){
      s.actions = [];
    }

    return s;
  };

  showReviewToast = (duration?: number): IToasterModel => {
    let s = this.newToast()
      .setPanelProps({
        type: PanelTypes.BORDER_TOP_SUCCESS
      })
      .setActionAlignedTitle(<h3 className="toaster__success-review-title">{I18n.t("phrases.reviewCompleted")}</h3>);

    if (duration) {
      s.startTimer(duration);
    }
    return s;
  };
}

interface TosterServiceViewProps {
  toastService: IToasterService;
  toasts: IToasterModel[];
}

export const ToasterServiceView: React.FunctionComponent<TosterServiceViewProps> = observer(props => {
  let { toasts } = props;
  return (
    <div className={`toaster-service toaster-service--${props.toastService.position}`}>
      {toasts.map((e, i) => (
        <ToasterView model={e} key={i} />
      ))}
    </div>
  );
});
