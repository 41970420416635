import { action, observable } from "mobx";
import * as React from "react";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../constants";
import { IHttpProgressModel } from "../../../../../../../core/httpProgress/HttpProgress_model";
import { IModalService } from "../../../../../../../core/modal/IModalService";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { IProjectStakeholdersApi } from "../../../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";

export class StakeholderImpactsSidebarModel extends BaseModel {
  appService: AppService;
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalService;
  httpProgress: IHttpProgressModel;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable stakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref stakeholderImpacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredStakeholderImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;
  stakeholderId: number;

  constructor(appService: AppService, stakeholderId: number, organisationId: number, projectId: number) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationId = organisationId;
    this.stakeholderId = stakeholderId;
  }

  onMount = async () => {
    await this.loadStakeholder(this.stakeholderId);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  setStakeholder = (stakeholder: FP.Entities.IProjectStakeholder) => {
    this.stakeholder = stakeholder;
  };

  @action
  setStakeholderImpacts = (impacts: FP.Entities.IImpact[]) => {
    this.stakeholderImpacts = impacts as FP.Entities.IImpact[];
    this.filteredStakeholderImpacts = impacts as FP.Entities.IImpact[];
    this.isLoading = false;
  };

  @action
  loadStakeholder = async (stakeholderId: number) => {
    this.isLoading = true;
    let res = await this.projectStakeholderProvider.getStakeholderById(
      this.organisationId,
      this.projectId,
      stakeholderId
    );
    if (!res || res.isError) return;
    let impactsRes = await this.projectStakeholderProvider.getProjectStakeholderImpacts(
      this.organisationId,
      this.projectId,
      res.payload.id
    );
    if (!impactsRes || impactsRes.isError) return;
    this.setStakeholder(res.payload);
    this.setStakeholderImpacts(impactsRes.payload);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterStakeholderImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterStakeholderImpacts();
    this.hideSearchMode();
  };

  @action
  filterStakeholderImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredStakeholderImpacts = this.stakeholderImpacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredStakeholderImpacts = this.stakeholderImpacts;
    }
  };
}
