import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { PositionedSpinner } from "../../../components/ui/PositionedSpinner";
import { Services } from "../../../constants";
import { Enums } from "../../../enums";
import { IOrganisationPermission } from "../../../contexts/permissions/PermissionsTypes";
import { IOrganisationsApi } from "../../api/v1/organisations/IOrganisations.api";
import { ILocalStorageService } from "../localStorageService/ILocalStorageService";
import * as _ from "lodash";
import { usePermissionUserOrganisations } from "../../../contexts/permissions/PermissionHooks";

type IOrganisationContext = {
  refetchCount: number;
  currentOrganisationId: number;
  organisationIds: number[];
  projects: FP.Entities.IProject[];
  programmes: FP.Entities.IProgramme[];
  currentOrganisation: null | IOrganisationPermission;
  organisations: IOrganisationPermission[];
  getCurrentOrganisation: () => IOrganisationPermission;
  setCurrentOrganisationId: (id: number) => void;
  loadProjects(): Promise<void>;
  loadProgrammes(): Promise<void>;
  refetch(): void;
};

const OrganisationContext = React.createContext<IOrganisationContext>({
  currentOrganisationId: 0,
  currentOrganisation: null,
  refetch: () => {},
  loadProgrammes: () => Promise.resolve(),
  getCurrentOrganisation: () => null as any,
  loadProjects: () => Promise.resolve(),
  programmes: [],
  projects: [],
  refetchCount: 0,
  organisations: [],
  organisationIds: [],
  setCurrentOrganisationId: (id: number) => {}
});

export class OrganisationContextModel implements IOrganisationContext {
  organisationsProvider: IOrganisationsApi;
  localStorageService: ILocalStorageService;
  @observable refetchCount = 0;
  @observable currentOrganisationId: number = 0;
  @observable.ref organisationIds: number[] = [];
  @observable.ref projects: FP.Entities.IProject[] = [];
  @observable.ref programmes: FP.Entities.IProgramme[] = [];
  @computed get currentOrganisation(): IOrganisationPermission {
    return this.organisations.find(e => `${e.id}` === `${this.currentOrganisationId}`) || null;
  }
  @observable.ref organisations: IOrganisationPermission[] = [];

  constructor(appService: AppService) {
    this.localStorageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.organisationsProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
  }
  getCurrentOrganisation() {
    return this.currentOrganisation;
  }

  @action.bound
  async loadProjects(): Promise<void> {
    const res = await this.organisationsProvider.getProjects(this.currentOrganisation.id);
    if (res && !res.isError) {
      this.setProjects(res.payload);
    }
  }

  @action.bound
  setProjects(projects: FP.Entities.IProject[]) {
    this.projects = projects;
  }

  @action.bound
  async loadProgrammes(): Promise<void> {
    const res = await this.organisationsProvider.getProgrammes(this.currentOrganisation.id);

    if (res && !res.isError) {
      this.setProgrammes(res.payload);
    }
  }

  @action.bound
  setProgrammes(programmes: FP.Entities.IProgramme[]) {
    this.programmes = programmes;
  }

  @action.bound
  setCurrentOrganisationId(id: number) {
    this.currentOrganisationId = id;
    this.localStorageService.set(Enums.LocalCookies.ORGANISATION_ID, `${id}`);
  }

  @action.bound
  refetch() {
    this.refetchCount++;
  }

  @action.bound
  async initialize(organisations: Dictionary<IOrganisationPermission>, initialOrganisationId?: number) {
    this.organisations = _.map(organisations, e => e);
    this.organisationIds = _.map(organisations, e => e.id);
    const currentOrganisationIdFromStorage = +this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID);

    let org = this.organisations.find(e => `${e.id}` === `${currentOrganisationIdFromStorage}`);
    this.setCurrentOrganisationId(initialOrganisationId || (org && org.id) || this.organisationIds[0]);
  }
}

export const OrganisationContextProvider: React.FC<{
  initialOrganisationId?: number;
  model: OrganisationContextModel;
}> = observer(({ children, model: ctx, initialOrganisationId }) => {
  const organisationIds = usePermissionUserOrganisations();

  useEffect(() => {}, [ctx, organisationIds]);

  const initialize = useCallback(async () => {
    await ctx.initialize(organisationIds, initialOrganisationId);
  }, [ctx, organisationIds, initialOrganisationId]);

  return (
    <Async promiseFn={initialize}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <OrganisationContext.Provider value={ctx} key={ctx.currentOrganisationId}>
            {children}
          </OrganisationContext.Provider>
        )}
      </Async.Resolved>
    </Async>
  );
});

const HasAccessToOrganisation = (organisationId: number): boolean => {
  const organisationIds = useCurrentOrganisationIds();
  return organisationIds.find(id => id === organisationId) > 0;
};

export const SetCurrentOrganisationFromRoute: React.FC = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const organisationContext = useOrganisationContext();

  if (!HasAccessToOrganisation(+organisationId)) {
    window.location.href = "/organisations/no-access";
    throw new Error("Unauthorised access");
  }

  useEffect(() => {
    if (organisationId !== `${organisationContext.currentOrganisationId}`) {
      organisationContext.setCurrentOrganisationId(+organisationId);
    }
  }, [organisationId, organisationContext]);

  return null;
};

export const SetCurrentOrganisationIdToLastAccessedOrganisation: React.FC = () => {
  const organisationIds = useCurrentOrganisationIds();
  const organisationContext = useOrganisationContext();

  const lastAccessedOrganisationId = localStorage.getItem(Enums.LocalCookies.ORGANISATION_ID);
  const currentOrgnisationId = organisationContext.currentOrganisationId;
  let selectedOrganisationId = lastAccessedOrganisationId || organisationIds[0];
  selectedOrganisationId = organisationIds.find(e => `${e}` === `${selectedOrganisationId}`) || organisationIds[0];

  if (!selectedOrganisationId) {
    window.location.href = "/organisations/no-access";
    throw new Error("Unauthorized access");
  }

  useEffect(() => {
    if (selectedOrganisationId && `${currentOrgnisationId}` !== `${selectedOrganisationId}`) {
      organisationContext.setCurrentOrganisationId(+selectedOrganisationId);
    }
  }, [selectedOrganisationId, currentOrgnisationId, organisationContext]);

  return null;
};

export function useCurrentOrganisationId() {
  return useContext(OrganisationContext).currentOrganisationId;
}

export function useCurrentOrganisationIds() {
  return useContext(OrganisationContext).organisationIds;
}

export function useOrganisationContext() {
  return useContext(OrganisationContext);
}

export function useUserOrganisations() {
  return useContext(OrganisationContext).organisations;
}

export function useCurrentOrganisation() {
  return useOrganisationContext().currentOrganisation as Omit<FP.Entities.IOrganisation, "projects" | "programmes">;
}
