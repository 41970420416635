import React, { useCallback, useEffect, useState } from "react";
import { LocationSettingsModel } from "./LocationsSettings_model";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Input } from "../../../../../components/ui/_forms/Input";
import { Enums } from "../../../../../enums";
import { TreeEditor } from "../../../../../components/widgets/treeEditor/TreeEditor_view";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Async } from "react-async";
import { useCanEditOrganisationClaim } from "../../../../../core/auth/authorise";
import { Animations } from "../../../../../core/util/Animations";
import { IconSymbols } from "../../../../../components/ui/Icon";

export interface LocationSettingsProps {
  model?: LocationSettingsModel;
}

const LocationSettings: React.FunctionComponent<LocationSettingsProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const canEditOrgnisation = useCanEditOrganisationClaim(organisation.id);

  const [model] = useState(() => m || new LocationSettingsModel(appService, organisation.id));

  useEffect(() => {
    model.onMount(canEditOrgnisation);
    return model.onUnmount;
  }, [model, canEditOrgnisation]);

  const load = useCallback(async () => {
    await model.loadLocations(organisation.id);
  }, [model, organisation]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <div
          className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <div className="d-flex flex-column" style={{ height: "100%", padding: "0 16px" }}>
            <div className="row mb-4">
              <div className="col-lg-8">
                <h1>
                  {organisation.name} | {I18n.t("phrases.manageLocations")}
                </h1>
                <p>{I18n.t("phrases.manageLocationsDescription")}</p>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-end">
                  <LinkButton
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    href={`/organisations/${organisation?.id}`}
                    className="float-right"
                  >
                    {I18n.t("phrases.closeSettings")}
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div style={{ flex: "1" }}>
                    <Input
                      onChange={model.performTreeSearch}
                      icon={IconSymbols.Search}
                      iconSize={Enums.UiSizes.SM}
                      placeholder={I18n.t("placeholders.searchLocation")}
                    />
                  </div>
                  <ButtonIcon
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    id={`Add${model.id}Button`}
                    size={Enums.UiSizes.MD}
                    className="ml-2"
                    title={I18n.t("phrases.add")}
                    onClick={model.addParentTreeNode}
                    symbol={IconSymbols.Plus}
                  />
                  <ButtonIcon
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={Enums.UiSizes.MD}
                    className="ml-2"
                    title={I18n.t("phrases.expandAll")}
                    onClick={model.expandAll}
                    symbol={IconSymbols.Maximize2}
                  />
                  <ButtonIcon
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={Enums.UiSizes.MD}
                    className="ml-2"
                    title={I18n.t("phrases.collapseAll")}
                    onClick={model.collapseAll}
                    symbol={IconSymbols.Minimize2}
                  />
                </div>
              </div>
            </div>
            {model.treeEditorModel && (
              <div className="mb-4" style={{ flex: 1 }}>
                <TreeEditor key="locations" model={model.treeEditorModel} />
              </div>
            )}
          </div>
        </div>
      </Async.Resolved>
    </Async>
  );
});

export { LocationSettings };
