import React from "react";
import { AppService } from "strikejs-app-service";
import { IModalService } from "../../../../../../../core/modal/IModalService";
import { Services } from "../../../../../../../constants";
import I18n from "../../../../../../../core/localization/I18n";
import { ActionImpactsSidebar } from "./actionImpactsSidebar/ActionImpactsSidebar_view";
import { ActionImpactsSidebarModel } from "./actionImpactsSidebar/ActionImpactsSidebar_model";
import { ActionNotesSideBar } from "./ActionNotesSidebar";
import { ActionTagsSidebar } from "./actionTagsSidebar/ActionTagsSidebar_view";
import { ActionTagsSidebarModel } from "./actionTagsSidebar/ActionTagsSidebar_model";
import { ActionDescriptionSidebar } from "../actionDescriptionSidebar/ActionDescriptionSidebar_view";
import { ActionDescriptionSidebarModel } from "../actionDescriptionSidebar/ActionDescriptionSidebar_model";
import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../../../core/grids/GridSideModals";

export interface IActionGridModals {
  showEditActionDescriptionFN?: (action: FP.Entities.IAction) => void;
  showNotesModalFN?: (action: FP.Entities.IAction) => void;
  showImpactsModalFN?: (action: FP.Entities.IAction) => void;
  showTagsModalFN: (action: FP.Entities.IAction) => void;
}

export const getActionGridModals = (
  appService: AppService,
  organisationId: number,
  projectId: number
): IActionGridModals => {
  const modalService = appService.getService<IModalService>(Services.ModalService);

  const showTagsModal = (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.tags")} />,
      content: (
        <ActionTagsSidebar
          model={new ActionTagsSidebarModel(appService, modalService.hide, action.id, organisationId, projectId)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showImpactsModal = (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
      content: (
        <ActionImpactsSidebar
          modalService={modalService}
          model={new ActionImpactsSidebarModel(appService, action.id, organisationId, projectId)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showEditDescriptionModal = async (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.description")} />,
      content: (
        <ActionDescriptionSidebar
          model={new ActionDescriptionSidebarModel(appService, modalService.hide, organisationId, projectId, action.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showNotesModal = (action: FP.Entities.IAction) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.notes")} />,
      content: (
        <ActionNotesSideBar
          modalService={modalService}
          projectId={projectId}
          organisationId={organisationId}
          actionId={action.id}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const ActionGridModals: IActionGridModals = {
    showTagsModalFN: showTagsModal,
    showImpactsModalFN: showImpactsModal,
    showEditActionDescriptionFN: showEditDescriptionModal,
    showNotesModalFN: showNotesModal
  };

  return ActionGridModals;
};
