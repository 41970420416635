import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../constants";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { IProjectsApi } from "../../../../../../../services/api/v1/projects/IProject.api";

export class ImpactMilestonesSidebarModel extends BaseModel {
    appService: AppService;
    impactsProvider: IImpactsApi;
    projectsProvider: IProjectsApi;
    @observable isLoading: boolean = true;
    @observable.ref impact: FP.Entities.IImpact;
    organisationId: number;
    projectId: number;
    closeModalFn: () => void;

    constructor(appService: AppService, closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
        super();
        this.appService = appService;
        this.organisationId = organisationId;
        this.projectId = projectId;
        this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi)
        this.isLoading = true;
        this.projectsProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
        this.loadImpact(impactId);
        this.closeModalFn = closeModalFn;
    }

    @action
    setImpact = (impact: FP.Entities.IImpact) => {
        this.impact = impact;
        this.isLoading = false;
    };

    @action
    loadImpact = async (impactId: number) => {
        let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
        if (!res || res.isError) return;

        this.setImpact(res.payload);
    };
}