import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, observable } from "mobx";
import {StakeholdersApi} from "../../../../../../services/api/v1/stakeholders/Stakeholders.api";
import {StakeholderUploadHub} from "../../../../../../services/hubs/StakeholderUploadHub/StakeholderUpload_hub";
import { SHOW_CONFIRM_DELETE_MODAL, SHOW_CONFIRM_IMPORT_MODAL, SHOW_STAKEHOLDER_ROW_DELETE_SUCCESS, SHOW_STAKEHOLDER_ROW_UPLOAD_SUCCESS } from "./UploadGrid_modals";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../constants";

export class UploadGridModel {
    appService: AppService;
    @observable organisationId: number;
    @observable fileId: number;
    @observable deleteButtonIsDisabled: boolean = true;
    @observable importButtonIsDisabled: boolean = true;
    stakeholderUploadHub:StakeholderUploadHub;
    stakeholdersProvider: StakeholdersApi;
    @observable connectedUsers: any[] = [];
    @observable isLoading: boolean = true;
    @observable respondentUploads: FP.Entities.IStakeholderImportRow[];
    @observable selectedStakeholderUploads: FP.Entities.IStakeholderImportRow[];
    gridApi: GridApi;
    @observable showUploadedRows: boolean = false;
    authUser: FP.Entities.IUser;
    @observable isComponentMounted: boolean = false;

    constructor(appService: AppService, organisationId: number, fileId: number, authUser: FP.Entities.IUser) {
        this.appService = appService;
        this.organisationId = organisationId;
        this.fileId = fileId;
        this.stakeholderUploadHub = this.appService.getService<StakeholderUploadHub>(Services.StakeholderUploadHub);
        this.stakeholdersProvider = this.appService.getService<StakeholdersApi>(Services.StakeholdersApi);
        this.authUser = authUser;
    }

    onMount = async () => {
      await this.registerSocketEvents();
    };

    onUnmount = () => {
      this.stakeholderUploadHub.stopConnection();
    };
    
    registerSocketEvents = async () => {
      if (this.stakeholderUploadHub.isConnectionStarted === true) {
        await this.stakeholderUploadHub.stopConnection();
      }
      await this.stakeholderUploadHub.startConnection();
      this.stakeholderUploadHub.onUserJoined((d) => {
        this.setConnectedUsers(d);
      });
  
      this.stakeholderUploadHub.onData((d) => {
        this.setRespondentsRowData(d);
      });
  
      this.stakeholderUploadHub.onUserCellSelected((d) => {
        this.setConnectedUsers(d);
      });
  
      await this.stakeholderUploadHub.invokeUserJoined(
        this.organisationId,
        0,
        this.authUser.sub
      );
      await this.stakeholderUploadHub.invokeLoadData(
        this.organisationId,
        this.fileId,
        this.showUploadedRows
      );

      this.isComponentMounted = true;
    };
    
    @action
    setConnectedUsers = (users) => {
      this.connectedUsers = [...users];
    };
    
    @action
    setRespondentsRowData = (data)=> {
      this.respondentUploads = data;
      this.isLoading = false;
    }

    @action
    updateSelectedStakeholderUploads = (event: SelectionChangedEvent) => {
      this.gridApi = event.api;
  
      this.selectedStakeholderUploads = _.map(event.api.getSelectedNodes(), (e) => {
        return {
          id: e.data.id,
          firstName: e.data.firstName,
          lastName: e.data.lastName,
          email: e.data.email,
          uploadedOn: e.data.uploadedOn
        } as FP.Entities.IStakeholderImportRow
      });
  
      this.deleteButtonIsDisabled = this.selectedStakeholderUploads.length === 0;
      this.importButtonIsDisabled = this.selectedStakeholderUploads.length === 0;
    };

    @action
    showConfirmImportModal = () => {
      if(this.selectedStakeholderUploads.length > 0){
        var respondentsToUpload = this.selectedStakeholderUploads.filter(x=> x.uploadedOn === undefined || x.uploadedOn === null);
        var notUploadedCount = this.selectedStakeholderUploads.length - respondentsToUpload.length;
        SHOW_CONFIRM_IMPORT_MODAL(this.appService, respondentsToUpload, notUploadedCount, this.uploadRespondentRows);
      }
    }

    @action
    uploadRespondentRows = async(respondentRowIds: number[]):Promise<boolean> => {
      var res = await this.stakeholdersProvider.uploadRows(this.organisationId, this.fileId, respondentRowIds, this.showUploadedRows);
      if(res){
        SHOW_STAKEHOLDER_ROW_UPLOAD_SUCCESS(this.appService, respondentRowIds.length);
        return true;
      }
      return false;

    }

    @action
    showConfirmDeleteModal = () => {
      if(this.selectedStakeholderUploads.length > 0){
        var respondentsToDelete = this.selectedStakeholderUploads.filter(x=> x.uploadedOn === undefined || x.uploadedOn === null);
        var notDeletedCount = this.selectedStakeholderUploads.length - respondentsToDelete.length;
        SHOW_CONFIRM_DELETE_MODAL(this.appService, respondentsToDelete, notDeletedCount, this.deleteRespondentRows);
      }
    }

    @action
    deleteRespondentRows = async(respondentRowIds: number[]):Promise<boolean> => {
      var res = await this.stakeholdersProvider.deleteRows(this.organisationId, this.fileId, respondentRowIds, this.showUploadedRows);
      if(res){
        SHOW_STAKEHOLDER_ROW_DELETE_SUCCESS(this.appService, respondentRowIds.length);
        return true;
      }
      return false;
    }

    @action
    setShowUploadedRows = async () => {
      this.showUploadedRows = !this.showUploadedRows;

      await this.stakeholderUploadHub.invokeLoadData(
        this.organisationId,
        this.fileId,
        this.showUploadedRows
      );
    }

    saveRowFn = async (id: number, value: string, field: number) => {
      var res = await this.stakeholdersProvider.updateUploadField(
        this.organisationId,
        id,
        value,
        field,
        this.fileId,
        this.showUploadedRows);

        if(res.code === 200)
        {
          return res.payload;
        }

        return false;
    }

    isRowSelectable = (params) => {
      return (this.isUploaded(params.data) && (this.dataIsValid(params.data)));
    }

    isUploaded = (data): boolean =>{
      return (data.uploadedOn === undefined || data.uploadedOn === null);
    }

    dataIsValid = (data):boolean => {
      return (data.businessAreaExists === true && data.roleExists === true && data.emailAddressValid === true)
    }
}