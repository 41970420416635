import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { StakeholdersApi } from "../../../../../../services/api/v1/stakeholders/Stakeholders.api";
import { Services } from "../../../../../../constants";

export class UploadFileModel {
  appService: AppService;
  organisationId: number;
  stakeholdersProvider: StakeholdersApi;
  @observable showFileTypeWarning: boolean = false;
  @observable showFileSizeWarning: boolean = false;
  @observable showSuccessMessage: boolean = false;
  @observable isUploading: boolean = false;

  constructor(appService: AppService, organisationId: number) {
    this.appService = appService;
    this.organisationId = organisationId;
    this.stakeholdersProvider = this.appService.getService<StakeholdersApi>(Services.StakeholdersApi);
  }

  @action
  onFileDrop = async (files: FileList, e: any) => {
    this.showFileSizeWarning = false;
    this.showFileTypeWarning = false;

    for (var f = 0; f < files.length; f++) {
      let isCorrectFileType: boolean = files[f].type === "text/csv";
      let fileHasContent: boolean = files[f].size > 0;

      if (!isCorrectFileType) this.showFileTypeWarning = true;

      if (!fileHasContent) this.showFileSizeWarning = true;

      if (isCorrectFileType && fileHasContent) {
        this.isUploading = true;
        var result = await this.stakeholdersProvider.upload(
          this.organisationId,
          files[f],
          files[f].name
        );

        if(result){
          this.isUploading = false;
          this.showSuccessMessage = true;
        }
      }
    }
  };
}
