import { useUserIdentity } from "../../setup";
import { FlightPathUserIdClaimKey } from "./constants";

export function useUserId() {
  const profile = useUserIdentity();

  return profile[`${FlightPathUserIdClaimKey}`] as number;
}

export function useOktaUserId() {
  const profile = useUserIdentity();
  let oktaUserId = profile["sub"] as string;
  return oktaUserId;
}