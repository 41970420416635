import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { MicroCreateImpactViewModel } from "./MicroGroupImpactsForm_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Button } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { Panel } from "@flightpath/coreui";
import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";

export interface MicroCreateImpactViewProps {
  impactGroupId: number;
  projectId: number;
  onImpactCreated: (impact?: FP.Entities.IImpact) => void;
}

export const MicroCreateImpactView: React.FC<MicroCreateImpactViewProps> = observer(props => {
  const appService = useAppService();
  const impactGroupId = props.impactGroupId;
  const projectId = props.projectId;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(
    () => new MicroCreateImpactViewModel(appService, organisationId, +impactGroupId, +projectId)
  );
  useEffect(() => {
    if (model.impactGroupId !== +impactGroupId || model.projectId !== +projectId) {
      setModel(new MicroCreateImpactViewModel(appService, organisationId, +impactGroupId, +projectId));
    }
  }, [appService, model, projectId, impactGroupId, organisationId]);

  return (
    <div>
      <Panel.Panel className="row" hasBorderRadius={true} hasShadow={true} background={PanelBackgrounds.BG_WHITE}>
        <div className="col-9">
          <h4 className="mt-3 mb-0">{I18n.t("forms.detailedImpactName")}</h4>
          <div>
            <SingleForm model={model.impactForm} />
          </div>
        </div>
        <div className="col-3 d-flex flex-row">
          <div className="align-self-end w-100">
            <Button
              children={I18n.t("phrases.create")}
              className="mb-4 w-100 d-block"
              onClick={async () => {
                let impact = await model.createImpact();
                props.onImpactCreated(impact);
              }}
            />
          </div>
        </div>
      </Panel.Panel>
    </div>
  );
});
