import React, { useEffect, useRef, useState } from "react";
import { UnassignedStakeholdersModel } from "./UnassignedStakeholders_model";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { IF } from "../../../../../components/hoc/If";
import { GridView } from "../../../../../core/grids/GridView_view";
import { UnassignedStakeholdersGridModel } from "./UnassignedStakeholdersGrid_model";
import { observer } from "mobx-react-lite";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { StakeholderType, UiSizes } from "../../../../../enums";
import { UnassignedAudiencesGridModel } from "./UnassignedAudiencesGrid_model";

export const UnassignedStakeholdersGrid: React.FC<{
  projectId: number;
  stakeholderType: StakeholderType;
  onAssignSuccess: () => void;
}> = observer(({ projectId, onAssignSuccess, stakeholderType }) => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const [hasFilters, setHasFilters] = useState(false);
  const filterRef = useRef(null);

  const [model, setModel] = useState(
    () => new UnassignedStakeholdersModel(appService, organisationId, +projectId, stakeholderType)
  );

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className="unassigned-stakeholder-grid">
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-5">
            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              isDisabled={model.selectedStakeholders.length === 0}
              className="mt-2 mr-2 align-self-start"
              onClick={async () => {
                let res = await model.assignStakeholders();
                if (res) {
                  onAssignSuccess();
                }
              }}
            >
              Assign
            </Button>
            <LinkButton
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              className=" mr-2 align-self-start"
              target="_blank"
              href={`/organisations/${organisationId}/settings/stakeholders`}
            >
              Manage Stakeholders
            </LinkButton>
          </div>
        </div>
      </div>
      <IF condition={!model.isLoading}>
        <GridView
          data={model.stakeholders}
          onSelectionChanged={model.updateSelectedStakeholders}
          modelClass={
            stakeholderType === StakeholderType.INDIVIDUAL
              ? UnassignedStakeholdersGridModel
              : UnassignedAudiencesGridModel
          }
          onCellClicked={function (cell: string, isEditMode?: boolean): void {}}
          onCellEditModeChange={function (cell: string, isEditMode: boolean): void {}}
          users={[]}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});
