import { AxiosRequestConfig } from "axios";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../constants";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { IFlightPathApiResponse } from "../../../../../../services/api/BaseApiModel";
import { IHeatmapQuery } from "../Heatmap_model";
import { FullHeatmapData, FullPieData, IDrillThroughQuery } from "../Heatmap_utils";
import { SHOW_PIECHART_MODAL } from "../modals/pieChart/PieChart_modal";

export interface HeatmapGridConfig {
  getHeatmapData?: (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<FullHeatmapData>>;
  getDrillThroughPieData?: (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<FullPieData>>;
  getTagFilterIdsAsString: () => string;
  getUrlForPieChart: (
    organisationId: number,
    projectId: number,
    month: number,
    year: number,
    itemIds?: string[]
  ) => string;
}

export class HeatmapGridModel {
  @observable.ref parentIdHistory: number[] = [];
  config: HeatmapGridConfig;
  organisationId: number;
  rowChildren: string[];
  appService: AppService;
  setParentId: (id: number) => void;
  modalService: IModalService;
  heatmapData: FullHeatmapData;

  constructor(
    appService: AppService,
    organisationId: number,
    heatmapData: FullHeatmapData,
    setParentId: (id: number) => void,
    config: HeatmapGridConfig
  ) {
    this.config = config;
    this.organisationId = organisationId;
    this.appService = appService;
    this.setParentId = setParentId;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.heatmapData = heatmapData;
  }

  showCellPieChart = async (rowId: number, month: number, year: number, cellValue: number) => {
    SHOW_PIECHART_MODAL(
      this.modalService,
      rowId,
      month,
      year,
      this.config.getDrillThroughPieData,
      this.config.getTagFilterIdsAsString,
      this.getRowName(rowId),
      this.config.getUrlForPieChart
    );
  };

  getRowName = rowId => {
    const heatmapData = this.heatmapData.heatmapRowData;
    let rowName = "";

    for (let i = 0; i < heatmapData.length; i++) {
      if (heatmapData[i].rowId === rowId) {
        rowName = heatmapData[i].rowName;
      }
    }
    return rowName;
  };

  drillDownQuery = (businessAreaId: number, month: number, year: number, tagIds: string) => {
    return {
      id: businessAreaId,
      month,
      year,
      tagIds
    };
  };

  @action
  onRowClick = (id?: string) => {
    const parentId = id ? this.drillDownRow(+id) : this.drillUpRow();
    this.setParentId(parentId);
  };

  getPreviousParentId = () => {
    const previousParentId = this.parentIdHistory.slice(-1);
    const result = previousParentId[0];
    return result;
  };

  @action
  drillUpRow = () => {
    this.removePreviousParentIdFromHistory();
    return this.parentIdHistory.length === 0 ? 0 : this.parentIdHistory[this.parentIdHistory.length - 1];
  };

  @action
  drillDownRow = (parentId: number) => {
    this.addToParentIdHistory(parentId);
    return parentId;
  };

  addToParentIdHistory = (parentId: number) => this.parentIdHistory.push(parentId);

  removePreviousParentIdFromHistory = () => this.parentIdHistory.pop();

  setRowChildren = async (data: string[]) => {
    this.rowChildren = data;
  };
}
