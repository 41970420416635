import React, { useState } from "react";
import { Panel } from "../../../../components/ui/Panel";
import { ProjectNavElement } from "../../../../components/widgets/projectNavElement";
import {
  useCanContributeOrg,
  useGetAllProgrammes,
  useGetAllProjects
} from "../../../../contexts/permissions/PermissionHooks";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import * as _ from "lodash";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Input } from "../../../../components/ui/_forms/Input";
import { Hr } from "../../../../components/ui/Hr";
import { Colours } from "@flightpath/coreui/dist/ui/hr";
import I18n from "../../../../core/localization/I18n";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { IF } from "../../../../components/hoc/If";
import { ProgrammeNavElement } from "../../../../components/widgets/programmeNavElement";
import { getProgrammesFromList } from "./ProjectMegaMenu";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";

export const ProjectNavList: React.FC<any> = ({ projectId }) => {
  const organisationId = useCurrentOrganisationId();
  const canContributeOrg = useCanContributeOrg(organisationId);
  let projects = _.orderBy(_.map(useGetAllProjects(organisationId)), e => e.name.toLowerCase());
  let [filteredProjects, setFilteredProjects] = useState(projects);

  const groupedProjects = _.map(_.groupBy(filteredProjects, "parentId"), e => {
    return e;
  });

  const cProgrammes = useGetAllProgrammes(organisationId);
  let programmes = getProgrammesFromList(groupedProjects, organisationId);

  const s = _.keyBy(
    _.filter(cProgrammes, e => e.projectIds.length === 0),
    "name"
  );

  programmes = { ...s, ...programmes };

  return (
    <div className="project-nav-list">
      <Panel.Panel style={{ width: "350px", backgroundColor: "black", zIndex: 999999999 }}>
        <div className="project-nav-list__search p-2 mt-1">
          <Input
            icon={IconSymbols.Search}
            borderStyle="underline"
            onChange={e => {
              const value = e.target.value;
              if (value === "") {
                setFilteredProjects(projects);
              } else {
                setFilteredProjects(projects.filter(e => e.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
              }
            }}
          />
        </div>
        {_.map(programmes, (programme: any) => {
          return (
            <div className="project-mega-menu__wrapper d-inline-block" key={`programme-${programme?.id || 0}`}>
              <ProgrammeNavElement
                isActive={programme && programme.id > 0}
                name={programme.name || "Standalone Projects"}
                url={(programme && `/organisations/${organisationId}/programmes/${programme.id}`) || ""}
              />
              {_.map(programme.projects, e => {
                const url = `/organisations/${organisationId}/projects/${e.id}`;
                return (
                  <div
                    key={e.id}
                    className={`project-mega-menu__item ${e.id === projectId ? "project-mega-menu__item--active" : ""}`}
                  >
                    <ProjectNavElement
                      key={`project-${e.id}`}
                      url={url}
                      name={e.name}
                      hasImpactsAccess={e.impact.canView}
                      hasActionsAccess={e.action.canView}
                      hasStakeholdersAccess={e.stakeholder.canView}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}

        <IF condition={canContributeOrg}>
          <Hr style={{ width: "100%" }} colour={Colours.GRAY_400} className="mt-2" />

          <LinkButton href="/create/programmes" type={ButtonTypes.LIGHT} className="ml-3 mb-2" size={UiSizes.XS}>
            {I18n.t("phrases.createProgramme")}
          </LinkButton>
          <LinkButton href="/create/projects" type={ButtonTypes.LIGHT} className="ml-3 mb-2" size={UiSizes.XS}>
            {I18n.t("phrases.createProject")}
          </LinkButton>
        </IF>
      </Panel.Panel>
    </div>
  );
};
