import { ILocalStorageService } from "./ILocalStorageService";

export class LocalStorageService implements ILocalStorageService {
  set = (name: string, value: string) => {
    localStorage.setItem(name, value);
  };

  get = (name: string) => {
    return localStorage.getItem(name);
  };

  remove = (name: string) => {
    localStorage.setItem(name, null);
  };
}
