import { IHttp } from "../../IHttp";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { IProcessesApi } from "./IProcesses.api";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";

export class ProcessesApi extends FilteredOrganisationApiModel<FP.Entities.IProcess> implements IProcessesApi {
  controller: string = "processes";

  constructor(http: IHttp) {
    super(http, "processes");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getAll = async (organisationId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProcess[]>;
    }
  };
}
