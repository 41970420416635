import * as React from "react";
import { AppService } from "strikejs-app-service";
import { Link } from "react-router-dom";
import { IUserReportsApi } from "../../../../services/api/v1/userReports/IUserReports.api";
import { Services } from "../../../../constants";
import { action, observable, reaction } from "mobx";
import { ReportCardConfig, ReportCardModel } from "../../../../components/widgets/reportCard/ReportCard_model";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { FilterModel, IFilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { Enums } from "../../../../enums";
import { GetActionListFilters } from "./ActionListViewFilters_fields";
import { TableModel } from "../../../../core/table/Table_model";
import { ITableModel } from "../../../../core/table/ITableModel";
import { GetUserActionsTableConfig } from "./userActions/UserActions_config";
import { TableView } from "../../../../core/table/Table_view";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { ActionCompactViewModel, ActionCompactView } from "../../actions/actionCompactView";
import { LinkButton, ButtonIcon, Button, ButtonTypes } from "../../../../components/ui/Button";
import { StakeholderProgress } from "./userStakeholders/UserStakeholders_config";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

import {
  ActionOverTime,
  REPORT_HEIGHT,
  CHART_MARGIN,
  X_AXIS_LABEL_DY,
  CHART_COLOUR_SECONDARY,
  CHART_COLOUR_PRIMARY,
  CHART_LINE_WIDTH,
  CHART_POINT_SIZE,
  CHART_COLOUR_TODAY_LINE,
  REFERENCE_LINE_LABEL_DY,
  CHART_COLOUR_ARRAY,
  Y_AXIS_LABEL_DX
} from "../../projects/reportsView/ReportsView_model";
import moment from "moment";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  CartesianGrid,
  Label,
  YAxis,
  Tooltip,
  TooltipProps,
  Legend,
  Line,
  ReferenceLine,
  PieChart,
  Pie,
  Cell
} from "recharts";
import { Panel } from "../../../../components/ui/Panel";
import { Accordion } from "../../../../components/ui/Accordion";
import { ButtonGroup } from "../../../../components/ui/ButtonGroup";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { Observer } from "mobx-react";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { Animations } from "../../../../core/util/Animations";
import { IconSymbols } from "../../../../components/ui/Icon";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";

export class UserReportsModel extends DisposableModel {
  appService: AppService;
  // routeProps: RouteComponentProps;
  userReportProvider: IUserReportsApi;
  userProvider: IUsersApi;
  modalService: IModalService;
  // Overdue Actions
  overdueActionsReport: ReportCardModel;
  overdueActionsFilter: IFilterModel<FP.Entities.IAction>;
  overdueActionsTableModel: ITableModel<FP.Entities.IAction>;
  @observable.ref overdueActionsType: "assignee" | "owner" = "assignee";
  // Upcoming Actions
  upcomingActionsReport: ReportCardModel;
  upcomingActionsFilter: IFilterModel<FP.Entities.IAction>;
  upcomingActionsTableModel: ITableModel<FP.Entities.IAction>;
  @observable.ref upcomingActionsType: "assignee" | "owner" = "assignee";
  // User Stakeholders
  stakeholderReport: ReportCardModel;
  stakeholderFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  // Actions Over Time
  actionsOverTimeReport: ReportCardModel;
  actionsOverTimeFilter: IFilterModel<any>;
  // Actions By Type
  actionsByTypeReport: ReportCardModel;
  actionsByTypeFilter: IFilterModel<any>;
  // Notifications
  notificationsReport: ReportCardModel;
  notificationsFilter: IFilterModel<FP.Entities.INotification>;

  @observable.ref reports: ReportCardModel[] = [];
  organisationId: number;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    super();
    this.appService = appService;
    this.userReportProvider = this.appService.getService<IUserReportsApi>(Services.UserReportsApi);
    this.userProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.organisationId = organisationId;
    this.installReactions();
    this.installFilters();
    this.installReports();
    this.setFilterConfig();
  }

  onMount = () => {};

  onUnmount = () => {
    this.dispose();
  };

  installReactions = () => {
    var d = reaction(
      () => {
        return this.overdueActionsType;
      },
      (e, reaction) => {
        this.setOverdueUserRelation(e);
      }
    );
    var e = reaction(
      () => {
        return this.upcomingActionsType;
      },
      (e, reaction) => {
        this.setUpcomingUserRelation(e);
      }
    );
    this.addDisposer(d);
    this.addDisposer(e);
  };

  installReports = () => {
    this.installOverdueReport();
    this.installUpcomingActionsReport();
    this.installStakeholderReport();
    this.installActionsOverTimeReport();
    this.installActionsByTypeReport();
    this.installNotificationsReport();
  };

  installFilters = () => {
    this.installOverdueFilters();
    this.installUpcomingActionsFilters();
    this.installStakeholderFilters();
    this.installActionsOverTimeFilters();
    this.installActionsByTypeFilters();
    this.installNotificationsFilter();
  };

  setFilterConfig = () => {
    SetUserActionFilter(
      this.organisationId,
      this.overdueActionsFilter,
      this.overdueActionsReport.setData,
      "overdueActions"
    );
    SetUserActionFilter(
      this.organisationId,
      this.upcomingActionsFilter,
      this.upcomingActionsReport.setData,
      "upcomingActions"
    );
    SetUserActionFilter(this.organisationId, this.actionsOverTimeFilter, this.actionsOverTimeReport.setData, null, 0);
    SetUserActionFilter(this.organisationId, this.actionsByTypeFilter, this.actionsByTypeReport.setData, null, 0);

    this.actionsOverTimeFilter.setConfig({
      onDataLoaded: data => {
        const formattedData: ActionOverTime[] = data.map(e => ({
          initTotalCount: e.initTotalCount,
          actualTotalCount: e.actualTotalCount,
          date: moment(e.date),
          ds: moment(e.date).valueOf()
        }));
        this.actionsOverTimeReport.setData(formattedData);
      }
    });

    this.setUserStakeholderFilter();
    this.setNotificationsFilter();
  };

  @action
  addReport = (report: ReportCardModel) => {
    let k = this.reports.slice();
    k.push(report);
    this.reports = k;
  };

  installOverdueReport = () => {
    this.overdueActionsTableModel = new TableModel();

    let tableConfig = GetUserActionsTableConfig(
      this.overdueActionsFilter,
      this.overdueActionsTableModel,
      this.showActionModal
    );

    this.overdueActionsTableModel.set(tableConfig);

    const overdueActions: ReportCardConfig = {
      key: "overdueActions",
      className: "col-lg-6 mb-5",
      title: (
        <Observer>
          {() => (
            <div className="d-flex justify-content-between align-items-center">
              <h3>
                {I18n.t("phrases.myActions")} | {I18n.t("phrases.overdue")}
              </h3>
              <ButtonGroup className="mr-3 mb-4" isToggle={true} size={Enums.UiSizes.XS}>
                <Button
                  type={this.overdueActionsType === "assignee" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                  onClick={() => (this.overdueActionsType = "assignee")}
                  size={Enums.UiSizes.XS}
                >
                  {I18n.t("phrases.assignedTo")}
                </Button>
                <Button
                  type={this.overdueActionsType === "owner" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                  onClick={() => (this.overdueActionsType = "owner")}
                  size={Enums.UiSizes.XS}
                >
                  {I18n.t("phrases.owner")}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </Observer>
      ),
      loadData: async cardModel => {},
      panelProps: {
        className: "user-reports__report-card",
        background: Panel.PanelBackgrounds.BG_WHITE
      },
      render: (data: FP.Entities.IAction[]) => {
        if (!data || data.length === 0) {
          return (
            <div>
              {this.overdueActionsType === "assignee"
                ? I18n.t("phrases.noOverdueActions")
                : I18n.t("phrases.noOwnerOverdueActions")}
            </div>
          );
        }

        this.overdueActionsTableModel.setData(data);

        return (
          <>
            <TableView model={this.overdueActionsTableModel} />
            <div className="row">
              <div className="col">
                <div className="d-flex">
                  <LinkButton
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    id="SeeAllOverdueActionsButton"
                    href={`/dashboard/overdueActions/${this.overdueActionsType}`}
                    className="ml-auto"
                  >
                    {I18n.t("phrases.seeAll")}
                  </LinkButton>
                </div>
              </div>
            </div>
          </>
        );
      },
      actions: [
        {
          id: "filters",
          label: I18n.t("phrases.applyFilters"),
          onAction: async () => {
            this.overdueActionsFilter.showFilterFormModal();
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "ml-auto"
          }
        }
      ]
    };

    this.overdueActionsReport = new ReportCardModel(this.appService, overdueActions);
    this.addReport(this.overdueActionsReport);
  };

  installOverdueFilters = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions =>
          await this.userReportProvider.getOverdueActionsAsync(this.organisationId, filterOptions)
      }
    };
    this.overdueActionsFilter = new FilterModel(config);
  };

  installUpcomingActionsReport = () => {
    this.upcomingActionsTableModel = new TableModel();

    let tableConfig = GetUserActionsTableConfig(
      this.upcomingActionsFilter,
      this.upcomingActionsTableModel,
      this.showActionModal
    );

    this.upcomingActionsTableModel.set(tableConfig);

    const upcomingActions: ReportCardConfig = {
      key: "upcomingActions",
      className: "col-lg-6 mb-5",
      title: (
        <Observer>
          {() => (
            <div className="d-flex justify-content-between align-items-center">
              <h3>
                {I18n.t("phrases.myActions")} | {I18n.t("phrases.upcoming")}
              </h3>
              <ButtonGroup className="mr-3 mb-4" isToggle={true} size={Enums.UiSizes.XS}>
                <Button
                  type={this.upcomingActionsType === "assignee" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                  onClick={() => (this.upcomingActionsType = "assignee")}
                  size={Enums.UiSizes.XS}
                >
                  {I18n.t("phrases.assignedTo")}
                </Button>
                <Button
                  type={this.upcomingActionsType === "owner" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                  onClick={() => (this.upcomingActionsType = "owner")}
                  size={Enums.UiSizes.XS}
                >
                  {I18n.t("phrases.owner")}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </Observer>
      ),
      loadData: async cardModel => {},
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE,
        className: "user-reports__report-card"
      },
      render: (data: FP.Entities.IAction[]) => {
        if (!data || data.length === 0) {
          return (
            <div>
              {this.upcomingActionsType === "assignee"
                ? I18n.t("phrases.noUpcomingActions")
                : I18n.t("phrases.noUpcomingActionsOwned")}
            </div>
          );
        }

        this.upcomingActionsTableModel.setData(data);

        return (
          <>
            <TableView model={this.upcomingActionsTableModel} />
            <div className="row">
              <div className="col">
                <div className="d-flex">
                  <LinkButton
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    href={`/dashboard/upcomingActions/${this.upcomingActionsType}`}
                    className="ml-auto"
                  >
                    {I18n.t("phrases.seeAll")}
                  </LinkButton>
                </div>
              </div>
            </div>
          </>
        );
      },

      actions: [
        {
          id: "filters",
          label: I18n.t("phrases.applyFilters"),
          onAction: async () => {
            this.upcomingActionsFilter.showFilterFormModal();
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "ml-auto"
          }
        }
      ]
    };

    this.upcomingActionsReport = new ReportCardModel(this.appService, upcomingActions);
    this.addReport(this.upcomingActionsReport);
  };

  installUpcomingActionsFilters = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions =>
          await this.userReportProvider.getUpcomingActionsAsync(this.organisationId, filterOptions)
      }
    };
    this.upcomingActionsFilter = new FilterModel(config);
  };

  installStakeholderReport = () => {
    const myStakeholders: ReportCardConfig = {
      key: "myStakeholders",
      className: "col-lg-6 mb-5",
      title: <h3>{I18n.t("phrases.myStakeholders")}</h3>,
      loadData: async cardModel => {},
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE,
        className: "user-reports__report-card"
      },
      render: (data: FP.Entities.IProjectStakeholder[][]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noStakeholderRelationships")}</div>;
        }

        return (
          <>
            <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="row mx-0">
              <div className="col-5">
                <h4 className="mb-0 strong my-3">{I18n.t("table.stakeholders")}</h4>
              </div>
              <div className="col-3 p-0">
                <h4 className="mb-0 strong my-3">{I18n.t("table.project")}</h4>
              </div>
              <div className="col-2 p-0">
                <h4 className="mb-0 strong my-3">{I18n.t("table.influence")}</h4>
              </div>
              <div className="col-2 p-0">
                <h4 className="mb-0 strong my-3">{I18n.t("table.impact")}</h4>
              </div>
            </Panel.Panel>
            {data.map((stakeholders: any[]) => {
              let projectNo = stakeholders.length;
              return (
                <Accordion
                  key={stakeholders[0].id}
                  side="right"
                  hideArrow={projectNo <= 1}
                  header={
                    <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="p-0 row mx-0">
                      <div className="col-5 my-3">
                        <StakeholderProgress projectStakeholder={stakeholders[0]} isProject={false} />
                      </div>
                      <div className="col-3 p-0 my-3">
                        <p className="mb-0">{projectNo > 1 ? projectNo : stakeholders[0].projectName}</p>
                      </div>
                      <div className="col-2 p-0 my-3">
                        <p className="mb-0">
                          {projectNo > 1 ? "" : Enums.Translator.InfluenceLevel(stakeholders[0].influence)}
                        </p>
                      </div>
                      <div className="col-2 p-0 my-3">
                        <p className="mb-0">
                          {projectNo > 1 ? "" : Enums.Translator.ImpactLevel(stakeholders[0].impact)}
                        </p>
                      </div>
                    </Panel.Panel>
                  }
                >
                  {projectNo > 1 &&
                    stakeholders?.map((pStakeholder: any) => {
                      return (
                        <Panel.Panel
                          type={Panel.PanelTypes.BORDER_LINES_Y}
                          key={pStakeholder.stakeholderId + "-s" + pStakeholder.projectId}
                          className="p-0 row mx-0"
                        >
                          <div className="col-5 my-3">
                            <Link
                              to={`/organisations/${pStakeholder.organisationId}/projects/${pStakeholder.projectId}/stakeholders/${pStakeholder.stakeholderId}`}
                            >
                              <StakeholderProgress projectStakeholder={pStakeholder} isProject={true} />
                            </Link>
                          </div>
                          <div className="col-3 p-0 my-3">
                            <p className="mb-0">{pStakeholder.projectName}</p>
                          </div>
                          <div className="col-2 p-0 my-3">
                            <p className="mb-0">{Enums.Translator.InfluenceLevel(pStakeholder.influence)}</p>
                          </div>
                          <div className="col-2 p-0 my-3">
                            <p className="mb-0">{Enums.Translator.ImpactLevel(pStakeholder.impact)}</p>
                          </div>
                        </Panel.Panel>
                      );
                    })}
                </Accordion>
              );
            })}
            <div className="row mt-4">
              <div className="col div d-flex">
                <LinkButton type={ButtonTypes.OUTLINE_PRIMARY} href="/dashboard/userStakeholders" className="ml-auto">
                  {I18n.t("phrases.seeAll")}
                </LinkButton>
              </div>
            </div>
          </>
        );
      },
      actions: [
        {
          id: "filters",
          label: I18n.t("phrases.applyFilters"),
          onAction: async () => {
            this.upcomingActionsFilter.showFilterFormModal();
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "ml-auto"
          }
        }
      ]
    };

    this.stakeholderReport = new ReportCardModel(this.appService, myStakeholders);
    this.addReport(this.stakeholderReport);
  };

  installStakeholderFilters = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions =>
          await this.userReportProvider.getUserStakeholderAsync(this.organisationId, filterOptions)
      }
    };
    this.stakeholderFilter = new FilterModel(config);
  };

  setUserStakeholderFilter = () => {
    this.stakeholderFilter.setConfig({
      onDataLoaded: this.stakeholderReport.setData,
      pageSize: 5
    });
    this.stakeholderFilter.loadData();
  };

  setNotificationsFilter = () => {
    this.notificationsFilter.setConfig({
      onDataLoaded: this.notificationsReport.setData,
      pageSize: 10
    });

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const latestFilter: IFilterAttribute = {
      key: "latestDaysNotifications",
      value: ["10"],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.notificationsFilter.addFilter(lifeCycleFilter);
    this.notificationsFilter.addFilter(latestFilter);

    this.notificationsFilter.addSort({ key: "createdAt", isAsc: false });

    this.notificationsFilter.loadData();
  };

  installActionsOverTimeFilters = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions =>
          await this.userReportProvider.getActionsOverTimeAsync(this.organisationId, filterOptions)
      }
    };
    this.actionsOverTimeFilter = new FilterModel(config);
  };

  installActionsOverTimeReport = () => {
    const actionsOverTime: ReportCardConfig = {
      key: "actionsOverTime",
      className: "col-lg-6 mb-5",
      title: <h3>{I18n.t("phrases.actionOverTime")}</h3>,
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE,
        className: "user-reports__report-card"
      },
      loadData: async cardModel => {},
      render: (data: ActionOverTime[]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noActionOverTimeReportData")}</div>;
        }

        data = data.map(e => {
          e.date = moment(e.date).valueOf() as any;
          return e;
        });

        const minDate = moment(data[0].date);
        const maxDate = moment(data[data.length - 1].date);
        return (
          <ResponsiveContainer height={REPORT_HEIGHT} width={"100%"}>
            <LineChart className={"reports__with-today"} data={data} margin={CHART_MARGIN}>
              <CartesianGrid fill="#fff" />
              <XAxis
                name="date"
                scale="time"
                type="number"
                domain={[minDate.subtract(1, "week").valueOf(), maxDate.add(1, "week").valueOf()]}
                dataKey="ds"
                axisLine={false}
                tickLine={false}
                tick={false}
              >
                <Label dy={X_AXIS_LABEL_DY}>{I18n.t("phrases.time")}</Label>
              </XAxis>
              <YAxis axisLine={false} tickLine={false} allowDecimals={false}>
                <Label angle={-90} dx={Y_AXIS_LABEL_DX}>
                  {I18n.t("phrases.actions")}
                </Label>
              </YAxis>
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">{moment(item.date).format("L")}</small>
                        <small className="d-block">
                          {I18n.t("phrases.planned")}: {item.initTotalCount}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.actual")}: {item.actualTotalCount}
                        </small>
                      </Panel.Panel>
                    );
                  }
                }}
              />
              <Legend
                verticalAlign="top"
                align="right"
                formatter={value => <small>{value}</small>}
                payload={[
                  { id: "initTotalCount", value: I18n.t("phrases.planned"), type: "line", color: CHART_COLOUR_PRIMARY },
                  {
                    id: "actualTotalCount",
                    value: I18n.t("phrases.actual"),
                    type: "line",
                    color: CHART_COLOUR_SECONDARY
                  },
                  { id: "today", value: I18n.t("phrases.today"), type: "circle", color: CHART_COLOUR_TODAY_LINE }
                ]}
              />
              <Line
                dataKey="initTotalCount"
                name={I18n.t("phrases.planned")}
                fill={CHART_COLOUR_PRIMARY}
                stroke={CHART_COLOUR_PRIMARY}
                strokeWidth={CHART_LINE_WIDTH}
                activeDot={{ r: CHART_POINT_SIZE }}
              />
              <Line
                dataKey="actualTotalCount"
                name={I18n.t("phrases.actual")}
                fill={CHART_COLOUR_SECONDARY}
                stroke={CHART_COLOUR_SECONDARY}
                strokeWidth={CHART_LINE_WIDTH}
                activeDot={{ r: CHART_POINT_SIZE }}
              />
              <ReferenceLine x={moment().valueOf()} stroke={CHART_COLOUR_TODAY_LINE} strokeWidth={CHART_LINE_WIDTH}>
                <Label position="insideBottom" dy={REFERENCE_LINE_LABEL_DY}></Label>
              </ReferenceLine>
            </LineChart>
          </ResponsiveContainer>
        );
      },
      actions: [
        {
          id: "filters",
          label: I18n.t("phrases.applyFilters"),
          onAction: async () => {
            this.actionsOverTimeFilter.showFilterFormModal();
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "ml-auto"
          }
        }
      ]
    };
    this.actionsOverTimeReport = new ReportCardModel(this.appService, actionsOverTime);
    this.addReport(this.actionsOverTimeReport);
  };

  installActionsByTypeFilters = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions =>
          await this.userReportProvider.getActionsByTypeAsync(this.organisationId, filterOptions)
      }
    };
    this.actionsByTypeFilter = new FilterModel(config);

    const nameFilter: IFilterAttribute = {
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const refFilter: IFilterAttribute = {
      key: "refNumber",
      label: I18n.t("filters.refNumber"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const ragStatusFilter: IFilterAttribute = {
      key: "ragStatus",
      label: I18n.t("filters.ragStatus"),
      operator: FilterOperator.EQUALS,
      value: [],
      valueRenderer: (str, obj) => {
        return Enums.Translator.RagStatus(parseInt(str));
      }
    };

    this.actionsByTypeFilter.addFilter(nameFilter);
    this.actionsByTypeFilter.addFilter(refFilter);
    this.actionsByTypeFilter.addFilter(lifeCycleFilter);
    this.actionsByTypeFilter.addFilter(ragStatusFilter);
  };

  installActionsByTypeReport = () => {
    const actionsByType: ReportCardConfig = {
      key: "actionsByType",
      className: "col-lg-6 mb-5",
      title: <h3>{I18n.t("phrases.myActionsByType")}</h3>,
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE,
        className: "user-reports__report-card"
      },
      loadData: async cardModel => {},
      actions: [
        {
          id: "filters",
          label: I18n.t("phrases.applyFilters"),
          onAction: async () => {
            this.actionsByTypeFilter.showFilterFormModal();
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "ml-auto"
          }
        }
      ],
      render: (data: any[]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noActionsByTypeReportData")}</div>;
        }

        return (
          <ResponsiveContainer height={REPORT_HEIGHT} width="100%">
            <PieChart margin={CHART_MARGIN}>
              <Pie
                data={data}
                dataKey="count"
                nameKey="name"
                outerRadius="75%"
                fill={CHART_COLOUR_PRIMARY}
                label={props => {
                  return props.name.length > 14 ? props.name.slice(0, 11).concat("...") : props.name;
                }}
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={CHART_COLOUR_ARRAY[index % data.length]} />
                ))}
              </Pie>
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">{item.name}</small>
                        <small className="d-block">
                          {I18n.t("phrases.notStarted")}: {item.notStarted}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.inProgress")}: {item.inProgress}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.completed")}: {item.completed}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.total")}: {item.count}
                        </small>
                      </Panel.Panel>
                    );
                  }
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        );
      }
    };
    this.actionsByTypeReport = new ReportCardModel(this.appService, actionsByType);
    this.addReport(this.actionsByTypeReport);
    this.actionsByTypeFilter.setConfig({
      onDataLoaded: this.actionsByTypeReport.setData,
      pageSize: 0
    });
  };

  installNotificationsFilter = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions => await this.userProvider.getNotifications(this.organisationId, filterOptions)
      }
    };
    this.notificationsFilter = new FilterModel(config);
  };

  installNotificationsReport = () => {
    const notifications: ReportCardConfig = {
      key: "notifications",
      className: "col-lg-6 mb-5",
      title: <h3>{I18n.t("phrases.notifications")}</h3>,
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE,
        className: "user-reports__report-card"
      },
      loadData: async cardModel => {},
      actions: [],
      render: (data: any[]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noNotifications")}</div>;
        }

        return (
          <>
            {data.map(notification => {
              return (
                <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="py-3">
                  <h4>{moment(notification.createdAt).format("L")}</h4>
                  <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(notification.content) }}></div>
                </Panel.Panel>
              );
            })}
          </>
        );
      }
    };

    this.notificationsReport = new ReportCardModel(this.appService, notifications);
    this.addReport(this.notificationsReport);
  };

  showActionModal = (row: ITableRowModel) => {
    let action: FP.Entities.IAction = row.rowObject;
    let actionCompactModel = new ActionCompactViewModel(
      this.appService,
      action.projectId,
      this.organisationId,
      action.id
    );
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${action.projectId}/actions/${action.id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <ButtonIcon
            key="2"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: <ActionCompactView model={actionCompactModel} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  setOverdueUserRelation = (type: "assignee" | "owner") => {
    this.overdueActionsFilter.setFilterValue("userRelation", type);
  };

  setUpcomingUserRelation = (type: "assignee" | "owner") => {
    this.upcomingActionsFilter.setFilterValue("userRelation", type);
  };
}

export const SetUserActionFilter = (
  orgId: number,
  filterModel: IFilterModel<FP.Entities.IAction>,
  dataSetter: (actions: FP.Entities.IAction[]) => void,
  reportFilter?: "overdueActions" | "upcomingActions",
  pageSize: number = 5
) => {
  const nameFilter: IFilterAttribute = {
    key: "name",
    label: I18n.t("filters.name"),
    value: [],
    operator: FilterOperator.CONTAINS
  };

  const refFilter: IFilterAttribute = {
    key: "refNumber",
    label: I18n.t("filters.refNumber"),
    value: [],
    operator: FilterOperator.CONTAINS
  };

  const lifeCycleFilter: IFilterAttribute = {
    key: "lifecycleStatus",
    value: [Enums.LifecycleStatus.Active + ""],
    isHidden: true,
    operator: FilterOperator.EQUALS
  };

  const ragStatusFilter: IFilterAttribute = {
    key: "ragStatus",
    label: I18n.t("filters.ragStatus"),
    operator: FilterOperator.EQUALS,
    value: [],
    valueRenderer: (str, obj) => {
      return Enums.Translator.RagStatus(parseInt(str));
    }
  };

  const projectId: IFilterAttribute = {
    key: "projectId",
    label: I18n.t("filters.project"),
    operator: FilterOperator.EQUALS,
    value: [],
    valueRenderer: (str, obj) => {
      return str;
    }
  };

  const userRelation: IFilterAttribute = {
    key: "userRelation",
    operator: FilterOperator.EQUALS,
    value: ["assignee"],
    isHidden: true
  };

  const organisationId: IFilterAttribute = {
    key: "project.OrganisationId",
    label: I18n.t("filters.organisation"),
    operator: FilterOperator.EQUALS,
    value: []
  };

  const actionType: IFilterAttribute = {
    key: "actionTypeId",
    label: I18n.t("filters.actionType"),
    operator: FilterOperator.EQUALS,
    value: []
  };

  filterModel.addSort({ key: "actualEndDate", isAsc: false });

  if (reportFilter) {
    const reportOn: IFilterAttribute = {
      key: reportFilter,
      useWithoutValue: true,
      isHidden: true
    };

    filterModel.addFilter(reportOn);
  }

  filterModel.addFilter(nameFilter);
  filterModel.addFilter(refFilter);
  filterModel.addFilter(lifeCycleFilter);
  filterModel.addFilter(ragStatusFilter);
  filterModel.addFilter(userRelation);
  filterModel.addFilter(projectId);
  filterModel.addFilter(organisationId);
  filterModel.addFilter(actionType);

  filterModel.setConfig({
    formFields: GetActionListFilters(orgId, null, ["name", "refNumber", "projectId", "organisationId", "actionTypeId"]),
    onDataLoaded: dataSetter,
    pageSize
  });
  filterModel.loadData();
};
