import { action, observable } from 'mobx';
import { AppService } from 'strikejs-app-service';
import { Services } from '../../../../../../../constants';
import { BaseModel } from '../../../../../../../core/util/BaseModel';
import { IImpactsApi } from '../../../../../../../services/api/v1/impacts/IImpacts.api';

export class ImpactGroupsSidebarModel extends BaseModel {
    appService: AppService;
    impactsProvider: IImpactsApi;
    @observable isLoading: boolean = true;
    @observable isSearchMode: boolean = false;
    @observable.ref impact: FP.Entities.IImpact;
    @observable searchValue: string = "";
    organisationId: number;
    projectId: number;
    closeModalFn: () => void;

    constructor(appService: AppService, closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
        super();
        this.appService = appService;
        this.organisationId = organisationId;
        this.projectId = projectId;
        this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi)
        this.isLoading = true;
        this.loadImpact(impactId);
        this.closeModalFn = closeModalFn;
    }

    onUnmount = () => {
        this.resetSearch();
    };

    @action
    resetSearch = () => {
        this.searchValue = "";
        this.hideSearchMode();
    };

    @action
    hideSearchMode = () => {
        this.isSearchMode = false;
    };

    @action
    showSearchMode = () => {
        this.isSearchMode = true;
    };


    @action
    updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
        this.searchValue = e.currentTarget.value;
    };

    @action
    setImpact = (impact: FP.Entities.IImpact) => {
        this.impact = impact;

        this.isLoading = false;
    };

    @action
    loadImpact = async (impactId: number) => {
        let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
        if (!res || res.isError) return;

        this.setImpact(res.payload);
    };
}