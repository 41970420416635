import { HubEvents } from "../common/HubEventsEnum";
import { GridBaseModel } from "../common/GridBaseModel";

export class ImpactsHub extends GridBaseModel {
  hubName: string = "impacts";

  constructor() {
    super("impacts");
  }

  onData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_IMPACT_DATA, callback);

  invokeLoadGridData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}
