import { observer } from "mobx-react-lite";
import React from "react";
import { Dropdown, DropdownOption } from "../../../../../../components/ui/_forms/Dropdown";
import { GetI18nParsedList } from "../../../../../../constants";
import I18n from "../../../../../../core/localization/I18n";
import { Enums } from "../../../../../../enums";

const monthsDisplay = [
  { key: Enums.HeatmapMonths.JANUARY, label: "phrases.heatmapJan" },
  { key: Enums.HeatmapMonths.FEBRUARY, label: "phrases.heatmapFeb" },
  { key: Enums.HeatmapMonths.MARCH, label: "phrases.heatmapMar" },
  { key: Enums.HeatmapMonths.APRIL, label: "phrases.heatmapApr" },
  { key: Enums.HeatmapMonths.MAY, label: "phrases.heatmapMay" },
  { key: Enums.HeatmapMonths.JUNE, label: "phrases.heatmapJun" },
  { key: Enums.HeatmapMonths.JULY, label: "phrases.heatmapJul" },
  { key: Enums.HeatmapMonths.AUGUST, label: "phrases.heatmapAug" },
  { key: Enums.HeatmapMonths.SEPTEMBER, label: "phrases.heatmapSep" },
  { key: Enums.HeatmapMonths.OCTOBER, label: "phrases.heatmapOct" },
  { key: Enums.HeatmapMonths.NOVEMBER, label: "phrases.heatmapNov" },
  { key: Enums.HeatmapMonths.DECEMBER, label: "phrases.heatmapDec" }
];

const createYearLabel = (year: number) => {
  return {
    label: `${year}`
  };
};

const getYearOptions = startYear => {
  const todayLabel = createYearLabel(startYear);
  let years = [todayLabel];
  for (let i = 1; i < 6; i++) {
    let pastLabel = createYearLabel(startYear - i);
    let futureLabel = createYearLabel(startYear + i);
    years.unshift(pastLabel);
    years.push(futureLabel);
  }
  return years;
};

export const StartDateMonth: React.FC<{ startMonth: number; setStartMonth: (month: number) => void }> = observer(
  ({ startMonth, setStartMonth }) => {
    const valueLabel = monthsDisplay[startMonth].key;
    return (
      <div className="organisation-dashboard__dropdown">
        <Dropdown
          items={GetI18nParsedList(I18n, monthsDisplay)}
          value={{ label: valueLabel }}
          onItemSelected={e => {
            let index = monthsDisplay.indexOf(e);
            setStartMonth(index);
          }}
          selector="label"
        >
          <DropdownOption label="" />
        </Dropdown>
      </div>
    );
  }
);

export const StartDateYear: React.FC<{ startYear: number; setStartYear: (startYear: number) => void }> = observer(
  ({ startYear, setStartYear }) => {
    const valueLabel = `${startYear}`;
    const yearOptions = getYearOptions(startYear);
    return (
      <div className="organisation-dashboard__dropdown">
        <Dropdown
          items={yearOptions}
          value={{ label: valueLabel }}
          onItemSelected={e => {
            setStartYear(+e.label);
          }}
          selector="label"
        >
          <DropdownOption label="" />
        </Dropdown>
      </div>
    );
  }
);
