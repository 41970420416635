import React, { useContext, useState, useCallback } from "react";
import { Async } from "react-async";
import { useOktaUserId } from ".";
import { Loader } from "../../components/ui/PositionedSpinner";
import { useUserIdentity } from "../../setup";
import { loadFlightPathUserClaims } from "./loader";
import { Enums } from "../../enums";
import { useAppService } from "../../contexts/AppService";
import { ILocalStorageService } from "../../services/local/localStorageService/ILocalStorageService";
import { Services } from "../../constants";

export interface IClaim {
  key: string;
  value: string | number;
}

const AuthoriseContext = React.createContext<IClaim[]>([]);

const ADMIN_GROUP_NAME = "Administrators";

export function useUserGroups() {
  return useUserIdentity().groups || [];
}

export function useUserGroup(groupName: string) {
  return useUserGroups().find(e => e === groupName);
}

export function useIsOktaAdmin() {
  return !!useUserGroup(ADMIN_GROUP_NAME);
}

export function useIsTestUser() {
  const isTestUser = useClaim("isTestUser");
  return window.appConfig.appEnvironment === "dev" && isTestUser && isTestUser.value === "1";
}

export function useUserId() {
  const userId = useClaim("userId");
  return userId.value;
}

const ClaimsContext = React.createContext<() => void>(() => {});

export const ClaimsContextProvider: React.FC = ({ children }) => {
  const oktaUserId = useOktaUserId();
  const orgId = parseInt(GetOrganisationIdFromUrl());
  const appService = useAppService();
  const localStorageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
  const organisationId = parseInt(localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
  const loadClaims = useCallback(() => {
    return loadFlightPathUserClaims(organisationId || orgId, oktaUserId);
  }, [oktaUserId, orgId]);
  const [count, setCount] = useState(0);
  const increment = useCallback(() => setCount(count => count + 1), [setCount]);

  return (
    <ClaimsContext.Provider value={increment}>
      <AuthorisationLoader key={count} loadClaims={loadClaims}>
        {children}
      </AuthorisationLoader>
    </ClaimsContext.Provider>
  );
};

const GetOrganisationIdFromUrl = () => {
  let s = window.location.href.match(/organisations\/(.*?)+/g);
  if (!s) return;
  return s[0].split("/")[1];
};

export function useRefreshClaims() {
  return useContext(ClaimsContext);
}

export const AuthorisationLoader: React.FC<{
  loadClaims(): Promise<IClaim[]>;
}> = ({ children, loadClaims }) => {
  return (
    <Async promiseFn={loadClaims}>
      <Async.Resolved<IClaim[]>>
        {claims => <AuthorisationProvider claims={claims}>{children}</AuthorisationProvider>}
      </Async.Resolved>
      <Async.Rejected>{err => err.message}</Async.Rejected>
      <Async.Loading>
        <Loader />
      </Async.Loading>
    </Async>
  );
};

export const AuthorisationProvider: React.FC<{ claims: IClaim[] }> = ({ claims, children }) => {
  return <AuthoriseContext.Provider value={claims}>{children}</AuthoriseContext.Provider>;
};

export function useClaim(key: string) {
  return useContext(AuthoriseContext).find(e => e.key === key);
}

export function useClaimValue(key: string) {
  const claim = useClaim(key);

  return claim ? claim.value : undefined;
}

export function useClaims() {
  return useContext(AuthoriseContext);
}

export function useHasClaim(key: string) {
  return !!useContext(AuthoriseContext).find(e => e.key === key);
}

export function useOrganisationScopeClaim(organisationId: number) {
  if (!organisationId) {
    throw new Error("Organisation id cannot be empty");
  }
  return useClaim(`organisation:${organisationId}:scope`);
}

export const Permission = {
  READER: "READER", //100
  CONTRIBUTOR: "CONTRIBUTOR", //200
  OWNER: "OWNER", //280
  ADMIN: "ADMIN" //300
};

export function useCanOwnOrganisationClaim(organisationId: number) {
  if (!organisationId) {
    throw new Error("Organisation id cannot be empty");
  }
  const isAdmin = useIsOrganisationAdmin(organisationId);
  const scope = useClaimValue(`organisation:${organisationId}:scope`);
  return isAdmin || scope === Permission.OWNER;
}

export function useIsOrganisationAdmin(organisationId: number) {
  if (!organisationId) {
    throw new Error("Organisation id cannot be empty");
  }
  const scope = useClaimValue(`organisation:${organisationId}:scope`);
  return scope === Permission.ADMIN;
}

export function useCanEditOrganisationClaim(organisationId: number) {
  if (!organisationId) {
    throw new Error("Organisation id cannot be empty");
  }
  const isAdmin = useIsOrganisationAdmin(organisationId);
  const scope = useClaimValue(`organisation:${organisationId}:scope`);
  return isAdmin || scope === Permission.OWNER || scope === Permission.CONTRIBUTOR;
}

export function useCanViewOrganisationClaim(organisationId: number) {
  if (!organisationId) {
    throw new Error("Organisation id cannot be empty");
  }
  const canEditOrganisation = useCanEditOrganisationClaim(organisationId);
  const scope = useClaimValue(`organisation:${organisationId}:scope`);
  return canEditOrganisation || scope === Permission.READER;
}

export function useCanEditProjectClaim(projectId: number) {
  if (!projectId) {
    throw new Error("Project id cannot be empty");
  }

  const canEdit = useHasClaim(`project:${projectId}:edit`);
  const isOwner = useHasClaim(`project:${projectId}:owner`);
  return canEdit || isOwner;
}

export function useCanViewProjectClaim(projectId: number) {
  if (!projectId) {
    throw new Error("Project id cannot be empty");
  }
  const canEdit = useCanEditProjectClaim(projectId);
  const canView = useHasClaim(`project:${projectId}:view`);
  return canEdit || canView;
}

export function useCanEditProgrammeClaim(programmeId: number) {
  if (!programmeId) {
    throw new Error("Programme id cannot be empty");
  }

  const canEdit = useHasClaim(`programme:${programmeId}:edit`);
  const isOwner = useHasClaim(`programme:${programmeId}:owner`);
  return canEdit || isOwner;
}

export function useCanViewProgrammeClaim(programmeId: number) {
  if (!programmeId) {
    throw new Error("Programme id cannot be empty");
  }
  const canEdit = useCanEditProgrammeClaim(programmeId);
  const canView = useHasClaim(`programme:${programmeId}:view`);
  return canEdit || canView;
}
