import { AxiosRequestConfig } from "axios";
import { BenefitField } from "../../../../enums";
import { FilteredProjectApiModel } from "../../filteredApi/FilteredProjectApiModel";
import { IHttp } from "../../IHttp";
import { IBenefitApi } from "./IBenefit.api";

export class BenefitApi extends FilteredProjectApiModel<FP.Entities.IBenefit> implements IBenefitApi {
  controller: string = "benefits";

  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "benefits");
  }
  updateField = async (
    organisationId: number,
    projectId: number,
    benefitId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    benefitField: BenefitField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}/${benefitField}`,
      data,
      config
    );
    if (res && (res.status === 200 || res.status === 400)) {
      return res.data;
    }
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
