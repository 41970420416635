import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { ActionListViewModel } from "./ActionListView_model";
import { useFlightPathUser } from "../../../../../setup";
import { IF } from "../../../../../components/hoc/If";
import { Tab, Tabs } from "../../../../../components/ui/Tabs";
import { CanEdit } from "../../../../../contexts/permissions/PermissionHelpers";
import { MicroForm } from "../../../../change/forms/microForm/MicroForm";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../core/localization/I18n";
import { useIsVisualisationsEnabled } from "../../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { Button, ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { EntityTypes, Enums, UiSizes } from "../../../../../enums";
import { ConnectedUserAvatars } from "../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { GridView } from "../../../../../core/grids/GridView_view";
import { AcGridViewModel } from "./AcGridView/AcGridView_model";
import { useIsOrganisationAdmin } from "../../../../../contexts/permissions/PermissionHooks";
import { Input } from "../../../../../components/ui/_forms/Input";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { ButtonIconDropdown } from "../../../../../components/ui/ButtonIconDropdown";
import { ILocalStorageService } from "../../../../../services/local/localStorageService/ILocalStorageService";
import { Services } from "../../../../../constants";

export interface ActionListViewProps {
  model?: ActionListViewModel;
}

const ActionListView: React.FunctionComponent<ActionListViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const history = useHistory();
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const pId = !!projectId ? +projectId : null;
  const [model, setModel] = useState(() => new ActionListViewModel(appService, history, organisationId, pId, authUser));
  const modelProjectId = model.projectId;
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      model.stopConnection().then(() => {
        setModel(new ActionListViewModel(appService, history, organisationId, pId, authUser));
      });
    }
  }, [appService, model, modelProjectId, history, projectId, organisationId, pId, authUser]);

  return <ActionListViewContent model={model} organisationId={organisationId} projectId={pId} />;
});

const ActionListViewContent: React.FC<{ model: ActionListViewModel; organisationId: number; projectId: number }> =
  observer(({ model, organisationId, projectId }) => {
    const isVisualisationsEnabled = useIsVisualisationsEnabled();
    const appService = useAppService();
    const wrapTextKey = `projects-${projectId}-${EntityTypes.ACTIONS}-wrap-text`;
    const storageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
    const filterRef = useRef(null);
    const tabHeadingWithCount = (title: string, count?: number) => {
      var countText = count != null ? `(${count})` : "";
      return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
    };
    const [hasFilters, setHasFilters] = useState(false);
    const [isTextWrapToggled, setIsTextWrapToggled] = useState(
      typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
    );
    const [hasColumnChanges, setHasColumnChanges] = useState(false);
    const isAdmin = useIsOrganisationAdmin(organisationId);
    return (
      <div className="action-list-view">
        <Tabs
          key="first"
          className="tabs--primary"
          ulClassName="tabs__menu--primary"
          initialTab={0}
          liClassName="tabs__item--primary"
          onTabClicked={model.changeCurrentView}
        >
          <Tab title={tabHeadingWithCount(I18n.t("phrases.actionTracker"), model.actionCount)}></Tab>

          <Tab
            isHidden={!isVisualisationsEnabled}
            title={tabHeadingWithCount(I18n.t("phrases.actionPlan"), null)}
          ></Tab>
        </Tabs>

        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col d-flex">
              <CanEdit field={PermissionFields.ACTIONS} projectId={model.projectId}>
                <MicroForm
                  iconSymbol={IconSymbols.Plus}
                  fieldName="name"
                  className="float-left mr-2"
                  buttonPlaceholder={I18n.t("phrases.add")}
                  formModel={model.microActionForm}
                  onSubmit={model.createMicroAction}
                />
                <ButtonIcon
                  type={ButtonTypes.LINK}
                  size={UiSizes.SM}
                  isDisabled={model.selectedActions.length === 0}
                  onClick={model.selectedActions.length > 0 ? model.showActionConfirmReviewModal : null}
                  className="mr-2 align-self-start"
                  symbol={IconSymbols.CommentPencil}
                >
                  {I18n.t("phrases.review")}
                </ButtonIcon>
                <ButtonIcon
                  type={ButtonTypes.LINK}
                  size={UiSizes.SM}
                  isDisabled={model.selectedActions.length === 0}
                  onClick={model.selectedActions.length > 0 ? model.showActionConfirmDeleteModal : null}
                  className="mr-2 align-self-start"
                  symbol={IconSymbols.Trash}
                >
                  {I18n.t("phrases.delete")}
                </ButtonIcon>

                <ButtonIconDropdown
                  iconSymbol={IconSymbols.Link}
                  buttonProps={{
                    size: UiSizes.SM,
                    type: ButtonTypes.LINK,
                    className: "p-0 mr-2",
                    isDisabled: model.selectedActions.length === 0
                  }}
                  actions={model.uiActions}
                >
                  {I18n.t("phrases.link")}
                </ButtonIconDropdown>
                {isAdmin && (
                  <ButtonIcon
                    type={ButtonTypes.LINK}
                    size={UiSizes.SM}
                    isDisabled={model.selectedActions.length === 0}
                    onClick={model.selectedActions.length > 0 ? model.exportRows : null}
                    className="mr-2 align-self-start"
                    symbol={IconSymbols.Download}
                  >
                    {I18n.t("phrases.export")}
                  </ButtonIcon>
                )}
                <span className="vertical-line"></span>
                <ButtonIcon
                  type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                  size={UiSizes.SM}
                  onClick={() => {
                    setIsTextWrapToggled(!isTextWrapToggled);
                    filterRef.current.toggleTextWrapper();
                  }}
                  symbol={IconSymbols.TextWrap}
                  className="mr-2 align-self-start"
                >
                  {I18n.t("phrases.textWrap")}
                </ButtonIcon>
                <ButtonIcon
                  type={ButtonTypes.LINK}
                  size={UiSizes.SM}
                  isDisabled={!hasFilters}
                  onClick={() => filterRef.current.clearFilters()}
                  className="mr-2 align-self-start"
                  symbol={IconSymbols.ClearFilter}
                >
                  {I18n.t("phrases.clearFilters")}
                </ButtonIcon>
                <ButtonIcon
                  type={ButtonTypes.LINK}
                  size={UiSizes.SM}
                  isDisabled={!hasColumnChanges}
                  onClick={() => filterRef.current.resetColumns()}
                  className="mr-2 align-self-start"
                  symbol={IconSymbols.MonitorBack}
                >
                  {I18n.t("phrases.resetColumns")}
                </ButtonIcon>
              </CanEdit>
            </div>
            <div className="col-3">
              <ConnectedUserAvatars connectedUsers={model.connectedUsers} />
            </div>
            <div className="col-2">
              <div className="text-right">
                <Input
                  autoFocus={true}
                  size={UiSizes.XS}
                  onChange={model.setSearchText}
                  placeholder="Search"
                  icon={IconSymbols.Search}
                  iconSize={Enums.UiSizes.SM}
                  style={{ height: 32 }}
                />
              </div>
            </div>
          </div>
        </div>

        <IF condition={!model.isLoading}>
          <GridView
            context={{
              deleteFn: model.deleteFieldData
            }}
            users={model.connectedUsers}
            data={model.gridActions}
            onCellClicked={model.updateUserSelectedCell}
            onCellEditModeChange={model.updateUserSelectedCell}
            onSelectionChanged={model.updateSelectedActions}
            modelClass={AcGridViewModel}
            onGridReady={model.onGridReady}
            ref={filterRef}
            filterHasChangedFn={setHasFilters}
            columnOrderHasChangedFn={setHasColumnChanges}
            overlayNoRowsTemplate={null}
          />
        </IF>
      </div>
    );
  });

export { ActionListView };
