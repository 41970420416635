import { ColDef } from "ag-grid-community";
import { ActionField, EntityTypes } from "../../../../../enums";
import {
  colCreatedByName,
  colCreatedOn,
  colDescription,
  colEndDate,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colOwner,
  colProgressStatus,
  colRefNumber,
  colReviewedBy,
  colSelected,
  colStartDate,
  colTags,
  colUpdatedOn,
  colImpacts
} from "../../../../../core/grids/CommonGridColumns";

import { IGridFns } from "../../../../../core/grids/GridFunctions";
import { IActionGridModals } from "../../Actions/ActionListView/AcGridView/modals/AcGridView_modals";
import {
  colActionTypes,
  colAssignedTo,
  colProjectAudiences,
  colProjectStakeholders,
  colRagStatus
} from "../../Actions/ActionListView/AcGridView/AcGridView_BaseColumns";

export interface IGetActionGridViewColumns {
  userCanViewImpacts: boolean;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypes: FP.Entities.IActionType[];
  // projectTeamMembers: FP.Entities.IUser[];
  modals: IActionGridModals;
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  userCanViewStakeholders: boolean;
  pinned: "left" | "right" | null;
}

export const GetActionGridViewColumns = (modelProps: IGetActionGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  const canEdit = modelProps.canEdit;
  let modals = modelProps.modals;

  result.push(colSelected());

  result.push(colRagStatus(canEdit, canEdit && modelProps.gridFns.updateIdField, { pinned: modelProps.pinned }));
  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.ACTIONS, { pinned: modelProps.pinned })
  );
  result.push(
    colName(canEdit, canEdit && modelProps.gridFns.updateTextField, ActionField.name, "", {
      width: 200,
      pinned: modelProps.pinned
    })
  );
  result.push(colDescription(canEdit, canEdit && modals.showEditActionDescriptionFN));
  result.push(
    colProgressStatus(
      canEdit,
      modelProps.progressStatuses,
      canEdit && modelProps.gridFns.updateIdField,
      ActionField.progressStatus,
      {}
    )
  );
  result.push(colStartDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ActionField.startDate, {}));
  result.push(colEndDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ActionField.endDate, {}));
  result.push(colOwner(canEdit, [], canEdit && modelProps.gridFns.updateIdField, ActionField.owner, {}));
  result.push(colAssignedTo(canEdit, [], canEdit && modelProps.gridFns.updateIdField));
  result.push(colActionTypes(canEdit, modelProps.actionTypes, canEdit && modelProps.gridFns.updateIdField));
  result.push(colNoteCount(canEdit, modals.showNotesModalFN));
  result.push(colTags(canEdit, modals.showTagsModalFN));

  // Should only be added if the user has permissions to see/edit them
  if (modelProps.userCanViewImpacts) {
    result.push(colImpacts(canEdit, modals.showImpactsModalFN));
  }

  // Should only be added if the user has permissions to see/edit them
  if (modelProps.userCanViewStakeholders) {
    result.push(colProjectStakeholders());
    result.push(colProjectAudiences());
  }

  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());

  return result;
};
