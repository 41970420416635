import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../../contexts/AppService";
import { Button, ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { FilterPills } from "../../../../../../components/widgets/filterPills/FilterPills_view";
import { Pagination } from "../../../../../../components/widgets/pagination/Pagination_view";
import { TopActionBar } from "../../../../../../components/widgets/TopActionBar/TopActionBar";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../../core/util/Animations";
import { IndividualUserPermissionsModel } from "./IndividualUsersPermissions_model";

export interface IndividualUserPermissionsProps {
  showTour: boolean;
}

export const IndividualUserPermissions: React.FC<IndividualUserPermissionsProps> = observer(props => {
  const organisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const { projectId } = useParams() as any;
  const [model, setModel] = useState(() => new IndividualUserPermissionsModel(appService, organisationId, projectId));
  const searchValue = model.filterModel.getFilter("searchPhrase").value;
  React.useEffect(() => {
    model.onMount(+organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  React.useEffect(() => {
    setModel(new IndividualUserPermissionsModel(appService, organisationId, projectId));
  }, [appService, organisationId, projectId]);

  return (
    <div className={`individual-user-permission mt-5 ${Animations.FP_ZOOM_IN}`}>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-2">{I18n.t("phrases.individualPermissionsTitle")}</h2>
          </div>
          <div className="col-12">
            <p className="mb-0">{I18n.t("phrases.individualPermissionsDesc")}</p>
          </div>
        </div>
      </div>
      <TopActionBar
        searchButtonProps={{
          onChange: model.onSearchChange,
          placeholder: I18n.t("placeholders.searchTableData"),
          value: searchValue.length > 0 ? searchValue[0] : "",
          className: "float-right ml-2",
          onClose: model.resetNameFilter,
          buttonType: ButtonTypes.LINK
        }}
        actions={[
          {
            id: "filter",
            label: (
              <>
                <Icon className="mr-2" symbol={IconSymbols.FilterLines} /> {I18n.t("phrases.filters")}
              </>
            ),
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.LINK
            },
            onAction: model.filterModel.showFilterFormModal
          }
        ]}
        showProductTour={props.showTour}
        productTourSteps={model.productTourSteps}
      >
        <Button
          className={"ml-2 float-left tour--permission-addindividualuser"}
          onClick={() => model.showAddIndividualUserForm()}
          type={ButtonTypes.PRIMARY}
        >
          <Icon symbol={IconSymbols.Plus} className="mr-2" />
          {I18n.t("phrases.addIndividualUser")}
        </Button>
      </TopActionBar>
      <div className="container-fluid pt-4">
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            {model.isLoading ? (
              <PositionedSpinner />
            ) : (
              <>
                <SmartTable
                  colHeaders={model.smartTableConfig.colHeaders}
                  actions={model.smartTableConfig.actions}
                  onRowClick={model.smartTableConfig.onRowClick}
                  data={model.users || []}
                  showProductTour={props.showTour}
                />
                <Pagination model={model.paginationModel} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
