import React, { useEffect, useState } from "react";
import { IF } from "../../../../../components/hoc/If";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../setup";
import "./_impact-grid.scss";
import { ImpactGridModel } from "./ImpactGrid_model";
import { GridView } from "../../../../../core/grids/GridView_view";
import { ImpactGridViewModel } from "./ImpactGridView_model";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Enums, UiSizes } from "../../../../../enums";
import { observer } from "mobx-react-lite";
import { Input } from "../../../../../components/ui/_forms/Input";
import { IconSymbols } from "../../../../../components/ui/Icon";

export const ImpactGrid: React.FC<{ projectId: number; onAssignSuccess: () => void; stakeholderIds: number[] }> =
  observer(({ projectId, onAssignSuccess, stakeholderIds }) => {
    const appService = useAppService();
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const [model] = useState(
      () => new ImpactGridModel(appService, projectId, organisationId, authUser, stakeholderIds)
    );

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [model]);
    return (
      <div className="impact-grid">
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-8">
              <Button
                type={ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                isDisabled={model.selectedImpacts.length === 0}
                className="mt-2 mr-2 align-self-start"
                onClick={async () => {
                  let res = await model.assignImpacts();
                  if (res) {
                    onAssignSuccess();
                  }
                }}
              >
                Assign
              </Button>
              <Input
                autoFocus={true}
                className="mt-2"
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                iconClass="mt-1"
                style={{ height: 32 }}
              />
            </div>
          </div>
        </div>
        <IF condition={!model.isLoading}>
          <GridView
            context={{}}
            data={model.gridImpacts}
            users={[]}
            onCellClicked={() => {}}
            onCellEditModeChange={() => {}}
            onSelectionChanged={model.updateSelectedImpacts}
            modelClass={ImpactGridViewModel}
            onGridReady={model.onGridReady}
            overlayNoRowsTemplate={null}
          />
        </IF>
      </div>
    );
  });
