import { action, observable } from "mobx";
import { GetGridFns, IGridFns } from "../../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import { GetUploadGridViewColumns } from "./UploadGrid_Grid_columns";
import {StakeholdersApi} from "../../../../../../../services/api/v1/stakeholders/Stakeholders.api";
import { Services } from "../../../../../../../constants";
import { AppService } from "strikejs-app-service";

export class UploadGrid_GridModel implements IGridModel {
  appService: AppService;
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  stakeholdersProvider: StakeholdersApi;

  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    this.appService = appService;
    this.organisationId = organisationId;
    this.stakeholdersProvider = this.appService.getService<StakeholdersApi>(Services.StakeholdersApi);
    this.gridFns = GetGridFns(
      this.stakeholdersProvider,
      this.organisationId,
      0
    );
  }

saveRowfn = async (id: number, value: string,fieldId: number, fileId: number, showUpdatedRows: boolean):Promise<boolean> => {
  var res = await this.stakeholdersProvider.updateUploadField(
    this.organisationId,
    id,
    value,
    fieldId,
    fileId,
    showUpdatedRows);

  if(res.code === 200){
    return res.payload;
  }

  return false;
}

  getColumnConfig = () => {
    return GetUploadGridViewColumns(
      this.saveRowfn
    );
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
