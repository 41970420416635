import {
  IOrganisationPermission,
  PermissionFields,
  IProjectPermission,
  IProgrammePermission
} from "./PermissionsTypes";
import * as _ from "lodash";
import { PermissionScope } from "../../enums";
import { action, observable } from "mobx";
import { IsDev, IsStaging } from "../../core/util/EnvironmentHelper";

export interface IPermissionsContextModel {
  updateFromHeaders: (version: string, data: string) => void;
  hasOrganisations: () => boolean;
  canEditField: (field: PermissionFields, organisationId: number, projectId: number) => boolean;
  canViewField: (field: PermissionFields, organisationId: number, projectId: number) => boolean;
  getOrganisation: (organisationId: number) => IOrganisationPermission;
  getAllOrganisations: () => Dictionary<IOrganisationPermission>;
  getAllProgrammes: (organisationId: number) => Dictionary<IProgrammePermission>;
  getAllProjects: (organisationId: number) => Dictionary<IProjectPermission>;
  isOrganisationAdmin: (organisationId: number) => boolean;
  isOranisationOwnerOrAdmin: (organisationId: number) => boolean;
  isOrganisationReader: (organisationId: number) => boolean;
  canEditOrganisation: (organisationId: number) => boolean;
  canManagePermissions: (organisationId: number, projectId: number) => boolean;
  getProject: (organisationId: number, projectId: number) => IProjectPermission;
  getProgramme: (organisationId: number, programmeId: number) => IProgrammePermission;
  getProgrammeByProjectId: (organisationId: number, projectId: number) => IProgrammePermission;
  canContributeOrg: (organisationId: number) => boolean;
  getVersion: () => string;
}

export class PermissionContextModel implements IPermissionsContextModel {
  permissions: Dictionary<IOrganisationPermission>;
  @observable version: string = "";

  updateFromHeaders = (version: string, data: string) => {
    if (this.version === version || typeof data === "undefined" || typeof version === "undefined") return;

    this.setVersion(version);
    this.permissions = this.mapToPermissions(JSON.parse(data));
    this.mapProjectsToProjectammes();

    if (IsDev() || IsStaging()) {
      console.info(this.permissions);
    }
  };

  @action
  setVersion = (version: string) => {
    this.version = version;
  };

  getVersion = () => {
    return this.version;
  };

  canEditOrganisation = (organisationId: number) => {
    const organisation = this.getOrganisation(organisationId);
    if (!organisation) return;
    return organisation.permissionScope >= PermissionScope.OWNER;
  };

  canManagePermissions = (organisationId: number, projectId: number) => {
    const project = this.getProject(organisationId, projectId);
    if (!project) return;
    return project.canManagePermissions;
  };

  canEditField = (field: PermissionFields, organisationId: number, projectId: number) => {
    let project = this.getProject(organisationId, projectId);
    if (!project) return false;
    if (field === PermissionFields.PROJECTS) {
      return project[field].canEdit;
    }
    return project.project.canEdit && project[field].canEdit;
  };

  canViewField = (field: PermissionFields, organisationId: number, projectId: number) => {
    let project = this.getProject(organisationId, projectId);
    if (!project) return false;
    if (field === PermissionFields.PROJECTS) {
      return project[field].canView;
    }
    return project.project.canView && project[field].canView;
  };

  canContributeOrg = (organisationId: number) => {
    const isAdmin = this.isOrganisationAdmin(organisationId);
    const organisationScope = this.getOrganisation(organisationId)?.permissionScope;
    return isAdmin || (organisationScope && organisationScope >= PermissionScope.CONTRIBUTOR);
  };

  isOrganisationAdmin = (organisationId: number) => {
    const org = this.getOrganisation(organisationId);
    if (org === undefined) return false;
    return org.permissionScope === PermissionScope.ADMIN;
  };

  isOranisationOwnerOrAdmin = (organisationId: number) => {
    const org = this.getOrganisation(organisationId);
    if (org === undefined) return false;
    return org.permissionScope >= PermissionScope.OWNER;
  };

  isOrganisationReader = (organisationId: number) => {
    const org = this.getOrganisation(organisationId);
    if (org === undefined) return false;
    return org.permissionScope === PermissionScope.READER;
  };

  mapProjectsToProjectammes = () => {
    _.forEach(this.permissions, k => {
      _.forEach(k.programmes, kk => {
        kk.projects = kk?.projectIds
          ?.map(e => k.projects[e])
          .filter(function (element) {
            return element !== undefined;
          });
      });
    });
  };

  hasOrganisations = () => {
    if(!this.permissions && !this.version) return true;
    if (!this.permissions) return false;
    return Object.keys(this.permissions).length > 0;
  };

  getAllOrganisations = () => {
    return this.permissions;
  };

  getAllProgrammes = (organisationId: number) => {
    return this.permissions[organisationId]?.programmes ?? {};
  };

  getAllProjects = (organisationId: number) => {
    return this.permissions[organisationId]?.projects ?? {};
  };

  mapToPermissions = (data: any[]): Dictionary<IOrganisationPermission> => {
    return _.keyBy(data.map(this.mapOrganisation), "id");
  };

  getProgrammeByProjectId = (organisationId: number, projectId: number) => {
    const programmes = this.getAllProgrammes(organisationId);
    const selProgramme = _.find(programmes, e => {
      return e.projectIds.indexOf(projectId) >= 0;
    });
    return selProgramme;
  };

  getOrganisation = (organisationId: number) => {
    return this.permissions[organisationId];
  };

  getProject = (organisationId: number, projectId: number) => {
    return this.getOrganisation(organisationId)?.projects[projectId] ?? null;
  };

  getProgramme = (organisationId: number, programmeId: number) => {
    return this.getOrganisation(organisationId)?.programmes[programmeId] ?? null;
  };

  mapOrganisation = e => {
    return {
      id: e.id,
      name: e.n,
      permissionScope: e.pl,
      profileImageUrl: e.iu,
      programmes: _.keyBy(e.pr.map(this.mapProgramme), "id"),
      projects: _.keyBy(e.p.map(this.mapProject), "id")
    };
  };

  mapProgramme = k => ({
    id: k.id,
    name: k.n,
    projectIds: k.p
  });

  mapProject = k => {
    return {
      id: k.id,
      name: k.n,
      parentId: k.pid,
      canManagePermissions: this.isTrue(k.per),
      project: {
        canEdit: this.isTrue(k.p.e),
        canView: this.isTrue(k.p.v)
      },
      impact: {
        canEdit: this.isTrue(k.i.e),
        canView: this.isTrue(k.i.v)
      },
      action: {
        canEdit: this.isTrue(k.a.e),
        canView: this.isTrue(k.a.v)
      },
      stakeholder: {
        canEdit: this.isTrue(k.s.e),
        canView: this.isTrue(k.s.v)
      }
    };
  };

  isTrue = val => val === 1;
}
