import * as React from "react";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";

export interface INoOrgAccess { }

export const NoOrgAccess: React.FunctionComponent<INoOrgAccess> = props => {
  return (
    <div className={`not-found-view pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-12">
            <h3 className="d-block mb-3">You do not have access</h3>
          </div>
          <div className="col-12">
            <p>{I18n.t("phrases.redirectNoAccess")}</p>
            <p>{I18n.t("phrases.redirectAccessAdvice")}</p>
          </div>
          <div className="col-12">
            <div>
              <LinkButton href="/" type={ButtonTypes.OUTLINE_PRIMARY}>
                {I18n.t("phrases.goDashboard")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
