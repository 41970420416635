import { IHttp } from "../../IHttp";
import { IActionsApi } from "./IActions.api";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";
import { ActionField } from "../../../../enums";

export class ActionsApi extends ExportableProjectApi<FP.Entities.IAction> implements IActionsApi {
  controller: string = "actions";

  constructor(http: IHttp) {
    super(http, "actions");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  deleteField = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    actionField: ActionField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/${actionField}`,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  reviewRange = async (
    organisationId: number,
    projectId: number,
    ids: number[],
    comment: string,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/review-range`,
      { actionIds: ids, comment },
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    projectId?: number,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getActionsSummary = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/actions-summary`,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  async updateDates(
    organisationId: number,
    projectId: number,
    actionId: number,
    data: FP.Entities.IAction,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> {
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/setDates`,
      data,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  }

  getDetailedByIdAsync = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  getActionCount = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/total-count`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<number>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  addImpactsAsync = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/impacts`,
      impactIds,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/impacts/${impactId}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  getUnassignedImpactsAsync = async (
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/unassignedImpacts`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  getComments = async (organisationId: number, projectId: number, actionId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/comments`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment[]>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  addComment = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/comments`,
      comment,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  async updateField(
    organisationId: number,
    projectId: number,
    actionId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    actionField: ActionField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/${actionField}`,
      data,
      config
    );
    if (res && (res.status === 200 || res.status === 400)) {
      return res.data;
    }
  }
}
