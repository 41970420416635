import React from "react";
import { Services } from "../../../../../../../constants";
import { useAppService } from "../../../../../../../contexts/AppService";
import I18n from "../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { ITagsApi } from "../../../../../../../services/api/v1/tags/ITags.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getAudienceTagFormFields } from "./AudienceTagsSidebar_formFields";

interface ManageAudienceTagFormProps {
  organisationId: number;
  projectId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  closeFn: () => void;
}

export const ManageAudienceTagForm: React.FC<ManageAudienceTagFormProps> = props => {
  const appService = useAppService();
  const tagsProvider = appService.getService<ITagsApi>(Services.TagsApi);

  const { projectStakeholder, closeFn, projectId, organisationId } = props;
  let formFields = getAudienceTagFormFields(tagsProvider, organisationId, projectId, projectStakeholder);
  let formModel = new SingleFormModel();
  let actions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        closeFn();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  formModel.formFields = formFields;
  formModel.actions = actions;

  return <div>{formModel.renderComponent()}</div>;
};
