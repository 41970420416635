import React from "react";
import { Enums } from "../../../../../../enums";

export const TimelineMilestoneLegend: React.FC<{ milestoneTypes?: any[] }> = ({ milestoneTypes }) => {
  const legendItems = [
    {
      key: "notSet",
      label: "Not set",
      milestoneType: Enums.MilestoneType.NOT_SET
    },
    {
      key: "goLive",
      label: "Go Live",
      milestoneType: Enums.MilestoneType.GO_LIVE
    },
    {
      key: "comms",
      label: "Comms",
      milestoneType: Enums.MilestoneType.COMMS
    },
    {
      key: "orgAnnouncement",
      label: "Org. Announcement",
      milestoneType: Enums.MilestoneType.ORG_ANNOUNCEMENT
    },
    {
      key: "changePlan",
      label: "Change Plan",
      milestoneType: Enums.MilestoneType.CHANGE_PLAN
    },
    {
      key: "businessReadiness",
      label: "Business Readiness",
      milestoneType: Enums.MilestoneType.READINESS
    },
    {
      key: "training",
      label: "Training",
      milestoneType: Enums.MilestoneType.TRAINING
    },
    {
      key: "engagementActivity",
      label: "Engagement Activity",
      milestoneType: Enums.MilestoneType.ENGAGEMENT_ACTIVITY
    }
  ];

  let res = [];

  if (milestoneTypes) {
    legendItems.forEach(e => {
      if (milestoneTypes.findIndex(ee => ee === e.milestoneType) >= 0) {
        res.push(e);
      }
    });
  } else {
    res = legendItems;
  }

  return (
    <div className="org-timeline__legend mb-2">
      {res.map(e => {
        return (
          <div className="org-timeline__legend-item" key={e.key}>
            <div className={`milestone milestone--${e.key}`}></div>
            <div className="org-timeline__legend-label ml-2">
              <p className="mb-0">{e.label}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
