import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { ImpactMilestonesSidebarModel } from "./ImpactMilestonesSidebar_model";
import { getImpactMilestonesFormFields } from "./ImpactMilestonesSidebar_fields";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import I18n from "../../../../../../../core/localization/I18n";
import { IModalService } from "../../../../../../../core/modal/IModalService";
interface ImpactMilestonesSidebarProps {
  model: ImpactMilestonesSidebarModel;
  modalService: IModalService;
}

export const ImpactMilestonesSidebar: React.FC<ImpactMilestonesSidebarProps> = observer(({ model, modalService }) => {
  const { impact, impactsProvider, organisationId, projectsProvider, projectId } = model;
  const [formModel] = useState(new SingleFormModel());
  useEffect(() => {
    formModel.formFields = getImpactMilestonesFormFields(
      impactsProvider,
      projectsProvider,
      organisationId,
      projectId,
      impact
    );
  });
  formModel.actions = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => modalService.hide(),
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid pt-3">
      <SingleForm model={formModel} />
    </div>
  );
});
