import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../../constants";
import { IHttpProgressModel } from "../../../../../../../../core/httpProgress/HttpProgress_model";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import { IProjectStakeholdersApi } from "../../../../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ITagsApi } from "../../../../../../../../services/api/v1/tags/ITags.api";

export class StakeholderTagsSidebarModel extends BaseModel {
  appService: AppService;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  tagsProvider: ITagsApi;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  organisationId: number;
  projectId: number;

  constructor(appService: AppService, organisationId: number, projectId: number, projectStakeholderId: number) {
    super();
    this.appService = appService;
    this.projectStakeholdersProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadProjectStakeholder(projectStakeholderId);
  }

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.isLoading = false;
  };

  @action
  loadProjectStakeholder = async (projectStakeholderId: number) => {
    let res = await this.projectStakeholdersProvider.getById(this.organisationId, this.projectId, projectStakeholderId);
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    // let res = await this.tagsProvider.removeImpactTagAssoc(this.organisationId, this.projectId, tagId, this.impact.id);
    // if (!res || res.isError) return;
  };
}
