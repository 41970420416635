import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { Services } from "../../../../constants";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getImpactFormFields } from "../../forms/impact/ImpactFormSection_data";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { IModalService } from "../../../../core/modal/IModalService";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  SaveAndReviewModalTitle
} from "../../../../components/ui/ReviewModal";
import I18n from "../../../../core/localization/I18n";
import { ButtonTypes } from "../../../../components/ui/Button";
import { IProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";

export class EditImpactViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectId: number = null;
  impactId: number = null;
  httpProgress: IHttpProgressModel;
  impactProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable.ref formModel: SingleFormModel;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  isTestUser: boolean;
  returnUrl: string;
  confirmationService: IModalService;
  @observable reviewCommentInput: string;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, isTestUser: boolean, organisationId: number) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.isTestUser = isTestUser;

    this.projectId = parseInt(this.routeProps.match.params["projectId"]);
    this.impactId = parseInt(this.routeProps.match.params["impactId"]);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.projectTeamUserPermissionsProvider = this.appService.getService<IProjectTeamUserPermissionsApi>(Services.ProjectTeamUserPermissionsApi);
    this.confirmationService = appService.getService<IModalService>(Services.ConfirmationService);
    this.organisationId = organisationId;
    this.loadImpact();

    const queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);
    // check if there is a previous url so it redirects with the previous url in the string params
    const prevUrl = queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");
    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${organisationId}/projects/${this.projectId}/impacts/${this.impactId}${prevUrl ? "?" + QUERY_STRING_PARAMS.RETURN_URL + "=" + encodeURIComponent(prevUrl) : ""
      }`
    );
  }

  onMount = async (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => { };

  @action
  loadImpact = async () => {
    let res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, this.impactId);
    if (!res || res.isError) return;
    this.impact = res.payload;
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactFormFields(this.organisationId, this.projectId, this.isTestUser, this.projectTeamUserPermissionsProvider, this.impact);
    this.isLoading = false;
  };

  updateImpact = async () => {
    this.formModel.isSaving = true;
    let res: any = await this.formModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.impactProvider.update(
      this.organisationId,
      this.projectId,
      this.impactId,
      res as FP.Entities.IImpact
    );
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;
    this.routeProps.history.push(this.returnUrl);
  };

  updateImpactAndMarkAsReviewed() {
    // Set mark as reviewed
    const markAsReviewedField = this.formModel.formFields.find(field => field.key === "markAsReviewed");

    if (markAsReviewedField) {
      markAsReviewedField.value = true;
    }

    // Set the review notes
    const reviewedNotes = this.formModel.formFields.find(field => field.key === "reviewNotes");

    if (reviewedNotes && this.reviewCommentInput) {
      reviewedNotes.value = this.reviewCommentInput;
    }

    // Hide the modal and save the form
    this.confirmationService.hide();
    this.updateImpact();
  }

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showReviewModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <SaveAndReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("forms.confirmSaveAndReview"),
        I18n.t("forms.cancelSaveAndReview"),
        ReviewModalComponentProps,
        async () => {
          await this.updateImpactAndMarkAsReviewed();
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };
}
