import React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { ReviewModalComponentProps, ReviewModalContent, ReviewModalTitle } from "../../../../components/ui/ReviewModal";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { StakeholderGrid } from "./StakeholderGrid/StakeholderGrid_view";
import { Animations } from "../../../../core/util/Animations";
import { gridSideModalComponentProps } from "../../../../core/grids/GridSideModals";
import { ActionGrid } from "./ActionGrid/ActionGrid_view";

export const SHOW_IMPACT_DELETE_CONFIRM_MODAL = (
  modalService: IModalService,
  impactIds: number[],
  removeImpactRange: (impactIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeImpactFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmImpactRangeRemove", { name: impactIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await removeImpactRange(impactIds);
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_IMPACT_REVIEW_CONFIRM_MODAL = (
  modalService: IModalService,
  impactIds: number[],
  reviewImpactRange: (impactIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewImpactRange(impactIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_STAKEHOLDER_IMPACT_LINK_MODAL = (
  modalService: IModalService,
  projectId: number,
  impactIds: number[]
) => {
  modalService.show({
    showClose: true,
    title: null,
    content: <StakeholderGrid projectId={projectId} onAssignSuccess={modalService.hide} impactIds={impactIds} />,
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_ACTION_LINK_MODAL = (
  modalService: IModalService,
  projectId: number,
  impactIds: number[]
) => {
  modalService.show({
    showClose: true,
    title: null,
    content: <ActionGrid projectId={projectId} onAssignSuccess={modalService.hide} impactIds={impactIds} />,
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};
