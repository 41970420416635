import { ITimelineItem } from "@flightpath/coreui/dist/widgets/timeline/Timeline_model";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { ReactCalendarItemRendererProps } from "react-calendar-timeline";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { Enums } from "../../../../../../enums";

export const TimelineItem: {
  (orgId: number, shouldNotLink?: boolean): React.FC<ReactCalendarItemRendererProps<ITimelineItem>>;
} = (organisationId, shouldNotLink) => props => {
  const { item, getItemProps } = props;
  const itemProps = getItemProps({});
  const top = parseInt((itemProps.style.top as string).replace("px", ""));
  itemProps.style = {
    ...itemProps.style,
    height: "12px",
    lineHeight: "12px",
    background: "#58C8D1",
    border: `1px solid ${"#58C8D1"}`,
    top: top + 6 + "px"
  };
  const project: FP.Entities.IProject = item.data;

  const totalDays = item.end_time.diff(item.start_time, "days");
  if (shouldNotLink) {
    return <BarLine itemProps={itemProps} project={project} totalDays={totalDays} item={item} />;
  }
  return (
    <Link key={item.id} target="_blank" to={`/organisations/${organisationId}/projects/${item.id}`}>
      <BarLine itemProps={itemProps} project={project} totalDays={totalDays} item={item} />
    </Link>
  );
};

const BarLine: React.FC<any> = ({ itemProps, project, totalDays, item }) => {
  const groupMilestoned = _.groupBy(project.milestones, e => e.deadline);
  return (
    <div {...itemProps} title={null}>
      {_.map(groupMilestoned, e => {
        if (e.length > 1) {
          return <GroupedMilestones milestones={e} totalDays={totalDays} startTime={item.start_time} />;
        }
        return (
          <Milestone
            milestone={e[0]}
            totalDays={totalDays}
            milestoneDayDifference={moment(e[0].deadline).diff(item.start_time, "days")}
          />
        );
      })}
    </div>
  );
};

const Milestone: React.FC<{
  totalDays: number;
  milestoneDayDifference: number;
  milestone: FP.Entities.IMilestone;
}> = ({ totalDays, milestoneDayDifference, milestone }) => {
  const left = (milestoneDayDifference * 100) / totalDays + "%";
  const cs = milestone.milestoneType ? `milestone--${Enums.Translator.MilestoneTypeKey(milestone.milestoneType)}` : "";
  return (
    <Tooltip
      theme="light"
      followCursor
      html={
        <>
          <h4 className="text-center">{moment(milestone.deadline).format("L")}</h4>
          <p className="mb-0">{milestone.name}</p>
        </>
      }
    >
      <div className={`timeline-milestone milestone ${cs}`} style={{ left }}></div>
    </Tooltip>
  );
};

const GroupedMilestones: React.FC<{ milestones: FP.Entities.IMilestone[]; totalDays: number; startTime: any }> = ({
  milestones,
  totalDays,
  startTime
}) => {
  const milestoneDayDifference = moment(milestones[0].deadline).diff(startTime, "days");
  const left = (milestoneDayDifference * 100) / totalDays + "%";

  return (
    <Tooltip theme="light" followCursor html={<MilestoneNames milestones={milestones} />}>
      <div className={`timeline-milestone milestone--grouped milestone`} style={{ left }}>
        {milestones.length}
      </div>
    </Tooltip>
  );
};

const MilestoneNames: React.FC<{ milestones: FP.Entities.IMilestone[] }> = ({ milestones }) => {
  return (
    <div className="milestone-names">
      <h4 className="text-center">{moment(milestones[0].deadline).format("L")}</h4>
      {_.map(milestones, (milestone, i) => {
        let cs = milestone.milestoneType
          ? `milestone--${Enums.Translator.MilestoneTypeKey(milestone.milestoneType)}`
          : "";
        return (
          <div key={i} className={`mb-0 d-flex align-items-center ${i < milestones.length - 1 ? "mb-2" : ""}`}>
            <div className={`milestone ${cs} mr-2`}></div>
            <p className="mb-0">{milestone.name}</p>
          </div>
        );
      })}
    </div>
  );
};
