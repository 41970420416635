import { ColDef } from "ag-grid-community";
import AppService from "../../../../../../contexts/AppService";
import {
  colCreatedByName,
  colCreatedOn,
  colDescription,
  colModifiedByName,
  colName,
  colSelected,
  colUpdatedOn
} from "../../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../../core/grids/GridFunctions";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { BenefitField } from "../../../../../../enums";
import { SHOW_BENEFIT_DESCRIPTION_SIDEPANEL } from "./modals/BenefitGrid_modals";

interface IGetBenefitsGridColumns {
  // modals: IActionGridModals;
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  modalService: IModalService;
}

export const GetBenefitsGridColumns = (modelProps: IGetBenefitsGridColumns) => {
  let result: ColDef[] = [];

  if (modelProps.canEdit) {
    result.push(colSelected());
  }

  result.push(colName(modelProps.canEdit, modelProps.gridFns.updateTextField, BenefitField.name));
  result.push(
    colDescription(
      modelProps.canEdit,
      SHOW_BENEFIT_DESCRIPTION_SIDEPANEL(
        AppService,
        modelProps.modalService,
        modelProps.organisationId,
        modelProps.projectId
      )
    )
  );
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());

  return result;
};
