import React from "react";
import { useParams } from "react-router-dom";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { CanEdit } from "../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../core/localization/I18n";
import { CountDisplay } from "../components/CountDisplay";
import { ImageDisplay } from "../components/ImageDisplay";
import actionImage from "../components/assets/actions.svg";
import impactImage from "../components/assets/impacts.svg";
import audienceImage from "../components/assets/audience.svg";
import stakeholderImage from "../components/assets/stakeholder.svg";
import { CountDisplayFrame } from "../components/CountDisplayFrame";
import { observer } from "mobx-react-lite";
import { useCanViewField } from "../../../../../contexts/permissions/PermissionHooks";
import { TimelineView } from "../../../../../components/widgets/timeline";
import { TimelineMilestoneLegend } from "../../../organisations/organisationDashboard/dashboardContentComponent/DashboardTimeline/components/TimelineMilestoneLegend";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";

export const ProjectMainPanel: React.FC<any> = observer(({ parentModel }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const { project } = parentModel;
  const baseUrl = `/organisations/${organisationId}/projects/${projectId}`;
  const canViewImpacts = useCanViewField(PermissionFields.IMPACTS, organisationId, +projectId);
  const canViewStakeholders = useCanViewField(PermissionFields.STAKEHOLDERS, organisationId, +projectId);
  const canViewActions = useCanViewField(PermissionFields.ACTIONS, organisationId, +projectId);

  return (
    <div className="project-main-panel container-fluid pt-5">
      <div className="row mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <h1 className="mb-0">
              {I18n.t("phrases.project")} | {project?.name}
            </h1>
            <CanEdit field={PermissionFields.PROJECTS} projectId={+projectId}>
              <LinkButton
                id="manageProjectButton"
                className="ml-auto mr-2"
                type={ButtonTypes.PRIMARY}
                href={`/organisations/${organisationId}/projects/${projectId}/settings`}
              >
                {I18n.t("phrases.manageProject")}
              </LinkButton>
            </CanEdit>
            <ButtonIcon
              symbol={IconSymbols.MenuArrowRight}
              onClick={parentModel.showInfoModal}
              type={ButtonTypes.LINK}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          {project && (
            <TimelineView
              model={parentModel.timelineModel}
              hideControls={true}
              timelineProps={{ lineHeight: 40 } as any}
              className={`project-overview__timeline`}
              labels={{
                noItemsText: I18n.t("phrases.noProjectsOrMilestones"),
                today: I18n.t("phrases.today"),
                scrollLeft: I18n.t("phrases.scrollLeft"),
                scrollRight: I18n.t("phrases.scrollRight"),
                zoomOut: I18n.t("phrases.zoomOut"),
                zoomIn: I18n.t("phrases.zoomIn"),
                resetZoom: I18n.t("phrases.resetZoom")
              }}
            />
          )}
        </div>
        {project?.milestones && (
          <div className="col-12 mb-2">
            <TimelineMilestoneLegend milestoneTypes={project.milestones.map(e => e.milestoneType)} />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-12 col-sm-6">
          <CountDisplayFrame
            countDisplay={
              <CountDisplay
                subtitle={I18n.t("entities.stakeholders")}
                count={parentModel.stakeholders.length}
                url={`${baseUrl}/stakeholders`}
                label={I18n.t("visualisations.stakeholderAnalysis")}
                canView={canViewStakeholders}
                parmissionLabel={I18n.t("warnings.noStaholderAccessPanel")}
              />
            }
          >
            <ImageDisplay
              isDisabled={parentModel.stakeholders.length === 0 || !canViewStakeholders}
              title={I18n.t("visualisations.stakeholderMap")}
              url={`${baseUrl}/stakeholders/visualisations/1/1`}
              img={stakeholderImage}
            />
          </CountDisplayFrame>
        </div>
        <div className="col-12 col-sm-6">
          <CountDisplayFrame
            countDisplay={
              <CountDisplay
                subtitle={I18n.t("entities.audiences")}
                count={parentModel.audiences.length}
                url={`${baseUrl}/audiences`}
                label={I18n.t("visualisations.audienceAnalysis")}
                canView={canViewStakeholders}
                parmissionLabel={I18n.t("warnings.noStaholderAccessPanel")}
              />
            }
          >
            <ImageDisplay
              isDisabled={parentModel.audiences.length === 0 || !canViewStakeholders}
              title={I18n.t("visualisations.audienceMap")}
              url={`${baseUrl}/stakeholders/visualisations/2/1`}
              img={audienceImage}
            />
          </CountDisplayFrame>
        </div>
        <div className="col-12 col-sm-6">
          <CountDisplayFrame
            countDisplay={
              <CountDisplay
                subtitle={I18n.t("entities.impacts")}
                count={parentModel.impacts.length}
                canView={canViewImpacts}
                url={`${baseUrl}/impacts`}
                label={I18n.t("visualisations.changeImpactAssesment")}
                parmissionLabel={I18n.t("warnings.noImpactAccessPanel")}
              />
            }
          >
            <ImageDisplay
              isDisabled={parentModel.impacts.length === 0 || !canViewImpacts}
              title={I18n.t("visualisations.impactReport")}
              url={`${baseUrl}/impacts/impact-assessment`}
              img={impactImage}
            />
          </CountDisplayFrame>
        </div>
        <div className="col-12 col-sm-6">
          <CountDisplayFrame
            countDisplay={
              <CountDisplay
                canView={canViewActions}
                subtitle={I18n.t("entities.actions")}
                count={parentModel.actions.length}
                url={`${baseUrl}/actions`}
                label={I18n.t("visualisations.changeActionPlan")}
                parmissionLabel={I18n.t("warnings.noActionAccessPanel")}
              />
            }
          >
            <ImageDisplay
              isDisabled={parentModel.actions.length === 0 || !canViewActions}
              title={I18n.t("visualisations.actionPlan")}
              url={`${baseUrl}/actions/visualisations`}
              img={actionImage}
            />
          </CountDisplayFrame>
        </div>
      </div>
    </div>
  );
});
