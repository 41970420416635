import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AppService } from "strikejs-app-service";
import * as H from "history";
import { LOCAL_STORAGE_FILTERS, Services } from "../../../../constants";
import _ from "lodash";
import { IModalService } from "../../../../core/modal/IModalService";
import { ButtonIcon, ButtonTypes, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { ImpactGroupModal } from "../impactsView/ImpactGroupModal/ImpactGroupModal_view";
import { ImpactGroupModalModel } from "../impactsView/ImpactGroupModal/ImpactGroupModal_model";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { FilterModel, IFilterAttribute, FilterOperator, IFilterModel } from "../../../../core/filter/Filter_model";
import { GetImpactGroupListFilters } from "./ImpactGroupListViewFilter_fields";
import { IExportService, ExportService } from "../../../../services/local/export/ExportService";
import moment from "moment";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { Animations } from "../../../../core/util/Animations";
import { Panel } from "../../../../components/ui/Panel";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { ImpactGroupNotesSideBar } from "./ImpactGroupNotesSideBar/ImpactGroupNotesSideBar";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { IInfiniteLoaderModel, InfiniteLoaderModel } from "../../../../core/filter/InfiniteLoader_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { action, observable } from "mobx";

export class ImpactGroupsViewModel extends BaseModel {
  authUser: FP.Entities.IUser;
  projectProvider: IProjectsApi;
  impactGroupProvider: IImpactGroupsApi;
  appService: AppService;
  modalService: IModalService;
  filterModel: IFilterModel<FP.Entities.IImpactGroup>;
  projectId: number;
  exportService: IExportService<FP.Entities.IImpactGroup>;
  @observable viewType: "groups" | "impacts" = "groups";
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  @observable impactCount: number;
  @observable impactGroupCount: number;
  @observable isLoading: boolean = true;
  @observable impactGroups: FP.Entities.IImpactGroup[];
  infiniteLoaderModel: IInfiniteLoaderModel;
  history: H.History<unknown>;
  httpProgress: IHttpProgressModel;

  constructor(appService: AppService, history: H.History<unknown>, organisationId: number, projectId: number) {
    super();
    this.appService = appService;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.impactGroupProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.history = history;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.installInfiniteLoader();
    this.installFilter();
    this.installExportService();
    this.setCountsForImpactAndImpactGroup();
    this.filterModel.setFromLocalStorage();
  }

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.impactCount = res.payload.impactCount;
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  installInfiniteLoader = () => {
    this.infiniteLoaderModel = new InfiniteLoaderModel();
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      infiniteLoaderModel: this.infiniteLoaderModel,
      initOpts: {
        filterCb: async filterOptions => {
          return await this.impactGroupProvider.getFiltered(this.organisationId, this.projectId, filterOptions);
        },
        uniqueIdentifier: this.projectId
      },
      localStorageName: LOCAL_STORAGE_FILTERS.IMPACTGROUPS,
      onInfiniteLoadReset: this.resetData
    };
    this.filterModel = new FilterModel(config);

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const nameFilter: IFilterAttribute = {
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const refFilter: IFilterAttribute = {
      key: "refNumber",
      label: I18n.t("filters.refNumber"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const typeFilter: IFilterAttribute = {
      key: "impactType",
      label: I18n.t("filters.impactType"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ProgressStatus(parseInt(k));
      }
    };
    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addFilter(projectFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(refFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(typeFilter);
    this.filterModel.setConfig({
      formFields: GetImpactGroupListFilters,
      onDataLoaded: d => {
        this.setImpactGroups(d);
      }
    });
  };

  loadPageData = async () => {
    const data = await this.filterModel.loadData();
    if (data) {
      this.setImpactGroups(data);
    }
  };

  onMount = async () => {
    this.loadProject();
    const data = await this.loadGroups();
    this.setImpactGroups(data);
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
  };

  @action
  setImpactGroups = (impactGroups: any[]) => {
    this.impactGroups = _.union(this.impactGroups, impactGroups);
    this.infiniteLoaderModel.setTotal(this.impactGroups.length);
    this.isLoading = false;
  };

  @action
  resetData = () => {
    this.impactGroups = [];
    this.setImpactGroups(this.impactGroups);
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IImpactGroup>(this.appService, this.filterModel, {
      filename: `${_.kebabCase(I18n.t("entities.highLevelImpacts"))}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.impactGroupProvider.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: [
        {
          key: "refNumber",
          label: I18n.t("table.refNo")
        },
        {
          key: "name",
          label: I18n.t("table.name")
        },
        {
          key: "changeBenefitText",
          label: I18n.t("table.changeBenefitText")
        },
        {
          key: "type",
          label: I18n.t("table.type"),
          selector: item => item.impactTypes
        },
        {
          key: "locations",
          label: I18n.t("table.locations")
        },
        {
          key: "impactLevels",
          label: I18n.t("table.impactLevels")
        },
        {
          key: "impactLevelsValue",
          label: I18n.t("table.impactLevelsValues")
        },
        {
          key: "impactRefs",
          label: I18n.t("table.impacts")
        }
      ]
    });
  };

  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  @action
  async loadGroups() {
    await this.filterModel.loadData();
    return this.impactGroups;
  }

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 3) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/impact-assessment`);
      return;
    }

    if (newTabIndex === 2) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroupss`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts`);
  };

  @action
  showImpactConfirmDeleteModal = (projectId: number, impactGroup: FP.Entities.IImpactGroup) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeHighLevelImpactFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impactGroup.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          await this.removeImpactGroup(projectId, impactGroup.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  removeImpactGroup = async (projectId: number, impactGroupId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.impactGroupProvider.remove(this.organisationId, projectId, impactGroupId);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    this.removeImpactGroupFromList(impactGroupId);
    this.infiniteLoaderModel.setConfig(res.pagination);
    return res.payload;
  };

  @action
  removeImpactGroupFromList = (impactGroupId: number) => {
    const list = _.remove(this.impactGroups, e => e.id !== impactGroupId);
    this.impactGroups = list;
  };

  onUnmount = () => { };

  showModal = (row: SmartTableRowProps) => {
    const id = row.content.id;
    this.showImpactGroupModal(id);
  };

  showNotesModal = (projectId: number, row: SmartTableRowProps) => {
    const impactGroupId = row.content.id;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="mr-1 ml-auto"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impactgroups/${impactGroupId}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Pencil}
            onClick={this.modalService.hide}
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impactgroups/${impactGroupId}/edit`}
          />
          <ButtonIcon
            key={"1"}
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            className=""
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: (
        <ImpactGroupNotesSideBar
          authUser={this.authUser}
          appService={this.appService}
          organisationId={this.organisationId}
          projectId={this.projectId}
          impactGroupId={impactGroupId}
        />
      ),

      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showImpactGroupModal = (id: number) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="mr-1 ml-auto"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impactgroups/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <CanEdit field={PermissionFields.IMPACTS} projectId={this.projectId}>
            <LinkButtonIcon
              key="2"
              className="mr-1"
              type={ButtonTypes.OUTLINE_PRIMARY}
              iconSize={Enums.UiSizes.SM}
              symbol={IconSymbols.Pencil}
              onClick={this.modalService.hide}
              href={`/organisations/${this.organisationId}/projects/${this.projectId}/impactgroups/${id}/edit`}
            />
          </CanEdit>
          <ButtonIcon
            key={"1"}
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            className=""
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: <ImpactGroupModal model={new ImpactGroupModalModel(this, this.projectId, id, this.organisationId)} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };
}
