import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { PROGRESS_STATUS_OPTIONS, Services } from "../../../../../constants";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import I18n from "../../../../../core/localization/I18n";
import { EntityTypes, GridTypes } from "../../../../../enums";
import { IProjectTeamUserPermissionsApi } from "../../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { ActionsHub } from "../../../../../services/hubs/ActionsHub/Actions_hub";
import { IActionTypeApi } from "../../../../../services/api/v1/actionTypes/IActionType.api";
import { IActionsApi } from "../../../../../services/api/v1/actions/IActions.api";
import { getActionGridModals } from "../../Actions/ActionListView/AcGridView/modals/AcGridView_modals";
import { GetActionGridViewColumns } from "./ActionGrid_columns";

export class ActionGridViewModel implements IGridModel {
  appService: AppService;
  organisationId: number;
  projectId: number;
  @observable.ref actionTypes: FP.Entities.IActionType[];
  // @observable.ref projectTeamMembers: any[];
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypeProvider: IActionTypeApi;
  actionsProvider: IActionsApi;
  // projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  actionsHub: ActionsHub;
  userCanViewImpacts: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  userCanViewStakeholders: boolean;

  constructor(appService: AppService, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    this.appService = appService;
    this.actionTypeProvider = appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.actionsProvider = appService.getService<IActionsApi>(Services.ActionsApi);
    // this.projectTeamUserPermissionsProvider = appService.getService<IProjectTeamUserPermissionsApi>(
    //   Services.ProjectTeamUserPermissionsApi
    // );
    this.actionsHub = appService.getService(Services.ActionsHub);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.mapProgressStatuses();
  }

  onMount = async () => {
    Promise.all([this.loadActionTypes(), this.loadProjectTeamMembers()]).then(values => {
      this.isLoading = false;
    });
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetActionGridViewColumns({
      userCanViewImpacts: this.userCanViewImpacts,
      progressStatuses: this.progressStatuses,
      actionTypes: this.actionTypes,
      // projectTeamMembers: this.projectTeamMembers,
      modals: getActionGridModals(this.appService, this.organisationId, this.projectId),
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: null,
      userCanViewStakeholders: this.userCanViewStakeholders,
      pinned: null
    });
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypeProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.actionTypes = res.payload;
  };

  @action
  loadProjectTeamMembers = async () => {
    // const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    // if (!res || res.isError) return;
    // this.projectTeamMembers = res.payload;
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
