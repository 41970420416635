import React from "react";

interface CountDisplayFrameProps {
  countDisplay: React.ReactNode;
}

export const CountDisplayFrame: React.FC<CountDisplayFrameProps> = props => {
  return (
    <div className="count-display-frame mb-2">
      <div className="count-display-frame__block count-display-frame__block--left">{props.countDisplay}</div>
      <div className="count-display-frame__block count-display-frame__block--right">{props.children}</div>
    </div>
  );
};
