import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Async } from "react-async";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { ListingView } from "../../../../../../components/ui/_forms/Listing";
import { DropdownList } from "../../../../../../components/widgets/dropdownList/DropdownList";
import I18n from "../../../../../../core/localization/I18n";
import { EntityTypes } from "../../../../../../enums";
import { TagSettingsOverviewModalModel } from "./TagSettingsOverviewModal_model";

export interface TagSettingsOverviewModalViewProps {
  model: TagSettingsOverviewModalModel;
}

export const TagSettingsOverviewModalView: React.FC<TagSettingsOverviewModalViewProps> = observer(({ model: m }) => {
  const [model] = useState(() => m);
  const { tag } = model;

  const getDropdownTitle = (entityType: string, dataLength: number) =>
    I18n.t(`entities.${entityType}`) + `: ${dataLength}`;
  const getListingTitle = (entity: FP.Entities.ITagRelatedEntity) => (
    <Link to={entity.url} onClick={model.modalService.hide}>
      <strong>{entity.project.name}</strong> | <span>{entity.name}</span>
    </Link>
  );

  return (
    <Async promiseFn={model.load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid impact-compact-view mt-4 tag-overview">
            <div className="row mb-5">
              <div className="col">
                <h1 className="mb-0">
                  {I18n.t("phrases.tagOverview")} | {tag.text}
                </h1>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <DropdownList
                  title={getDropdownTitle("actionTags", tag.actionTags.length)}
                  className="mb-2"
                  hasData={tag.actionTags.length > 0}
                >
                  <ListingView
                    className="mb-1"
                    data={tag.actionTags}
                    selector={(e: FP.Entities.ITagRelatedEntity) => {
                      return getListingTitle(e);
                    }}
                    removeFn={idx => {
                      let datum = tag.actionTags[idx];
                      return model.showTagAssocConfirmDeleteModal(datum, EntityTypes.ACTION);
                    }}
                  />
                </DropdownList>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <DropdownList
                  title={getDropdownTitle("impactTags", tag.impactTags.length)}
                  className="mb-2"
                  hasData={tag.impactTags.length > 0}
                >
                  <ListingView
                    data={tag.impactTags}
                    selector={e => getListingTitle(e)}
                    removeFn={idx => {
                      let datum = tag.impactTags[idx];
                      return model.showTagAssocConfirmDeleteModal(datum, EntityTypes.IMPACT);
                    }}
                  />
                </DropdownList>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <DropdownList
                  title={getDropdownTitle("projectStakeholderTags", tag.projectStakeholderTags.length)}
                  className="mb-2"
                  hasData={tag.projectStakeholderTags.length > 0}
                >
                  <ListingView
                    data={tag.projectStakeholderTags}
                    selector={e => getListingTitle(e)}
                    removeFn={idx => {
                      let datum = tag.projectStakeholderTags[idx];
                      return model.showTagAssocConfirmDeleteModal(datum, EntityTypes.PROJECT_STAKEHOLDER);
                    }}
                  />
                </DropdownList>
              </div>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
