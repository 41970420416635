import { observer } from "mobx-react-lite";
import React from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ImpactProcessesSidebarModel } from "./ImpactProcessesSidebar_model";
import { IModalService } from "../../../../../../../core/modal/IModalService";

export interface ImpactProcessesSidebarProps {
  model: ImpactProcessesSidebarModel;
}

export const ImpactProcessesSidebar: React.FC<ImpactProcessesSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
