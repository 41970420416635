import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import {
  getTopProjectAudienceFormFields,
  getBottomProjectAudienceFormFields,
  getProjectAudienceSearchField
} from "../../forms/projectStakeholder/ProjectStakeholder_data";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { IModalService } from "../../../../core/modal/IModalService";
import { INIT_FILTER_OPTIONS } from "../../../../services/api/filteredApi/FilteredApiModel";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { getAudienceFormFields } from "../../forms/stakeholder/StakeholderFormSection_data";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { Panel } from "../../../../components/ui/Panel";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";

export class AddAudienceModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectProvider: IProjectsApi;
  orgProvider: IOrganisationsApi;
  stakeholderProvider: IStakeholdersApi;
  httpProgress: IHttpProgressModel;
  localStorageService: ILocalStorageService;
  modalService: IModalService;
  projectId: number;
  orgId: number;
  searchFormModel: SingleFormModel;
  createFormModel: SingleFormModel;
  @observable isLoading: boolean = true;
  @observable.ref audience: FP.Entities.IStakeholder;
  @observable.ref topProjectAudienceFormModel: SingleFormModel;
  @observable.ref bottomProjectAudienceFormModel: SingleFormModel;
  projectStakeholderProvider: IProjectStakeholdersApi;
  stakeholderGroupProvider: IStakeholderGroupsApi;
  tagsProvider: ITagsApi;

  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.projectId = parseInt(routeProps.match.params["projectId"]);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.stakeholderProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.stakeholderGroupProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.setCreateForm();
    this.setSearchForm();
    this.isLoading = false;
  }

  onMount = () => {};

  onUnmount = () => {};

  @action
  setAudience = (audience: FP.Entities.IStakeholder) => {
    this.audience = audience;
  };

  @action
  setCreateForm = () => {
    this.createFormModel = new SingleFormModel();

    this.createFormModel.formFields = getAudienceFormFields(this.orgId, this.orgProvider, null);

    this.createFormModel.actions = [
      {
        id: "create",
        label: I18n.t("phrases.create"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          className: "ml-auto"
        },
        onAction: this.createStakeholderAudience
      }
    ];
  };

  @action
  setSearchForm = () => {
    this.searchFormModel = new SingleFormModel();
    this.searchFormModel.formFields = getProjectAudienceSearchField(
      this.orgId,
      this.projectProvider,
      this.checkProjectProfileExists,
      this.projectId,
      Enums.StakeholderType.AUDIENCE
    );
  };

  @action
  createStakeholderAudience = async () => {
    const formRes = await this.createFormModel.submit();

    if (!formRes) return;

    this.httpProgress.showOverlay();
    let res = await this.stakeholderProvider.create(this.orgId, formRes as FP.Entities.IStakeholder);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    if (res.payload) {
      this.setAudience(res.payload);
    }

    this.setTopProjectStakeholderFormModel();
    this.setBottomProjectStakeholderFormModel();
  };

  addAudienceToProject = async () => {
    this.topProjectAudienceFormModel.isSaving = true;
    this.bottomProjectAudienceFormModel.isSaving = true;
    const topFormRes = await this.topProjectAudienceFormModel.submit();
    const bottomFormRes = await this.bottomProjectAudienceFormModel.submit();

    let formRes = { ...topFormRes, ...bottomFormRes };

    if (!formRes) return;

    this.httpProgress.showOverlay();
    const res = await this.projectStakeholderProvider.create(
      this.orgId,
      this.projectId,
      formRes as FP.Entities.IProjectStakeholder
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    if (res.payload) {
      this.routeProps.history.push(
        `/organisations/${this.orgId}/projects/${this.projectId}/stakeholders/${res.payload.stakeholderId}`
      );
    }
  };

  @action
  resetTabs = () => {
    this.audience = null;
    this.searchFormModel.resetFieldByKey("stakeholdersSearch");
    // this.searchFormModel.formFields[1].reset();
  };

  checkProjectProfileExists = async (audience: FP.Entities.IStakeholder) => {
    this.httpProgress.showOverlay();
    const res = await this.projectStakeholderProvider.getDeletedStakeholder(this.orgId, this.projectId, audience.id);
    this.httpProgress.hideOverlay();

    if (res.payload) {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.audienceExists")}</div>
          </div>
        </div>,
        I18n.t("phrases.bringBackExisting"),
        I18n.t("phrases.createNew"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.activateExistingAudience(res.payload);
          this.modalService.hide();
        },
        () => {
          this.loadAudience(audience);
          this.modalService.hide();
        }
      );
      return;
    } else {
      this.loadAudience(audience);
    }
  };

  @action
  loadAudience = async (audience: FP.Entities.IStakeholder) => {
    let filterOptions = { ...INIT_FILTER_OPTIONS };
    filterOptions.filters = "email==" + audience.email;

    this.httpProgress.showOverlay();
    const res = await this.orgProvider.getStakeholders(this.orgId, filterOptions);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    if (res.payload.length) {
      this.setAudience(res.payload[0]);
    }

    this.setTopProjectStakeholderFormModel();
    this.setBottomProjectStakeholderFormModel();
  };

  activateExistingAudience = async (projectAudience: FP.Entities.IProjectStakeholder) => {
    this.httpProgress.showOverlay();
    projectAudience.lifecycleStatus = Enums.LifecycleStatus.Active;
    let res = await this.projectStakeholderProvider.update(
      this.orgId,
      this.projectId,
      projectAudience.stakeholder.id,
      projectAudience
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    this.routeProps.history.push(
      `/organisations/${this.orgId}/projects/${this.projectId}/stakeholders/${projectAudience.stakeholder.id}`
    );
  };

  @action
  setTopProjectStakeholderFormModel = () => {
    this.topProjectAudienceFormModel = new SingleFormModel();
    this.topProjectAudienceFormModel.formFields = getTopProjectAudienceFormFields(
      this.orgId,
      this.projectId,
      this.audience
    );
  };

  @action
  setBottomProjectStakeholderFormModel = () => {
    this.bottomProjectAudienceFormModel = new SingleFormModel();

    this.topProjectAudienceFormModel.formFields = getBottomProjectAudienceFormFields(
      this.orgId,
      this.projectId,
      this.tagsProvider,
      this.audience
    );
  };
}
