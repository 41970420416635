import React, { useState } from "react";
import { Tabs, Tab } from "../../../../../../components/ui/Tabs";
import I18n from "../../../../../../core/localization/I18n";
import { GroupUsersPermissions } from "../groupUsers/GroupUsersPermissions_view";
import { IndividualUserPermissions } from "../IndividualUsers/IndividualUsersPermissions_view";
import { ProjectTeamUsersPermissions } from "../projectTeamUsers/ProjectTeamUsersPermissions_view";
import { ProductTour } from "../../../../../../components/ui/Tour";
import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { ProjectPermissionsModel } from "./ProjectPermissions_model";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import IntercomLiveChat from "../../../../../../services/external/Intercom/IntercomLiveChat";
import { useOrganisationContext } from "../../../../../../services/local/organisationContext/OrganisationContextModel";

interface ProjectPermissionProps {}
export const ProjectPermission: React.FC<ProjectPermissionProps> = observer(props => {
  const orgContext = useOrganisationContext();
  const [model] = useState(() => new ProjectPermissionsModel());

  let steps = [
    {
      target: ".tour--permissions-projectteam",
      content: I18n.t("tours.permissionsProjectTeam")
    },
    {
      target: ".tour--permissions-all",
      content: I18n.t("tours.permissionsAll")
    }
  ];

  return (
    <div className="project-permissions pt-6">
      <div className="container-fluid mb-1">
        <div className="row">
          <div className="col-9">
            <h1 className="mb-3 d-flex">{I18n.t("phrases.projectPermissions")}</h1>
          </div>
          <div className="col-3  d-flex justify-content-end align-items-center">
            <div className="d-flex flex-row align-items-center c-pointer" onClick={() => model.toggleShowTour()}>
              <Icon className="flex-column mr-1" symbol={IconSymbols.Compass} size={UiSizes.MD} />
              <div className="flex-column">{model.tourText()}</div>
            </div>
            <div className="d-flex flex-row align-items-end ml-3">
              <IntercomLiveChat
                currentOrganisationId={orgContext.currentOrganisation.id}
                currentOrganisationName={orgContext.currentOrganisation.name}
              />
            </div>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={0}
        liClassName="tabs__item--primary"
      >
        <Tab title={<h3 className={`mb-0 tour--permissions-projectteam`}>{I18n.t("phrases.projectTeam")}</h3>}>
          <ProjectTeamUsersPermissions showTour={model.showTour} />
        </Tab>
        <Tab title={<h3 className={`mb-0 tour--permissions-all`}>{I18n.t("phrases.allPermissionsTitle")}</h3>}>
          <GroupUsersPermissions showTour={model.showTour} />
          <IndividualUserPermissions showTour={model.showTour} />
        </Tab>
      </Tabs>
      <ProductTour steps={steps} showTour={model.showTour} />
    </div>
  );
});
