import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IProjectStakeholdersApi } from "../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { StakeholderProfilingType, StakeholderType } from "../../../../../enums";
import { ProjectStakeholderVisualisationsHub } from "../../../../../services/hubs/ProjectStakeholderVisualisationsHub/ProjectStakeholderVisualisations_hub";
import { ProjectAudienceVisualisationsHub } from "../../../../../services/hubs/ProjectAudienceVisualisationsHub/ProjectAudienceVisualisations_hub";
export class StakeholderReportingModel {
  appService: AppService;
  projectId: number;
  organisationId: number;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  @observable.ref stakeholders: FP.Entities.IProjectStakeholderSummary[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  location: any;
  gridToastService: any;
  @observable stakeholderType: StakeholderType;
  @observable profileType: StakeholderProfilingType;
  @observable isLoading: boolean = true;
  hub: ProjectStakeholderVisualisationsHub;

  constructor(
    appService: AppService,
    organisationId: number,
    projectId: number,
    location: any,
    stakeholderType: StakeholderType,
    profileType: StakeholderProfilingType
  ) {
    this.appService = appService;
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.gridToastService = this.appService.getService(Services.GridToastService);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.location = location;
    this.stakeholderType = stakeholderType;
    this.profileType = profileType;

    this.hub =
      this.stakeholderType === StakeholderType.INDIVIDUAL
        ? appService.getService<ProjectStakeholderVisualisationsHub>(Services.ProjectStakeholderVisualisationsHub)
        : appService.getService<ProjectAudienceVisualisationsHub>(Services.ProjectAudienceVisualisationsHub);
  }

  registerSocketEvents = async () => {
    if (this.hub.isConnectionStarted === true) {
      await this.hub.stopConnection();
    }
    await this.hub.startConnection();

    this.hub.onData(d => {
      this.setData(d);
      // this.loadCharts();
    });

    await this.hub.invokeLoadData(this.organisationId, this.projectId);
  };

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.hub.stopConnection();
  };

  @action
  setData = (data: FP.Entities.IProjectStakeholderSummary[]) => {
    this.isLoading = false;
    this.stakeholders = data;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.location.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/visualisations/1/0`
      );
      return;
    }
    if (newTabIndex === 0) {
      this.location.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.location.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );
    if (!res || res.isError) return;
    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };
}
