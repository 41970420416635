import React from "react";
import { TopNav } from "../../../components/ui/TopNav";
import { UiPortal } from "../../../components/ui/UiPortal";
import { useCurrentOrganisation } from "../../../services/local/organisationContext/OrganisationContextModel";
import IntercomLiveChat from "../../../services/external/Intercom/IntercomLiveChat";
import { AuthUserAvatar } from "../../change/navigation/config/AuthUserAvatar";
import HelpNavigation from "../../change/navigation/config/HelpNavigation";
import { NavBreadcrumbs } from "./NavBreadcrumbs";

export const NAV_UI_PORTAL = "nav_ui_portal";

export interface TopNavigationProviderProps extends React.HTMLProps<HTMLDivElement> {}

export const TopNavigationProvider: React.FC<TopNavigationProviderProps> = props => {
  const organisation = useCurrentOrganisation();
  const currentOrganisationId = organisation.id;
  const currentOrganisationName = organisation.name ?? "";
  return (
    <TopNav className="top-navigation">
      <div className="w-100 d-flex justify-content-between bg-black">
        <div className="d-flex align-items-center p-2 pl-4">
          <NavBreadcrumbs />
        </div>
        <div className="d-flex align-items-center p-2 pr-4">
          {/* <SearchNav className="px-2 navigation-view__search" /> */}
          {/* <Notifications className="pl-2" /> */}
          <HelpNavigation
            className="pl-2"
            currentOrganisationName={currentOrganisationName}
            currentOrganisationId={currentOrganisationId}
          />
          <IntercomLiveChat
            currentOrganisationId={organisation.id}
            currentOrganisationName={organisation.name}
            showAsButton="icon"
            className="pl-2"
          />
          <AuthUserAvatar className="pl-2" />
        </div>
      </div>
      <div className="top-navigation__mega-menu">
        <UiPortal name={NAV_UI_PORTAL}></UiPortal>
      </div>
    </TopNav>
  );
};
