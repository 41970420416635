import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { UiPortalContent } from "../../../../../components/ui/UiPortal";
import { useAppService } from "../../../../../contexts/AppService";
import I18n from "../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { DashboardWrapper, DASHBOARD_VIEW_RIGHT_SIDE } from "../DashboardWrapper";
import { StartDateMonth, StartDateYear } from "./components/HeatmapDatesSelectors";
import { HeatmapFilterMenu } from "./components/HeatmapFilterMenu_view";
import { HeatmapFilterPills } from "./components/HeatmapFilterPills";
import { HeatmapGrid } from "./HeatmapGrid/HeatmapGrid_view";
import { HeatmapModel } from "./Heatmap_model";
import { SupportingHeatmapData } from "./SupportingHeatmapData/SupportingHeatmap_view";

export const Heatmap: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const { section } = useParams<{ section: string; projectId: string }>();

  const [model, setModel] = useState(() => new HeatmapModel(appService, organisationId, section));

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    setModel(new HeatmapModel(appService, organisationId, section));
  }, [section, appService, organisationId]);

  return (
    <DashboardWrapper section="heatmap">
      <UiPortalContent name={DASHBOARD_VIEW_RIGHT_SIDE}>
        <HeatmapFilterMenu />
        <div className="organisation-dashboard__divider">
          <p className="mb-0"> | </p>
        </div>
        <p className="organisation-dashboard__start-date-text mb-0">{I18n.t("phrases.startDate")}:</p>
        <StartDateMonth startMonth={model.currentMonth} setStartMonth={model.setCurrentMonth} />
        <StartDateYear startYear={model.currentYear} setStartYear={model.setCurrentYear} />

        <Button type={ButtonTypes.LINK} onClick={() => model.showFilterModal()}>
          <Icon symbol={IconSymbols.FilterLines} className="pr-2" />
          {I18n.t("phrases.filters")}
        </Button>
      </UiPortalContent>

      <div className="container-fluid">
        <HeatmapFilterPills tags={model.tagFilters as any[]} removeFilter={model.removeTagFilterById} />
        <SupportingHeatmapData
          getTagFilterIdsAsString={model.getTagFilterIdsAsString}
          getSupportingData={model.config.getSupportingData}
          tags={model.tagFilters}
        />
        {model.isLoading ? (
          <PositionedSpinner />
        ) : (
          <HeatmapGrid
            heatmapData={model.heatmapData}
            setParentId={model.setParentId}
            getDrillThroughPieData={model.config.getDrillThroughPieData}
            getTagFilterIdsAsString={model.getTagFilterIdsAsString}
            getUrlForPieChart={model.config.getUrlForPieChart}
          />
        )}
      </div>
    </DashboardWrapper>
  );
});
