import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import React from "react";
import { Link } from "react-router-dom";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Pill } from "../../../../components/ui/Pill";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { Enums } from "../../../../enums";
import { AddSmartTableColumnSort } from "../../../../services/local/smartTableViewManager/utils";
import { ImpactGroupsViewModel } from "./ImpactGroupsView_model";

export const GetImpactGroupTableConfig = (model: ImpactGroupsViewModel) => {
  let actions = [];
  const canEditImpacts = PermissionsContext.canEditField(
    PermissionFields.IMPACTS,
    model.organisationId,
    model.projectId
  );
  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    componentProps: {
      className: "px-2",
      type: "link",
      symbol: IconSymbols.Review,
      linkElement: Link
    },
    hrefFn: context => `/organisations/${model.organisationId}/projects/${model.projectId}/impactGroups/${context.id}`
  });
  if (canEditImpacts) {
    actions.push(
      {
        id: "edit",
        label: I18n.t("phrases.edit"),
        rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Pencil,
          linkElement: Link
        },
        hrefFn: context =>
          `/organisations/${model.organisationId}/projects/${model.projectId}/impactGroups/${context.id}/edit`
      },
      {
        id: "delete",
        label: I18n.t("phrases.delete"),
        onAction: (ev, row: any) => {
          model.showImpactConfirmDeleteModal(model.projectId, row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Trash
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    );
  }
  return {
    actions,
    colHeaders: [
      {
        id: "refNumber",
        content: <h5 className="mb-0">{I18n.t("table.refNo")}</h5>,
        selector: (obj: FP.Entities.IImpactGroup) => {
          return (
            <p className="strong mb-0 d-flex">
              <Icon symbol={IconSymbols.ChevronRight} className="mr-2" />
              {obj.refNumber}
            </p>
          );
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, model.filterModel, "Id");
        },
        showSortIcons: true
      },
      {
        id: "name",
        content: <h5 className="mb-0">{I18n.t("table.name")}</h5>,
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, model.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "impactType",
        content: <h5 className="mb-0">{I18n.t("table.type")}</h5>,
        selector: (obj: FP.Entities.IImpactGroup) => {
          return obj.nImpactTypes.map(e => e.name).join(", ");
        }
      },
      {
        id: "impactLevel",
        content: <h5 className="mb-0">{I18n.t("table.levelAverage")}</h5>,
        selector: (obj: FP.Entities.IImpactGroup) => {
          const level = Math.round(parseFloat(obj.impactLevels));

          if (level === 0) {
            return I18n.t("phrases.noImpacts");
          }

          return (
            <Pill style={{ width: "90px" }} type={Enums.Translator.ImpactLevelToPill(level)}>
              {Enums.Translator.ImpactLevel(level)}
            </Pill>
          );
        }
      }
    ] as any[],
    onRowClick: model.showModal,
    tableProps: {}
  };
};
