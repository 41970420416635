import React from "react";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { NavLink, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import I18n from "../../../../core/localization/I18n";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useNavigationContext } from "../contentNav/nav";
import { Animations } from "../../../../core/util/Animations";
import { IEditView, IProgrammePermission, IProjectPermission } from "../../../../contexts/permissions/PermissionsTypes";
import { usePermissionProject } from "../../../../contexts/permissions/PermissionHooks";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Panel } from "../../../../components/ui/Panel";
import { Enums } from "../../../../enums";
import { IsDev, IsStaging } from "../../../../core/util/EnvironmentHelper";

export interface ProjectListNavProps {
  programme?: IProgrammePermission;
  projects: IProjectPermission[];
  project?: IProjectPermission;
}

export const ProjectListNav: React.FC<ProjectListNavProps> = observer(({ programme, projects, project }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId?: string }>();
  const { setCurrentLevel } = useNavigationContext();

  useOrganisationSwitch(true);
  if (programme) {
    projects = programme.projects;
  }

  return (
    <div className="project-list-nav">
      <div
        className="navigation__back navigation__link"
        onClick={() => {
          setCurrentLevel("organisations");
        }}
      >
        <Icon symbol={IconSymbols.ChevronLeft} className="mr-2" />
        <h5 className="text-uppercase mb-0">{I18n.t("phrases.back")}</h5>
      </div>

      <>
        {programme && (
          <NavLink
            className={`navigation__link mb-3`}
            activeClassName="navigation__link--active disabled-link"
            to={`/organisations/${organisationId}/programmes/${programme.id}`}
          >
            {programme.name}
          </NavLink>
        )}
        {projects.length
          ? projects.map(project => {
              return (
                <div className={`project-list-nav__project mb-4 ${Animations.FADE_IN} speed-1`} key={project.id}>
                  <div
                    className={`project-list-nav__label navigation__label${
                      projectId === `${project.id}` ? "--highlight" : ""
                    } pl-2`}
                  >
                    <h5 className="text-uppercase mb-2">{project.name}</h5>
                  </div>

                  <ProjectLinks projectId={project.id} setCurrentLevel={setCurrentLevel} />
                </div>
              );
            })
          : null}
      </>
    </div>
  );
});

const DisabledNavItem: React.FC<{ label: string }> = props => {
  return (
    <Tooltip
      className={`navigation__link__disabled mt-0`}
      shownElement={props.label}
      triggeredOn="hover"
      position={TooltipPositions.BOTTOM}
    >
      <Panel.Panel
        background={Panel.PanelBackgrounds.BG_WHITE}
        className="p-3 ml-1 mb-3 navigation__link__disabled__tooltip"
        hasBorderRadius={true}
        type={Panel.PanelTypes.OUTLINES}
      >
        <p className="mb-0">
          {I18n.t("navigation.noNavAccess")} {props.label}
        </p>
      </Panel.Panel>
    </Tooltip>
  );
};

const EnabledNavItem: React.FC<{
  onClick: any;
  organisationId: number;
  projectPermissionsId: number;
  link: any;
}> = props => {
  return (
    <NavLink
      key={props.link.key}
      data-testid={`navigation-link-${props.link.key}`}
      className={`navigation__link`}
      activeClassName="navigation__link--active disabled-link"
      isActive={match => {
        return match && (match.isExact as any);
      }}
      onClick={props.onClick}
      to={`/organisations/${props.organisationId}/projects/${props.projectPermissionsId}${props.link.href}`}
    >
      {props.link.label}
    </NavLink>
  );
};

const ProjectLinks: React.FC<{ projectId: number; setCurrentLevel: (v: string) => void }> = props => {
  const organisationId = useCurrentOrganisationId();
  let projectPermissions = usePermissionProject(organisationId, props.projectId);
  let links = GetPermissionLinks(projectPermissions);
  return (
    <>
      {links.map(e => {
        let navItem =
          e.hasAccess === false ? (
            <DisabledNavItem label={e.label} />
          ) : (
            <EnabledNavItem
              link={e}
              onClick={() => props.setCurrentLevel("programme")}
              organisationId={organisationId}
              projectPermissionsId={projectPermissions.id}
            />
          );
        return navItem;
      })}
    </>
  );
};

const GetPermissionLinks = (project: IProjectPermission) => {
  if (!project) return [];
  let links = [
    {
      key: "overview",
      label: GetPermLabel(I18n.t("phrases.overview"), project.project),
      href: "",
      hasAccess: hasAccess(project.project)
    },
    {
      key: "stakeholders",
      label: GetPermLabel(I18n.t("entities.stakeholders"), project.stakeholder),
      href: "/stakeholders",
      hasAccess: hasAccess(project.stakeholder)
    },
    {
      key: "impacts",
      label: GetPermLabel(I18n.t("entities.impacts"), project.impact),
      href: "/impacts",
      hasAccess: hasAccess(project.impact)
    },
    {
      key: "actions",
      label: GetPermLabel(I18n.t("entities.actions"), project.action),
      href: "/actions",
      hasAccess: hasAccess(project.action)
    },
    {
      key: "reports",
      label: GetPermLabel(I18n.t("phrases.reports"), project.project),
      href: "/reports",
      hasAccess: hasAccess(project.project)
    }
  ];
  return links;
};

const hasAccess = (item: IEditView) => {
  return item.canEdit || item.canView;
};

const GetPermLabel = (label: string, item: IEditView): any => {
  return (
    <>
      <span className="mr-2">{label}</span>
      {item.canEdit && ShouldShowPermissionsIcons() && (
        <Icon symbol={IconSymbols.Pencil} size={Enums.UiSizes.SM} className="permissions-icon" />
      )}
      {item.canView && ShouldShowPermissionsIcons() && (
        <Icon symbol={IconSymbols.Eye} size={Enums.UiSizes.SM} className="permissions-icon" />
      )}
    </>
  );
};

const ShouldShowPermissionsIcons = () => {
  return IsDev() || IsStaging();
};
