import { ColDef } from "ag-grid-community";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { GridPills } from "../../components/widgets/gridPills/GridPills";
import { EntityTypes, Enums } from "../../enums";
import { baseColumn, baseDateColumn, baseSelectionColumn, baseTextColumn } from "./BaseColumns";
import { IBaseColumnProps } from "./BaseColumns";
import AgGridAutocomplete from "./editors/AgGridAutocomplete";
import { areDatesEqual, makeCellDeletable, openModalOnDelete, stringOrMomentToDate, stringToDate } from "./GridHelpers";
import { progressStatusTextMatcher } from "./filters/TextMatcher";

export const colSelected = (): ColDef => {
  return baseSelectionColumn();
};

export const colRefNumber = (
  organisationId: number,
  projectId: number,
  entityType: EntityTypes,
  props?: IBaseColumnProps
): ColDef => {
  return {
    ...baseTextColumn({ fieldName: "refNumber", heading: "grids.refNo", width: 100, pinned: "left", ...props }),
    cellRenderer: params => {
      const mainUrl = `/organisations/${organisationId}/projects/${projectId}`;
      const itemId = params.data.id;
      return (
        <Link key={itemId} to={`${mainUrl}/${entityType}/${itemId}`}>
          <span className="hover-link">{params.data.refNumber}</span>
        </Link>
      );
    }
  };
};

export const colName = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  url?: string,
  props?: IBaseColumnProps
): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "name",
      heading: "grids.name",
      width: 150,
      pinned: "left",
      autoHeight: true,
      editable: canEdit,
      ...props
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      saveFn(field, params.node.id, params.newValue);
      return true;
    }
  };

  if (url) {
    res = {
      ...res,
      cellRenderer: params => {
        const itemId = params.data.id;
        const mainUrl = `${url}/${params.data.stakeholderId}`;
        return (
          <Link key={itemId} to={`${mainUrl}`}>
            <span className="hover-link">{params.data.name}</span>
          </Link>
        );
      }
    };
  }
  return res;
};

export const colOwner = (
  canEdit: boolean,
  projectTeamMembers: any[],
  saveFn: any,
  field: number,
  props: IBaseColumnProps
): ColDef => {
  let res = {
    ...baseTextColumn({ fieldName: "ownerName", heading: "grids.owner", editable: canEdit, ...props }),
    valueGetter: e => {
      if (!e.data.ownerName) return;
      return `${e.data.ownerName ?? ""}`;
    },
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.ownerId = params.newValue.id;
      params.data.ownerName = `${params.newValue.firstName} ${params.newValue.lastName}`;

      saveFn(field, params.node.id, params.newValue.id);
      return true;
    },
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    cellEditorParams: {
      field: "owner",
      getValueLabel: (ee: any) => {
        if (!projectTeamMembers) return "";

        const user = projectTeamMembers.find(e => e.id === ee.id);
        if (!user) return "";
        return user.firstName + " " + user.lastName;
      },
      options: projectTeamMembers ?? [],
      allowFreeText: true,
      isPerson: true,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return _.filter(items, (item: FP.Entities.IUser) => {
          const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
          const lowerEmail = item.email.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
        });
      }
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colTags = (canEdit: boolean, editFn?: any): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({ fieldName: "tagNames", heading: "grids.tags", width: 500, editable: false, autoHeight: true }),
    cellRenderer: e => <GridPills text={e.data.tagNames} />
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => editFn(e.data);
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colStartDate = (canEdit: boolean, saveFn: any, field: number, props: IBaseColumnProps): ColDef => {
  let res = {
    ...baseDateColumn({ fieldName: "startDate", heading: "grids.startDate", editable: canEdit, ...props }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      let newDate = stringOrMomentToDate(params.newValue);
      let oldDate = stringToDate(params.oldValue);

      if (areDatesEqual(newDate, oldDate) === false) {
        saveFn(field, params.node.id, newDate);
        return true;
      }
      return false;
    }
  };
  res.type = canEdit && makeCellDeletable(res.type);

  return res;
};

export const colEndDate = (canEdit: boolean, saveFn: any, field: number, props: IBaseColumnProps): ColDef => {
  let res = {
    ...baseDateColumn({
      fieldName: "endDate",
      heading: "grids.endDate",
      editable: canEdit,
      context: "startDate",
      ...props
    }),
    cellRenderer: props => {
      const obj = props.data;

      if (!obj.endDate) return "";

      const today = moment(new Date());
      const isComplete = obj.progressStatus === Enums.ProgressStatus.COMPLETED;
      const isOverdue = today > moment(obj.endDate) && !isComplete;
      const shouldEndToday = today.format("L") === moment(obj.endDate).format("L") && !isComplete;

      let cls = "";
      if (isOverdue) cls = "text-danger";
      if (shouldEndToday) cls = "text-warning";

      return <span className={`${cls}`}>{moment(obj.endDate).format("L")}</span>;
    },
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      let newDate = stringOrMomentToDate(params.newValue);
      let oldDate = stringToDate(params.oldValue);

      if (areDatesEqual(newDate, oldDate) === false) {
        saveFn(field, params.node.id, newDate);
        return true;
      }
      return false;
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colProgressStatus = (
  canEdit: boolean,
  progressStatuses: any[],
  saveFn: any,
  field: number,
  props: IBaseColumnProps
): ColDef => {
  return {
    ...baseColumn({ fieldName: "progressStatus", heading: "grids.status", editable: canEdit, ...props }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filter: "agTextColumnFilter",
    cellEditorParams: {
      field: "progressStatus",
      getValueLabel: (ee: any) => {
        const progress = progressStatuses.find(e => e.key === ee + "");
        return progress.label;
      },
      options: progressStatuses ?? []
    },
    filterParams: {
      textMatcher: progressStatusTextMatcher
    },
    cellRenderer: props => {
      if (!props.data.progressStatus || !progressStatuses) return null;
      const progress = progressStatuses.find(e => e.key === props.data.progressStatus + "");

      if (!progress) return "";
      return <>{progress.label}</>;
    },
    getQuickFilterText: params => {
      if (!params.data.progressStatus || !progressStatuses) return "";

      const progress = progressStatuses.find(e => +e.key === params.value);

      if (!progress) return "";

      return progress.label;
    }
  };
};

export const colCreatedByName = (): ColDef => {
  return baseTextColumn({ fieldName: "createdByName", heading: "grids.createdBy" });
};

export const colCreatedOn = (): ColDef => {
  return baseDateColumn({ fieldName: "createdAt", heading: "grids.createdOn" });
};

export const colModifiedByName = (): ColDef => {
  return baseTextColumn({ fieldName: "modifiedByName", heading: "grids.lastModifiedBy" });
};

export const colUpdatedOn = (): ColDef => {
  return baseDateColumn({ fieldName: "updatedAt", heading: "grids.lastModifiedOn" });
};

export const colReviewedBy = (): ColDef => {
  return baseTextColumn({ fieldName: "reviewedByName", heading: "grids.reviewedBy" });
};

export const colLastReviewedOn = (): ColDef => {
  return baseDateColumn({ fieldName: "lastReviewedOn", heading: "grids.lastReviewedOn" });
};

export const colNextReviewDue = (): ColDef => {
  return {
    ...baseDateColumn({ fieldName: "nextReviewDue", heading: "grids.nextReviewDue" }),
    cellRenderer: props => {
      const obj = props.data;
      if (!props.data.nextReviewDue) return null;

      let now = new Date();

      let cls =
        moment(now) > moment(obj.nextReviewDue) && obj.progressStatus !== Enums.ProgressStatus.COMPLETED
          ? "text-danger"
          : "";
      cls =
        moment(now.getDate() - 7).format("L") > moment(obj.nextReviewDue).format("L") &&
        obj.progressStatus !== Enums.ProgressStatus.COMPLETED
          ? "text-warning"
          : cls;

      let displayValue =
        obj.progressStatus !== Enums.ProgressStatus.COMPLETED ? moment(obj.nextReviewDue).format("L") : "";

      return <span className={cls}>{displayValue}</span>;
    }
  };
};

export const colNoteCount = (canEdit: boolean, editFn?): ColDef => {
  let result: ColDef = {
    ...baseColumn({ fieldName: "noteCount", heading: "grids.notes", width: 100, editable: false }),
    cellRenderer: e => <div>{e.data.noteCount > 0 ? e.data.noteCount : ""}</div>
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => editFn(e.data);
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }
  return result;
};

export const colDescription = (canEdit: boolean, editFn?): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({ fieldName: "description", heading: "grids.description", width: 500, editable: false }),
    autoHeight: true
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colImpacts = (canEdit: boolean, editFn?) => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "impacts",
      heading: "grids.impacts",
      width: 500,
      editable: false,
      autoHeight: true
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.impacts} />;
    }
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};
