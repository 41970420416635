import { ColDef } from "ag-grid-community";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { ProgressStatus } from "../../components/ui/ProgressStatus";
import { RagStatus } from "../../components/ui/RagStatus";
import I18n from "../localization/I18n";
import { Enums } from "../../enums";
import AgGridAutocomplete from "./editors/AgGridAutocomplete";
import DatePicker from "./editors/Datepicker";
import { RAGStatusEditor } from "./editors/editorRAGStatus";
import { dateComparator } from "./utils";

export interface IBaseColumnProps {
  fieldName?: string;
  heading?: string;
  width?: number;
  editable?: boolean;
  autoHeight?: boolean;
  resizable?: boolean;
  pinned?: "left" | "right" | null;
  context?: any;
}

export const baseColumn = (props: IBaseColumnProps): ColDef => {
  return {
    field: props.fieldName,
    headerName: props.heading.length > 0 ? I18n.t(props.heading) : "",
    width: props.width ?? 200,
    resizable: props.resizable ?? true,
    pinned: props.pinned,
    editable: props.editable,
    autoHeight: props.autoHeight,
    type: ["selectableClass"],
    enableCellChangeFlash: true,
    filterParams: {
      buttons: ["clear"]
    }
  };
};

export const baseRAGStatusColumn = (props: IBaseColumnProps): ColDef => {
  return {
    ...baseColumn(props),
    cellEditorSelector: () => ({ component: RAGStatusEditor, popup: true, popupPosition: "under" }),
    cellRenderer: columnProps => {
      let obj = columnProps.data;
      return obj.progressStatus === Enums.ProgressStatus.COMPLETED ? (
        <ProgressStatus state={obj.progressStatus} />
      ) : (
        <RagStatus state={obj.ragStatus} />
      );
    },
    comparator: (valueA, valueB, nodeA, nodeB) => {
      if (nodeA.data.progressStatus === Enums.ProgressStatus.COMPLETED) valueA = 4;
      if (nodeB.data.progressStatus === Enums.ProgressStatus.COMPLETED) valueB = 4;

      return valueA - valueB;
    },
    filter: true,
    filterValueGetter: params => {
      let val = params.data.progressStatus === Enums.ProgressStatus.COMPLETED ? 4 : params.data.ragStatus;
      if (val === -1) return I18n.t("phrases.unknown");
      return Enums.Translator.RagStatus(val);
    }
  };
};

export const baseSelectionColumn = (): ColDef => {
  return {
    ...baseColumn({ fieldName: "selected", heading: "", width: 50, resizable: false, pinned: "left" }),
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true
  };
};

export const baseDateColumn = (props: IBaseColumnProps): ColDef => {
  return {
    ...baseColumn(props),
    filter: "agDateColumnFilter",
    cellEditorSelector: () => ({ component: DatePicker, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    cellEditorParams: { field: props.fieldName, defaultDate: props.context },
    comparator: dateComparator,
    valueGetter: columnProps =>
      columnProps.data[props.fieldName] ? moment(columnProps.data[props.fieldName]).format("L") : "",
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (isEmpty(dateAsString)) {
          return -1;
        }

        const dateParts = dateAsString.split("/");
        const day = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      buttons: ["clear"]
    }
  };
};

export const baseTextColumn = (props: IBaseColumnProps): ColDef => {
  return {
    ...baseColumn(props),
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["clear"]
    },
    valueGetter: getterProps => valueOrNoInput(getterProps.data[props.fieldName])
  };
};

export const baseDropdownColumn = (props: IBaseColumnProps): ColDef => {
  return {
    ...baseColumn(props),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["clear"]
    },
    valueGetter: getterProps => valueOrNoInput(getterProps.data[props.fieldName])
  };
};

const valueOrNoInput = (value?: string): string => {
  if (!value) return "";
  if (value.length === 0) return "";
  return value;
};
