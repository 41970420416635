import React from "react";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../constants";
import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../../core/grids/GridSideModals";
import I18n from "../../../../../../core/localization/I18n";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { ImpactBusinessAreasSidebarModel } from "./impactBusinessAreasSidebar/ImpactBusinessAreasSidebar_model";
import { ImpactBusinessAreasSidebar } from "./impactBusinessAreasSidebar/ImpactBusinessAreasSidebar_view";
import { StakeholderType } from "../../../../../../enums";
import { ImpactDescriptionSidebar } from "./impactDescriptionSidebar/ImpactDescriptionSidebar_view";
import { ImpactNotesSidebar } from "./ImpactNotesSidebar";
import { ImpactStakeholdersSidebarModel } from "./impactStakeholderSidebar/ImpactStakeholdersSidebar_model";
import { ImpactStakeholdersSidebar } from "./impactStakeholderSidebar/impactStakeholdersSidebar_view";
import { ImpactTagsSidebarModel } from "./impactTagsSidebar/ImpactTagsSidebar_model";
import { ImpactTagsSidebar } from "./impactTagsSidebar/ImpactTagsSidebar_view";
import { ImpactTypesSidebarModel } from "./impactTypesSidebar/ImpactTypesSidebar_model";
import { ImpactTypesSidebar } from "./impactTypesSidebar/ImpactTypesSidebar_view";
import { ImpactLocationsSidebar } from "./impactLocationsSidebar/ImpactLocationsSidebar_view";
import { ImpactLocationsSidebarModel } from "./impactLocationsSidebar/ImpactLocationsSidebar_model";
import { ImpactGroupsSidebar } from "./impactGroupsSidebar/impactGroupsSidebar_view";
import { ImpactGroupsSidebarModel } from "./impactGroupsSidebar/impactGroupsSidebar_model";
import { ImpactDescriptionSidebarModel } from "./impactDescriptionSidebar/impactDescriptionSidebar_model";
import { ImpactMilestonesSidebarModel } from "./impactMilestonesSidebar/ImpactMilestonesSidebar_model";
import { ImpactMilestonesSidebar } from "./impactMilestonesSidebar/ImpactMilestonesSidebar_view";
import { ImpactActionSidebar } from "./impactActionSidebar/ImpactActionSidebar_view";
import { ImpactProcessesSidebar } from "./impactProcessesSidebar/ImpactProcessesSidebar_view";
import { ImpactProcessesSidebarModel } from "./impactProcessesSidebar/ImpactProcessesSidebar_model";

export interface IImpactGridModals {
  showDescriptionModalFn?: (impact: FP.Entities.IImpact) => void;
  showTypesModalFn: (impact: FP.Entities.IImpact) => void;
  showTagsModalFn: (impact: FP.Entities.IImpact) => void;
  showNotesModalFn: (impact: FP.Entities.IImpact) => void;
  showStakeholdersModalFn: (impact: FP.Entities.IImpact) => void;
  showAudiencesModalFn: (impact: FP.Entities.IImpact) => void;
  showBusinessAreasModalFN: (impact: FP.Entities.IImpact) => void;
  showLocationsModalFN: (impact: FP.Entities.IImpact) => void;
  showActionModalFN: (impact: FP.Entities.IImpact) => void;
  showGroupsModalFn: (impact: FP.Entities.IImpact) => void;
  showMilestonesModalFn: (impact: FP.Entities.IImpact) => void;
  showProcessesModalFn: (impact: FP.Entities.IImpact) => void;
}

export const getImpactGridModals = (
  appService: AppService,
  organisationId: number,
  projectId: number
): IImpactGridModals => {
  const modalService = appService.getService<IModalService>(Services.ModalService);

  const impactDescriptionModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.description")} />,
      content: (
        <ImpactDescriptionSidebar
          model={new ImpactDescriptionSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactTypesModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.impactType")} />,
      content: (
        <ImpactTypesSidebar
          model={new ImpactTypesSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactTagsModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.tags")} />,
      content: (
        <ImpactTagsSidebar
          modalService={modalService}
          model={new ImpactTagsSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactNotesModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.tags")} />,
      content: (
        <ImpactNotesSidebar
          modalService={modalService}
          projectId={projectId}
          impactId={impact.id}
          organisationId={organisationId}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactBusinessAreasModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.businessAreas")} />,
      content: (
        <ImpactBusinessAreasSidebar
          model={
            new ImpactBusinessAreasSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)
          }
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactActionModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.actions")} />,
      content: (
        <ImpactActionSidebar modalService={modalService} projectId={projectId} impactId={impact.id} impact={impact} />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactLocationModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.locations")} />,
      content: (
        <ImpactLocationsSidebar
          model={new ImpactLocationsSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactPStakeholdersModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.stakeholders")} />,
      content: (
        <ImpactStakeholdersSidebar
          modalService={modalService}
          model={
            new ImpactStakeholdersSidebarModel(
              appService,
              modalService.hide,
              organisationId,
              projectId,
              impact.id,
              StakeholderType.INDIVIDUAL
            )
          }
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactAudiencesModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.audiences")} />,
      content: (
        <ImpactStakeholdersSidebar
          modalService={modalService}
          model={
            new ImpactStakeholdersSidebarModel(
              appService,
              modalService.hide,
              organisationId,
              projectId,
              impact.id,
              StakeholderType.AUDIENCE
            )
          }
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactGroupsModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.impactGroups")} />,
      content: (
        <ImpactGroupsSidebar
          modalService={modalService}
          model={new ImpactGroupsSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactMilestonesModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.milestones")} />,
      content: (
        <ImpactMilestonesSidebar
          modalService={modalService}
          model={new ImpactMilestonesSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const impactProcessesModal = (impact: FP.Entities.IImpact) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.processes")} />,
      content: (
        <ImpactProcessesSidebar
          model={new ImpactProcessesSidebarModel(appService, modalService.hide, organisationId, projectId, impact.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const ImpactGridModals: IImpactGridModals = {
    showDescriptionModalFn: impactDescriptionModal,
    showTypesModalFn: impactTypesModal,
    showTagsModalFn: impactTagsModal,
    showNotesModalFn: impactNotesModal,
    showStakeholdersModalFn: impactPStakeholdersModal,
    showAudiencesModalFn: impactAudiencesModal,
    showBusinessAreasModalFN: impactBusinessAreasModal,
    showLocationsModalFN: impactLocationModal,
    showActionModalFN: impactActionModal,
    showGroupsModalFn: impactGroupsModal,
    showMilestonesModalFn: impactMilestonesModal,
    showProcessesModalFn: impactProcessesModal
  };

  return ImpactGridModals;
};
