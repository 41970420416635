import * as React from "react";
import { StakeholderExtendedViewModel } from "./StakeholderExtendedView_model";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ProjectStakeholderCard } from "../../projects/projectStakeholderCard/ProjectStakeholderCard_view";
import { StakeholderDetails } from "../StakeholderDetails";
import { Enums } from "../../../../enums";
import { StakeholderImpactsView } from "../stakeholderImpactsView/StakeholderImpactsView_view";
import { StakeholderConcernsView } from "../stakeholderConcernsView/StakeholderConcernsView_view";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryView } from "../../revisionHistory/RevisionHistory_view";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { Hr } from "../../../../components/ui/Hr";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { Tag } from "../../../../components/ui/Tag";
import { ReviewBar } from "../../../../components/layouts/ReviewBar";
import { ReviewButton } from "../../../../components/ui/ReviewButton";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useCanEditField } from "../../../../contexts/permissions/PermissionHooks";

export interface StakeholderExtendedViewProps {
  model: StakeholderExtendedViewModel;
}

export const StakeholderExtendedView: React.FunctionComponent<StakeholderExtendedViewProps> = observer(({ model }) => {
  const { stakeholder, projectStakeholder } = model;
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  const returnUrl = model.queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.RETURN_URL, "");
  const editReturnURL = returnUrl ? `?${QUERY_STRING_PARAMS.PREV_RETURN_URL}=${encodeURIComponent(returnUrl)}` : "";
  const canEditField = useCanEditField(PermissionFields.STAKEHOLDERS, organisationId, model.projectId);

  return (
    <div className={`data-extended-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`} id="ProjectStakeholderPage">
      <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
        <div className="row">
          <div className="col p-0 m-0">
            <LinkButton size={Enums.UiSizes.MD} type={ButtonTypes.LINK} className="p-0 m-2" href={model.returnUrl}>
              <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
              {I18n.t("phrases.back")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="data-extended-view__block data-extended-view__block--wrapper">
        <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-4`}>
          <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
            <div className="row mb-5">
              <div className="col">
                <ReviewButton hasBeenReviewed={model.hasBeenReviewed} onClick={model.showMarkReviewedModal} />
                <LinkButton
                  className="ml-auto float-right"
                  id="EditStakeholderButton"
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders/${model.stakeholderId}/edit${editReturnURL}`}
                >
                  <Icon symbol={IconSymbols.Pencil} size={Enums.UiSizes.SM} className="mr-2" />
                  {I18n.t("phrases.edit")}
                </LinkButton>
              </div>
            </div>
          </CanEdit>
          <div className={`row ${canEditField ? "mb-4" : "mb-2"}`}>
            <div className="col">
              <div className="d-flex">
                <h2 className="mb-0">{convertStakeholderToName(stakeholder)}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Hr className="mb-0 mb-5" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>{I18n.t("phrases.projectProfile")}</h2>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <ProjectStakeholderCard item={projectStakeholder} />
              <hr />
            </div>
          </div>

          {projectStakeholder.reviewedBy && (
            <ReviewBar
              onwardLink={`/organisations/${organisationId}/users/${projectStakeholder.reviewedBy.sub}?${
                QUERY_STRING_PARAMS.RETURN_URL
              }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
              item={projectStakeholder}
            />
          )}

          <div className="row">
            <div className="col-12">
              <Hr className="mb-0 mb-5" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h2>{I18n.t("phrases.stakeholderProfile")}</h2>
              <StakeholderDetails item={stakeholder} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Hr className="mb-0 mt-0" />
            </div>
          </div>

          <div className="row pb-4 mt-5">
            <div className="col">
              <h5 className="mb-1">{I18n.t("phrases.tags")}</h5>
              {projectStakeholder.tags.length === 0 && <p className="mb-0">{I18n.t("phrases.noTags")}</p>}
              {projectStakeholder.tags.map(e => (
                <Tag tagText={e.text} isEditable={false} testId={e.id + ""} isNew={false} />
              ))}
            </div>
          </div>
        </div>
        <Panel.Panel
          background={Panel.PanelBackgrounds.BG_LIGHT}
          className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-4`}
        >
          <TabGroup
            className="data-extended-view__tabs"
            tabs={[
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.impactedBy")}</h3>,
                children: <StakeholderImpactsView isRouteView={false} />
              },
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.keyConcerns")}</h3>,
                children: <StakeholderConcernsView model={model.stakeholderConcernsModel} />
              },
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                children: (
                  <div className="col">
                    <CommentListView model={model.commentViewModel} field={PermissionFields.STAKEHOLDERS} />
                  </div>
                )
              },
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.stakeholderHistory")}</h3>,
                children: <RevisionHistoryView model={model.revisionHistoryModel} />
              }
            ]}
          ></TabGroup>
        </Panel.Panel>
      </div>
    </div>
  );
});
