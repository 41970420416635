import { ColDef } from "ag-grid-community";
import _ from "lodash";
import React from "react";
import { ActionField } from "../../../../../../enums";
import AgGridAutocomplete from "../../../../../../core/grids/editors/AgGridAutocomplete";
import {
  IBaseColumnProps,
  baseColumn,
  baseDropdownColumn,
  baseRAGStatusColumn
} from "../../../../../../core/grids/BaseColumns";
import { makeCellDeletable } from "../../../../../../core/grids/GridHelpers";
import { GridPills } from "../../../../../../components/widgets/gridPills/GridPills";

export const colRagStatus = (canEdit: boolean, saveFn, props?: IBaseColumnProps): ColDef => {
  return {
    ...baseRAGStatusColumn({
      fieldName: "ragStatus",
      heading: "forms.ragStatus",
      width: 100,
      pinned: props.pinned,
      editable: canEdit
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      saveFn(ActionField.ragStatus, params.node.id, params.newValue);
      return true;
    }
  };
};

export const colActionTypes = (canEdit: boolean, actionTypes: any[], saveFn?: any): ColDef => {
  let res = {
    ...baseDropdownColumn({ fieldName: "actionTypeName", heading: "grids.actionType", editable: canEdit }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.actionTypeId = params.newValue.id;
      params.data.actionTypeName = params.newValue.name;

      saveFn(ActionField.actionType, params.node.id, params.newValue.id);
      return true;
    },
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorParams: {
      field: "actionTypeName",
      getValueLabel: (ee: any) => {
        return ee.name;
      },
      options: actionTypes ?? [],
      valueSelector: "name",
      allowFreeText: true,
      charInputNumber: 1,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return _.filter(items, (item: FP.Entities.IImpactType) => {
          const name = `${item.name}`.toLowerCase();
          return name.indexOf(lowerQuery) > -1;
        });
      }
    },
    cellRenderer: props => {
      if (!props.data.progressStatus || !actionTypes) return null;
      const actionType = actionTypes.find(e => e.id === props.data.actionTypeId);

      if (!actionType) return "";
      return <>{actionType.name}</>;
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colAssignedTo = (canEdit: boolean, projectTeamMembers: any[], saveFn?: any): ColDef => {
  let res = {
    ...baseColumn({ fieldName: "assignedToName", heading: "grids.assignedTo", editable: canEdit }),
    cellRenderer: e => <div>{e.data.assignedToId > 0 ? e.data.assignedToName : ""}</div>,
    valueGetter: e => {
      if (!e.data.assignedToName) return;
      return `${e.data.assignedToName ?? ""}`;
    },
    filter: "agTextColumnFilter",
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.assignedToId = params.newValue.id;
      params.data.assignedToName = `${params.newValue.firstName} ${params.newValue.lastName}`;

      saveFn(ActionField.assignedTo, params.node.id, params.newValue.id);
      return true;
    },
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    cellEditorParams: {
      field: "assignedToName",
      getValueLabel: (ee: any) => {
        if (!projectTeamMembers) return "";

        const user = projectTeamMembers.find(e => e.id === ee.id);
        if (!user) return "";
        return user.firstName + " " + user.lastName;
      },
      options: projectTeamMembers ?? [],
      allowFreeText: true,
      isPerson: true,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return _.filter(items, (item: FP.Entities.IUser) => {
          const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
          const lowerEmail = item.email.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
        });
      }
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);

  return res;
};

export const colProjectStakeholders = (): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "actionStakeholders",
      heading: "grids.stakeholdersFromImpacts",
      editable: false,
      autoHeight: true,
      width: 500
    })
  };
  res.cellRenderer = e => <GridPills text={e.data.actionStakeholders} />;

  return res;
};

export const colProjectAudiences = (): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "actionAudiences",
      heading: "grids.audiencesFromImpacts",
      editable: false,
      autoHeight: true,
      width: 500
    })
  };
  res.cellRenderer = e => <GridPills text={e.data.actionAudiences} />;

  return res;
};
