import React, { useCallback, useEffect } from "react";
import { ImpactActionsModel } from "./ImpactActions_model";
import { Button, ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { ButtonGroup } from "../../../../components/ui/ButtonGroup";
import { observer } from "mobx-react-lite";
import { ActionCard } from "../../actions/actionCard/ActionCard_view";
import { PositionedSpinner, PositionedSpinnerBackground } from "../../../../components/ui/PositionedSpinner";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { Panel } from "../../../../components/ui/Panel";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { CanEdit, CanView } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionErrorMessage } from "../../../../contexts/permissions/PermissionMessages";

export interface ImpactActionsProps {
  model: ImpactActionsModel;
}

const ImpactActions: React.FunctionComponent<ImpactActionsProps> = observer(({ model }) => {
  const { impactId } = useParams() as any;
  const organisationId = useCurrentOrganisationId();
  const impactIdMain = impactId || model.impactId;
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  const loadImpact = useCallback(async () => {
    await model.loadActions(impactIdMain);
  }, [impactIdMain, model]);

  const initUrl = `/organisations/${organisationId}/projects/${model.projectId}`;

  const actions = model.filteredActions
    .filter(action => {
      return model.toggle === "closed"
        ? action.progressStatus === Enums.ProgressStatus.COMPLETED
        : action.progressStatus !== Enums.ProgressStatus.COMPLETED;
    })
    .map(action => (
      <ActionCard
        key={action.id}
        item={action}
        returnUrl={`${initUrl}/actions/${action.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
          `${initUrl}/impacts/${impactIdMain}`
        )}`}
        actions={model.actionCardActions}
        className="mb-2"
      />
    ));

  return (
    <div className={`container-fluid pt-5 ${Animations.FADE_IN} speed-4`}>
      <CanView
        field={PermissionFields.ACTIONS}
        projectId={model.projectId}
        errorMessage={
          <div className="row mb-4">
            <div className="col">
              <PermissionErrorMessage>
                <p className="mb-0">{I18n.t("phrases.noActionAccess")}</p>
              </PermissionErrorMessage>
            </div>
          </div>
        }
      >
        <Async promiseFn={loadImpact}>
          <Async.Loading>
            <PositionedSpinner background={PositionedSpinnerBackground.TRANSPARENT} />
          </Async.Loading>
          <Async.Resolved>
            <div className="row mb-4">
              <div className="col">
                <CanEdit field={PermissionFields.ACTIONS} projectId={model.projectId}>
                  <Button onClick={model.showAddActionModal}>
                    <Icon symbol={IconSymbols.Plus} className="mr-2" />
                    {I18n.t("phrases.addAction")}
                  </Button>
                </CanEdit>

                <ButtonIcon
                  className="float-right ml-1"
                  symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                  iconSize={Enums.UiSizes.SM}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
                />

                <ButtonGroup className="float-right" isToggle={true}>
                  <Button
                    type={model.toggle === "active" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                    onClick={() => {
                      model.toggle = "active";
                    }}
                  >
                    {I18n.t("phrases.active")}
                  </Button>
                  <Button
                    type={model.toggle === "closed" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                    onClick={() => {
                      model.toggle = "closed";
                    }}
                  >
                    {I18n.t("phrases.completed")}
                  </Button>
                </ButtonGroup>

                <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                  <Input
                    autoFocus={true}
                    size={-1}
                    onChange={model.updateSearchValue}
                    borderStyle="underline"
                    placeholder={I18n.t("placeholders.searchImpactActions")}
                    icon="search"
                    value={model.searchValue}
                    onBlur={null}
                    className="mt-3"
                  />
                </AnimateHeight>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                {actions.length ? (
                  actions
                ) : (
                  <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                    <div>{I18n.t("phrases.noImpactActions")}</div>
                  </Panel.Panel>
                )}
              </div>
            </div>
          </Async.Resolved>
        </Async>
      </CanView>
    </div>
  );
});

export { ImpactActions };
