import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../constants";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { ITreeDataItem } from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { IHttpProgressModel } from "../../../../../../../core/httpProgress/HttpProgress_model";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { IImpactTypeApi } from "../../../../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import {
  getImpactBusinessAreasSidebarFormActions,
  getImpactBusinessAreasSidebarFormFields
} from "./ImpactBusinessAreasSidebar_fields";

export class ImpactBusinessAreasSidebarModel extends BaseModel {
  appService: AppService;
  impactTypesProvider: IImpactTypeApi;
  impactsProvider: IImpactsApi;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  businessAreas: ITreeDataItem[];
  formModel: SingleFormModel;
  canEdit: boolean;

  constructor(
    appService: AppService,
    closeModalFn: () => void,
    organisationId: number,
    projectId: number,
    impactId: number
  ) {
    super();
    this.appService = appService;
    this.impactTypesProvider = this.appService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.loadSidebar(impactId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  getBusinessAreas = async (impactId: number) => {
    let res = await this.impactsProvider.getBusinessAreaSelection(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.businessAreas = res.payload;
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
  };

  @action
  loadSidebar = async (impactId: number) => {
    this.isLoading = true;
    await this.loadImpact(impactId);
    await this.getBusinessAreas(impactId);
    this.loadForm();
    this.isLoading = false;
  };

  loadForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactBusinessAreasSidebarFormFields(this.businessAreas);
    this.formModel.actions = getImpactBusinessAreasSidebarFormActions(
      this.updateImpact,
      this.canEdit,
      this.closeModalFn
    );
  };

  updateImpact = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;

    await this.impactsProvider.updateBusinessAreas(
      this.organisationId,
      this.projectId,
      this.impact.id,
      res.businessAreas
    );
  };
}
