import React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { ReviewModalComponentProps, ReviewModalContent, ReviewModalTitle } from "../../../../components/ui/ReviewModal";
import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../core/grids/GridSideModals";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { StakeholderImpactsSidebar } from "./AudienceGridView/modals/impactsSidebar/StakeholderImpactsSidebar_view";
import { AudienceNotesSidebar } from "./AudienceGridView/modals/notesSidebar/AudienceNotesSidebar";
import { AudienceTagsSidebar } from "./AudienceGridView/modals/tagsSidebar/AudienceTagsSidebar_view";

export const SHOW_AUDIENCE_EXISTS_MODAL = (
  modalService: IModalService,
  res,
  stakeholder,
  activateExistingStakeholder,
  addProjectStakeholder
) => {
  modalService.showConfirmDialog(
    <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
    <div className="container-fluid">
      <div className="row">
        <div className="col">{I18n.t("phrases.stakeholderExists")}</div>
      </div>
    </div>,
    I18n.t("phrases.bringBackExisting"),
    I18n.t("phrases.createNew"),
    {
      wrapWidth: "small",
      spacing: "small",
      position: "middle",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE
      }
    },
    async () => {
      activateExistingStakeholder(res.payload);
      modalService.hide();
    },
    () => {
      addProjectStakeholder(stakeholder);
      modalService.hide();
    }
  );
};

export const SHOW_AUDIENCE_DELETE_CONFIRM_MODAL = (
  modalService: IModalService,
  projectStakeholderIds: number[],
  deleteRange
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeStakeholderFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {I18n.t("phrases.confirmStakeholderRangeRemove", { name: projectStakeholderIds.length })}
          </div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await deleteRange();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_AUDIENCE_REVIEW_CONFIRM_MODAL = (
  modalService: IModalService,
  projectStakeholderIds: number[],
  reviewStakeholderRange: (projectStakeholderIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewStakeholderRange(projectStakeholderIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_AUDIENCE_TAGS_MODAL = (
  modalService: IModalService,
  audience: FP.Entities.IProjectAudienceSummary
) => {
  modalService.show({
    showClose: true,
    title: <GridSideModalTitle name={audience.name} field={I18n.t("phrases.tags")} />,
    content: (
      <AudienceTagsSidebar
        projectId={audience.projectId}
        stakeholderId={audience.stakeholderId}
        modalService={modalService}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_AUDIENCE_IMPACT_MODAL =
  (modalService: IModalService) => (projectStakeholder: FP.Entities.IProjectStakeholderSummary) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
      content: (
        <StakeholderImpactsSidebar
          modalService={modalService}
          projectId={projectStakeholder.projectId}
          stakeholderId={projectStakeholder.stakeholderId}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

export const SHOW_AUDIENCE_NOTES_MODAL = (
  modalService: IModalService,
  projectStakeholder: FP.Entities.IProjectAudienceSummary
) => {
  modalService.show({
    showClose: true,
    title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.tags")} />,
    content: (
      <AudienceNotesSidebar
        projectId={projectStakeholder.projectId}
        modalService={modalService}
        audienceId={projectStakeholder.stakeholderId}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
