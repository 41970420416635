import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { Enums, ProjectStakeholderField, StakeholderType } from "../../../../../enums";
import { IProjectStakeholdersApi } from "../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ProjectStakeholdersHub } from "../../../../../services/hubs/ProjectStakeholdersHub/ProjectStakeholders_hub";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { GetStakeholderURLFilters } from "./StakeholdersView_filters";
import {
  SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL,
  SHOW_STAKEHOLDER_EXISTS_MODAL,
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL,
  SHOW_STAKEHOLDER_LINK_MODAL,
  SHOW_STAKEHOLDER_REVIEW_CONFIRM_MODAL
} from "./StakeholdersView_modal";
import I18n from "../../../../../core/localization/I18n";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
// import * as apiFilters from "./StakeholdersView_apiFilter";

export class StakeholdersViewModel extends BaseModel {
  appService: AppService;
  projectId: number;
  organisationId: number;
  projectStakeholdersHub: ProjectStakeholdersHub;
  @observable.ref connectedUsers: FP.Entities.IUser[] = [];
  @observable isLoading: boolean = true;
  @observable.ref gridStakeholders: FP.Entities.IProjectStakeholderSummary[];
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  authUser: FP.Entities.IUser;
  httpProgress: IHttpProgressModel;
  toasterService: IToasterService;
  gridToastService: IGridToastService;
  gridApi: any;
  @observable.ref selectedStakeholders: number[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalService;
  location: any;
  filterModel?: any;
  @observable searchText: string;
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.impacts"),
      onAction: ev => {
        this.showLinkToImpact();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(
    appService: AppService,
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    location: any
  ) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.gridToastService = this.appService.getService(Services.GridToastService);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.projectStakeholdersHub = this.appService.getService<ProjectStakeholdersHub>(Services.ProjectStakeholdersHub);
    this.location = location;
  }

  onMount = async () => {
    await this.registerSocketEvents();
    await this.loadStakeholderAndAudienceCounts();
  };

  onUnmount = () => {
    this.stopConnection();
  };

  stopConnection = async () => {
    await this.projectStakeholdersHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.projectStakeholdersHub.isConnectionStarted === true) {
      await this.projectStakeholdersHub.stopConnection();
    }
    await this.projectStakeholdersHub.startConnection();

    this.projectStakeholdersHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.projectStakeholdersHub.onLoadGridData(d => {
      this.setStakeholderRowData(d);
    });

    this.projectStakeholdersHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    this.projectStakeholdersHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.projectStakeholdersHub.invokeUserJoined(this.organisationId, this.projectId, this.authUser.sub);
    await this.projectStakeholdersHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.filterModel = {
      ...GetStakeholderURLFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setStakeholderRowData = stakeholders => {
    this.gridStakeholders = stakeholders;
    this.isLoading = false;
  };

  updateUserSelectedCell = async (cell: string, isEditMode?: boolean) => {
    await this.projectStakeholdersHub.invokeUserClickedCell(this.organisationId, this.projectId, cell, isEditMode);
  };

  updateSelectedStakeholders = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedStakeholders = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  deleteFieldData = async (stakeholderId: number, actionField: ProjectStakeholderField) => {
    const res = await this.projectStakeholderProvider.deleteField(
      this.organisationId,
      this.projectId,
      stakeholderId,
      actionField
    );
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.location.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/visualisations/1/0`
      );
      return;
    }
    if (newTabIndex === 0) {
      this.location.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.location.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };

  showConfirmReviewModal = () => {
    return SHOW_STAKEHOLDER_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedStakeholders,
      this.reviewStakeholderRange,
      this.toasterService,
      this.deselectRows
    );
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  showStakeholderConfirmDeleteModal = () => {
    return SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL(this.modalService, this.selectedStakeholders, this.removeStakeholders);
  };

  showLinkStakeholderModal = () => {
    return SHOW_STAKEHOLDER_LINK_MODAL(this.modalService, this.projectId, StakeholderType.INDIVIDUAL);
  };

  showLinkToImpact = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedStakeholders);
  };

  removeStakeholders = async () => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectStakeholderProvider.deleteRange(
      this.organisationId,
      this.projectId,
      this.selectedStakeholders
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;
    return res.payload;
  };

  reviewStakeholderRange = async (projectStakeholderIds: number[], comment: string) => {
    let res = await this.projectStakeholderProvider.reviewRange(
      this.organisationId,
      this.projectId,
      projectStakeholderIds,
      comment
    );

    if (!res || res.isError) return;
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );
    if (!res || res.isError) return;
    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-stakeholders-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedStakeholders && this.selectedStakeholders.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setQuickFilter(this.searchText);
    }
  };

  // @action
  // onGridReady(gridReadyEvent: GridReadyEvent): void {

  //   this.filterModel = {
  //     ...apiFilters.getFilters()
  //   };
  //   this.gridApi = gridReadyEvent.api;
  //   this.gridApi.setFilterModel({});

  //   setTimeout(() => {
  //     this.gridApi.setFilterModel(this.filterModel);
  //   });
  // }
}
