import { action, observable } from 'mobx';
import { AppService } from 'strikejs-app-service';
import { Services } from '../../../../../../../../constants';
import { IHttpProgressModel } from '../../../../../../../../core/httpProgress/HttpProgress_model';
import { BaseModel } from '../../../../../../../../core/util/BaseModel';
import { IActionsApi } from '../../../../../../../../services/api/v1/actions/IActions.api';
import { ITagsApi } from '../../../../../../../../services/api/v1/tags/ITags.api';

export class ActionTagsSidebarModel extends BaseModel {
    appService: AppService;
    actionsProvider: IActionsApi;
    tagsProvider: ITagsApi;
    httpProgress: IHttpProgressModel;
    @observable isLoading: boolean = true;
    @observable.ref action: FP.Entities.IAction;
    organisationId: number;
    projectId: number;
    closeModalFn: () => void;

    constructor(appService: AppService, closeModalFn: () => void, actionId: number, organisationId: number, projectId: number) {
        super();
        this.appService = appService;
        this.actionsProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
        this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
        this.organisationId = organisationId;
        this.projectId = projectId;
        this.loadAction(actionId);
        this.closeModalFn = closeModalFn;
    }

    @action
    setAction = (action: FP.Entities.IAction) => {
        this.action = action;
        this.isLoading = false;
    };

    @action
    loadAction = async (actionId: number) => {
        this.isLoading = true;
        let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
        if (!res || res.isError) return;

        this.setAction(res.payload);
    };

    @action
    removeTag = async (tagId: number) => {
        let res = await this.tagsProvider.removeActionTagAssoc(this.organisationId, this.projectId, tagId, this.action.id);
        if (!res || res.isError) return;
    }

}