import {
  ColDef,
  ColumnApi,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent
} from "ag-grid-community";
import _ from "lodash";
import { AppService } from "strikejs-app-service";
import { Services } from "../../constants";
import { EntityTypes } from "../../enums";
import { ILocalStorageService } from "../../services/local/localStorageService/ILocalStorageService";
import { BaseModel } from "../util/BaseModel";

export class GridFilterModel extends BaseModel {
  appService: AppService;
  storageService: ILocalStorageService;
  projectId: number;
  gridType: EntityTypes;
  filterHasChangedFn: (e?) => void;
  columnOrderHasChangedFn: (e?) => void;
  filterStorageKey: string;
  colDefStorageKey: string;
  defaultColumnState: any;
  gridApi: GridApi<any>;
  columnsApi: ColumnApi;
  colDefs: any;
  isWrappedText: boolean = false;
  wrapTextKey: string;

  constructor(
    appService: AppService,
    projectId: number,
    filterHasChangedFn: (e?) => void,
    gridType: EntityTypes,
    columnOrderHasChangedFn: (e?) => void,
    colDefs
  ) {
    super();
    this.appService = appService;
    this.filterHasChangedFn = filterHasChangedFn;
    this.columnOrderHasChangedFn = columnOrderHasChangedFn;
    this.projectId = projectId;
    this.gridType = gridType;
    this.colDefs = colDefs;
    this.storageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.filterStorageKey = `projects-${this.projectId}-${this.gridType}-filters`;
    this.colDefStorageKey = `projects-${this.projectId}-${this.gridType}-colDef`;
    this.wrapTextKey = `projects-${this.projectId}-${this.gridType}-wrap-text`;
    this.isWrappedText =
      (typeof this.storageService.get(this.wrapTextKey) !== "undefined" &&
        this.storageService.get(this.wrapTextKey) === "1") ||
      this.isWrappedText;
  }

  onGridReady = (params: GridReadyEvent<any, any>) => {
    this.gridApi = params.api;
    this.columnsApi = params.columnApi;
    this.setTextWrap();
  };

  toggleTextWrap = () => {
    this.isWrappedText = !this.isWrappedText;
    this.setTextWrap();
  };

  setTextWrap = () => {
    let cols = this.gridApi.getColumnDefs();

    cols.forEach((e: ColDef) => {
      if (e.autoHeight) {
        e.wrapText = this.isWrappedText;
        const shouldUpdateWidth = e.headerName !== "Name" && e.headerName !== "Description";
      }
    });

    this.storageService.set(this.wrapTextKey, this.isWrappedText ? "1" : "0");
    this.gridApi.setColumnDefs(cols);
  };

  onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    this.defaultColumnState = JSON.stringify(params.columnApi.getColumnState());
    let filterModel = this.storageService.get(this.filterStorageKey);
    let columnState = JSON.parse(this.storageService.get(this.colDefStorageKey));
    if (filterModel) {
      params.api.setFilterModel(JSON.parse(filterModel));
    }
    if (columnState) {
      params.columnApi.applyColumnState({ state: columnState, applyOrder: true });
    }
    this.checkColumnOrder();
    this.checkFilters();
  };

  onFilterChanged = (params: FilterChangedEvent) => {
    let filterModel = params.api.getFilterModel();
    if (filterModel) this.storageService.set(this.filterStorageKey, JSON.stringify(filterModel));
    this.checkFilters();
  };

  onSaveGridColumnState = params => {
    let columnState = params.columnApi.getColumnState();
    if (!_.isEqual(columnState, this.defaultColumnState))
      this.storageService.set(this.colDefStorageKey, JSON.stringify(columnState));
    this.checkColumnOrder();
  };

  checkFilters = () => {
    let hasFilters = this.storageService.get(this.filterStorageKey);

    if (hasFilters?.length > 2 && hasFilters !== "null") {
      return this.filterHasChangedFn(true);
    }

    return this.filterHasChangedFn(false);
  };

  checkColumnOrder = () => {
    let colDef = this.storageService.get(this.colDefStorageKey);
    if (colDef && !_.isEqual(this.defaultColumnState, colDef) && colDef !== "null") {
      return this.columnOrderHasChangedFn(true);
    }
    return this.columnOrderHasChangedFn(false);
  };

  clearFilters = gridRef => {
    this.storageService.remove(this.filterStorageKey);
    gridRef.current.api.setFilterModel(null);
    this.filterHasChangedFn(false);
  };

  resetColumns = gridRef => {
    this.storageService.remove(this.colDefStorageKey);
    gridRef.current.columnApi.resetColumnState();
    this.gridApi.setColumnDefs(this.getDefaultColDefsBaseOnWrapText());

    this.columnsApi.resetColumnState();
    this.columnOrderHasChangedFn(false);
  };

  getDefaultColDefsBaseOnWrapText = () => {
    return this.colDefs.map(e => {
      if (e.autoHeight) {
        e.wrapText = this.isWrappedText;
      }
      return e;
    });
  };
}
