import _ from "lodash";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import ColorScale from "../../../../../core/colorScale/ColorScale";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { ImpactLevel, ProgressStatus, RagStatus, Translator } from "../../../../../enums";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";

export class SunburstModel extends BaseModel {
  appService: AppService;
  orgProvider: IOrganisationsApi;
  @observable organisationId: number;
  @observable sunburst: any;
  @observable isLoading: boolean;
  @observable impactLevelCounts: any[];
  @observable actionRagCounts: any[];

  constructor(appService: AppService, organisationId: number) {
    super();
    this.organisationId = organisationId;
    this.appService = appService;
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.load();
  }

  @action
  load = async () => {
    this.isLoading = true;
    await this.loadSunburst();
    await this.loadImpactLevels();
    await this.loadRagStatuses();
    this.isLoading = false;
  };

  @action
  loadImpactLevels = async () => {
    let res = await this.orgProvider.getAllOrganisationImpacts(this.organisationId);
    if (!res || res.isError) return;
    let impacts: FP.Entities.IImpact[] = res.payload;
    this.impactLevelCounts = [
      {
        label: I18n.t("phrases.high"),
        value: impacts.filter(e => Translator.ImpactLevelFromFullRange(e.impactLevel) === ImpactLevel.HIGH).length,
        id: "high"
      },
      {
        label: I18n.t("phrases.medium"),
        value: impacts.filter(e => Translator.ImpactLevelFromFullRange(e.impactLevel) === ImpactLevel.MEDIUM).length,
        id: "medium"
      },
      {
        label: I18n.t("phrases.low"),
        value: impacts.filter(e => Translator.ImpactLevelFromFullRange(e.impactLevel) === ImpactLevel.LOW).length,
        id: "low"
      },
      {
        label: I18n.t("phrases.unknown"),
        value: impacts.filter(e => Translator.ImpactLevelFromFullRange(e.impactLevel) === ImpactLevel.UNKNOWN).length,
        id: "unknown"
      }
    ];
  };

  @action
  loadRagStatuses = async () => {
    let res = await this.orgProvider.getAllOrganisationActions(this.organisationId);
    if (!res || res.isError) return;
    let actions: FP.Entities.IAction[] = res.payload;
    this.actionRagCounts = [
      {
        label: I18n.t("visualisations.Red"),
        value: actions.filter(e => e.ragStatus === RagStatus.RED && e.progressStatus !== ProgressStatus.COMPLETED)
          .length,
        id: "red"
      },
      {
        label: I18n.t("visualisations.Orange"),
        value: actions.filter(e => e.ragStatus === RagStatus.AMBER && e.progressStatus !== ProgressStatus.COMPLETED)
          .length,
        id: "orange"
      },
      {
        label: I18n.t("visualisations.Green"),
        value: actions.filter(e => e.ragStatus === RagStatus.GREEN && e.progressStatus !== ProgressStatus.COMPLETED)
          .length,
        id: "green"
      },
      {
        label: I18n.t("visualisations.Completed"),
        value: actions.filter(e => e.progressStatus === ProgressStatus.COMPLETED).length,
        id: "completed"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: actions.filter(
          e => (e.ragStatus === 0 || e.ragStatus === null) && e.progressStatus !== ProgressStatus.COMPLETED
        ).length,
        id: "unknown"
      }
    ];
  };

  @action
  loadSunburst = async () => {
    let res = await this.orgProvider.getOrganisationSunburst(this.organisationId);
    if (!res || res.isError) return;
    this.sunburst = res.payload;
    this.loadSunburstProjectColours();
    this.sortProjectsBySize();
  };

  sortProjectsBySize = () => {
    this.sunburst.children = this.sunburst.children.sort((a, b) => b.projectValue - a.projectValue);
  };

  loadSunburstProjectColours = () => {
    let projectValues = this.sunburst.children.map(p => (p.projectValue = this.getProjectValue(p)));
    let maxVal: number = _.max(projectValues);
    const projectColourScheme = ["#BFA1FF", "#23006E"];

    let colourScale = new ColorScale(0, maxVal, projectColourScheme, 1);
    this.sunburst.children.forEach((c, i) => {
      let rgbaColourObject = colourScale.getColor(projectValues[i]);
      let rgbaString = `rgba(${rgbaColourObject.r}, ${rgbaColourObject.g}, ${rgbaColourObject.b}, ${rgbaColourObject.a}, 1)`;
      c.colour = "#" + this.rgba2hex(rgbaString);
    });
  };

  rgba2hex = orig => {
    var a,
      rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || "").trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : orig;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = 0o1;
    }
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return hex;
  };

  getProjectValue = (project): number => {
    let projectEntities = project.children;
    if (!project.children.length) return project.value;

    return projectEntities.reduce((prev, curr) => {
      if (curr.value) return prev + curr.value;
      return prev + this.getChildrensValues(curr);
    }, 0);
  };

  getChildrensValues = (entity): number => {
    let val = entity.children.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    return val;
  };
}
