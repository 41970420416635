import _ from "lodash";
import { action, observable } from "mobx";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { AppService } from "strikejs-app-service";
import {
  ITimelineGroup,
  ITimelineItem,
  ITimelineMarker,
  TimeLineModel
} from "../../../../../../components/widgets/timeline";
import { PROGRESS_STATUS_OPTIONS, Services } from "../../../../../../constants";
import I18n from "../../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import { ProgressStatus } from "../../../../../../enums";
import { IQueryStringService } from "../../../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../../../services/local/queryStringService/QueryStringService";

export interface IKeyValue {
  key: string | number;
  value: number;
}
export class ActionsReportingTimelineModel extends BaseModel {
  @observable allActions: FP.Entities.IActionSummary[];
  @observable allConfidentActions: FP.Entities.IActionSummary[];
  @observable currentFilterData: FP.Entities.IActionSummary[];
  @observable timelineData: any;
  @observable actionTypes: FP.Generic.IIdName[] = [];
  @observable isLoading: boolean = true;
  @observable progressStatusFilters: any[];
  @observable typeFilter: FP.Generic.IIdName;
  @observable setTypeFilterState: (type: FP.Generic.IIdName) => void;
  @observable setStatusFiltersState: (statuses: ProgressStatus[]) => void;
  @observable confidenceMetric: string;
  @observable confidenceData: any;
  @observable.ref selectedMilestone?: FP.Entities.IMilestone;
  @observable.ref project: FP.Entities.IProject;
  timelineModel: TimeLineModel;
  organisationId: number;
  queryStringService: IQueryStringService;
  returnUrl: string;
  appService: AppService;

  constructor(
    appService: AppService,
    data: FP.Entities.IActionSummary[],
    organisationId: number,
    project: FP.Entities.IProject,
    typeFilter,
    setTypeFilterFn,
    statusFilters,
    setStatusFilters
  ) {
    super();
    this.appService = appService;
    this.allActions = data;
    this.organisationId = organisationId;
    this.project = project;
    this.getActionTypes();
    this.allConfidentActions = this.currentFilterData = data.filter(e => e.startDate !== null && e.endDate !== null);
    this.progressStatusFilters = statusFilters;
    this.setStatusFiltersState = setStatusFilters;
    this.typeFilter = typeFilter;
    this.setTypeFilterState = setTypeFilterFn;
    this.applyFilters();
    this.timelineModel = new TimeLineModel();
    this.setTimelineDefaultDates(this.project);
    this.setTimeLineItems();
    this.queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);
    this.returnUrl = this.queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      this.queryStringService.getValue(QUERY_STRING_PARAMS.PREV_RETURN_URL) ||
        `/organisations/${this.organisationId}/projects/${this.project.id}/actions/visualisations`
    );

    this.isLoading = false;
  }

  @action
  getActionTypes = () =>
    this.allActions.forEach(a => {
      if (a.actionTypeName !== null && !this.actionTypes.some(e => e.id === a.actionTypeId))
        this.actionTypes.push({ id: a.actionTypeId, name: a.actionTypeName });
    });

  @action
  filterByType = () => {
    if (!this.typeFilter) return;
    this.loadConfidencePie();
    this.currentFilterData = this.currentFilterData.filter(e => e.actionTypeId === this.typeFilter.id);
  };

  @action
  filterByProgress = () => {
    let hasOverdueFilter = this.progressStatusFilters.includes(ProgressStatus.OVERDUE);
    let showAllOverdue = PROGRESS_STATUS_OPTIONS.every(e => this.progressStatusFilters.includes(e.key));
    if (hasOverdueFilter)
      this.currentFilterData = this.currentFilterData.filter(
        e => !(this.actionShouldHaveFinished(e) || this.actionShouldHaveStarted(e))
      );
    if (showAllOverdue && !hasOverdueFilter) {
      this.currentFilterData = this.currentFilterData.filter(
        e => this.actionShouldHaveFinished(e) || this.actionShouldHaveStarted(e)
      );
      return;
    }
    this.currentFilterData = this.currentFilterData.filter(e => !this.progressStatusFilters.includes(e.progressStatus));
  };

  getConfidenceMetric = (allData: any[], confidentData: any): string => {
    let count = allData.reduce((prev, cur) => prev + cur.value, 0);
    if (!count) return "";
    return ((confidentData / count) * 100).toFixed(0);
  };

  @action
  loadConfidencePie = () => {
    let typedData = this.allActions.filter(e => e.actionTypeId === this.typeFilter.id);

    let confidentData = typedData.filter(e => e.startDate !== null && e.endDate !== null).length;
    let unconfidentData = typedData.filter(e => e.startDate === null || e.endDate === null).length;

    this.confidenceData = [
      {
        label: I18n.t("visualisations.confidentTypeData"),
        value: confidentData,
        id: "confidentData"
      },
      {
        label: I18n.t("visualisations.unconfidentData"),
        value: unconfidentData,
        id: "unconfidentData"
      }
    ];

    this.confidenceMetric = this.getConfidenceMetric(this.confidenceData, confidentData);
  };

  @action
  applyFilters = () => {
    this.currentFilterData = this.allConfidentActions;
    this.filterByProgress();
    this.filterByType();
  };

  @action
  setTypeFilter = (type: FP.Generic.IIdName) => {
    this.setTypeFilterState(type);
    this.applyFilters();
  };

  @action
  toggleProgressFilters = status => {
    this.setStatusFiltersState(status);
    this.applyFilters();
  };

  getTimelineBarColour = (action: FP.Entities.IActionSummary) => {
    if (this.actionIsComplete(action)) return "#58C8D1";
    if (this.actionShouldHaveStarted(action))
      return "repeating-linear-gradient(45deg, #23006E, #23006E 10px, #F91E1E 10px, #F91E1E 20px)";
    if (this.actionIsUnstarted(action)) return "#23006E";
    if (this.actionShouldHaveFinished(action))
      return "repeating-linear-gradient(45deg, #FFC036, #FFC036 10px, #F91E1E 10px, #F91E1E 20px)";
    if (this.actionIsInProgress(action)) return "#FFC036";
  };

  actionShouldHaveStarted = (action: FP.Entities.IActionSummary) =>
    action.progressStatus === ProgressStatus.NOT_STARTED && moment().add(-1, "day").isAfter(action.startDate);
  actionIsUnstarted = (action: FP.Entities.IActionSummary) => action.progressStatus === ProgressStatus.NOT_STARTED;
  actionIsComplete = (action: FP.Entities.IActionSummary) => action.progressStatus === ProgressStatus.COMPLETED;
  actionShouldHaveFinished = (action: FP.Entities.IActionSummary) =>
    action.progressStatus === ProgressStatus.IN_PROGRESS && moment().add(-1, "day").isAfter(action.endDate);
  actionIsInProgress = (action: FP.Entities.IActionSummary) => action.progressStatus === ProgressStatus.IN_PROGRESS;

  convertDateStringToMoment = (date: string) => moment(date, "YYYY-MM-DD");

  setTimeLineItems = () => {
    const items: ITimelineItem[] = this.currentFilterData.map(action => {
      return {
        id: action.id,
        group: action.id,
        title: action.name,
        start_time: moment(action.startDate).startOf("day"),
        start_time_v: moment(action.startDate).startOf("day").valueOf(),
        end_time: moment(action.endDate).endOf("day"),
        data: action
      };
    });

    let groups: ITimelineGroup[] = this.currentFilterData.map(action => {
      return {
        id: action.id,
        start_time_v: moment(action.startDate).startOf("day").valueOf(),
        title:
          action.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{action.name}</small>}>
              {action.name}
            </Tooltip>
          ) : (
            action.name
          )
      };
    });

    groups = _.sortBy(groups, (e: any) => e.start_time_v);

    const markers: ITimelineMarker[] = this.project.milestones.map(milestone => {
      return {
        id: milestone.id,
        date: moment(milestone.deadline),
        title: milestone.name
      };
    });

    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setMarkers(markers);
    this.timelineModel.setSidebarWidth(350);
    this.timelineModel.setSideheaderTitle(<strong className="pl-3 pb-1">{I18n.t("entities.actions")}</strong>);
    this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = props => {
      const { item, getItemProps } = props;
      const action: FP.Entities.IActionSummary = item.data;
      const itemProps = getItemProps({});
      const top = parseInt(itemProps.style.top.replace("px", ""));
      itemProps.style = {
        ...itemProps.style,
        height: "12px",
        background: this.getTimelineBarColour(action),
        lineHeight: "12px",
        top: top + 3 + "px",
        border: `1px solid ${this.getTimelineBarColour(action)}`
      };
      return (
        <Link
          key={item.id}
          to={`/organisations/${this.organisationId}/projects/${this.project.id}/actions/${item.id}?${
            QUERY_STRING_PARAMS.PREV_RETURN_URL
          }=${encodeURIComponent(this.returnUrl)}`}
        >
          <Tooltip
            theme="light"
            followCursor
            html={
              <>
                <small className="d-block">{action.name}</small>
                <small className="d-block;">
                  {I18n.t("table.startDate")}: {moment(action.startDate).format("L")}
                </small>
                <small className="d-block">
                  {I18n.t("table.endDate")}: {moment(action.endDate).format("L")}
                </small>
              </>
            }
          >
            <div {...itemProps} title={null} />
          </Tooltip>
        </Link>
      );
    };
    this.timelineModel.isLoading = false;
  };

  handleMilestoneClick = (milestone: FP.Entities.IMilestone) => {
    this.setMilestone(milestone);
    const foundMarker = this.timelineModel.markers.find(marker => marker.id === milestone.id);
    if (foundMarker) {
      this.timelineModel.setSelectedMarker(foundMarker);
      this.timelineModel.scrollToDate(foundMarker.date);
    }
  };

  @action
  setMilestone = (milestone: FP.Entities.IMilestone) => {
    this.selectedMilestone = milestone;
  };

  handleTimelineMarkerSelect = (marker: ITimelineMarker) => {
    const foundMilestone = this.project.milestones.find(milestone => milestone.id === marker.id);
    if (foundMilestone) {
      this.setMilestone(foundMilestone);
    }
  };

  setTimelineDefaultDates = (project: FP.Entities.IProject) => {
    const startDate = moment(project.startDate);
    const endDate = moment(project.actualEndDate);
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate, endDate);
  };
}
