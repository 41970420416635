import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { IMPACT_LEVEL_OPTIONS, PROGRESS_STATUS_OPTIONS, Services } from "../../../../../constants";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { EntityTypes, GridTypes, ImpactLevel } from "../../../../../enums";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { IProjectTeamUserPermissionsApi } from "../../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { ImpactsHub } from "../../../../../services/hubs/ImpactsHub/Impacts_hub";
import { GetGridFns } from "../../../../../core/grids/GridFunctions";
import { GetImpactGridViewColumns } from "../../ImpactsView/ImpactGridView/ImpactGridView_columns";

export class ImpactGridViewModel extends BaseModel implements IGridModel {
  appService: AppService;
  impactsProvider: IImpactsApi;
  impactsHub: ImpactsHub;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  type: GridTypes = GridTypes.STAKEHOLDER_IMPACTS_GRID;
  organisationId: number;
  projectId: number;
  userCanViewActions: boolean;

  constructor(appService: AppService, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    this.appService = appService;
    this.impactsProvider = appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactsHub = appService.getService<ImpactsHub>(Services.ImpactsHub);
    this.projectTeamUserPermissionsProvider = appService.getService<IProjectTeamUserPermissionsApi>(
      Services.ProjectTeamUserPermissionsApi
    );
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mapProgressStatuses();
    this.mapImpactLevels();
    this.userCanViewActions = PermissionsContext.canViewField(PermissionFields.ACTIONS, organisationId, projectId);
  }

  onMount = async () => {
    this.isLoading = false;
    // Promise.all([this.loadImpactTypes(), this.loadProjectTeamMembers()]).then(v => {
    //     this.isLoading = false;
    // })
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetImpactGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      projectTeamMembers: [],
      impactLevels: this.impactLevels,
      progressStatuses: this.progressStatuses,
      gridFns: GetGridFns(this.impactsProvider, this.organisationId, this.projectId),
      modals: null, // getImpactGridModals(this.appService, this.organisationId, this.projectId),
      userCanViewStakeholders: true,
      userCanViewActions: this.userCanViewActions,
      pinned: null
    });
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  mapImpactLevels = () => {
    this.impactLevels = IMPACT_LEVEL_OPTIONS.filter(e => e.key !== ImpactLevel.UNKNOWN).map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
