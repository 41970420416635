import React from "react"
import { ReportingCard } from "./ReportingCard";
import { ReportingMetric } from "./ReportingMetric";
import { ReportingTitle } from "./ReportingTitle";
import { PieChart } from "../../../../../../components/widgets/pie/pie";
import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import { Panel } from "../../../../../../components/ui/Panel";

interface ReportingPieProps {
    data: any[];
    metric: string | React.ReactNode;
    colourArray: string[];
    title: string;
    onClick?: (dataObject: any) => void;
}

export const PieTooltip = (props) => {
    return <Panel.Panel
        background={PanelBackgrounds.BG_WHITE}
        hasBorderRadius={true}
        className="p-2"
    >
        <span className="mb-0 body">
            {props.label}: {props.value}
        </span>
    </Panel.Panel>
}

export const ReportingPie: React.FC<ReportingPieProps> = ({ data, metric, colourArray, title, onClick }) => {
    const PieLegend = ({ colours, data }) => {
        if (!data) return null;
        return <div style={{ maxWidth: "35%" }}>
            {data.map((e, i) => <div className="d-flex align-items-center mb-1" key={e.id}>
                <div className="legend-circle mr-1" style={{ backgroundColor: colours[i] }}></div>
                <span className="body" style={{ flex: 1 }}>{e.label}</span>
            </div>)}
        </div>
    }


    return <ReportingCard className="px-3">
        <div className="d-flex action_reporting__Card-info mb-1">
            <ReportingTitle
                title={title}
                className="pt-3"
            />
        </div>
        <div className="action-reporting__metric-pie pb-2">
            <ReportingMetric metric={metric} className="pl-2" />
            <div className="px-2" style={{ width: "50%", height: "150px" }}>
                <PieChart
                    data={data}
                    colors={colourArray}
                    hasLegend={false}
                    translateLegendX={100}
                    translateLegendY={0}
                    tooltipFn={(e) => <PieTooltip {...e} />}
                    margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
                    onClickFn={onClick} />
            </div>
            <PieLegend data={data} colours={colourArray} />
        </div>
    </ReportingCard>
}

