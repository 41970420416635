import { AxiosRequestConfig } from "axios";
import {
  DrillThroughImpactTable,
  FullHeatmapData,
  FullPieData,
  HeatmapSupportObject,
  IBusinessAreaImpactsTableQuery,
  IDrillThroughQuery,
  IHeatmapQuery
} from "../../../../pages/insight/organisations/organisationDashboard/models/dashboard_interfaces";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { BaseOrganisationApiModel } from "../../BaseOrganisationApiModel";
import { IHttp } from "../../IHttp";
import { IHeatmapsApi } from "./IHeatmaps.Api";

export class HeatmapsApi extends BaseOrganisationApiModel<any> implements IHeatmapsApi {
  controller: string = "heatmaps";

  constructor(http: IHttp) {
    super(http, "heatmaps");
    this.url = `${this.http.url}/${this.version}/organisations/`;
  }

  getBusinessAreasHeatmap = async (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const parentId = query.id;
    const startMonth = query.startDateMonth;
    const startYear = query.startDateYear;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/business-areas/${parentId}/${startMonth}/${startYear}/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<FullHeatmapData>;
    }
  };

  getBusinessAreasHeatmapSupportingData = async (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}${organisationId}/heatmaps/business-areas/supporting-data/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<HeatmapSupportObject>;
    }
  };

  getBusinessAreaDrillThroughPieData = async (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const id = query.id;
    const month = query.month;
    const year = query.year;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/business-areas/drill-through-pie/${id}/${month}/${year}/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<FullPieData>;
    }
  };

  getBusinessAreasImpactTabelData = async (
    organisationId: number,
    query: IBusinessAreaImpactsTableQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const projectId = query.projectId;
    const month = query.month;
    const year = query.year;
    const businessAreaId = query.businessAreaId;

    const url = `${this.url}${organisationId}/
      heatmaps/
      business-areas/
      drill-through-table/
      ${projectId}/${month}/${year}/${businessAreaId}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<DrillThroughImpactTable>;
    }
  };

  getLocationsHeatmap = async (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const parentId = query.id;
    const startMonth = query.startDateMonth;
    const startYear = query.startDateYear;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/locations/${parentId}/${startMonth}/${startYear}/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<FullHeatmapData>;
    }
  };

  getLocationsHeatmapSupportingData = async (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}${organisationId}/heatmaps/locations/supporting-data/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<HeatmapSupportObject>;
    }
  };

  getLocationsDrillThroughPieData = async (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const id = query.id;
    const month = query.month;
    const year = query.year;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/locations/drill-through-pie/${id}/${month}/${year}/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<FullPieData>;
    }
  };

  getProjectDrillThroughPieData = async (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const id = query.id;
    const month = query.month;
    const year = query.year;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/projects/drill-through-pie/${id}/${month}/${year}/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<FullPieData>;
    }
  };

  getProjectsHeatmap = async (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const parentId = query.id;
    const startMonth = query.startDateMonth;
    const startYear = query.startDateYear;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/projects/${parentId}/${startMonth}/${startYear}/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<FullHeatmapData>;
    }
  };

  getProjectsHeatmapSupportingData = async (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}${organisationId}/heatmaps/projects/supporting-data/${tagIds}`;

    const result = await this.http.get(url, config);
    if (result && result.status === 200) {
      return result.data as IFlightPathApiResponse<HeatmapSupportObject>;
    }
  };
}
