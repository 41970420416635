import { Services } from "../../constants";
import AppService from "../../contexts/AppService";
import { IAuthApi } from "../../services/api/v1/auth/IAuth.api";

export async function loadFlightPathUserClaims(organisationId: number, oktaUserId?: string) {
  let authApi = AppService.getService<IAuthApi>(Services.AuthApi);
  let result = await authApi.getUserClaims(organisationId);
  
  if (!result.payload.length || !oktaUserId || oktaUserId.length === 0) {
    await authApi.createSystemUser();
    result = await authApi.getUserClaims(organisationId);
  }
  return result.payload;
}
