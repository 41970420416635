import React, { useEffect, useState } from "react";
import { CreateActionViewModel } from "./CreateActionView_model";
import { observer } from "mobx-react-lite";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../core/localization/I18n";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { UiSizes } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { useAppService } from "../../../../contexts/AppService";
import { Panel } from "../../../../components/ui/Panel";
import { useFlightPathUser } from "../../../../setup";
import { useCurrentOrganisation } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useRouteMatch } from "react-router-dom";

export interface CreateActionViewProps {
  model?: CreateActionViewModel;
}

const CreateActionView: React.FunctionComponent<CreateActionViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const router = useRouteMatch();
  const currentOrganisation = useCurrentOrganisation();
  const [model] = useState(
    () =>
      m ||
      new CreateActionViewModel({
        appService,
        user,
        organisationId: currentOrganisation.id,
        projectId: router.params["projectId"]
      })
  );

  const getImpactIdFromQueryString = (): number => {
    let querystring = window.location.search;
    let params = new URLSearchParams(querystring);
    let impactId = params.get("impactId");
    return +impactId ?? 0;
  };

  const actionCreatingFromImpact = (): boolean => {
    return getImpactIdFromQueryString() > 0;
  };

  useEffect(() => {
    var impactId = getImpactIdFromQueryString();
    model.onMount(impactId);
  }, [model]);

  return (
    <div className="create-Action-view container-fluid pt-8">
      {!!model.projectId && (
        <div className="row mb-3">
          <div className="col-12 p-2">
            {actionCreatingFromImpact() && (
              <LinkButton
                size={UiSizes.MD}
                type={ButtonTypes.LINK}
                className="p-0"
                href={`/organisations/${currentOrganisation.id}/projects/${model.projectId}/impacts`}
              >
                <Icon className="mr-1" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.goBackToActions")}
              </LinkButton>
            )}
            {actionCreatingFromImpact() === false && (
              <LinkButton
                size={UiSizes.MD}
                type={ButtonTypes.LINK}
                className="p-0"
                href={`/organisations/${currentOrganisation.id}/projects/${model.projectId}/actions`}
              >
                <Icon className="mr-1" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.goBackToActions")}
              </LinkButton>
            )}
          </div>
        </div>
      )}
      <div className="row mb-4">
        <div className="col-12">
          <h1 ref={ref => (model.scrollToRef = ref)}>
            {I18n.t("phrases.createNew")} {I18n.t("entities.action")}
          </h1>
        </div>
        <div className="col-lg-7">
          <p>{I18n.t("phrases.createActionDescription")}</p>
        </div>
      </div>

      <div className="col-6">
        <Panel.Panel hasBorderRadius={true} type={Panel.PanelTypes.OUTLINES} className="px-3">
          {model.projectForm && (
            <div className="row bg-gray-200 py-5">
              <div className="col-12">
                <SingleForm model={model.projectForm} />
              </div>
            </div>
          )}
          {model.formModel && (
            <div className="row mb-4 mt-4">
              <div className="col-12">
                <SingleForm model={model.formModel} />
              </div>
            </div>
          )}
        </Panel.Panel>
      </div>
    </div>
  );
});

export { CreateActionView };
