import { AppService } from "strikejs-app-service";
import { ImpactsHub } from "../../../../../services/hubs/ImpactsHub/Impacts_hub";
import { Services } from "../../../../../constants";
import { action, observable } from "mobx";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { IActionTypeApi } from "../../../../../services/api/v1/actionTypes/IActionType.api";
import { ActionsHub } from "../../../../../services/hubs/ActionsHub/Actions_hub";

export class ActionGridModel {
  appService: AppService;
  projectId: number;
  actionsHub: ActionsHub;
  organisationId: number;
  @observable isLoading: boolean = true;
  actionTypeProvider: IActionTypeApi;
  @observable gridActions: FP.Entities.IActionSummary[];
  @observable.ref actionTypes: FP.Entities.IActionType[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedActions: number[] = [];
  gridApi: GridApi;
  impactsProvider: IImpactsApi;
  impactIds: number[];
  searchText: string;

  /**
   *
   */
  constructor(
    appService: AppService,
    projectId: number,
    organisationId: number,
    authUser: FP.Entities.IUser,
    impactIds: number[]
  ) {
    this.appService = appService;
    this.projectId = projectId;
    this.actionTypeProvider = appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.actionsHub = this.appService.getService<ActionsHub>(Services.ActionsHub);
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.organisationId = organisationId;
    this.impactIds = impactIds;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.actionsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.actionsHub.isConnectionStarted === true) {
      await this.actionsHub.stopConnection();
    }
    await this.actionsHub.startConnection();

    this.actionsHub.onLoadGridData(d => {
      this.setActionRowData(d);
    });

    await this.actionsHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  @action
  setActionRowData = impacts => {
    this.gridActions = impacts;
    this.isLoading = false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedActions = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;
    if (this.gridApi !== undefined) {
      this.gridApi.setQuickFilter(this.searchText);
    }
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypeProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.actionTypes = res.payload;
  };

  assignImpacts = async () => {
    console.log(this.impactIds, this.selectedActions);
    return await this.impactsProvider.addBulkActionsToImpacts(
      this.organisationId,
      this.projectId,
      this.impactIds,
      this.selectedActions
    );
  };
}
