import * as React from "react";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  useCurrentOrganisation,
  useOrganisationContext,
  useUserOrganisations
} from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { IOrganisationPermission } from "../../../../contexts/permissions/PermissionsTypes";

export interface OrganisationNavListProps {
  onOrganisationSelected: () => void;
  shouldRedirectToOrganisation?: boolean;
}

const OrganisationNavList: React.FunctionComponent<OrganisationNavListProps> = observer(
  ({ onOrganisationSelected, shouldRedirectToOrganisation = false }) => {
    const currentOrganisationContext = useOrganisationContext();
    const orgs: IOrganisationPermission[] = useUserOrganisations();
    const currentOrg = useCurrentOrganisation();
    const history = useHistory();
    return (
      <div className="organisation-nav-list">
        {orgs.map((e, i) => {
          return (
            <div
              key={e.id}
              className={`organisation-nav-list__item ${Animations.FADE_IN} speed-3 delay-${i} ${
                e.id === currentOrg.id ? "organisation-nav-list__item--active" : ""
              }`}
            >
              <AvatarContent
                onClick={() => {
                  currentOrganisationContext.setCurrentOrganisationId(e.id);
                  onOrganisationSelected();

                  if (shouldRedirectToOrganisation) {
                    history.push(`/organisations/${e.id}`);
                  }
                }}
                avatarProps={{
                  imgSrc: e.profileImageUrl
                }}
              >
                <p className="mb-0 text-truncate">{e.name}</p>
              </AvatarContent>
            </div>
          );
        })}
      </div>
    );
  }
);

export { OrganisationNavList };
