import { BaseModel } from "../../../../../core/util/BaseModel";
import * as H from "history";
import { AppService } from "strikejs-app-service";
import { action, observable } from "mobx";
import { Services } from "../../../../../constants";
import { ActionVisualisationsHub } from "../../../../../services/hubs/ActionVisualisationsHub/ActionVisualisations_hub";
import I18n from "../../../../../core/localization/I18n";
import { ProgressStatus, RagStatus } from "../../../../../enums";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";

export class ActionReportingViewModel extends BaseModel {
  appService: AppService;
  history: H.History<unknown>;
  @observable.ref project: FP.Entities.IProject;
  @observable actions: FP.Entities.IActionSummary[];
  @observable actionCount: number;
  organisationId: number;
  projectId: number;
  @observable currentPage?: string;
  @observable.ref actionRagStatus: any;
  @observable isLoading: boolean = true;
  actionVisualisationsHub: ActionVisualisationsHub;
  projectProvider: IProjectsApi;
  @observable ragData: any[];
  @observable totalConfidenceData: any[];
  @observable totalConfidenceMetric: string;

  constructor(appService: AppService, organisationId: number, projectId: number, history: H.History, page: string) {
    super();
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.history = history;
    this.currentPage = page || "";
    this.actionVisualisationsHub = appService.getService(Services.ActionVisualisationsHub);
    this.projectProvider = appService.getService(Services.ProjectsApi);
  }

  onMount = async () => {
    await this.registerSocketEvents();
    await this.loadProjectInfo(this.projectId);
    this.isLoading = false;
  };

  onUnmount = () => {
    this.actionVisualisationsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.actionVisualisationsHub.isConnectionStarted === true) {
      await this.actionVisualisationsHub.stopConnection();
    }
    await this.actionVisualisationsHub.startConnection();

    this.actionVisualisationsHub.onLoadGridData(d => {
      this.setData(d);
      this.loadCharts();
    });

    await this.actionVisualisationsHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  @action
  loadCharts = () => {
    this.loadActionRagStatus();
    this.loadTotalConfidence();
  };

  @action
  loadProjectInfo = async (id: number) => {
    const res = await this.projectProvider.getById(this.organisationId, id);
    if (!res || res.isError) return;
    this.project = res.payload;
  };

  @action
  setData = actions => {
    this.actions = actions;
    this.actionCount = actions.length;
  };

  @action
  loadActionRagStatus = () => {
    this.ragData = [
      {
        label: I18n.t("visualisations.Red"),
        value: this.actions.filter(e => e.ragStatus === RagStatus.RED && e.progressStatus !== ProgressStatus.COMPLETED)
          .length,
        id: "red"
      },
      {
        label: I18n.t("visualisations.Orange"),
        value: this.actions.filter(
          e => e.ragStatus === RagStatus.AMBER && e.progressStatus !== ProgressStatus.COMPLETED
        ).length,
        id: "orange"
      },
      {
        label: I18n.t("visualisations.Green"),
        value: this.actions.filter(
          e => e.ragStatus === RagStatus.GREEN && e.progressStatus !== ProgressStatus.COMPLETED
        ).length,
        id: "green"
      },
      {
        label: I18n.t("visualisations.Completed"),
        value: this.actions.filter(e => e.progressStatus === ProgressStatus.COMPLETED).length,
        id: "completed"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: this.actions.filter(e => e.ragStatus === -1 && e.progressStatus !== ProgressStatus.COMPLETED).length,
        id: "unknown"
      }
    ];
  };

  @action
  loadTotalConfidence = () => {
    let confidentData = this.actions.filter(e => e.startDate !== null && e.endDate !== null).length;
    let unconfidentData = this.actions.filter(e => e.startDate === null || e.endDate === null).length;

    this.totalConfidenceData = [
      {
        label: I18n.t("visualisations.confidentData"),
        value: confidentData,
        id: "confidentData"
      },
      {
        label: I18n.t("visualisations.unconfidentData"),
        value: unconfidentData,
        id: "unconfidentData"
      }
    ];

    this.totalConfidenceMetric = this.getConfidenceMetric(this.totalConfidenceData, confidentData);
  };

  getConfidenceMetric = (allData: any[], confidentData: any): string => {
    let count = allData.reduce((prev, cur) => prev + cur.value, 0);
    if (!count) return "";

    return ((confidentData / count) * 100).toFixed(0);
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 1) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions/visualisations`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions`);
  };
}
