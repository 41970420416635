import { INavigationModel } from "./Navigation_model";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import { Avatar } from "../../../components/ui/Avatar";
import { observer } from "mobx-react";
import { UiSizes } from "../../../enums";
import textLogo from "./logos/logo-serendata.svg";
import iconLogo from "./logos/logo-serendata-icon.svg";
import I18n from "../../../core/localization/I18n";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UiPortal, UiPortalContent } from "../../ui/UiPortal";
import { AuthUserAvatar } from "../../../pages/change/navigation/config/AuthUserAvatar";
import HelpNavigation from "../../../pages/change/navigation/config/HelpNavigation";
import Notifications from "../notifications/Notifications_view";
import { useOrganisationContext } from "../../../services/local/organisationContext/OrganisationContextModel";
import { GetChangeNavActions } from "../../../pages/change/navigation/config/actions";
import { useAppService } from "../../../contexts/AppService";
import { IModalService } from "../../../core/modal/IModalService";
import { Services } from "../../../constants";
import { OrganisationSwitch } from "../organisationSwitch/OrganisationSwitch_view";
import { Animations } from "../../../core/util/Animations";
import { Tooltip } from "../../ui/Tooltip";
import { Panel } from "../../ui/Panel";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";

const NAVIGATION_SECONDARY_CONTENT_TOP_PORTAL_NAME = "navigation.topSecondaryContent";
const NAVIGATION_SECONDARY_BOTTOM_CONTENT_PORTAL_NAME = "navigation.bottomSecondaryContent";
const NAVIGATION_BOTTOM_CONTENT_PORTAL_NAME = "navigation.bottomContent";

export interface NavigationViewProps {
  model: INavigationModel;
}

export const NavigationView: React.FC<NavigationViewProps> = observer(({ model }) => {
  const path = useLocation();
  const orgContext = useOrganisationContext();
  const appService = useAppService();
  let asideModalService = appService.getService<IModalService>(Services.AsideModalService);
  const currentOrganisationId = orgContext.currentOrganisationId;
  const currentOrganisationName = orgContext.currentOrganisation?.name ?? "";
  useEffect(() => {}, [currentOrganisationId, model, asideModalService]);

  return (
    <div className="navigation">
      <aside className="navigation__main">
        <div className={`navigation__brand mb-4 ${Animations.FADE_IN} speed-3`}>
          <Link to="/">
            <Avatar size={UiSizes.SM} imgSrc={iconLogo} />
          </Link>
        </div>
        <div className="navigation__actions">
          <ActionsList model={model} />
        </div>
        <div className="navigation__account">
          <UiPortal name={NAVIGATION_BOTTOM_CONTENT_PORTAL_NAME}>
            <div className="d-flex flex-column align-items-center">
              <HelpNavigation
                currentOrganisationName={currentOrganisationName}
                currentOrganisationId={currentOrganisationId}
              />
              <Notifications />
              <AuthUserAvatar />
            </div>
          </UiPortal>
          {model.mainBottomContent}
        </div>
      </aside>
      <div className="navigation__secondary">
        <div className="navigation__brand-logo">
          <Link to="/">
            <img className="navigation__brand-logo-img" src={textLogo} alt={I18n.t("phrases.flightPath")} />
          </Link>
        </div>
        <nav>
          {model.isOrganisationSwitchActive ? (
            <OrganisationSwitch
              navigationModel={model}
              shouldRedirectToOrganisation={path.pathname.startsWith("/organisations/")}
            />
          ) : null}
          {model.isOrganisationListVisible ? null : (
            <>
              {model.secondaryContent}
              <UiPortal name={NAVIGATION_SECONDARY_CONTENT_TOP_PORTAL_NAME}></UiPortal>
              <UiPortal name={NAVIGATION_SECONDARY_BOTTOM_CONTENT_PORTAL_NAME}></UiPortal>
            </>
          )}
        </nav>
      </div>
    </div>
  );
});

export function useOrganisationSwitch(isActive: boolean) {
  const appService = useAppService();
  const navModel = appService.getService<INavigationModel>(Services.NavigationModel);
  useEffect(() => {
    navModel.setOrganisationSwitchActive(isActive);
  }, [navModel, isActive]);
}

const ActionsList: React.FC<NavigationViewProps> = observer(({ model }) => {
  const [section, setSection] = useState("");
  const appService = useAppService();
  const modalService = appService.getService<IModalService>(Services.ModalService);
  const path = useLocation();
  const pathName = path.pathname;

  const actions = useMemo(
    () => GetChangeNavActions(model, modalService, setSection, section, pathName),
    [model, modalService, pathName, section, setSection]
  );

  return (
    <>
      {actions.map((e: any) => {
        let k = { ...e };
        delete k.label;
        return (
          <div key={e.id} className={`navigation__action`} data-testid={`nav-action-${e.id}`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={k} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{e.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
});

export const NavigationSecondaryBottomContent: React.FC<{}> = ({ children }) => {
  const appService = useAppService();
  const navModel = appService.getService<INavigationModel>(Services.NavigationModel);
  useEffect(() => {
    navModel.secondaryContent = children;

    return () => {
      navModel.onBack();
    };
    // eslint-disable-next-line
  }, [navModel]);
  return null;
};

export const NavigationBottomContent: React.FC<{}> = ({ children }) => (
  <UiPortalContent name={NAVIGATION_BOTTOM_CONTENT_PORTAL_NAME}>{children}</UiPortalContent>
);
