import { action, observable } from "mobx";
import * as React from "react";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../../constants";
import { IHttpProgressModel } from "../../../../../../../../core/httpProgress/HttpProgress_model";
import { IModalService } from "../../../../../../../../core/modal/IModalService";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import { IActionsApi } from "../../../../../../../../services/api/v1/actions/IActions.api";

export class ActionImpactsSidebarModel extends BaseModel {
    appService: AppService;
    actionsProvider: IActionsApi;
    modalService: IModalService;
    httpProgress: IHttpProgressModel;
    projectId: number;
    @observable isLoading: boolean = true;
    @observable isSearchMode: boolean = false;
    @observable searchValue: string = "";
    @observable.ref action: FP.Entities.IAction;
    @observable.ref actionImpacts: FP.Entities.IImpact[] = [];
    @observable.ref filteredActionImpacts: FP.Entities.IImpact[] = [];
    organisationId: number;

    constructor(appService: AppService, actionId: number, organisationId: number, projectId: number) {
        super();
        this.appService = appService;
        this.projectId = projectId;
        this.actionsProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
        this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
        this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
        this.organisationId = organisationId;
        this.loadAction(actionId)
    }

    onMount = () => {

    };

    onUnmount = () => {
        this.resetSearch();
    };

    @action
    setAction = (action: FP.Entities.IAction) => {
        this.action = action;
        this.actionImpacts = action.impacts as FP.Entities.IImpact[];
        this.filteredActionImpacts = action.impacts as FP.Entities.IImpact[];
        this.isLoading = false;
    };

    @action
    loadAction = async (actionId: number) => {
        this.isLoading = true;
        let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
        if (!res || res.isError) return;

        this.setAction(res.payload);
    };

    @action
    updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
        this.searchValue = e.currentTarget.value;
        this.filterActionImpacts();
    };

    @action
    showSearchMode = () => {
        this.isSearchMode = true;
    };

    @action
    hideSearchMode = () => {
        this.isSearchMode = false;
    };

    @action
    resetSearch = () => {
        this.searchValue = "";
        this.filterActionImpacts();
        this.hideSearchMode();
    };

    @action
    filterActionImpacts = () => {
        if (this.searchValue) {
            const lowerSearch = this.searchValue.toLowerCase();
            this.filteredActionImpacts = this.actionImpacts.filter(impact => {
                const lowerName = impact.name.toLowerCase();
                const lowerRefNo = impact.refNumber.toLowerCase();

                return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
            });
        } else {
            this.filteredActionImpacts = this.actionImpacts;
        }
    };
}
