import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImpactAssessmentChart } from "../../../../../../components/widgets/impactAssessmentChart/ImpactAssessmentChart";
import { useAppService } from "../../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ChangeImpactAssessmentModel } from "./ChangeImpactAssessment_model";

interface ChangeImpactAssessmentProps {
  model?: ChangeImpactAssessmentModel;
  impacts: FP.Entities.IImpactAssessmentDataGroup[];
  dataConfidence: number;
  heading: string;
  gapAnalysisClick?: any;
  drillThroughClick?: any;
  noDataLabel: string;
}

export const ChangeImpactAssessment: React.FC<ChangeImpactAssessmentProps> = observer(
  ({ impacts, dataConfidence, heading, gapAnalysisClick, drillThroughClick, noDataLabel }) => {
    const appService = useAppService();
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams<{ projectId: string; page: string }>();
    const [model, setModel] = useState(
      () => new ChangeImpactAssessmentModel(appService, impacts, +organisationId, +projectId)
    );
    const modelProjectId = model.projectId;

    useEffect(() => {
      if (`${modelProjectId}` !== projectId) {
        setModel(new ChangeImpactAssessmentModel(appService, impacts, +organisationId, +projectId));
      }
    }, [projectId, impacts, organisationId, appService, model, modelProjectId]);

    const chartMargin = { top: 10, right: 0, bottom: 100, left: 30 };

    const keys = ["High", "Medium", "Low"];

    return (
      <div style={{ width: "100%", height: 400, paddingBottom: 100 }}>
        {impacts && (
          <ImpactAssessmentChart
            data={impacts}
            dataConfidence={dataConfidence}
            keys={keys}
            heading={heading}
            containerWidth={400}
            margin={chartMargin}
            onClick={drillThroughClick}
            gapAnalysisOnClick={gapAnalysisClick}
            noDataLabel={noDataLabel}
          ></ImpactAssessmentChart>
        )}
      </div>
    );
  }
);
