import React from "react";
import { Switch, Route } from "react-router-dom";
import { useAppService } from "../../contexts/AppService";
import {
  NavigationSecondaryBottomContent,
  useOrganisationSwitch
} from "../../components/widgets/navigation/Navigation_view";
import { useIsTestUser } from "../../core/auth/authorise";
import I18n from "../../core/localization/I18n";
import { CanContributeOrgRoute } from "../../core/router/Route";
import { CreateImpactGroupView } from "../../pages/change/impactGroup/createImpactGroupView/CreateImpactGroupView_view";
import { CreateImpactViewModel } from "../../pages/change/impacts/createImpactView/CreateImpactView_model";
import { CreateImpactView } from "../../pages/change/impacts/createImpactView/CreateImpactView_view";
import { CreateNav } from "../../pages/change/navigation/create/CreateNav_view";
import { CreateProjectView } from "../../pages/change/projects/createProjectView/CreateProjectView_view";
import { CreateStakeholderGroupViewModel } from "../../pages/change/stakeholderGroup/createStakeholderGroupView/CreateStakeholderGroupView_model";
import { CreateStakeholderGroupView } from "../../pages/change/stakeholderGroup/createStakeholderGroupView/CreateStakeholderGroupView_view";
import { CreateAudienceViewModel } from "../../pages/change/stakeholders/createAudienceView/CreateAudienceView_model";
import { CreateAudienceView } from "../../pages/change/stakeholders/createAudienceView/CreateAudienceView_view";
import { CreateStakeholderViewModel } from "../../pages/change/stakeholders/createStakeholderView/CreateStakeholderView_model";
import { CreateStakeholderView } from "../../pages/change/stakeholders/createStakeholderView/CreateStakeholderView_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import { CreateActionFormWrapper } from "./ActionsContainer";
import { CreateProgramme } from "./ProgrammesContainer";

export const CreateContainer: React.FC = () => {
  const appService = useAppService();
  useOrganisationSwitch(true);
  const isTestUser = useIsTestUser();
  return (
    <>
      <NavigationSecondaryBottomContent>
        <CreateNav doRouteBack={true} />
      </NavigationSecondaryBottomContent>
      <Switch>
        <Route path="/create/projects">
          <CanContributeOrgRoute>
            <PageTitle title={`${I18n.t("phrases.project")}`} />
            <CreateProjectView />
          </CanContributeOrgRoute>
        </Route>
        <Route path="/create/actions">
          <CreateActionFormWrapper />
        </Route>
        <Route
          path="/create/impacts"
          render={props => (
            <CanContributeOrgRoute>
              <PageTitle title={I18n.t(`phrases.impacts`)} />
              <CreateImpactView model={new CreateImpactViewModel(appService, props, isTestUser)} />
            </CanContributeOrgRoute>
          )}
        />
        <Route path="/create/impactgroups">
          <CanContributeOrgRoute>
            <PageTitle title={I18n.t(`phrases.highLevelImpact`)} />
            <CreateImpactGroupView />
          </CanContributeOrgRoute>
        </Route>
        <Route
          path="/create/stakeholders"
          render={props => (
            <CanContributeOrgRoute>
              <PageTitle title={I18n.t("phrases.createStakeholder")} />
              <CreateStakeholderView model={new CreateStakeholderViewModel(appService, props)} />
            </CanContributeOrgRoute>
          )}
        />
        <Route
          path="/create/audiences"
          render={props => (
            <CanContributeOrgRoute>
              <PageTitle title={I18n.t("phrases.createAudience")} />
              <CreateAudienceView model={new CreateAudienceViewModel(appService, props)} />
            </CanContributeOrgRoute>
          )}
        />
        <Route
          path="/create/stakeholdergroups"
          render={props => (
            <CanContributeOrgRoute>
              <PageTitle title={I18n.t(`phrases.stakeholderGroups`)} />
              <CreateStakeholderGroupView model={new CreateStakeholderGroupViewModel(appService, props)} />
            </CanContributeOrgRoute>
          )}
        />
        <Route path="/create/programmes">
          <CreateProgramme />
        </Route>
      </Switch>
    </>
  );
};
