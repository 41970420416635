import React, { useState } from "react";
import {
  useCanContributeOrg,
  useGetAllProgrammes,
  useGetAllProjects
} from "../../../../contexts/permissions/PermissionHooks";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectNavElement } from "../../../../components/widgets/projectNavElement";
import * as _ from "lodash";
import { Input } from "../../../../components/ui/_forms/Input";
import { IconSymbols } from "../../../../components/ui/Icon";
import { IF } from "../../../../components/hoc/If";
import { UiSizes } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { ProgrammeNavElement } from "../../../../components/widgets/programmeNavElement";
import { IProjectPermission } from "../../../../contexts/permissions/PermissionsTypes";
import { Masonry } from "@flightpath/coreui/dist/widgets/masonry";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";

export const ProjectMegaMenu: React.FC<any> = ({ projectId }) => {
  const organisationId = useCurrentOrganisationId();
  const canContributeOrg = useCanContributeOrg(organisationId);

  let projects = _.orderBy(_.map(useGetAllProjects(organisationId)), e => e.name.toLowerCase());

  let [filteredProjects, setFilteredProjects] = useState(projects);

  const groupedProjects = _.map(_.groupBy(filteredProjects, "parentId"), e => {
    return e;
  });

  const cProgrammes = useGetAllProgrammes(organisationId);
  let programmes = getProgrammesFromList(groupedProjects, organisationId);

  const s = _.keyBy(
    _.filter(cProgrammes, e => e.projectIds.length === 0),
    "name"
  );

  programmes = { ...s, ...programmes };
  return (
    <div className={`project-mega-menu py-4`}>
      <div className="project-mega-menu__search container-fluid">
        <div className="row align-items-center">
          <div className="col-3">
            <Input
              icon={IconSymbols.Search}
              borderStyle="underline"
              onChange={e => {
                const value = e.target.value;
                if (value === "") {
                  setFilteredProjects(projects);
                } else {
                  setFilteredProjects(projects.filter(e => e.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
                }
              }}
            />
          </div>
          <IF condition={canContributeOrg}>
            <div className="col-5 offset-4 text-right">
              <LinkButton href="/create/programmes" type={ButtonTypes.LIGHT} size={UiSizes.XS} className="mr-2">
                {I18n.t("phrases.createProgramme")}
              </LinkButton>
              <LinkButton href="/create/projects" type={ButtonTypes.LIGHT} size={UiSizes.XS}>
                {I18n.t("phrases.createProject")}
              </LinkButton>
            </div>
          </IF>
        </div>
      </div>

      <div className="project-mega-menu__list mt-1">
        <div className="px-3">
          {/* <div className="card-columns"> */}
          <Masonry
            onLayoutComplete={() => {}}
            onRemoveComplete={() => {}}
            elementType="ul"
            options={{
              transitionDuration: 10,
              itemSelector: ".project-mega-menu__wrapper",
              columnWidth: 10
            }}
          >
            {_.map(programmes, (programme: any) => {
              return (
                <li
                  className={`project-mega-menu__wrapper card d-inline-block ${
                    programme.projects.length > 9
                      ? "project-mega-menu__wrapper--double"
                      : "project-mega-menu__wrapper--single"
                  }`}
                  key={`programme-${programme.id}`}
                >
                  <ProgrammeNavElement
                    name={programme?.name || "Standalone Projects"}
                    isActive={programme.id > 0}
                    url={`/organisations/${organisationId}/programmes/${programme.id}`}
                  />
                  {/* <Hr colour={Colours.GRAY_200} /> */}
                  <div
                    className={`project-mega-menu__projects ${
                      programme.projects.length > 9 ? "project-mega-menu__projects--split" : ""
                    }`}
                  >
                    {_.map(programme.projects, e => {
                      const url = `/organisations/${organisationId}/projects/${e.id}`;
                      return (
                        <div
                          key={e.id}
                          className={`project-mega-menu__item ${
                            e.id === projectId ? "project-mega-menu__item--active" : ""
                          }`}
                        >
                          <ProjectNavElement
                            key={`project-${e.id}`}
                            url={url}
                            name={e.name}
                            hasImpactsAccess={e.impact.canView}
                            hasActionsAccess={e.action.canView}
                            hasStakeholdersAccess={e.stakeholder.canView}
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              );
            })}
          </Masonry>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export const getProgrammesFromList = (list: IProjectPermission[][], organisationId: number) => {
  let res = {};
  list.forEach((e, i) => {
    let programme: any = PermissionsContext.getProgramme(organisationId, e[0].parentId);
    if (!programme) {
      programme = { id: 0, name: "Standalone Projects" };
    }
    if (res[programme.name]) {
      res[programme.name + "-" + i] = {
        id: programme.id,
        name: programme.name + " (Continued)",
        projects: e
      };
      return;
    }
    res[programme.name] = {
      id: programme.id,
      name: programme.name,
      projects: e
    };
  });

  return res;
};
