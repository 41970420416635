import React from "react";
import { Application } from "../../Application";
import { Switch, Route, Redirect } from "react-router-dom";
import { OrganisationsContainer } from "./OrganisationsContainer";
import { IModalService } from "../../core/modal/IModalService";
import { Services } from "../../constants";
import { SideBarLayout } from "../../components/layouts/SideBarLayout";
import { AppService } from "strikejs-app-service";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { IToasterService, ToasterServiceView } from "../../core/toaster/ToasterService";
import { IHttpProgressModel } from "../../core/httpProgress/HttpProgress_model";
import { HttpProgress } from "../../core/httpProgress/HttpProgress_view";
import { setPageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { DashboardContainer } from "./DashboardContainer";
import { useAppService } from "../../contexts/AppService";
import {
  SetCurrentOrganisationIdToLastAccessedOrganisation,
  useCurrentOrganisationId
} from "../../services/local/organisationContext/OrganisationContextModel";
import { CreateContainer } from "./createContainer";
import { TopNavigationProvider } from "../../pages/insight/navigation/TopNavigationsProvider";
import { SideNavigationProvider } from "../../pages/insight/navigation/SideNavigationProvider";

export interface ContainerProps {
  appService: AppService;
}

export interface AppProps {
  model: Application;
}

const App: React.FC<AppProps> = () => {
  const appService = useAppService();
  const asideModalService = appService.getService<IModalService>(Services.AsideModalService);
  const innerModalService = appService.getService<IModalService>(Services.InnerModalService);
  const confirmationModalService = appService.getService<IModalService>(Services.ConfirmationService);
  const httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
  const toastService = appService.getService<IToasterService>(Services.ToasterService);
  return (
    <SideBarLayout>
      <SideNavigationProvider />
      <SideBarLayout direction="horizontal">
        <div className="side-bar-section__wrapper">
          <TopNavigationProvider />
          <div className="side-bar-section__content">
            <div className="side-bar-section__modal">
              {innerModalService.renderComponent()}
              {confirmationModalService.renderComponent()}
            </div>
            <Switch>
              <Route path="/takemeto/current-organisation">
                <RedirectToCurrentOrganisation />
              </Route>
              <Route path="/" exact>
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/create">
                <CreateContainer />
              </Route>
              <Route path="/dashboard">
                <SetCurrentOrganisationIdToLastAccessedOrganisation />
                <DashboardContainer appService={appService} />
              </Route>

              <Route path="/organisations">
                <OrganisationsContainer appService={appService} />
              </Route>

              <Route path="*">
                <>
                  {setPageTitle(I18n.t(`phrases.pageNotFound`))}
                  <NotFoundIndex />
                </>
              </Route>
            </Switch>

            <HttpProgress model={httpProgress} />
          </div>
        </div>
        <ToasterServiceView toasts={toastService.toasts} toastService={toastService} />

        {asideModalService.renderComponent()}
      </SideBarLayout>
      <ToasterServiceView toasts={toastService.toasts} toastService={toastService} />
    </SideBarLayout>
  );
};

const RedirectToCurrentOrganisation: React.FC = () => {
  const currentOrganisationId = useCurrentOrganisationId();

  return <Redirect to={`/organisations/${currentOrganisationId}`} />;
};

// const RedirectToCurrentOrganisationReports: React.FC = props => {
//   const currentOrganisationId = useCurrentOrganisationId();
//   return (
//     <Redirect to={`/organisations/${currentOrganisationId}/reports/heatmap/${ImpactReportViews.IMPACT_BY_PROJECT}`} />
//   );
// };
export { App };
