import React, { useEffect } from "react";
import { ProgrammeViewModel } from "./View_model";
import { Panel } from "../../../../components/ui/Panel";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { ProjectInfoCardView } from "../../../../components/widgets/projects/ProjectInfoCard/ProjectInfoCard_view";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { TimelineView } from "../../../../components/widgets/timeline";
import { Enums, ProgressStatus } from "../../../../enums";
import { Timeline } from "../../../../components/widgets/TimelineWidget";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { CanEditProgramme } from "../../../../components/hoc/CanEdit";
import moment from "moment";
import { Animations } from "../../../../core/util/Animations";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useIsOrganisationOwnerOrAdmin } from "../../../../contexts/permissions/PermissionHooks";
import { IF } from "../../../../components/hoc/If";
import { ProgrammeLocations } from "./ProgrammeLocations";
import { ProgrammeSponsor } from "./ProgrammeSponsor";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { IModalService } from "../../../../core/modal/IModalService";
import { useAppService } from "../../../../contexts/AppService";
import { Services } from "../../../../constants";
import { ProgrammeLocationsModalContent } from "./ProgrammeLocationsModalContent";
import { ProgrammeContacts } from "./ProjectContacts";

export interface IProgrammeView {
  model: ProgrammeViewModel;
}

export const ProgrammeView: React.FunctionComponent<IProgrammeView> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  const canEditProgramme = useIsOrganisationOwnerOrAdmin(organisationId);

  const appService = useAppService();
  const modalService = appService.getService<IModalService>(Services.AsideModalService);

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  let pillType =
    model.programmeDashboardData.programmeDetails.progressStatus === ProgressStatus.NOT_STARTED
      ? PillTypes.LIGHT_GRAY
      : PillTypes.GRAY;

  const showLocationsModal = event => {
    event.preventDefault();
    modalService.show({
      showClose: true,
      title: (
        <div className="mt-6">
          <h1>{I18n.t("phrases.locations")}</h1>
        </div>
      ),
      content: (
        <ProgrammeLocationsModalContent
          programmeLocations={model.programmeDashboardData.programmeLocations}
          organisationId={organisationId}
        />
      ), // <div className="container-fluid pt-2">Test</div>,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "extra-small",
        position: "right"
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
        speed: 5
      },
      actions: []
    });
  };

  return (
    <div className="programme-overview">
      <div className={`container-fluid pt-8 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <h1 className="mb-0">{model.programmeDashboardData.programmeDetails.name}</h1>

              <Pill type={PillTypes.OUTLINE_LIGHT_GRAY} className="ml-4 align-self-center">
                {I18n.t("phrases.programme")}
              </Pill>

              <IF condition={canEditProgramme}>
                <LinkButton
                  id="manageProgrammeButton"
                  className="ml-auto"
                  type={ButtonTypes.PRIMARY}
                  size={UiSizes.SM}
                  href={`/organisations/${organisationId}/programmes/${model.programmeDashboardData.programmeDetails.id}/settings`}
                >
                  {I18n.t("phrases.manageProgramme")}
                </LinkButton>
              </IF>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-6">
            <div className="mb-5">
              <h2>{I18n.t("phrases.overview")}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: RenderXssSafeString(model.programmeDashboardData.programmeDetails.description)
                }}
              />
            </div>
            <div className="mb-5">
              <h2>{I18n.t("phrases.objectives")}</h2>
              {model.programmeDashboardData.programmeDetails.objectives ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: RenderXssSafeString(model.programmeDashboardData.programmeDetails.objectives)
                  }}
                ></div>
              ) : (
                <div>{I18n.t("phrases.noObjectives")}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 mb-5">
                <div className="mb-2 d-flex align-items-center">
                  <Icon symbol={IconSymbols.Stakeholders} size={UiSizes.MD} className="mr-1" />
                  <h2 className="m-0">{I18n.t("phrases.programmeContacts")}</h2>
                </div>
                <ProgrammeContacts
                  organisationId={organisationId}
                  contacts={model.programmeDashboardData.programmeContacts}
                />
              </div>
              <div className="col-lg-6 mb-5">
                <div className="mb-2 d-flex align-items-center">
                  <Icon symbol={IconSymbols.Stakeholders} size={UiSizes.MD} className="mr-1" />
                  <h2 className="m-0">{I18n.t("phrases.programmeSponsor")}</h2>
                </div>
                <ProgrammeSponsor
                  organisationId={organisationId}
                  programmeSponsor={model.programmeDashboardData.programmeDetails.programmeSponsor}
                />
              </div>
              <div className="col-lg-6 mb-5">
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  <span className="mb-2 d-flex align-items-center">
                    <Icon symbol={IconSymbols.Location} size={UiSizes.MD} className="mr-1" />
                    <h2 className="mb-0">{I18n.t("phrases.locations")}</h2>
                    <Tooltip
                      className="table__tooltip"
                      triggeredOn="click"
                      position={TooltipPositions.LEFT}
                      shownElement={<Icon symbol={IconSymbols.HelpCircle} size={UiSizes.SM} className="ml-2" />}
                    >
                      <Panel.Panel
                        className="p-2"
                        style={{ width: 300 }}
                        background={Panel.PanelBackgrounds.BG_WHITE}
                        hasShadow={true}
                        hasBorderRadius={true}
                      >
                        {I18n.t("phrases.programmeDashboard_LocationHelp")}
                      </Panel.Panel>
                    </Tooltip>
                  </span>

                  <Panel.Panel
                    hasShadow={true}
                    hasBorderRadius={true}
                    type={Panel.PanelTypes.OUTLINES}
                    className="d-flex flex-column justify-content-center p-3"
                    style={{ flex: 1 }}
                  >
                    <ProgrammeLocations
                      programmeLocations={model.programmeDashboardData.programmeLocations}
                      organisationId={organisationId}
                    />
                    <hr />
                    <div className="d-flex flex-column align-items-end">
                      <a
                        href=""
                        onClick={e => showLocationsModal(e)}
                        className="d-flex flex-row align-items-center text-right"
                      >
                        <span>{I18n.t("phrases.locationDetails")}</span>{" "}
                        <Icon symbol={IconSymbols.ArrowRightCircle} size={UiSizes.SM} className="ml-1" />
                      </a>
                    </div>
                  </Panel.Panel>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 z-index-1">
                <div className="programme-overview__progress">
                  <h2 className="mb-0">{I18n.t("phrases.programmeTimeline")}</h2>
                  <Pill data-testid="progress-status-label" size={UiSizes.SM} type={pillType}>
                    {Enums.Translator.ProgrammeProgressStatus(
                      model.programmeDashboardData.programmeDetails.progressStatus
                    )}
                  </Pill>
                </div>
                <Timeline
                  startDate={new Date(model.programmeDashboardData.programmeDetails.startDate)}
                  initialEndDate={new Date(model.programmeDashboardData.programmeDetails.endDate)}
                  actualEndDate={new Date(model.programmeDashboardData.programmeDetails.endDate)}
                />
              </div>
              <div className="col-6 mt-2">
                <p>{moment(model.programmeDashboardData.programmeDetails.startDate).format("L")}</p>
              </div>
              <div className="col-6 mt-2">
                <p className="text-right">
                  {moment(model.programmeDashboardData.programmeDetails.endDate).format("L")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="d-flex align-items-center">
              <h2 className="mb-0">{I18n.t("phrases.programmeProjects")}</h2>
              <CanEditProgramme programmeId={model.programmeId}>
                <LinkButton type={ButtonTypes.OUTLINE_PRIMARY} className="ml-auto" href={`/create/projects`}>
                  <Icon symbol={IconSymbols.Plus} className="mr-2" />
                  {I18n.t("phrases.createProject")}
                </LinkButton>
              </CanEditProgramme>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {model.programmeDashboardData.hasProgrammeProjects &&
                model.programmeDashboardData.programmeProjects.map(x => (
                  <div className="col-lg-12" key={x.id}>
                    <ProjectInfoCardView projectData={x} />
                  </div>
                ))}
              {model.programmeDashboardData.hasProgrammeProjects === false && (
                <div className="col-12">{I18n.t("phrases.noProgrammeProjects")}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row py-6 bg-light">
          <div className="col-12">
            <h1 className="mb-4">
              {model.programmeDashboardData.programmeDetails.name} | {I18n.t("phrases.projectsAndMilestones")}
            </h1>
            <div className="mb-4">
              <TimelineView
                model={model.timelineModel}
                labels={{
                  noItemsText: I18n.t("phrases.noProgrammeProjectsOrMilestones"),
                  today: I18n.t("phrases.today"),
                  scrollLeft: I18n.t("phrases.scrollLeft"),
                  scrollRight: I18n.t("phrases.scrollRight"),
                  zoomOut: I18n.t("phrases.zoomOut"),
                  zoomIn: I18n.t("phrases.zoomIn"),
                  resetZoom: I18n.t("phrases.resetZoom")
                }}
              />
            </div>

            {model.programmeDashboardData.programmeMilestones?.length ? (
              <div className="d-flex flex-wrap mb-4 milestone-list">
                {model.programmeDashboardData.programmeMilestones.map(milestone => {
                  const isSelected = model.selectedMilestone?.id === milestone.id;

                  return (
                    <div className="mr-2 mb-2" key={milestone.id}>
                      <Button
                        key={milestone.id}
                        type={isSelected ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                        onClick={() => model.handleMilestoneClick(milestone)}
                      >
                        {milestone.name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : null}

            {model.selectedMilestone && (
              <div>
                <h2 className="mb-0">{model.selectedMilestone.name}</h2>
                <p>{moment(model.selectedMilestone.deadline).format("L")}</p>
                <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(model.selectedMilestone.description) }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
