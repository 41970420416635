import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IF } from "../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { Tab, Tabs } from "../../../../components/ui/Tabs";
import { ConnectedUserAvatars } from "../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { useAppService } from "../../../../contexts/AppService";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { useIsOrganisationAdmin } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { GridView } from "../../../../core/grids/GridView_view";
import I18n from "../../../../core/localization/I18n";
import { EntityTypes, Enums, UiSizes } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../setup";
import { MicroForm } from "../../../change/forms/microForm/MicroForm";
import { ImpactGridViewModel } from "./ImpactGridView/ImpactGridView_model";
import { ImpactsViewModel } from "./ImpactsView_model";
import { Input } from "../../../../components/ui/_forms/Input";
import { ButtonIconDropdown } from "../../../../components/ui/ButtonIconDropdown";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { Services } from "../../../../constants";

export interface ImpactsViewProps {
  model?: ImpactsViewModel;
}

const ImpactsView: React.FunctionComponent<ImpactsViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const wrapTextKey = `projects-${projectId}-${EntityTypes.IMPACTS}-wrap-text`;
  const storageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const authUser = useFlightPathUser();
  const [hasFilters, setHasFilters] = useState(false);
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const filterRef = useRef(null);
  const isAdmin = useIsOrganisationAdmin(organisationId);
  const [model, setModel] = useState(
    () => m || new ImpactsViewModel(appService, !!projectId ? +projectId : null, organisationId, authUser)
  );

  const modelProjectId = model.projectId;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      model.stopConnection().then(() => {
        setModel(new ImpactsViewModel(appService, !!projectId ? +projectId : null, organisationId, authUser));
      });
    }
  }, [projectId, modelProjectId, organisationId, appService, model, authUser]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="impacts-view">
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={1}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab
          title={
            <h2 className="mb-0">{tabHeadingWithCount(I18n.t("table.highLevelImpacts"), model.impactGroupCount)}</h2>
          }
        />
        <Tab
          title={<h2 className="mb-0">{tabHeadingWithCount(I18n.t("table.detailedImpacts"), model.impactCount)}</h2>}
        />
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
        />
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.impactAssessment")}</h2>} />
      </Tabs>

      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex" style={{ height: "32px" }}>
            <CanEdit field={PermissionFields.ACTIONS} projectId={model.projectId}>
              <MicroForm
                fieldName="name"
                iconSymbol={IconSymbols.Plus}
                className="float-left mr-2"
                buttonPlaceholder={I18n.t("phrases.add")}
                formModel={model.microImpactForm}
                onSubmit={model.createMicroImpact}
              />
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedImpacts.length === 0}
                onClick={model.selectedImpacts.length > 0 ? model.showImpactConfirmReviewModal : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.CommentPencil}
              >
                {I18n.t("phrases.review")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedImpacts.length === 0}
                onClick={model.selectedImpacts.length > 0 ? model.showImpactConfirmDeleteModal : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Trash}
              >
                {I18n.t("phrases.delete")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedImpacts.length === 0 || model.isDuplicatingImpacts}
                onClick={model.selectedImpacts.length > 0 ? model.duplicateImpacts : null}
                className="mr-2 align-self-start"
                symbol={IconSymbols.Copy}
              >
                {I18n.t("phrases.duplicate")}
              </ButtonIcon>

              <ButtonIconDropdown
                iconSymbol={IconSymbols.Link}
                buttonProps={{
                  size: UiSizes.SM,
                  type: ButtonTypes.LINK,
                  className: "p-0 mr-2",
                  isDisabled: model.selectedImpacts.length === 0
                }}
                actions={model.actions}
              >
                {I18n.t("phrases.link")}
              </ButtonIconDropdown>
              {isAdmin && (
                <ButtonIcon
                  type={ButtonTypes.LINK}
                  size={UiSizes.SM}
                  isDisabled={model.selectedImpacts.length === 0}
                  onClick={model.selectedImpacts.length > 0 ? model.exportRows : null}
                  className="mr-2 align-self-start"
                  symbol={IconSymbols.Download}
                >
                  {I18n.t("phrases.export")}
                </ButtonIcon>
              )}
              <span className="vertical-line"></span>
              <ButtonIcon
                type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                onClick={() => {
                  setIsTextWrapToggled(!isTextWrapToggled);
                  filterRef.current.toggleTextWrapper();
                }}
                symbol={IconSymbols.TextWrap}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.textWrap")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasFilters}
                onClick={() => filterRef.current.clearFilters()}
                symbol={IconSymbols.ClearFilter}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.clearFilters")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasColumnChanges}
                onClick={() => filterRef.current.resetColumns()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.MonitorBack}
              >
                {I18n.t("phrases.resetColumns")}
              </ButtonIcon>
            </CanEdit>
          </div>
          {model.gapAnalysisFilter && (
            <div>
              <Pill
                type={model.gapAnalysisCompleteData ? PillTypes.OUTLINE_SUCCESS : PillTypes.OUTLINE_WARNING}
                size={UiSizes.SM}
              >
                <span className="body-small">
                  <span style={{ fontWeight: "bold" }} className="mr-1">
                    Filter:
                  </span>
                  Gap Analysis - {model.gapAnalysisCompleteData ? "complete data" : "missing data"} for Impact
                  <span style={{ fontWeight: "bold" }} className="ml-1">
                    "{model.gapAnalysisFilter}"
                  </span>
                </span>
                <Icon
                  symbol={IconSymbols.Close}
                  className="ml-1"
                  size={UiSizes.SM}
                  style={{ cursor: "pointer" }}
                  onClick={model.clearGapAnalysisFilter}
                />
              </Pill>
            </div>
          )}
          <div className="col-3">
            <ConnectedUserAvatars connectedUsers={model.connectedUsers} />
          </div>
          <div className="col-2">
            <div className="text-right">
              <Input
                autoFocus={true}
                size={UiSizes.XS}
                onChange={model.setSearchText}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                style={{ height: 32 }}
              />
            </div>
          </div>
        </div>
      </div>

      <IF condition={!model.isLoading}>
        <GridView
          context={{ deleteFn: model.deleteFieldData }}
          data={model.gridImpacts}
          users={model.connectedUsers}
          onCellClicked={model.updateUserSelectedCell}
          onCellEditModeChange={model.updateUserSelectedCell}
          onSelectionChanged={model.updateSelectedImpacts}
          modelClass={ImpactGridViewModel}
          onGridReady={model.onGridReady}
          ref={filterRef}
          filterHasChangedFn={setHasFilters}
          isExternalFilterPresent={model.isExternalFilterPresent}
          doesExternalFilterPass={model.doesExternalFilterPass}
          columnOrderHasChangedFn={setHasColumnChanges}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});

export { ImpactsView };
