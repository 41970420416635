import _ from "lodash";
import React from "react";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import { filterOutExistingIds } from "../../../../../../../core/grids/GridHelpers";
import I18n from "../../../../../../../core/localization/I18n";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { IProjectsApi } from "../../../../../../../services/api/v1/projects/IProject.api";

export const getImpactMilestonesFormFields = (impactsProvider: IImpactsApi, projectsProvider: IProjectsApi, organisationId: number, projectId: number, impact: FP.Entities.IImpact) => {

    const removeMilestone = async (milestoneId: number) => {
        const res = await impactsProvider.removeMilestone(organisationId, projectId, impact.id, milestoneId);
        if (!res) return;

        return res;
    }

    const addMilestone = async (milestoneId: number) => {
        const res = await impactsProvider.addMilestone(organisationId, projectId, impact.id, milestoneId);
        if (!res || res.payload) return;

        return res.payload;
    }

    const impactMilestones: Partial<IMultiSelectorModel> = {
        ...INIT_MULTISELECTOR,
        key: "impactMilestones",
        label: <label htmlFor={"impactMilestones"}>{I18n.t("forms.associatedMilestones")}</label>,
        placeholder: I18n.t("placeholders.searchMilestones"),
        optionElement: (
            <AutocompleteOption
                key={"e"}
                className={"autocomplete__chip"}
                label={(e: FP.Entities.IImpactGroup) => `${e.name}`}
            />
        ),
        onItemSelected: (e) => addMilestone(e.id),
        onFocus: async function () {
            const self: IMultiSelectorModel = this;
            const res = await projectsProvider.getMilestones(organisationId, projectId);

            if (res.payload) {
                const existingVals = self.extractValue();
                let sortedMilestones = _.orderBy(res.payload, [milestone => milestone.name.toLowerCase()]);
                sortedMilestones = filterOutExistingIds(existingVals, sortedMilestones);
                self.setOptions(sortedMilestones);
            }
        },
        componentProps: {
            icon: IconSymbols.Search
        },
        searchAttribute: "name",
        fieldClassName: FORM_COL.FULL_WIDTH,
        extractValue: function () {
            return this.isHidden ? null : this.selectedItems.map(e => e.id);
        },
        value: impact?.milestones,
        removeItemFn: (ig, idx) => removeMilestone(ig.id)
    };

    const fields = []
    fields.push(impactMilestones)

    return generateFormFieldsFromJson(fields);
}