import { SkinnyDoughnutChart } from "@flightpath/coreui/dist/widgets/skinnyDoughnutChart";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { CircleStatusColours } from "../../../../../components/ui/CircleStatus";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import {
  HorizontalBarChart,
  RagStatusHorizontalBarChartTooltip
} from "../../../../../components/widgets/horizontalBarChart/HorizontalBarChart";
import { SkinnyDonutImpactsToolTip } from "../../../../../components/widgets/skinnyDoughnutChart/SkinnyDoughnutChart";
import { TargetBarChart } from "../../../../../components/widgets/targetBarChart/TargetBarChart";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";
import { ImpactTotalsModel } from "./ImpactTotals_model";

export interface ImpactSummaryViewProps {
  model?: ImpactTotalsModel;
}

export const ImpactTotals: React.FC<ImpactSummaryViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const params: any = useParams();
  const projectId: number = parseInt(params["id"]);
  const [model] = useState(() => m || new ImpactTotalsModel(appService, orgId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`impact-summary pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4"></div>
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadImpactStatusBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      title={model.impactStatusBreakdown.title}
                      subtitle={model.impactStatusBreakdown.subtitle}
                      data={model.impactStatusBreakdown.data}
                      colourScheme={model.impactStatusBreakdown.colourScheme}
                      total={model.impactStatusBreakdown.total}
                      tooltipElement={SkinnyDonutImpactsToolTip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>

          <div className="col-8">
            <Async promiseFn={model.loadAbsoluteImpactStatusTotals}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <HorizontalBarChart
                      title={model.absoluteImpactStatusTotals.title}
                      subtitle={model.absoluteImpactStatusTotals.subtitle}
                      trimTickWords={false}
                      data={model.absoluteImpactStatusTotals.data}
                      colourScheme={model.absoluteImpactStatusTotals.colourScheme}
                      margin={{
                        top: 20,
                        right: 20,
                        left: 48,
                        bottom: 5
                      }}
                      tooltipContent={RagStatusHorizontalBarChartTooltip}
                      circleColourScheme={CircleStatusColours.INDICATOR_1}
                      fieldNames={model.absoluteImpactStatusTotals.fieldNames}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadImpactLevelBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      title={model.impactLevelPercentage.title}
                      subtitle={model.impactLevelPercentage.subtitle}
                      data={model.impactLevelPercentage.data}
                      colourScheme={model.impactLevelPercentage.colourScheme}
                      total={model.impactLevelPercentage.total}
                      tooltipElement={SkinnyDonutImpactsToolTip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-8">
            <Async promiseFn={model.loadImpactandImpactGroupImpactLevelTotals}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <TargetBarChart
                      title={model.impactAndImpactGroupImpactLevelTotals.title}
                      subtitle={model.impactAndImpactGroupImpactLevelTotals.subtitle}
                      data={model.impactAndImpactGroupImpactLevelTotals.data}
                      colourScheme={model.impactAndImpactGroupImpactLevelTotals.colourScheme}
                      circleColourScheme={CircleStatusColours.INDICATOR_1}
                      tooltipContent={model.ImpactLevelBarChartTooltip}
                      fieldNames={model.impactAndImpactGroupImpactLevelTotals.fieldNames}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
      </div>
    </div>
  );
});
