import * as React from "react";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { ModalService } from "../../../../../core/modal/ModalService";
import { Services } from "../../../../../constants";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getImpactListViewForm } from "./ImpactListView_data";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { observable, action } from "mobx";
import { ImpactGroupExtendedViewModel } from "../ImpactGroupExtendedView_model";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { Panel } from "../../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";

export class ImpactListViewModel extends BaseModel {
  appService: AppService;
  projectId: number;
  impactGroupId: number;
  modalService: ModalService;
  impactService: IImpactsApi;
  projectService: IProjectsApi;
  impactGroupService: IImpactGroupsApi;
  @observable searchValue: string = "";
  @observable isSearchMode: boolean = false;
  @observable.ref owner: ImpactGroupExtendedViewModel;
  @observable impactGroupImpacts: FP.Entities.IImpact[] = [];
  @observable.ref impactGroup: FP.Entities.IImpactGroup;
  @observable.ref filteredImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;

  constructor(appService: AppService, owner: ImpactGroupExtendedViewModel) {
    super();
    this.appService = appService;
    this.owner = owner;
    this.organisationId = this.owner.organisationId
    this.projectId = this.owner.projectId;
    this.impactGroupId = this.owner.impactGroupId;
    this.modalService = appService.getService<ModalService>(Services.ModalService);
    this.projectService = appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactService = appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactGroupService = appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.impactGroup = owner.impactGroup;

    this.filteredImpacts = this.impactGroup.impacts;
  }
  onMount = () => { };

  onUnmount = () => {
    this.resetSearch();
  };

  async loadImpactGroup() {
    await this.owner.loadImpactGroup();
    this.impactGroup = this.owner.impactGroup;
    this.filteredImpacts = this.impactGroup.impacts;
  }

  @action
  loadImpactGroupImpacts = async () => {
    const res = await this.owner.impactGroupProvider.getImpactGroupImpacts(this.organisationId, this.projectId, this.impactGroupId);
    if (!res) return;
    this.filteredImpacts = res.payload;
  }


  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterImpacts();
    this.hideSearchMode();
  };

  @action
  filterImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredImpacts = this.impactGroup.impacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredImpacts = this.impactGroup.impacts;
    }
  };

  showAddImpactModal = () => {
    const formModel = new SingleFormModel();
    formModel.formFields = getImpactListViewForm(this.organisationId, this.projectService, this.impactGroup);
    formModel.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          await this.impactGroupService.addImpactsAsync(this.organisationId, this.impactGroup.project.id, this.owner.impactGroup.id, res.impacts);

          this.modalService.hide();
          this.loadImpactGroup();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.addDetailedImpacts")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };
}
