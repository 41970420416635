import { ColDef } from "ag-grid-community";
import {
  colCreatedByName,
  colCreatedOn,
  colImpacts,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colReviewedBy,
  colSelected,
  colTags,
  colUpdatedOn
} from "../../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../../core/grids/GridFunctions";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { ProjectStakeholderField, Translator } from "../../../../../../enums";
import {
  SHOW_STAKEHOLDER_IMPACT_MODAL,
  SHOW_STAKEHOLDER_NOTES_MODAL,
  SHOW_STAKEHOLDER_TAGS_MODAL
} from "../StakeholdersView_modal";
import {
  colBusinessArea,
  colCommitment,
  colEmail,
  colImpact,
  colInfluence,
  colIsKeyStakeholder,
  colOwnerName,
  colReceptiveness,
  colRole,
  colSentiment
} from "./StakeholderGridView_baseColumns";

export interface IGetStakeholderGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  stakeholderId: number;
  isKeyStakeholdersOptions: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  gridFns: IGridFns;
  modalService: IModalService;
  userCanViewImpacts: boolean;
  pinned: "left" | "right" | null;
  // modals: IImpactGridModals;
}

export const GetStakeholderGridViewColumns = (modelProps: IGetStakeholderGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  // const modals = modelProps.modals;
  result.push(colSelected());
  result.push(
    colName(
      false,
      null,
      null,
      `/organisations/${modelProps.organisationId}/projects/${modelProps.projectId}/stakeholders`,
      { width: 150, pinned: modelProps.pinned }
    )
  );
  result.push(colEmail({ pinned: modelProps.pinned }));
  result.push(colRole());
  result.push(colBusinessArea());
  result.push(colOwnerName());
  result.push(
    colIsKeyStakeholder(
      modelProps.canEdit,
      modelProps.gridFns.updateTextField,
      ProjectStakeholderField.isKeyStakeholder,
      modelProps.isKeyStakeholdersOptions
    )
  );
  result.push(
    colInfluence(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.influence,
      Translator.InfluenceLevelMapped()
    )
  );
  result.push(
    colImpact(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.impact,
      Translator.ImpactLevelMapped()
    )
  );
  result.push(
    colSentiment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.sentiment,
      Translator.StakeholderProfilingMapped()
    )
  );
  result.push(
    colCommitment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.commitment,
      Translator.StakeholderProfilingMappedSecondary()
    )
  );
  result.push(
    colReceptiveness(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.receptiveness,
      Translator.StakeholderProfilingMapped()
    )
  );

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewImpacts) {
    result.push(colImpacts(modelProps.canEdit, SHOW_STAKEHOLDER_IMPACT_MODAL(modelProps.modalService)));
  }

  result.push(
    colNoteCount(modelProps.canEdit, audience => {
      SHOW_STAKEHOLDER_NOTES_MODAL(modelProps.modalService, audience);
    })
  );
  result.push(colTags(modelProps.canEdit, audience => SHOW_STAKEHOLDER_TAGS_MODAL(modelProps.modalService, audience)));
  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());
  return result;
};
