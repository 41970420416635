import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, observable } from "mobx";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { Enums, ImpactField } from "../../../../enums";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { ICommentsApi } from "../../../../services/api/v1/comments/IComments.api";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { ImpactsHub } from "../../../../services/hubs/ImpactsHub/Impacts_hub";
import { IGridToastService } from "../../../../services/local/gridToastService/IGridToastService";
import { appHistory } from "../../../../setup";
import { getEntityNameMicroFormFields } from "../../../change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../change/forms/singleFormModel/SingleForm_model";
import { SHOW_CONFIRM_CREATION_MODAL } from "../Actions/ActionListView/ActionListView_modals";
import {
  SHOW_IMPACT_DELETE_CONFIRM_MODAL,
  SHOW_IMPACT_REVIEW_CONFIRM_MODAL,
  SHOW_STAKEHOLDER_ACTION_LINK_MODAL,
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL
} from "./ImpactsView_modals";
import * as apiFilters from "./ImpactsView_apiFilter";
import { getGapAnalysisParameter, getValueParameter } from "../../../../core/grids/GridQueryStringHelpers";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ButtonTypes } from "../../../../components/ui/Button";

export class ImpactsViewModel extends DisposableModel {
  appService: AppService;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  commentsProvider: ICommentsApi;
  actionProvider: IActionsApi;
  gridToastService: IGridToastService;
  projectId: number;
  routeProps: RouteComponentProps;
  modalService: IModalService;
  @observable confirmationService: IModalService;
  httpProgress: IHttpProgressModel;
  @observable.ref selectedImpacts: number[] = [];
  toasterService: IToasterService;
  @observable.ref project: FP.Entities.IProject;
  @observable isDuplicatingImpacts: boolean = false;
  organisationId: number;
  impactsHub: ImpactsHub;
  @observable impactCount: number;
  @observable gridImpacts: FP.Entities.IImpactSummary[];
  @observable impactGroupCount: number;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable connectedUsers: FP.Entities.IUser[] = [];
  microImpactForm: SingleFormModel;
  gridApi: GridApi;
  filterModel?: any;
  @observable gapAnalysisFilter: string;
  @observable gapAnalysisCompleteData: boolean;
  @observable searchText: string;
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.stakeholders"),
      onAction: ev => {
        this.showLinkToStakeholders();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("entities.actions"),
      onAction: async ev => {
        this.showLinkToActions();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(appService: AppService, projectId: number, organisationId: number, authUser: FP.Entities.IUser) {
    super();
    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.commentsProvider = this.appService.getService<ICommentsApi>(Services.CommentsApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.confirmationService = this.appService.getService<IModalService>(Services.ConfirmationService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.projectId = projectId;
    this.impactsHub = this.appService.getService<ImpactsHub>(Services.ImpactsHub);
    this.gridToastService = this.appService.getService<IGridToastService>(Services.GridToastService);
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.setCountsForImpactAndImpactGroup();
    this.setMicroAddForm();
  }

  registerSocketEvents = async () => {
    if (this.impactsHub.isConnectionStarted === true) {
      await this.impactsHub.stopConnection();
    }
    await this.impactsHub.startConnection();

    this.impactsHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.impactsHub.onData(d => {
      this.setImpactRowData(d);
      this.impactCount = d.length;
    });

    this.impactsHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.impactsHub.invokeUserJoined(this.organisationId, this.projectId, this.authUser.sub);
    await this.impactsHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  stopConnection = async () => {
    await this.impactsHub.stopConnection();
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setImpactRowData = impacts => {
    this.gridImpacts = impacts;
    this.isLoading = false;
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  updateUserSelectedCell = async (cell: string, isEditMode?: boolean) => {
    await this.impactsHub.invokeUserClickedCell(this.organisationId, this.projectId, cell, isEditMode);
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedImpacts = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.filterModel = {
      ...apiFilters.getImpactGridFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-impact-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedImpacts && this.selectedImpacts.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.stopConnection();
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    return res.payload;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 3) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/impact-assessment`);
      return;
    }

    if (newTabIndex === 2) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups`);
      return;
    }
    appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts`);
  };

  @action
  deleteFieldData = async (impactId: number, impactField: ImpactField) => {
    const res = await this.impactProvider.deleteGridField(this.organisationId, this.projectId, impactId, impactField);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  @action
  setMicroAddForm = () => {
    this.microImpactForm = new SingleFormModel();
    this.microImpactForm.formFields = getEntityNameMicroFormFields(
      this.createMicroImpact,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.impact") })
    );
  };

  showImpactConfirmDeleteModal = () => {
    return SHOW_IMPACT_DELETE_CONFIRM_MODAL(this.modalService, this.selectedImpacts, this.removeImpacts);
  };

  showLinkToStakeholders = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedImpacts);
  };

  showLinkToActions = () => {
    return SHOW_STAKEHOLDER_ACTION_LINK_MODAL(this.modalService, this.projectId, this.selectedImpacts);
  };

  duplicateImpacts = async () => {
    this.httpProgress.showTopProgressBarVisible();
    this.isDuplicatingImpacts = true;
    await this.impactProvider.duplicateImpacts(this.organisationId, this.projectId, this.selectedImpacts);
    this.httpProgress.hideTopProgressBarVisible();
    this.isDuplicatingImpacts = false;
  };

  showImpactConfirmReviewModal = () => {
    return SHOW_IMPACT_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedImpacts,
      this.reviewImpacts,
      this.toasterService,
      this.deselectRows
    );
  };

  reviewImpacts = async (impactIds: number[], comment: string) => {
    let res = await this.impactProvider.reviewRange(this.organisationId, this.projectId, impactIds, comment);

    if (!res || res.isError) return;
  };

  removeImpacts = async (impactIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.deleteRange(this.organisationId, this.projectId, impactIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  createMicroImpact = async () => {
    let impactFormRes = await this.microImpactForm.submit();
    this.microImpactForm.isSaving = true;
    if (!impactFormRes) return;

    impactFormRes = { ...impactFormRes, projectId: this.projectId, tags: [], impactTypes: [] };

    this.httpProgress.showOverlay();
    let impactExists = await this.impactProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${impactFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });
    if (impactExists && !impactExists.isError && impactExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateImpact = await this.confirmCreateImpact(impactFormRes.name);
      if (!confirmCreateImpact) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...impactFormRes
    };

    this.httpProgress.showOverlay();
    const result = await this.impactProvider.create(this.organisationId, this.projectId, res as FP.Entities.IImpact);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const impact = result.payload;
    this.microImpactForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.impact") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return impact;
  };

  confirmCreateImpact = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(this.modalService, name);
  };

  isExternalFilterPresent = (): boolean => {
    let gapAnalysisFilter = getGapAnalysisParameter();

    if (gapAnalysisFilter && gapAnalysisFilter.length > 0) {
      return true;
    }

    return false;
  };

  doesExternalFilterPass = (node): boolean => {
    let gapAnalysisFilter = getGapAnalysisParameter();
    let showFieldsWithData = getValueParameter() === "1";
    let result: boolean = true;
    let level = node.data.impactLevel;

    let type = node.data.impactTypeNames;
    let locations = node.data.locationNames;
    let businessAreas = node.data.businessAreaNames;
    let owner = node.data.ownerName;

    switch (gapAnalysisFilter) {
      case "type":
        result = this.rowHasValidTypeAndLevelData(type, level, showFieldsWithData);
        this.setGapAnalysisFilter("Type", showFieldsWithData);
        break;
      case "location":
        result = this.rowHasValidLocationAndLevelData(locations, level, showFieldsWithData);
        this.setGapAnalysisFilter("Locations", showFieldsWithData);
        break;
      case "businessArea":
        result = this.rowHasValidBusinessAreaAndLevelData(businessAreas, level, showFieldsWithData);
        this.setGapAnalysisFilter("Business Areas", showFieldsWithData);
        break;
      case "owner":
        result = this.rowHasValidOwnerAndLevelData(owner, level, showFieldsWithData);
        this.setGapAnalysisFilter("Owner", showFieldsWithData);
        break;
      case "all":
        if (showFieldsWithData) {
          result =
            this.rowHasValidTypeAndLevelData(type, level, showFieldsWithData) &&
            this.rowHasValidLocationAndLevelData(locations, level, showFieldsWithData) &&
            this.rowHasValidBusinessAreaAndLevelData(businessAreas, level, showFieldsWithData) &&
            this.rowHasValidOwnerAndLevelData(owner, level, showFieldsWithData);
        } else {
          result =
            this.rowHasValidTypeAndLevelData(type, level, showFieldsWithData) ||
            this.rowHasValidLocationAndLevelData(locations, level, showFieldsWithData) ||
            this.rowHasValidBusinessAreaAndLevelData(businessAreas, level, showFieldsWithData) ||
            this.rowHasValidOwnerAndLevelData(owner, level, showFieldsWithData);
        }

        this.setGapAnalysisFilter("All", showFieldsWithData);
        break;
      default:
        result = true;
        break;
    }

    return result;
  };

  setGapAnalysisFilter = (filter: string, showFieldsWithData: boolean) => {
    this.gapAnalysisFilter = `${filter} & Level`;
    this.gapAnalysisCompleteData = showFieldsWithData;
  };

  levelIsValid = (level: number): boolean => {
    const validValues: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return validValues.indexOf(level) > -1;
  };

  rowHasValidTypeAndLevelData = (types: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && types !== null;
    } else {
      return this.levelIsValid(level) === false || types === null;
    }
  };

  rowHasValidLocationAndLevelData = (locations: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && locations !== null;
    } else {
      return this.levelIsValid(level) === false || locations === null;
    }
  };

  rowHasValidBusinessAreaAndLevelData = (businessArea: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && businessArea !== null;
    } else {
      return this.levelIsValid(level) === false || businessArea === null;
    }
  };

  rowHasValidOwnerAndLevelData = (owner: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && owner !== null;
    } else {
      return this.levelIsValid(level) === false || owner === null;
    }
  };

  clearGapAnalysisFilter = () => {
    window.location.href = `/organisations/${this.organisationId}/projects/${this.projectId}/impacts/`;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setQuickFilter(this.searchText);
    }
  };
}
