import * as Buttons from "../../components/ui/Button";
import { IUiAction } from "./IUiAction";
import * as React from "react";

const getUiActionComponent = (action: IUiAction<any>, contextModel?, testId?: string) => {
  let props = {
    onClick: ev => action.onAction && action.onAction(ev, contextModel),
    id: action.id,
    target: action.target || null,
    key: action.id,
    testId: testId ?? ""
  };

  let buttonProps: Buttons.ButtonProps = {
    ...props
  };
  if (action.componentProps) {
    buttonProps = {
      ...buttonProps,
      isDisabled: action.componentProps.isDisabled,
      size: action.componentProps.size,
      type: action.componentProps.type
    };
  }

  switch (action.rendersIn) {
    case "HTMLAnchor":
      const href = action.hrefFn && typeof action.hrefFn === "function" ? action.hrefFn(contextModel) : "";
      delete buttonProps.onClick;
      return (
        <Buttons.LinkButton {...buttonProps} externalUrl={href} key={action.id}>
          {action.label}
        </Buttons.LinkButton>
      );
    case "LinkButton":
      if (action.hrefFn && typeof action.hrefFn === "function") {
        buttonProps.href = action.hrefFn(contextModel);
      }
      delete buttonProps.onClick;
      return (
        <Buttons.LinkButton {...buttonProps} key={action.id}>
          {action.label}
        </Buttons.LinkButton>
      );
    case "Button":
      return (
        <Buttons.Button {...buttonProps} key={action.id} data-testid={props.testId}>
          {action.label}
        </Buttons.Button>
      );
    case "ButtonIcon":
      let biProps = action.componentProps as Buttons.ButtonIconProps;
      return (
        <Buttons.ButtonIcon
          {...(buttonProps as any)}
          {...biProps}
          symbol={biProps.symbol}
          title={action.label}
          key={action.id}
        ></Buttons.ButtonIcon>
      );
    case "LinkButtonIcon":
      let bisProps = action.componentProps as Buttons.LinkButtonIconProps;
      if (action.hrefFn && typeof action.hrefFn === "function") {
        bisProps.href = action.hrefFn(contextModel);
      }
      delete bisProps.onClick;
      return (
        <Buttons.LinkButtonIcon {...bisProps} symbol={bisProps.symbol} key={action.id}>
          {action.label}
        </Buttons.LinkButtonIcon>
      );
    default:
      break;
  }
};

export const InjectElementToAction = (action: IUiAction<any>, element: any) => {
  let s = { ...action };
  let k = s.onAction;
  s.onAction = ev => {
    k(ev, element);
  };
  return s;
};

export interface UiActionComponentProps {
  action: IUiAction<any>;
  contextModel?: any;
  testId?: string;
}

const UiActionComponent: React.FunctionComponent<UiActionComponentProps> = prop => {
  let { action, contextModel, testId } = prop;

  let props = {
    onClick: ev => action.onAction(ev, contextModel),
    id: action.id,
    key: action.id
  };

  props["data-testid"] = testId;

  let buttonProps: Buttons.ButtonProps = {
    ...props,
    ...prop.action.componentProps
  };

  if (action.componentProps) {
    buttonProps = {
      ...buttonProps,
      isDisabled: action.componentProps.isDisabled,
      size: action.componentProps.size,
      type: action.componentProps.type
    };
  }

  switch (action.rendersIn) {
    case "HTMLAnchor":
      const href = action.hrefFn && typeof action.hrefFn === "function" ? action.hrefFn(contextModel) : "";
      delete buttonProps.onClick;
      return (
        <Buttons.LinkButton {...buttonProps} externalUrl={href} key={action.id}>
          {action.label}
        </Buttons.LinkButton>
      );
    case "LinkButton":
      if (action.hrefFn && typeof action.hrefFn === "function") {
        buttonProps.href = action.hrefFn(contextModel);
      }
      delete buttonProps.onClick;
      return (
        <Buttons.LinkButton {...buttonProps} key={action.id}>
          {action.label}
        </Buttons.LinkButton>
      );
    case "Button":
      return (
        <Buttons.Button {...buttonProps} key={action.id}>
          {action.label}
        </Buttons.Button>
      );
    case "ButtonIcon":
      let biProps = action.componentProps as Buttons.ButtonIconProps;
      return (
        <Buttons.ButtonIcon
          {...(buttonProps as any)}
          {...biProps}
          symbol={biProps.symbol}
          title={action.label}
          key={action.id}
        ></Buttons.ButtonIcon>
      );
    case "LinkButtonIcon":
      let bisProps = action.componentProps as Buttons.LinkButtonIconProps;
      if (action.hrefFn && typeof action.hrefFn === "function") {
        bisProps.href = action.hrefFn(contextModel);
      }
      return (
        <Buttons.LinkButtonIcon
          {...bisProps}
          onClick={ev => action.onAction(ev, contextModel)}
          id={action.id}
          isDisabled={action.componentProps.isDisabled}
          size={action.componentProps.size}
          type={action.componentProps.type}
          symbol={bisProps.symbol}
          key={action.id}
        >
          {action.label}
        </Buttons.LinkButtonIcon>
      );
    default:
      break;
  }
};

export { getUiActionComponent, UiActionComponent };
