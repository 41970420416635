import React, { useEffect, useState } from "react";
import { AddImpactGroupModel } from "./AddImpactGroup_model";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { Panel } from "../../../../components/ui/Panel";
import { ImpactGroupFormSectionView } from "../../forms/impactGroups/ImpactGroupFormSection_view";
import I18n from "../../../../core/localization/I18n";
import { useAppService } from "../../../../contexts/AppService";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";

export interface AddImpactGroupProps {
  model?: AddImpactGroupModel;
}

export const AddImpactGroup: React.FC<AddImpactGroupProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => m || new AddImpactGroupModel(appService, +projectId, organisationId));
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  return (
    <div className={`add-stakeholder pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col p-2">
            <LinkButton
              size={Enums.UiSizes.MD}
              type={ButtonTypes.LINK}
              className="p-0"
              href={`/organisations/${organisationId}/projects/${projectId}/impactGroups`}
            >
              <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
              {I18n.t("phrases.backToHighLevelImpacts")}
            </LinkButton>
          </div>
        </div>

        <Panel.Panel background={Panel.PanelBackgrounds.BG_LIGHT} className="row py-4 mb-4">
          <div className="col-lg-7">
            <h1>
              {I18n.t("phrases.create")} {I18n.t("entities.highLevelImpact")}
            </h1>
            <p className="mb-0">{I18n.t("phrases.createHighLevelImpactDescription")}</p>
          </div>
        </Panel.Panel>

        <ImpactGroupFormSectionView model={model.impactGroupFormModel} />
      </div>
    </div>
  );
});
