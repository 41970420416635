import * as React from "react";
import { observer } from "mobx-react-lite";
import { useFlightPathUser } from "../../../../../../../../setup";
import { useAppService } from "../../../../../../../../contexts/AppService";
import { Services } from "../../../../../../../../constants";
import I18n from "../../../../../../../../core/localization/I18n";
import { CommentListViewModel } from "../../../../../../../change/comments/commentListView/CommentListView_model";
import { Async } from "react-async";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import { CommentListView } from "../../../../../../../change/comments/commentListView/CommentListView_view";
import { IProjectStakeholdersApi } from "../../../../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { IModalService } from "../../../../../../../../core/modal/IModalService";
import { Button } from "../../../../../../../../components/ui/Button";

export interface StakeholderNotesSidebarModel {
  projectId: number;
  stakeholderId: number;
  modalService: IModalService;
}

export const StakeholderNotesSidebar: React.FunctionComponent<StakeholderNotesSidebarModel> = observer(p => {
  let [, setStakeholder] = React.useState(null);
  const authUser = useFlightPathUser();
  const appService = useAppService();
  const projectId = p.projectId;
  const stakeholderId = p.stakeholderId;
  const modalService = p.modalService;
  const organisationId = useCurrentOrganisationId();
  const projectStakeholdersProvider = appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "projectStakeholderId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.stakeholderNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const commentViewModel = React.useMemo(
    () => new CommentListViewModel(appService, projectId, authUser, listViewConfig),
    [appService, projectId, authUser, listViewConfig]
  );

  const load = React.useCallback(async () => {
    const res = await projectStakeholdersProvider.getById(organisationId, projectId, stakeholderId);

    if (!res || res.isError) {
      return;
    }

    setStakeholder(res.payload);
    commentViewModel.setConfig({
      id: res.payload.id
    });
  }, [stakeholderId, projectId, organisationId, commentViewModel, projectStakeholdersProvider]);
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid stakeholder-compact-view">
            <CanEdit projectId={projectId} field={PermissionFields.STAKEHOLDERS}>
              <div className="row mb-0">
                <div className="col">
                  <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
                </div>
              </div>
            </CanEdit>
            <CommentListView model={commentViewModel} field={PermissionFields.STAKEHOLDERS} />
            <div className="row mb-5">
              <div className="col">
                <div className="d-flex">
                  <Button
                    className="ml-auto"
                    onClick={() => {
                      modalService.hide();
                    }}
                  >
                    {I18n.t("phrases.done")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
