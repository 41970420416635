import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getProjectFormFields, getProjectInitFormFields } from "../../forms/project/ProjectFormSection_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IModalService } from "../../../../core/modal/IModalService";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { appHistory } from "../../../../setup";
import { Panel } from "../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { IProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";

export class CreateProjectViewModel extends BaseModel {
  initFormModel: SingleFormModel;
  formModel: SingleFormModel;
  projectProvider: IProjectsApi;
  organisationsProvider: IOrganisationsApi;
  userPermissionsProvider: IProjectTeamUserPermissionsApi;
  httpProgress: IHttpProgressModel;
  programme: FP.Entities.IProgramme;
  appService: AppService;
  orgId: number;
  toasterService: IToasterService;
  modalService: IModalService;
  user: FP.Entities.IUser;
  refreshClaims: () => void;
  /**
   *
   */
  constructor(
    appService: AppService,
    user: FP.Entities.IUser,
    refreshClaims: () => void,
    organisationId: number,
    programme?: FP.Entities.IProgramme
  ) {
    super();
    this.programme = programme;
    this.appService = appService;
    this.user = user;
    this.refreshClaims = refreshClaims;
    this.projectProvider = appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.organisationsProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.orgId = organisationId;
    this.initFormModel = new SingleFormModel();
    this.formModel = new SingleFormModel();
    this.setForms();
  }

  setForms = () => {
    this.initFormModel.formFields = getProjectInitFormFields(this.orgId, this.organisationsProvider);
    this.formModel.formFields = getProjectFormFields(this.orgId, this.organisationsProvider);
    this.formModel.actions = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: async () => {
          let res = await this.createProject();
          if (res) {
            this.toasterService
              .showSuccessToast()
              .setContent(
                <span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.project") })}</span>
              )
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
            this.initFormModel.resetFields();
            this.formModel.resetFields();
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        }
      },
      {
        id: "createProjectButton",
        label: I18n.t("phrases.createProject"),
        onAction: async () => {
          let res = await this.createProject();
          if (res) {
            let url = `/organisations/${this.orgId}/projects/${res.id}`;
            appHistory.push(url);
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  };

  createProject = async (): Promise<FP.Entities.IProject> => {
    let projectFormEl = await this.formModel.submit();
    if (projectFormEl) {
      let formResult = {
        ...projectFormEl
      };

      let projectExists = await this.projectProvider.getFiltered(this.orgId, {
        filters: `name==${projectFormEl.name},lifecycleStatus==${Enums.LifecycleStatus.Active},organisationId==${this.orgId}`
      });
      if (projectExists && !projectExists.isError && projectExists.payload.length) {
        let confirmCreateProject = await this.confirmCreateProject(projectFormEl.name);
        if (!confirmCreateProject) return;
      }

      this.httpProgress.showOverlay();
      const res = await this.projectProvider.create(this.orgId, formResult as FP.Entities.IProject);
      if (!res || res.isError) {
        this.httpProgress.hideOverlay();
        return;
      }

      let result = await this.projectProvider.getById(this.orgId, res.payload.id);
      this.httpProgress.hideOverlay();
      if (!result || result.isError) return;
      return result.payload;
    }
  };

  confirmCreateProject = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.createSameNameProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };
}
