import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { GetGridFns, IGridFns } from "../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { EntityTypes, GridTypes } from "../../../../../enums";
import { IProjectStakeholdersApi } from "../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { IProjectTeamUserPermissionsApi } from "../../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { GetStakeholderGridViewColumns } from "../../Stakeholders/StakeholdersListView/StakeholderGridView/StakeholderGridView_columns";

export class StakeholderGridViewModel extends BaseModel implements IGridModel {
  appService: AppService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  organisationId: number;
  projectId: number;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  gridFns: IGridFns;
  modalService: IModalService;
  stakeholderId: number;
  type: GridTypes = GridTypes.IMPACT_STAKEHOLDER_GRID;

  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    this.appService = appService;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = appService.getService<IModalService>(Services.InnerModalService);
    this.projectStakeholdersProvider = appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.gridFns = GetGridFns(this.projectStakeholdersProvider as any, this.organisationId, this.projectId);
  }

  getColumnConfig = () => {
    return GetStakeholderGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      stakeholderId: this.stakeholderId,
      modalService: this.modalService,
      isKeyStakeholdersOptions: [
        { key: "0", label: I18n.t("phrases.no") },
        { key: "1", label: I18n.t("phrases.yes") }
      ],
      impactLevels: [],
      gridFns: this.gridFns,
      userCanViewImpacts: true,
      pinned: null
    });
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
