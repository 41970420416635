import { IMilestonesApi } from "./IMilestones.api";
import { IHttp } from "../../IHttp";
import { FilteredApiModel } from "../../filteredApi/FilteredApiModel";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { AxiosRequestConfig } from "axios";

export class MilestonesApi extends FilteredApiModel<FP.Entities.IMilestone> implements IMilestonesApi {
  controller: string = "Milestones";
  urlWithOrg: string;

  constructor(http: IHttp) {
    super(http, "Milestones");
    this.urlWithOrg = `${this.http.url}/${this.version}/organisations`;
  }

  //#region programme

  async createProgrammeMilestone(
    organisationId: number,
    programmeId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.post(
      `${this.urlWithOrg}/${organisationId}/programmes/${programmeId}/milestones`,
      milestone
    );
    return data;
  }

  async createProjectMilestone(
    organisationId: number,
    projectId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.post(
      `${this.urlWithOrg}/${organisationId}/projects/${projectId}/milestones`,
      milestone
    );
    return data;
  }

  async updateProgrammeMilestone(
    organisationId: number,
    programmeId: number,
    milestoneId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.put(
      `${this.urlWithOrg}/${organisationId}/programmes/${programmeId}/milestones/${milestoneId}`,
      milestone
    );
    return data;
  }

  async updateProjectMilestone(
    organisationId: number,
    projectId: number,
    milestoneId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.put(
      `${this.urlWithOrg}/${organisationId}/projects/${projectId}/milestones/${milestoneId}`,
      milestone
    );
    return data;
  }

  async deleteProgrammeMilestone(
    organisationId: number,
    programmeId: number,
    milestoneId: number
  ): Promise<IFlightPathApiResponse<boolean>> {
    const { data } = await this.http.delete(
      `${this.urlWithOrg}/${organisationId}/programmes/${programmeId}/milestones/${milestoneId}`
    );
    return data;
  }

  async deleteProjectMilestone(
    organisationId: number,
    projectId: number,
    milestoneId: number
  ): Promise<IFlightPathApiResponse<boolean>> {
    const { data } = await this.http.delete(
      `${this.urlWithOrg}/${organisationId}/projects/${projectId}/milestones/${milestoneId}`
    );
    return data;
  }
  getProgrammeMilestones = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    const url = `${this.urlWithOrg}/${organisationId}/programmes/${programmeId}/milestones`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IMilestone[]>;
    }
    return null;
  };

  //#endregion programme
}
