import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Tab, Tabs } from "../../../../../components/ui/Tabs"
import { useAppService } from "../../../../../contexts/AppService"
import { useIsVisualisationsEnabled } from "../../../../../contexts/organisationSettings/OrganisationSettingsContext"
import I18n from "../../../../../core/localization/I18n"
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel"
import { ActionReportingViewModel } from "./ActionReportingView_model"
import { ActionsReportingTimeline } from "./ActionsReportingTimeline/ActionsReportingTimeline_view"
import { TopActionsReportingBar } from "./TopActionsReportingBar/TopActionsReportingBar_view"

export interface ActionReportingViewProps {
    model?: ActionReportingViewModel;
}

export const ActionReportingView: React.FC<ActionReportingViewProps> = observer(({ model: m }) => {
    const appService = useAppService();
    const history = useHistory();
    const organisationId = useCurrentOrganisationId();
    const { projectId, page } = useParams<{ projectId: string; page: string }>();
    const isVisualisationsEnabled = useIsVisualisationsEnabled();
    const [model, setModel] = useState(
        () => m || new ActionReportingViewModel(appService, organisationId, +projectId, history, page)
    );
    const modelProjectId = model.projectId;



    useEffect(() => {
        model.onMount();
        return model.onUnmount;
    }, [model]);

    useEffect(() => {
        if (`${modelProjectId}` !== projectId) {
            setModel(new ActionReportingViewModel(appService, organisationId, +projectId, history, page));
        }
    }, [projectId, modelProjectId, organisationId, appService, model, history, page]);


    return (
        <div>
            <ActionTabs
                isVisualisationsEnabled={isVisualisationsEnabled}
                changeCurrentView={model.changeCurrentView}
            />
            {!model.isLoading &&
                <div className="container-fluid">
                    <TopActionsReportingBar
                        ragData={model.ragData}
                        actionCount={model.actionCount}
                        totalConfidenceMetric={model.totalConfidenceMetric}
                        totalConfidenceData={model.totalConfidenceData}
                    />
                    <ActionsReportingTimeline actions={model.actions} project={model.project} />
                </div>
            }
        </div>
    )
})

const ActionTabs = ({ isVisualisationsEnabled, changeCurrentView }) => {
    return <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={1}
        liClassName="tabs__item--primary"
        onTabClicked={changeCurrentView}
    >
        <Tab
            title={<h2 className="mb-0">{I18n.t("phrases.actionTracker")}</h2>}>
        </Tab>
        <Tab
            isHidden={!isVisualisationsEnabled}
            title={<h2 className="mb-0">{I18n.t("phrases.actionPlan")}</h2>}>
        </Tab>
    </Tabs>
}

