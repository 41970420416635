import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { RouteComponentProps } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getAudienceFormFields } from "../../forms/stakeholder/StakeholderFormSection_data";
import { Services } from "../../../../constants";
import { Enums } from "../../../../enums";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { action } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";

export class CreateAudienceViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  localStorageService: ILocalStorageService;
  orgId: number;
  stakeholdersProvider: IStakeholdersApi;
  organisationsProvider: IOrganisationsApi;
  businessAreaProvider: IBusinessAreasApi;
  httpProgress: IHttpProgressModel;
  toasterService: IToasterService;
  formModel: SingleFormModel;
  stakeholderGroupProvider: IStakeholderGroupsApi;

  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.stakeholdersProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.stakeholderGroupProvider = appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);

    this.setForm();
  }

  onMount = () => {};

  onUnmount = () => {};

  @action
  setForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getAudienceFormFields(this.orgId, this.organisationsProvider);
    this.formModel.actions = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: () => {
          this.createAudience(true);
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        }
      },
      {
        id: "create",
        label: I18n.t("phrases.createAudience"),
        onAction: () => {
          this.createAudience();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
  };

  createAudience = async (createAnother: boolean = false) => {
    const formRes = await this.formModel.submit();
    if (!formRes) return;

    formRes.lastName = formRes.firstName + "-last-name";
    formRes.email = Date.now() + "@example.com";
    formRes.isChange = false;

    this.httpProgress.showOverlay();
    const res = await this.stakeholdersProvider.create(this.orgId, formRes as FP.Entities.IStakeholder);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    if (createAnother) {
      this.toasterService
        .showSuccessToast()
        .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.audience") })}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      this.formModel.resetFields();
      return;
    }

    this.routeProps.history.push(`/organisations/${this.orgId}/stakeholders/${res.payload.id}`);
  };
}
