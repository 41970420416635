import * as React from "react";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { FORM_COL, IMPACT_TYPE_OPTIONS } from "../../../../constants";

export const GetImpactGroupListFilters = (filterModel: IFilterModel<FP.Entities.IImpactGroup>) => {
  // define filters
  const nameFilter = filterModel.getFilter("name");
  const refFilter = filterModel.getFilter("refNumber");
  const type = filterModel.getFilter("impactType");

  // add filter form fields
  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "impactGroupName",
    label: <label htmlFor={"impactGroupName"}>{I18n.t("forms.highLevelImpactName")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.highLevelImpactName"),
    onValueChange: value => {
      filterModel.setFilterValue(nameFilter.key, value);
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: nameFilter?.value[0]
  };

  const ref: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: refFilter.key,
    label: <label htmlFor={refFilter.key}>{I18n.t("table.refNo")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.searchReference"),
    onValueChange: value => {
      filterModel.setFilterValue(refFilter.key, value);
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: refFilter?.value[0]
  };

  let val = IMPACT_TYPE_OPTIONS.find(e => (e.key as any) === type.value);
  if (val && val.label.startsWith("phrases")) {
    val.label = I18n.t(val.label);
  }

  const fields = [];
  fields.push(ref);
  fields.push(name);
  // fields.push(impactType);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
