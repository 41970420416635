import { HubEvents } from "./HubEventsEnum";
import { SignalRBaseModel } from "./SignalRBaseModel";

export class GridBaseModel extends SignalRBaseModel {
  hubName: string;

  invokeUserJoined = async (organisationId: number, projectId: number, userOktaId: string) => {
    if (!this.isConnectionStarted) return;
    const connectionGroupName = this.getConnectionGroupName(organisationId, projectId, this.hubName);
    await this.connection.invoke(HubEvents.INVOKE_USER_JOIN, { userOktaId, groupName: connectionGroupName });
  };

  onUserCellSelected = callback => this.registerIncomingEvent(HubEvents.ON_USER_CELL_SELECTED, callback);

  invokeUserClickedCell = async (organisationId: number, projectId: number, cell: string, isEditMode: boolean) => {
    if (!this.isConnectionStarted) return;
    const connectionGroupName = this.getConnectionGroupName(organisationId, projectId, this.hubName);
    await this.connection.invoke(HubEvents.INVOKE_USER_CELL_SELECTED, {
      groupName: connectionGroupName,
      cell,
      isEditMode
    });
  };
}
