import React, { useState } from "react";
import { Panel } from "../../ui/Panel";
import { observer } from "mobx-react-lite";
import { Button, ButtonTypes } from "../../ui/Button";
import { CanCreateOrganisation } from "../../hoc/CanCreate";
import { IOrganisationCreateModel, OrganisationCreateModel } from "./OrganisationCreate_Model";
import { useAppService } from "../../../contexts/AppService";
import { isEmpty } from "lodash";

export interface OrganisationCreateProps {
  model?: IOrganisationCreateModel;
}

const OrganisationCreate: React.FunctionComponent<OrganisationCreateProps> = observer(({ model: m }) => {
  const appService = useAppService();

  const [model] = useState(() => m || new OrganisationCreateModel(appService));

  return (
    <>
      <CanCreateOrganisation>
        <Panel.Panel className="organisation-create p-4">
          <Button
            data-testid="create-organisation-button"
            className="ml-auto"
            type={ButtonTypes.PRIMARY}
            onClick={e => model.onActionClick(e)}
            isDisabled={!model.isActive}
          >
            {model.actionLabel}
          </Button>
          {!isEmpty(model.resultMessage) && (
            <div data-testid="created-organisation-message">
              {model.resultMessage}
              <span data-testid="created-organisation-id">{model.createdOrganisationId}</span>
            </div>
          )}
        </Panel.Panel>
      </CanCreateOrganisation>
    </>
  );
});

export { OrganisationCreate };
