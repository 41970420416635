import { IHttp } from "../../IHttp";
import { IChangeRoleApi } from "./IChangeRole.api";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";

export class ChangeRoleApi extends FilteredOrganisationApiModel<FP.Entities.IChangeRole> implements IChangeRoleApi {
  controller: string = "change-roles";

  constructor(http: IHttp) {
    super(http, "change-roles");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  addImpactChangeRoleAssoc = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    projectStakeholderId: number,
    change: FP.Entities.IChangeRole,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impacts/${impactId}`,
      { ...change, projectStakeholderId },
      config
    );
    if (res && res.status === 200) return res.data;
  };

  getAllAsync = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IChangeRole[]>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/${this.controller}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  removeImpactChangeRoleAssoc = async (
    organisationId: number,
    projectId: number,
    changeRoleId: number,
    impactId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${changeRoleId}/impacts-project-stakeholders/${projectStakeholderId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) return res.data;
  };
}
