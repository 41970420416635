import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IChangeRoleApi } from "../../../../../services/api/v1/changeRoles/IChangeRole.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getChangeRoleFormFields } from "./ImpactStakeholderChangeRole_fields";

export class ImpactStakeholderChangeRoleModel {
  @observable formModel: SingleFormModel;
  organisationId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  appService: AppService;
  stakeholderRoleProvider: IChangeRoleApi;
  projectId: number;
  impactId: number;
  onRoleAdded: any;

  constructor(
    appService,
    organisationId: number,
    projectId: number,
    impactId: number,
    projectStakeholder: FP.Entities.IProjectStakeholder,
    onRoleAdded
  ) {
    this.organisationId = organisationId;
    this.impactId = impactId;
    this.projectId = projectId;
    this.projectStakeholder = projectStakeholder;
    this.appService = appService;
    this.onRoleAdded = onRoleAdded;
    this.stakeholderRoleProvider = this.appService.getService<IChangeRoleApi>(Services.ChangeRolesApi);
    this.initForm();
  }

  onMount = () => {};

  onUnmount = () => {};

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getChangeRoleFormFields(
      this.stakeholderRoleProvider,
      this.organisationId,
      this.projectId,
      this.impactId,
      this.projectStakeholder,
      this.onRoleAdded
    );
  };
}
