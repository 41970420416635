import React, { useEffect } from "react";
import { CreateImpactViewModel } from "./CreateImpactView_model";
import { observer } from "mobx-react-lite";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Panel } from "../../../../components/ui/Panel";

export interface CreateImpactViewProps {
  model: CreateImpactViewModel;
}

const CreateImpactView: React.FunctionComponent<CreateImpactViewProps> = observer(props => {
  const { model } = props;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  });

  return (
    <div className="create-Impact-view pt-8">
      {!!model.projectId && (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col p-2">
              <LinkButton
                size={Enums.UiSizes.MD}
                type={ButtonTypes.LINK}
                className="p-0"
                href={`/organisations/${organisationId}/projects/${model.projectId}/impacts`}
              >
                <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.backToDetailedImpacts")}
              </LinkButton>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12">
            <h1>{I18n.t("phrases.createNewDetailedImpact")}</h1>
          </div>
          <div className="col-lg-7">
            <p>{I18n.t("phrases.createDetailedImpactDescription")}</p>
          </div>
        </div>

        <div className="col-6">
          <Panel.Panel hasBorderRadius={true} type={Panel.PanelTypes.OUTLINES} className="px-3 mb-5">
            {model.projectForm && (
              <div className="row  bg-gray-200 py-5">
                <div className="col-12">
                  <SingleForm model={model.projectForm} />
                </div>
              </div>
            )}

            {model.impactForm && (
              <div className="row py-5">
                <div className="col-12">
                  <SingleForm model={model.impactForm} />
                </div>
              </div>
            )}
          </Panel.Panel>
        </div>
      </div>
    </div>
  );
});

export { CreateImpactView };
