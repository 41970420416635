import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { Services } from "../../../../../../../constants";
import I18n from "../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { IImpactGroupsApi } from "../../../../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ImpactGroupsSidebarModel } from "./impactGroupsSidebar_model";
import { getImpactGroupFormFields } from "./impactGroup_formFields";
import { IModalService } from "../../../../../../../core/modal/IModalService";

interface ImpactGroupsSidebarProps {
  model: ImpactGroupsSidebarModel;
  modalService: IModalService;
}

export const ImpactGroupsSidebar: React.FC<ImpactGroupsSidebarProps> = observer(({ model, modalService }) => {
  const { impact, organisationId, projectId } = model;
  const impactGroupsProvider = model.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);

  const formModel = useMemo(() => new SingleFormModel(), []);

  useEffect(() => {
    formModel.formFields = getImpactGroupFormFields(impactGroupsProvider, organisationId, projectId, impact);
  }, [impact, impactGroupsProvider, organisationId, projectId, formModel]);
  formModel.actions = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        modalService.hide();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={formModel} />
    </div>
  );
});
