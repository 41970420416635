import React from "react";
import { Panel } from "../../../../../../../components/ui/Panel";
import I18n from "../../../../../../../core/localization/I18n";
import { IModalService } from "../../../../../../../core/modal/IModalService";
import { Animations } from "../../../../../../../core/util/Animations";
import { PieModalContent } from "./PieChart_view";

export const SHOW_PIECHART_MODAL = (
  modalService: IModalService,
  rowId,
  cellMonth,
  cellYear,
  getChartData,
  getTagFilterIdsAsString,
  rowName,
  getUrl: (organisationId: number, projectId: number, month: number, year: number, itemIds?: string[]) => string
) => {
  modalService.show({
    showClose: true,
    title: (
      <h4 className="pt-4">
        {rowName} {I18n.t("phrases.impacts")} - {getMonthText(cellMonth)} {cellYear}
      </h4>
    ),
    content: (
      <PieModalContent
        getChartData={getChartData}
        rowId={rowId}
        rowName={rowName}
        month={cellMonth}
        year={cellYear}
        getUrl={getUrl}
        getTagFilterIdsAsString={getTagFilterIdsAsString}
      />
    ),
    componentProps: {
      wrapHeight: "full",
      wrapWidth: "small",
      position: "right",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_LIGHT,
        className: "h-auto min-h-100",
        hasShadow: true
      }
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_RIGHT,
      animateOut: Animations.SLIDE_OUT_RIGHT
    }
  });
};

export const getMonthText = (month: number) => {
  const date = new Date();
  date.setMonth(month - 1);
  return date.toLocaleString("en-UK", {
    month: "long"
  });
};

export const getTitleString = (rowName: string, cellMonth: number, cellYear) => {
  const nameLower = rowName;
  const name = nameLower.toUpperCase();
  const monthText = getMonthText(cellMonth).toUpperCase();
  const impactsText = I18n.t("phrases.impacts");

  return `${name}  ${impactsText.toUpperCase()} - ${monthText} ${cellYear}`;
};
