import React from "react";
import { Link } from "react-router-dom";

interface ImageDisplayProps {
  img: any;
  url: string;
  title: string;
  isDisabled?: boolean;
}

export const ImageDisplay: React.FC<ImageDisplayProps> = ({ title, url, img, isDisabled }) => {
  return (
    <Link to={url} className={`image-display ${isDisabled ? "image-display--disabled" : ""}`}>
      <h3 className="image-display__title text-center">{title}</h3>
      <img src={img} alt="" className="image-display__image" />
    </Link>
  );
};
