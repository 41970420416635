import { FORM_COL } from "../../../../../../../constants";
import {
  ISearchTreePickerModel,
  ITreeDataItem
} from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../../../../core/forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";

export const getImpactProcessesSidebarFormActions = (updateImpactFn: () => void, canEdit: boolean, closeModalFn) => {
  let actions = [];

  if (canEdit)
    actions.push({
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        updateImpactFn();
        closeModalFn();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    });

  return actions;
};

export const getImpactProcessesSidebarFormFields = (busAreas: ITreeDataItem[]) => {
  const processes: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "businessProcesses",
    value: busAreas.filter(e => e.isChecked === true),
    options: busAreas,
    placeholder: I18n.t("placeholders.searchProcess"),
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  let fields = [];
  fields.push(processes);

  return generateFormFieldsFromJson(fields);
};
