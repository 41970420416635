import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { RagStatus } from "../../../components/ui/RagStatus";
import { RAG_STATUS_OPTIONS } from "../../../constants";

const RAGStatusRenderer = (value: number, setValue) => {
  return (
    <div onClick={e => setValue(value)} className="m-2 c-pointer" key={value}>
      <RagStatus state={value} />
    </div>
  );
};

export const RAGStatusEditor: React.FC<any> = memo(
  forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value ?? -1);
    const isFirstRender = useRef(true);

    const saveChanges = (value: number) => {
      setValue(value);
    };

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      props.stopEditing(false);
    }, [value, props]);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          // this simple editor doubles any value entered into the input
          return value;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // our editor will reject any value greater than 1000
          return false;
        }
      };
    });

    return <div className="d-flex">{RAG_STATUS_OPTIONS.map(x => RAGStatusRenderer(x.key, saveChanges))}</div>;
  })
);
