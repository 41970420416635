import { action, observable } from 'mobx';
import { AppService } from 'strikejs-app-service';
import { Services } from '../../../../../../../constants';
import { IHttpProgressModel } from '../../../../../../../core/httpProgress/HttpProgress_model';
import { BaseModel } from '../../../../../../../core/util/BaseModel';
import { IImpactsApi } from '../../../../../../../services/api/v1/impacts/IImpacts.api';
import { ITagsApi } from '../../../../../../../services/api/v1/tags/ITags.api';

export class ImpactTagsSidebarModel extends BaseModel {
  appService: AppService;
  impactsProvider: IImpactsApi;
  tagsProvider: ITagsApi;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;

  constructor(appService: AppService, closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    this.appService = appService;
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadImpact(impactId);
    this.closeModalFn = closeModalFn;
  }

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.isLoading = false;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpact(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    // let res = await this.tagsProvider.removeImpactTagAssoc(this.organisationId, this.projectId, tagId, this.impact.id);
    // if (!res || res.isError) return;
  };
}
