import * as React from "react";
import { ImpactStakeholdersModel } from "./ImpactStakeholders_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { IF } from "../../../../components/hoc/If";
import { LinkButton, Button, ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import { Panel } from "../../../../components/ui/Panel";
import { useParams } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { observer } from "mobx-react-lite";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useEffect } from "react";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { CanEdit, CanView } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { PermissionErrorMessage } from "../../../../contexts/permissions/PermissionMessages";
import { ImpactStakeholderCard } from "../../stakeholders/impactStakeholderCard";

export interface ImpactStakeholdersProps {
  model: ImpactStakeholdersModel;
}

export const ImpactStakeholders: React.FC<ImpactStakeholdersProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId, impactId } = useParams<{ projectId: string; impactId: string }>();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading || !model.impact) {
    return <PositionedSpinner />;
  }

  return (
    <div className="impact-stakeholders pt-5">
      <CanView
        field={PermissionFields.STAKEHOLDERS}
        projectId={model.projectId}
        errorMessage={
          <div className="row mb-4">
            <div className="col">
              <PermissionErrorMessage>
                <p className="mb-0">{I18n.t("phrases.noImpactAccess")}</p>
              </PermissionErrorMessage>
            </div>
          </div>
        }
      >
        <div className="container-fluid">
          <IF condition={model.isRouteView}>
            <div className="row mb-3">
              <div className="col">
                <LinkButton
                  size={Enums.UiSizes.MD}
                  type={ButtonTypes.LINK_BIG}
                  className="p-0"
                  href={`/organisations/${organisationId}/projects/${model.impact.project.id}/impacts/${model.impact.id}`}
                >
                  <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                  {I18n.t("phrases.backToImpactDetails")}
                </LinkButton>
              </div>
            </div>
          </IF>

          <div className="row mb-5">
            <div className="col">
              <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.impact.project.id}>
                <Button onClick={model.showAddStakeholdersModal}>{I18n.t("phrases.addStakeholder")}</Button>
                <Button
                  className="ml-2"
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  onClick={model.showAddStakeholderGroupModal}
                >
                  {I18n.t("phrases.addCollection")}
                </Button>
              </CanEdit>

              <ButtonIcon
                className="float-right"
                symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                type={ButtonTypes.OUTLINE_PRIMARY}
                onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
              />

              <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                <Input
                  autoFocus={true}
                  size={-1}
                  onChange={model.updateSearchValue}
                  borderStyle="underline"
                  placeholder={I18n.t("placeholders.searchImpactStakeholders")}
                  icon={IconSymbols.Search}
                  value={model.searchValue}
                  onBlur={null}
                  className="mt-3"
                />
              </AnimateHeight>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {model.filteredProjectStakeholders?.length ? (
                model.filteredProjectStakeholders.map(e => {
                  let stakeholder = e.stakeholder;
                  return (
                    <ImpactStakeholderCard
                      stakeholder={stakeholder}
                      pStakeholderId={e.id}
                      onLinkClickFn={() => {}}
                      projectId={+projectId}
                      deleteFn={model.removeProjectStakeholder}
                      impactId={model.impactId}
                      projectStakeholder={e}
                      onRoleAdded={() => model.loadProjectStakeholders(+impactId)}
                    />
                  );
                })
              ) : (
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noImpactStakeholders")}</div>
                </Panel.Panel>
              )}
            </div>
          </div>
        </div>
      </CanView>
    </div>
  );
});
