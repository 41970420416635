import React, { useEffect, useState } from "react";
import { MilestoneSettingsModel } from "./MilestoneSettings_model";
import { TableView } from "../../../../../core/table/Table_view";
import { observer } from "mobx-react";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";

export interface MilestoneSettingsViewProps {
  model?: MilestoneSettingsModel;
  programme: FP.Entities.IProgramme;
}

const MilestoneSettingsView: React.FunctionComponent<MilestoneSettingsViewProps> = observer(
  ({ model: m, programme }) => {
    const appService = useAppService();
    const [model] = useState(() => m || new MilestoneSettingsModel(appService, programme));
    const organisationId = useCurrentOrganisationId();
    useEffect(() => {
      model.onMount();
    }, [model]);

    useEffect(() => {
      model.setProgramme(programme);
      model.setMilestones(programme.milestones);
    }, [programme, model]);

    return (
      <div className={`container-fluid milestone-settings-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row">
          <div className="col">
            <h1 className="d-inline-block">
              {model.programme?.name} | {I18n.t("phrases.manageMilestones")}
            </h1>
            <LinkButton
              id="CloseProgrammeSettingsButton"
              type={ButtonTypes.OUTLINE_PRIMARY}
              href={`/organisations/${organisationId}/programmes/${model.programme?.id}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-7">
            <p>{I18n.t("phrases.manageProgrammeMilestonesDescription")}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <Button
              id="ShowMilestoneFormButton"
              onClick={() => {
                model.showMilestoneModal();
              }}
            >
              {I18n.t("phrases.createMilestone")}
            </Button>

            <SearchButton className={"float-right"} value={""} onChange={() => {}} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TableView model={model.tableModel} />
          </div>
        </div>
      </div>
    );
  }
);

export { MilestoneSettingsView };
