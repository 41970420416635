import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IActionsApi } from "../../../../../services/api/v1/actions/IActions.api";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";
import * as H from "history";
import { action, observable } from "mobx";
import I18n from "../../../../../core/localization/I18n";
import React from "react";
import { IUiAction, UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import {
  SHOW_ACTION_DELETE_CONFIRM_MODAL,
  SHOW_ACTION_REVIEW_CONFIRM_MODAL,
  SHOW_CONFIRM_CREATION_MODAL,
  SHOW_NOTES_MODAL,
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL
} from "./ActionListView_modals";
import { ActionsHub } from "../../../../../services/hubs/ActionsHub/Actions_hub";
import { SingleFormModel } from "../../../../change/forms/singleFormModel/SingleForm_model";
import { getEntityNameMicroFormFields } from "../../../../change/forms/microForm/getEntityNameMicroFormFields";
import { ActionField, Enums } from "../../../../../enums";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { ILocalStorageService } from "../../../../../services/local/localStorageService/ILocalStorageService";
import { getActionGridFilters } from "./AcGridView/AcGridView_apiFilter";
import { ButtonTypes } from "../../../../../components/ui/Button";

export class ActionListViewModel extends BaseModel {
  appService: AppService;
  history: H.History<unknown>;
  organisationId: number;
  toasterService: IToasterService;
  modalService: IModalService;
  gridToastService: IGridToastService;
  projectProvider: IProjectsApi;
  actionProvider: IActionsApi;
  httpProgress: IHttpProgressModel;
  projectId: number;
  @observable.ref project: FP.Entities.IProject;
  @observable.ref actions: FP.Entities.IAction[];
  @observable.ref gridActions: FP.Entities.IActionSummary[];
  @observable.ref selectedActions: number[] = [];
  @observable isLoading: boolean = true;
  @observable actionCount: number = 0;
  @observable connectedUsers: FP.Entities.IUser[] = [];
  hasBeenReviewed: boolean;
  reviewCommentInput: string;
  @observable topActionBarActions: IUiAction<any>[] = [];
  actionsHub: ActionsHub;
  authUser: FP.Entities.IUser;
  microActionForm: SingleFormModel;
  commentsProvider: any;
  gridApi: GridApi;
  filterModel: any;
  storageService: ILocalStorageService;
  @observable searchText: string;
  uiActions = [
    {
      id: "action1",
      label: I18n.t("entities.impacts"),
      onAction: ev => {
        this.showLinkActionsToImpactModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(
    appService: AppService,
    history: H.History<unknown>,
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser
  ) {
    super();
    this.appService = appService;
    this.history = history;
    this.organisationId = organisationId;
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.actionsHub = this.appService.getService(Services.ActionsHub);
    this.commentsProvider = this.appService.getService(Services.CommentsApi);
    this.gridToastService = this.appService.getService(Services.GridToastService);
    this.authUser = authUser;
    this.storageService = this.appService.getService(Services.LocalStorageService);

    if (projectId) {
      this.projectId = projectId;
    }

    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.setMicroActionForm();
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.actionsHub.isConnectionStarted === true) {
      await this.actionsHub.stopConnection();
    }
    await this.actionsHub.startConnection();
    this.actionsHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.actionsHub.onLoadGridData(d => {
      this.setActionRowData(d);
      this.actionCount = d.length;
    });

    this.actionsHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.actionsHub.invokeUserJoined(this.organisationId, this.projectId, this.authUser.sub);
    await this.actionsHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  stopConnection = async () => {
    await this.actionsHub.stopConnection();
  };

  @action
  updateSelectedActions = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedActions = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setActionRowData = actions => {
    this.gridActions = actions;
    this.isLoading = false;
  };

  @action
  setMicroActionForm = () => {
    this.microActionForm = new SingleFormModel();
    this.microActionForm.formFields = getEntityNameMicroFormFields(
      this.createMicroAction,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.action") })
    );
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
    this.filterModel = getActionGridFilters();
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  @action
  deleteFieldData = async (impactId: number, actionField: ActionField) => {
    const res = await this.actionProvider.deleteField(this.organisationId, this.projectId, impactId, actionField);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  @action
  createMicroAction = async () => {
    let microActionFormRes = await this.microActionForm.submit();
    this.microActionForm.isSaving = true;
    if (!microActionFormRes) return;

    microActionFormRes = { ...microActionFormRes, projectId: this.projectId, impacts: [], tags: [] };

    this.httpProgress.showOverlay();
    let actionNameExists = await this.actionProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${microActionFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId=${this.projectId}`
    });

    if (actionNameExists && !actionNameExists.isError && actionNameExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateAction = await this.confirmCreateAction(microActionFormRes.name);
      if (!confirmCreateAction) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...microActionFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.actionProvider.create(this.organisationId, this.projectId, res as FP.Entities.IAction);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const action = result.payload;
    this.microActionForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.action") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return action;
  };

  confirmCreateAction = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(this.modalService, name);
  };

  updateUserSelectedCell = async (cell: string, isEditMode?: boolean) => {
    await this.actionsHub.invokeUserClickedCell(this.organisationId, this.projectId, cell, isEditMode);
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  removeAction = async (actionIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.actionProvider.deleteRange(this.organisationId, this.projectId, actionIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  reviewActionRange = async (actionIds: number[], comment: string) => {
    let res = await this.actionProvider.reviewRange(this.organisationId, this.projectId, actionIds, comment);

    if (!res || res.isError) return;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 1) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions/visualisations`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions`);
  };

  showActionConfirmReviewModal = () => {
    return SHOW_ACTION_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedActions,
      this.reviewActionRange,
      this.toasterService,
      this.deselectRows
    );
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-actions-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedActions && this.selectedActions.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  showNotesModal = row => {
    const id = row.id;
    SHOW_NOTES_MODAL(this.modalService, this.organisationId, this.projectId, id);
  };

  showActionConfirmDeleteModal = () => {
    return SHOW_ACTION_DELETE_CONFIRM_MODAL(this.modalService, this.selectedActions, this.removeAction);
  };

  showLinkActionsToImpactModal = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedActions);
  };

  @action
  resetReviewAndSidebar = () => {
    this.hasBeenReviewed = false;
    this.modalService.hide();
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setQuickFilter(this.searchText);
    }
  };
}
