import { PROGRESS_STATUS_OPTIONS, Services } from "../../../../../../constants";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { AppService } from "strikejs-app-service";
import I18n from "../../../../../../core/localization/I18n";
import { action, observable } from "mobx";
import { IActionTypeApi } from "../../../../../../services/api/v1/actionTypes/IActionType.api";
import { IProjectTeamUserPermissionsApi } from "../../../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { GetActionGridViewColumns } from "./AcGridView_columns";
import { ActionsHub } from "../../../../../../services/hubs/ActionsHub/Actions_hub";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { IActionsApi } from "../../../../../../services/api/v1/actions/IActions.api";
import { EntityTypes } from "../../../../../../enums";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { getActionGridModals } from "./modals/AcGridView_modals";
import { IGridModel } from "../../../../../../core/grids/IGridModel";
import { GetGridFns } from "../../../../../../core/grids/GridFunctions";

export class AcGridViewModel implements IGridModel {
  appService: AppService;
  modalService: IModalService;
  organisationId: number;
  projectId: number;
  @observable.ref actionTypes: FP.Entities.IActionType[];
  @observable.ref projectTeamMembers: any[];
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionTypeProvider: IActionTypeApi;
  actionsProvider: IActionsApi;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  actionsHub: ActionsHub;
  userCanViewImpacts: boolean;
  type: EntityTypes;
  gridToastService: IGridToastService;
  userCanViewStakeholders: boolean;

  constructor(appService: AppService, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    this.appService = appService;
    this.actionTypeProvider = appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.actionsProvider = appService.getService<IActionsApi>(Services.ActionsApi);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.projectTeamUserPermissionsProvider = appService.getService<IProjectTeamUserPermissionsApi>(
      Services.ProjectTeamUserPermissionsApi
    );
    this.actionsHub = appService.getService(Services.ActionsHub);
    this.gridToastService = this.appService.getService<IGridToastService>(Services.GridToastService);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(PermissionFields.STAKEHOLDERS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.type = EntityTypes.ACTIONS;
    this.mapProgressStatuses();
  }

  onMount = async () => {
    Promise.all([this.loadActionTypes(), this.loadProjectTeamMembers()]).then(values => {
      this.isLoading = false;
    });
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );

    return GetActionGridViewColumns({
      userCanViewImpacts: this.userCanViewImpacts,
      progressStatuses: this.progressStatuses,
      actionTypes: this.actionTypes,
      projectTeamMembers: this.projectTeamMembers,
      modals: getActionGridModals(this.appService, this.organisationId, this.projectId),
      canEdit: canEditActions,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: GetGridFns(this.actionsProvider as any, this.organisationId, this.projectId),
      userCanViewStakeholders: this.userCanViewStakeholders
    });
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypeProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.actionTypes = res.payload;
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;

    this.projectTeamMembers = res.payload;
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
