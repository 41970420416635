import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { IHttp } from "../../IHttp";
import { IImpactTypeApi } from "./IImpactTypes.api";

export class ImpactTypesApi extends FilteredOrganisationApiModel<FP.Entities.IImpactType> implements IImpactTypeApi {
  controller: string = "impact-types";

  constructor(http: IHttp) {
    super(http, "impact-types");
  }

  addTypeToImpact = async (
    organisationId: number,
    projectId: number,
    impactImpactType: object,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(`${this.url}/${organisationId}/projects/${projectId}/${this.controller}`, impactImpactType, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
    return null;
  }

  removeTypeFromImpact = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    impactTypeId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(`${this.url}/${organisationId}/projects/${projectId}/impacts/${impactId}/${this.controller}/${impactTypeId}`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
    return null;
  }

  getImpactTypes = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactType[]>> => {
    let res = await this.http.get(this.url + '/' + orgId + '/' + this.controller, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpactType[]>;
    }
    return null;
  };

  getUnselectedByOrganisation = async (
    organisationId: number,
    selectedOrganisationImpactTypeIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactType[]>> => {
    let res = await this.http.post(this.url + "/" + organisationId + "/impact-types/unselected-by-organisation", selectedOrganisationImpactTypeIds, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpactType[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  }
}
