import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { action, observable } from "mobx";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { IProjectStakeholdersApi } from "../../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ProjectStakeholdersHub } from "../../../../../services/hubs/ProjectStakeholdersHub/ProjectStakeholders_hub";

export class StakeholderGridModel {
  appService: AppService;
  projectId: number;
  projectStakeholdersHub: ProjectStakeholdersHub;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable gridStakeholders: FP.Entities.IImpactSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedStakeholders: number[] = [];
  gridApi: GridApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  impactIds: number[];
  searchText: string;

  /**
   *
   */
  constructor(
    appService: AppService,
    projectId: number,
    organisationId: number,
    authUser: FP.Entities.IUser,
    impactIds: number[]
  ) {
    this.appService = appService;
    this.projectId = projectId;
    this.projectStakeholdersHub = this.appService.getService<ProjectStakeholdersHub>(Services.ProjectStakeholdersHub);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.organisationId = organisationId;
    this.impactIds = impactIds;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.projectStakeholdersHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.projectStakeholdersHub.isConnectionStarted === true) {
      await this.projectStakeholdersHub.stopConnection();
    }
    await this.projectStakeholdersHub.startConnection();

    this.projectStakeholdersHub.onLoadGridData(d => {
      this.setStakeholderRowData(d);
    });

    await this.projectStakeholdersHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  @action
  setStakeholderRowData = stakeholders => {
    this.gridStakeholders = stakeholders;
    this.isLoading = false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setQuickFilter(this.searchText);
    }
  };

  @action
  updateSelectedStakeholders = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedStakeholders = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  assignStakeholders = async () => {
    return await this.projectStakeholderProvider.assignMultipleImpacts(
      this.organisationId,
      this.projectId,
      this.selectedStakeholders,
      this.impactIds
    );
  };
}
