import { FormFieldModel } from "../../formField/FormField_model";
import { SearchTreePicker, SearchTreePickerProps } from "../../../../components/ui/SearchTreePicker";
import { INIT_FORM_FIELD } from "../../formField/FormField_init";
import { ISearchTreePickerModel, ITreeDataItem } from "./ISearchTreePickerModel";
import { action, observable } from "mobx";
import { FormFieldType } from "../../formField/FormFieldTypes";
import React from "react";
import * as _ from "lodash";


export const INIT_SEARCHTREEPICKER: Partial<ISearchTreePickerModel> = {
    ...INIT_FORM_FIELD,
    type: FormFieldType.SearchTreePicker,
    value: [],
}

export class SearchTreePickerModel
    extends FormFieldModel<ITreeDataItem[], SearchTreePickerProps>
    implements ISearchTreePickerModel {
    @observable value: ITreeDataItem[] = [];
    readonly type: FormFieldType = FormFieldType.SearchTreePicker;
    isCascading: boolean = true;
    placeholder: string = "";
    @observable options: ITreeDataItem[] = [];
    showSelectAll: boolean = true;


    constructor(initOpts?: ISearchTreePickerModel) {
        super(initOpts as any);
        if (initOpts) {
            this.options = initOpts.options || this.options;
            this.initCheckboxFn = initOpts.initCheckboxFn || this.initCheckboxFn;
            this.getTreeData = initOpts.getTreeData || this.getTreeData;
            this.onItemSelected = initOpts.onItemSelected || this.onItemSelected;
            this.showSelectAll = initOpts.showButtonOpts || this.showSelectAll;
            this.placeholder = initOpts.placeholder || this.placeholder;
            this.value = initOpts.value || this.value;
        }
    }

    getTreeData = (data: any[]) => data.map((item) => ({
        ...item,
        isChecked: this.initCheckboxFn(item),
        isCollapsed: false,
        hasChildren:
            data.filter((i) => i.parent === item.id).length > 0,
    }));

    validate: any | ((...args: any[]) => any);


    @action.bound
    setValue(val: ITreeDataItem[]): void {
        this.value = val;
    }

    initCheckboxFn = (item: ITreeDataItem): boolean => item.isChecked;

    setFieldValue(val: any): void {
        throw new Error("Method not implemented.");
    }

    extractValue() {
        let res = _.filter(this.value, e => e.isChecked);
        return res.map(e => e.id)
    }

    reset(): void {
        this.value.forEach(e => (e.isChecked = false));
    }

    @action.bound
    onItemSelected = (checkedData: ITreeDataItem[], allData: ITreeDataItem[]) => {
        this.setValue(checkedData);
    };

    setIsCascading = (isCascading) => this.isCascading = isCascading;

    renderComponent = (): React.ReactNode => {
        return <SearchTreePicker
            onItemSelected={this.onItemSelected}
            placeholder={this.placeholder}
            checkTreeProps={{
                showSelectAll: this.showSelectAll,
                isCascading: this.isCascading,
                initCheckBoxFn: this.initCheckboxFn
            }}
            data={this.getTreeData(this.options)} />;
    };
}