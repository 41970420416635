import React, { useCallback, useState } from "react";
import { StakeholderViewModel } from "./View_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { LinkButton, Button, ButtonTypes } from "../../../../components/ui/Button";
import { UiSizes } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { Panel } from "../../../../components/ui/Panel";
import { StakeholderDetails } from "../StakeholderDetails";
import { Observer, observer } from "mobx-react-lite";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { SimpleAccordion } from "../../../../components/ui/SimpleAccordion";
import { AccordionContent } from "./accordionContent/AccordionContent_view";
import { AccordionContentModel } from "./accordionContent/AccordionContent_model";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { CanEditOrg } from "../../../../contexts/permissions/PermissionHelpers";

export interface StakeholderViewProps {
  model?: StakeholderViewModel;
}

export const StakeholderView: React.FunctionComponent<StakeholderViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const currentOrganisationId = useCurrentOrganisationId();
  const { stakeholderId } = useParams<{ stakeholderId: string }>();
  const [model] = useState(() => m || new StakeholderViewModel(appService, currentOrganisationId));
  const loadStakeholder = useCallback(async () => {
    await Promise.all([model.loadStakeholder(+stakeholderId), model.loadProjectDetails(+stakeholderId)]);
  }, [model, stakeholderId]);
  const { stakeholder } = model;

  return (
    <Async promiseFn={loadStakeholder}>
      <Async.Loading>
        <PositionedSpinner />;
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <Observer>
            {() => (
              <div className="data-extended-view pt-6">
                <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
                  <div className="row">
                    <div className="col">
                      <LinkButton size={UiSizes.MD} type={ButtonTypes.LINK} className="p-0" href={model.returnUrl}>
                        <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                        {I18n.t("phrases.back")}
                      </LinkButton>
                    </div>
                  </div>
                </div>
                <div className="data-extended-view__block data-extended-view__block--wrapper">
                  <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="d-flex">
                          <h2 className="mb-0">
                            {convertStakeholderToName(stakeholder)} | {I18n.t("phrases.stakeholderProfile")}
                          </h2>
                          {!model.isEditView && (
                            <CanEditOrg>
                              <Button
                                className="ml-auto"
                                type={ButtonTypes.OUTLINE_PRIMARY}
                                onClick={() => {
                                  model.setIsEditView(true);
                                }}
                              >
                                {" "}
                                <Icon symbol={IconSymbols.Pencil} size={UiSizes.SM} className="mr-2" />
                                {I18n.t("phrases.edit")}
                              </Button>
                            </CanEditOrg>
                          )}
                        </div>
                      </div>
                    </div>

                    {model.isEditView ? (
                      <div className="col-12">
                        <SingleForm model={model.formModel} />
                      </div>
                    ) : (
                      <StakeholderDetails item={stakeholder} />
                    )}
                  </div>
                  <Panel.Panel
                    background={Panel.PanelBackgrounds.BG_LIGHT}
                    className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-5`}
                  >
                    <div className="container-fluid">
                      <div className="row mt-5">
                        <div className="col">
                          <h1>{I18n.t("entities.projects")}</h1>
                        </div>
                      </div>
                    </div>

                    {model.projectDetails.map(e => {
                      return (
                        <div>
                          <SimpleAccordion
                            actions={[
                              {
                                id: "action1",
                                label: I18n.t("phrases.viewProject"),
                                hrefFn: () => {
                                  return `/organisations/${currentOrganisationId}/projects/${e.project.id}`;
                                },
                                componentProps: {
                                  type: ButtonTypes.LINK
                                },
                                rendersIn: UiActionRenderers.HTML_ANCHOR
                              },
                              {
                                id: "action2",
                                label: I18n.t("phrases.viewDetails"),
                                hrefFn: () => {
                                  return `/organisations/${currentOrganisationId}/projects/${e.project.id}/stakeholders/${model.stakeholderId}`;
                                },
                                componentProps: {
                                  type: ButtonTypes.LINK
                                },
                                rendersIn: UiActionRenderers.HTML_ANCHOR
                              }
                            ]}
                            header={
                              <Panel.Panel>
                                <div className="d-flex align-items-center mx-4">
                                  <h2 className="m-0">{e.project.name}</h2>
                                </div>
                              </Panel.Panel>
                            }
                          >
                            <AccordionContent model={new AccordionContentModel(e)} />
                          </SimpleAccordion>
                        </div>
                      );
                    })}
                  </Panel.Panel>
                </div>
              </div>
            )}
          </Observer>
        )}
      </Async.Resolved>
    </Async>
  );
});
