import React from 'react'
import { Services } from '../../../../../../../constants';
import { useAppService } from '../../../../../../../contexts/AppService';
import I18n from '../../../../../../../core/localization/I18n';
import { IUiAction, UiActionRenderers } from '../../../../../../../core/uiAction/IUiAction';
import { ITagsApi } from '../../../../../../../services/api/v1/tags/ITags.api';
import { SingleFormModel } from '../../../../../../change/forms/singleFormModel/SingleForm_model';
import { getImpactTagFormFields } from './ImpactTagsSidebar_formFields';

interface ManageImpactTagProps {
    organisationId: number;
    projectId: number;
    impact: FP.Entities.IImpact;
    closeFn: () => void;
}

export const ManageImpactTagForm: React.FC<ManageImpactTagProps> = (props) => {
    const appService = useAppService();
    const tagsProvider = appService.getService<ITagsApi>(Services.TagsApi);
    const { impact, closeFn, projectId, organisationId } = props;
    let formFields = getImpactTagFormFields(tagsProvider, organisationId, projectId, impact);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
        {
            id: "action1",
            label: I18n.t("phrases.done"),
            onAction: ev => {
                closeFn();
            },
            componentProps: {
                className: "ml-auto"
            },
            rendersIn: UiActionRenderers.BUTTON
        }
    ]


    formModel.formFields = formFields;
    formModel.actions = actions;

    return <div>{formModel.renderComponent()}</div>
}