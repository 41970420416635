import { AxiosRequestConfig } from "axios";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IFlightPathApiResponse } from "../../../../../services/api/BaseApiModel";
import { IHeatmapsApi } from "../../../../../services/api/v1/heatmaps/IHeatmaps.Api";
import { HeatmapSupportObject } from "../../organisationDashboard/models/dashboard_interfaces";
import { FullHeatmapData, FullPieData, IDrillThroughQuery } from "./Heatmap_utils";
import { SHOW_FILTER_MODAL } from "./modals/filters/HeatmapFilter_view";

export interface IHeatmapQuery {
  id: number;
  startDateMonth: number;
  startDateYear: number;
  tagIds: string;
}

interface HeatmapConfig {
  getSupportingData?: (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<HeatmapSupportObject>>;
  getHeatmapData?: (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<FullHeatmapData>>;
  getDrillThroughPieData?: (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<FullPieData>>;
  getUrlForPieChart?: (
    organisationId: number,
    projectId: number,
    month: number,
    year: number,
    itemIds?: string[]
  ) => string;
}

export class HeatmapModel {
  @observable currentMonth = new Date().getMonth();
  @observable currentYear = new Date().getFullYear();
  @observable.ref heatmapData: FullHeatmapData;
  @observable isLoading: boolean = true;
  @observable currentView: "business-areas" | "locations" | "projects" = "business-areas";
  appService: AppService;
  @observable tagFilters: FP.Entities.ITag[] = [];
  organisationId: number;
  parentId: number = 0;
  config: HeatmapConfig = {};
  heatmapProvider: IHeatmapsApi;
  httpProgress: IHttpProgressModel;
  modalService: IModalService;

  constructor(appService: AppService, organisationId: number, section: string) {
    this.appService = appService;
    this.currentView = section as any;
    this.heatmapProvider = this.appService.getService<IHeatmapsApi>(Services.HeatmapsApi);
    this.setConfig();
    this.organisationId = organisationId;
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
  }

  onMount = () => {
    this.loadHeatmapData();
  };

  showFilterModal = () => {
    SHOW_FILTER_MODAL(this.modalService, this.organisationId, this.tagFilters, this.setTagFilters);
  };

  @action
  removeTagFilterById = (id: number) => {
    const tagIndex = this.tagFilters.map(e => e.id).indexOf(id);
    let k = this.tagFilters.slice();
    k.splice(tagIndex, 1);
    this.setTagFilters(k);
    this.loadHeatmapData();
  };

  getTagFilterIdsAsString = () => {
    if (this.tagFilters.length === 0) return "";
    let queryString = this.tagFilters.map((e, i) => e.id).join("|");
    return queryString;
  };

  getQuery = (parentId, startMonth, startYear, tagIds) => {
    const query = {} as IHeatmapQuery;
    query.id = parentId;
    query.startDateMonth = startMonth + 1;
    query.startDateYear = startYear;
    query.tagIds = tagIds;
    return query;
  };

  loadHeatmapData = async () => {
    if (!this.isLoading) this.httpProgress.showTopProgressBarVisible();

    const query = this.getQuery(this.parentId, this.currentMonth, this.currentYear, this.getTagFilterIdsAsString());
    const result = await this.config.getHeatmapData(this.organisationId, query);
    this.httpProgress.hideTopProgressBarVisible();
    this.setHeatmapData(result.payload);
  };

  //#region setters

  @action
  setParentId = async parentId => {
    this.parentId = parentId;
    await this.loadHeatmapData();
  };

  @action
  setCurrentYear = async (year: number) => {
    this.currentYear = year;
    await this.loadHeatmapData();
  };

  @action
  setCurrentMonth = async (month: number) => {
    this.currentMonth = month;
    await this.loadHeatmapData();
  };

  @action
  setHeatmapData = (data: FullHeatmapData) => {
    this.heatmapData = data;
    this.isLoading = false;
  };

  @action
  setTagFilters = (items: any[]) => {
    this.tagFilters = items;
    this.loadHeatmapData();
  };

  setConfig = () => {
    const configs = {
      "business-areas": {
        getHeatmapData: this.heatmapProvider.getBusinessAreasHeatmap,
        getSupportingData: this.heatmapProvider.getBusinessAreasHeatmapSupportingData,
        getDrillThroughPieData: this.heatmapProvider.getBusinessAreaDrillThroughPieData,
        getUrlForPieChart: createImpactBusinessAreaUrl
      },
      locations: {
        getHeatmapData: this.heatmapProvider.getLocationsHeatmap,
        getSupportingData: this.heatmapProvider.getLocationsHeatmapSupportingData,
        getDrillThroughPieData: this.heatmapProvider.getLocationsDrillThroughPieData,
        getUrlForPieChart: createImpactLocationUrl
      },
      projects: {
        getHeatmapData: this.heatmapProvider.getProjectsHeatmap,
        getSupportingData: this.heatmapProvider.getProjectsHeatmapSupportingData,
        getDrillThroughPieData: this.heatmapProvider.getProjectDrillThroughPieData,
        getUrlForPieChart: createImpactProjectUrl
      }
    };
    this.config = configs[this.currentView];
  };
  //#endregion setters
}

const createImpactBusinessAreaUrl = (
  organisationId: number,
  projectId: number,
  month: number,
  year: number,
  itemIds?: string[]
) => {
  const baseUrl = window.location.origin;
  const organisationUrl = `/organisations/${organisationId}`;
  const projectUrl = `/projects/${projectId}`;
  const busAreas = itemIds && itemIds.length > 0 ? `businessArea=${itemIds?.join("|")}&` : "";
  const impactsQuery = `/impacts?${busAreas}withinMonth=${month}&withinYear=${year}`;
  const url = `${baseUrl}${organisationUrl}${projectUrl}${impactsQuery}`;
  return url;
};

const createImpactLocationUrl = (
  organisationId: number,
  projectId: number,
  month: number,
  year: number,
  itemIds?: string[]
) => {
  const baseUrl = window.location.origin;
  const organisationUrl = `/organisations/${organisationId}`;
  const projectUrl = `/projects/${projectId}`;
  const locations = itemIds && itemIds.length > 0 ? `location=${itemIds?.join("|")}&` : "";
  const impactsQuery = `/impacts?${locations}withinMonth=${month}&withinYear=${year}`;
  const url = `${baseUrl}${organisationUrl}${projectUrl}${impactsQuery}`;
  return url;
};

const createImpactProjectUrl = (
  organisationId: number,
  projectId: number,
  month: number,
  year: number,
  itemIds?: string[]
) => {
  const baseUrl = window.location.origin;
  const organisationUrl = `/organisations/${organisationId}`;
  const projectUrl = `/projects/${projectId}`;
  const impactsQuery = `/impacts?withinMonth=${month}&withinYear=${year}`;
  const url = `${baseUrl}${organisationUrl}${projectUrl}${impactsQuery}`;
  return url;
};
