import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class ProjectAudiencesHub extends GridBaseModel {
  hubName: string = "project-audiences";

  constructor() {
    super("project-audiences");
  }
  onLoadGridData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_PROJECT_AUDIENCE_DATA, callback);

  invokeLoadGridData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_PROJECT_AUDIENCE_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}
