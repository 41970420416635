export const ActiveUsersHandler = {
  removeAllClassNames: () => {
    const items: NodeListOf<Element> = document.querySelectorAll('div[class*="user-active-cell"]');

    items.forEach(el => {
      for (let i = el.classList.length - 1; i >= 0; i--) {
        const className = el.classList[i];
        if (className.startsWith("user-active-cell")) {
          el.classList.remove(className);
        }
      }
    });
  },
  styleSelectedCells: (users: any[]) => {
    users.forEach((e, i) => {
      const el = document.querySelector(`.ag-selectable-${e.cell}`);
      if (!el) return;
      el.classList.add("user-active-cell" + (e.isEditMode ? "--edit" : ""));
      el.classList.add("user-active-cell--" + (i + 1));
    });
  }
};
// DATE COMPARATOR FOR SORTING
export function dateComparator(date1, date2) {
  var date1Number = _monthToNum(date1);
  var date2Number = _monthToNum(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}

// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  // 29/08/2004 => 20040829
  return result;
}
