import React from "react";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Panel } from "../../../../../../components/ui/Panel";
import { CreateSuccessContent } from "../../../../../../components/ui/ToasterContent/CreateSuccessContent";
import I18n from "../../../../../../core/localization/I18n";
import { ModalService } from "../../../../../../core/modal/ModalService";
import {IToasterService} from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import { useAppService } from "../../../../../../contexts/AppService";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../constants";

export const SHOW_CONFIRM_IMPORT_MODAL = (
  appService: AppService,
  respondentsToUpload: FP.Entities.IStakeholderImportRow[],
  notUploadedCount: number,
  uploadFn: (respondentIds: number[]) => Promise<boolean>
) => {
  return new Promise<boolean>((resolve) => {
    const modalService = appService.getService<ModalService>(Services.AsideModalService);

    modalService.showConfirmDialog(
      <h1 className="mt-4">
        {I18n.t("pulse.organisationRespondents_uploadRows_heading")}
      </h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            {I18n.t("pulse.organisationRespondents_uploadRows_phrase", {
              count: respondentsToUpload.length,
            })}
          </div>
        </div>
        {notUploadedCount > 0 && (
          <div className="row mb-3">
            <div className="col-12">
              {I18n.t("pulse.organisationRespondents_noUploadRows_phrase", {
                count: notUploadedCount,
              })}
            </div>
          </div>
        )}
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE,
        },
      },
      async () => {
        modalService.hide();

        const res = await uploadFn(respondentsToUpload.map((e) => e.id));

        resolve(res);

        if (!res) return;
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.WARNING
    );
  });
};

export const SHOW_CONFIRM_DELETE_MODAL = (
  appService: AppService,
  respondentsToDelete: FP.Entities.IStakeholderImportRow[],
  notDeletedCount: number,
  deleteFn: (respondentIds: number[]) => Promise<boolean>
) => {
  return new Promise<boolean>((resolve) => {
    const modalService = appService.getService<ModalService>(Services.AsideModalService);

    modalService.showConfirmDialog(
      <h1 className="mt-4">
        {I18n.t("pulse.organisationRespondents_upload_deleteRows_heading")}
      </h1>,
      <div className="container-fluid">
        {respondentsToDelete.length > 0 && (
          <div className="row">
            <div className="col">
              {I18n.t(
                "pulse.organisationRespondents_upload_deleteRows_warning",
                {
                  count: respondentsToDelete.length,
                }
              )}
            </div>
          </div>
        )}
        {notDeletedCount > 0 && (
          <div className="row mt-2">
            <div className="col">
              {I18n.t(
                "pulse.organisationRespondents_upload_deleteRows_phrase",
                {
                  count: notDeletedCount,
                }
              )}
            </div>
          </div>
        )}
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE,
        },
      },
      async () => {
        modalService.hide();

        const res = await deleteFn(respondentsToDelete.map((e) => e.id));
        resolve(res);
        if (!res) return;
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_STAKEHOLDER_ROW_UPLOAD_SUCCESS = (appService: AppService, count: number) => {
  const toasterService = appService.getService<IToasterService>(Services.ToasterService);

  toasterService.showSuccessToast()
    .setContent(
      <CreateSuccessContent
        locale={I18n.t(
          "pulse.organisationRespondents_uploadRows_uploadSuccessful",
          { count: count }
        )}
      />
    )
    .startTimer(TOASTER_TOAST_TIME.NORMAL);
};

export const SHOW_STAKEHOLDER_ROW_DELETE_SUCCESS = (appService: AppService, count: number) => {
  const toasterService = appService.getService<IToasterService>(Services.ToasterService);

  toasterService.showSuccessToast()
    .setContent(
      <CreateSuccessContent
        locale={I18n.t(
          "pulse.organisationRespondents_uploadRows_deleteSuccessful",
          { count: count }
        )}
      />
    )
    .startTimer(TOASTER_TOAST_TIME.NORMAL);
};
