import * as React from "react";
import { AddStakeholderModel } from "./AddStakeholder_model";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { IF } from "../../../../components/hoc/If";
import { StakeholderDetails } from "../../stakeholders/StakeholderDetails";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { useCanEditOrganisationClaim } from "../../../../core/auth/authorise";
import { Panel, PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import { useParams } from "react-router-dom";
import { useCanEditField } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export interface AddStakeholderProps {
  model: AddStakeholderModel;
}

export const AddStakeholder: React.FC<AddStakeholderProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  const { projectId } = useParams<{ projectId: string }>();
  const canEditProjects = useCanEditField(PermissionFields.PROJECTS, organisationId, +projectId);
  const canEditOrganisation = useCanEditOrganisationClaim(organisationId);
  const routeParams = useParams<{ organisationId?: string; id?: string; tabId?: string }>();

  return (
    <div className={`add-stakeholder pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col p-2">
            <LinkButton
              size={Enums.UiSizes.MD}
              type={ButtonTypes.LINK}
              className="p-0"
              href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders`}
            >
              <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
              {I18n.t("phrases.backToStakeholders")}
            </LinkButton>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        onTabClicked={model.resetTabs}
        initialTab={routeParams.tabId && !isNaN(parseInt(routeParams.tabId)) ? parseInt(routeParams.tabId) : 0}
      >
        <Tab
          isHidden={!canEditOrganisation || !canEditProjects}
          title={<h2 className="mb-0">{I18n.t("phrases.createNew")}</h2>}
        >
          <IF condition={!model.stakeholder}>
            <div className="container-fluid">
              <Panel background={PanelBackgrounds.BG_LIGHT} className="row py-4 mb-4">
                <div className="col-lg-7">
                  <h1>
                    {I18n.t("phrases.create")} {I18n.t("phrases.stakeholder")}
                  </h1>
                  <p>
                    {I18n.t("phrases.stakIndExp")} {I18n.t("phrases.stakIndExample")}
                  </p>
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: I18n.t("phrases.createStakeholderInfo", {
                        orgId: routeParams.organisationId,
                        projId: routeParams.id
                      })
                    }}
                  ></p>
                </div>
              </Panel>

              <div className="row mb-4">
                <div className="col-lg-10">
                  <SingleForm model={model.createStakeholderFormModel} />
                </div>
              </div>
            </div>
          </IF>
        </Tab>
      </Tabs>

      <IF condition={!!model.stakeholder}>
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-12">
              <h2>{I18n.t("phrases.stakeholderProfile")}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <StakeholderDetails item={model.stakeholder} />
            </div>
          </div>
        </div>
      </IF>

      <IF condition={!!model.stakeholder}>
        <hr />
        <div className="container-fluid">
          <div className="row mt-5 mb-4">
            <div className="col-12">
              <h2>{I18n.t("phrases.projectProfile")}</h2>
              <p>{I18n.t("phrases.stakeholderProjectProfileDescription")}</p>
              <p>{I18n.t("phrases.stakeholderProjectProfileGuidance")}</p>
            </div>
          </div>

          {model.topProStakeholderFormModel && model.bottomProStakeholderFormModel && (
            <>
              <div className="row">
                <div className="col-lg-10">
                  <SingleForm model={model.topProStakeholderFormModel} />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-10">
                  <hr />
                </div>
              </div>

              <div className="row pt-3 mt-3">
                <div className="col-lg-10">
                  <SingleForm model={model.bottomProStakeholderFormModel} />
                </div>
              </div>
            </>
          )}
        </div>

        <hr />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12">
              <Button id="AddProjectStakeholderButton" className="float-right" onClick={model.addProjectStakeholder}>
                {I18n.t("phrases.addToProject")}
              </Button>
            </div>
          </div>
        </div>
      </IF>
    </div>
  );
});
