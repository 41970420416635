import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { ButtonTypes } from "../../../../../../../components/ui/Button";
import { Services } from "../../../../../../../constants";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { IHttpProgressModel } from "../../../../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { IActionsApi } from "../../../../../../../services/api/v1/actions/IActions.api";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getImpactActionFormFields } from "./ImpactActionSidebar_fields";

export class ImpactActionSidebarModel {
  @observable.ref formModel: SingleFormModel;
  @observable.ref actions: FP.Entities.IAction[] = [];
  @observable isLoading: boolean = true;
  appService: AppService;
  organisationId: number;
  projectId: number;
  actionsProvider: IActionsApi;
  impactsProvider: IImpactsApi;
  impact: FP.Entities.IImpact;
  actionCardActions: IUiAction<FP.Entities.IAction>[] = [];
  impactId: number;
  httpProgress: IHttpProgressModel;

  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number, impact: FP.Entities.IImpact) {
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.actionsProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.impact = impact;
    this.setActionCardActions();
    this.initForm();
  }

  onMount = async () => {
    await this.loadActions(this.impact.id);
  };

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactActionFormFields(
      this.actionsProvider,
      this.impactsProvider,
      this.organisationId,
      this.projectId,
      this.impact,
      this.loadActions,
      this.httpProgress
    );
  };

  loadActions = async (impactId: number) => {
    this.impactId = impactId;
    this.isLoading = true;
    let res = await this.impactsProvider.getActions(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  setActionCardActions = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );
    this.actionCardActions.push({
      id: "action1",
      label: I18n.t("phrases.view"),
      rendersIn: UiActionRenderers.HTML_ANCHOR,
      componentProps: {
        type: ButtonTypes.LINK
      },
      hrefFn: action => `/organisations/${this.organisationId}/projects/${action.projectId}/actions/${action.id}`
    });
    if (canEditActions) {
      this.actionCardActions.push({
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: async (ev, action: FP.Entities.IAction) => {
          await this.removeAction(this.impactId, action.id);
          this.loadActions(this.impactId);
        },
        componentProps: {
          type: ButtonTypes.LINK
        },
        rendersIn: UiActionRenderers.BUTTON
      });
    }
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.actions = actions;
    this.isLoading = false;
  };

  removeAction = async (impactId: number, actionId: number) => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.impactsProvider.removeAction(this.organisationId, this.projectId, impactId, actionId);
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;

    return res.payload;
  };
}
