import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Panel } from "../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { PieChart } from "../../../../../../../components/widgets/pie/pie";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { PieChartModel } from "./PieChart_model";

interface PieModalContentProps {
  getChartData: any;
  rowId: number;
  month: number;
  year: number;
  getTagFilterIdsAsString: any;
  getUrl: (organisationId: number, projectId: number, month: number, year: number, itemIds?: string[]) => string;
  rowName: string;
}

export const PieModalContent: React.FC<PieModalContentProps> = observer(
  ({ getChartData, rowId, month, year, getTagFilterIdsAsString, getUrl, rowName }) => {
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(
      () =>
        new PieChartModel(organisationId, getChartData, rowId, month, year, getTagFilterIdsAsString, getUrl, rowName)
    );
    const getPieColors = colorArray => {
      const result = [...colorArray];
      const zeroIndexHasValue = model.drillDownPieData[0].value > 0;
      const zeroIndexIsNoAccessProject = model.drillDownPieData[0].label === "Projects with no access";
      if (zeroIndexHasValue && zeroIndexIsNoAccessProject) result.unshift("#E5E5E5");
      return result;
    };

    useEffect(() => {
      model.onMount();
    }, [model]);

    if (model.isLoading) {
      return <PositionedSpinner />;
    }

    return (
      <div style={{ width: "100%", height: "70vh" }}>
        <div className="w-100 h-100 d-flex  justify-content-center align-items-center">
          <PieChart
            data={model.drillDownPieData}
            tooltipFn={PieTooltip}
            colors={getPieColors(pieColors)}
            hasLinkLabels={true}
            margin={{
              top: 120,
              right: 100,
              bottom: 100,
              left: 100
            }}
            onClickFn={model.onPieClick}
          />
        </div>
      </div>
    );
  }
);

const PieTooltip = props => {
  return (
    <Panel.Panel background={PanelBackgrounds.BG_WHITE} hasBorderRadius={true} className="p-2">
      <span className="mb-0 body">
        {props.label}: {props.value}
      </span>
    </Panel.Panel>
  );
};

const pieColors = [
  "#fdd886",
  "#ffc036",
  "#feae95",
  "#f49d4e",
  "#dc9700",
  "#f7820d",
  "#cc660a",
  "#966700",
  "#ed7650",
  "#ac5337",
  "#8a4100",
  "#452015"
];
