import React, { useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { B } from "../../../../core/util/BootstrapHelper";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { observer } from "mobx-react";
import { useAppService } from "../../../../contexts/AppService";
import { Services } from "../../../../constants";
import { INavigationModel } from "../../../../components/widgets/navigation/Navigation_model";
import { Animations } from "../../../../core/util/Animations";

export interface CreateNavProps {
  doRouteBack?: boolean;
  onBack?: () => void;
}

export const CreateNav: React.FunctionComponent<CreateNavProps> = observer(({ doRouteBack = false, onBack }) => {
  const appService = useAppService();
  const navigationModel = appService.getService<INavigationModel>(Services.NavigationModel);
  const cls = B().bl("create-nav");
  const history = useHistory();
  const links = useMemo(
    () => [
      { key: "programmes", label: I18n.t("entities.programme") },
      { key: "projects", label: I18n.t("entities.project") },
      { key: "stakeholders", label: I18n.t("entities.stakeholder") },
      { key: "audiences", label: I18n.t("entities.audience") },
      { key: "stakeholdergroups", label: I18n.t("entities.stakeholderGroup") },
      { key: "impacts", label: I18n.t("entities.impact") },
      { key: "impactGroups", label: I18n.t("entities.highLevelImpact") },
      { key: "actions", label: I18n.t("entities.action") }
    ],
    []
  );

  useOrganisationSwitch(true);

  return (
    <>
      <div className={`${cls.bem} ${Animations.FADE_IN} speed-4`}>
        {navigationModel.canGoBack ? (
          <div
            className="navigation__back navigation__link"
            onClick={() => {
              onBack && onBack();
              if (doRouteBack) {
                history.goBack();
                return;
              }
              navigationModel.onBack();
            }}
          >
            <Icon symbol={IconSymbols.ChevronLeft} className="mr-2" />
            <h5 className="text-uppercase mb-0">{I18n.t("phrases.back")}</h5>
          </div>
        ) : null}
        <h5 className={`${cls.el("title").bem} navigation__light-title text-uppercase`}>{I18n.t("phrases.create")}</h5>
        {links.map(e => {
          return (
            <NavLink
              data-testid={`create-${e.key}`}
              key={e.key}
              className={`navigation__link`}
              activeClassName="navigation__link--active"
              to={`/create/${e.key}`}
            >
              {e.label}
            </NavLink>
          );
        })}
      </div>
    </>
  );
});
