import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FileDrop } from "react-file-drop";
import I18n from "../../../../../../core/localization/I18n";
import NoRespondents from "../assets/NoRespondents.svg";
import { UploadFileModel } from "./UploadFile_model";
import React from "react";
import { useAppService } from "../../../../../../contexts/AppService";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { UiPositions } from "../../../../../../enums";
import { UploadFileWarning } from "./UploadFile_warning";
import { UploadFileSuccess } from "./UploadFile_success";

export interface IUploadFileProps {
    organisationId: number;
}

export const UploadFile: React.FC<IUploadFileProps> = observer((props: IUploadFileProps) => {
    const appService = useAppService();
    const [model] = useState(
        () => new UploadFileModel(appService, props.organisationId)
    );
    return <>
        <FileDrop
            className="organisation-stakeholders--upload--file-drop"
            onDrop={(files, event) =>
                model.onFileDrop(files, event)
            }
        >
            <div className="organisation-stakeholders--upload--file-drop--graphic">
                <img src={NoRespondents} alt="Upload Data" />
                <h3>{I18n.t("phrases.organisationStakeholders_dragAndDropHeading")}</h3>
                <p>{I18n.t("phrases.organisationStakeholders_dragAndDropP1")}</p>
                <p>{I18n.t("phrases.organisationStakeholders_dragAndDropP2")}</p>
                {model.isUploading && (
                    <div className="text-center">
                        {I18n.t("phrases.organisationStakeholders_fileUploading")}
                        <PositionedSpinner position={UiPositions.TOP} className="h-auto" />  
                    </div>
                )}
                {model.showSuccessMessage && (
                    <UploadFileSuccess />
                )}
                {model.showFileSizeWarning && (
                    <UploadFileWarning message={I18n.t("phrases.organisationStakeholders_emptyFileWarning")}></UploadFileWarning>
                )}
                {model.showFileTypeWarning && (
                    <UploadFileWarning message={I18n.t("phrases.organisationStakeholders_wrongFileType")}></UploadFileWarning>
                )}
            </div>
        </FileDrop>
    </>
});