import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { BaseModel } from "../../../../../../core/util/BaseModel";

export class ChangeImpactAssessmentModel extends BaseModel {
  appService: AppService;
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable allImpacts: FP.Entities.IImpactAssessmentDataGroup[];

  constructor(
    appService: AppService,
    data: FP.Entities.IImpactAssessmentDataGroup[],
    organisationId: number,
    projectId: number
  ) {
    super();
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.allImpacts = data;
  }
}
