import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ReactCheckbox } from "../../../../../../components/ui/_forms/Checkbox";
import { Heatmap } from "../../../../../../components/widgets/heatmap/Heatmap_view";
import { useAppService } from "../../../../../../contexts/AppService";
import I18n from "../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { FullHeatmapData } from "../Heatmap_utils";
import { HeatmapGridConfig, HeatmapGridModel } from "./HeatmapGrid_model";

export const HeatmapGrid: React.FC<
  { heatmapData: FullHeatmapData; setParentId: (id: number) => void; getTagFilterIdsAsString } & HeatmapGridConfig
> = props => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const [isValuesShown, setIsValuesShown] = useState(true);

  const [model] = useState(
    () =>
      new HeatmapGridModel(appService, organisationId, props.heatmapData, props.setParentId, {
        getTagFilterIdsAsString: props.getTagFilterIdsAsString,
        getHeatmapData: props.getHeatmapData,
        getDrillThroughPieData: props.getDrillThroughPieData,
        getUrlForPieChart: props.getUrlForPieChart
      })
  );

  return (
    <div className="row">
      <div className="col">
        <div className="heatmap__container--background mb-5">
          <HeaderRow toggleShowValue={() => setIsValuesShown(!isValuesShown)} />
          <div className="heatmap__container--content-flex-container">
            <div className="heatmap__container--content pb-5">
              <Heatmap
                showValues={isValuesShown}
                noDataLabel="No data"
                data={props.heatmapData}
                onRowClick={model.onRowClick}
                onCellClick={model.showCellPieChart}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ValueCheckbox: React.FC<{ toggleShowValue: () => void }> = observer(({ toggleShowValue }) => {
  return (
    <div className="heatmap__checkbox">
      <ReactCheckbox onChange={() => toggleShowValue()} defaultChecked={true} color="#58c8d1" />
      <p>{I18n.t("phrases.showCount")}</p>
    </div>
  );
});

const HeaderRow: React.FC<{ toggleShowValue: () => void }> = observer(({ toggleShowValue }) => {
  return (
    <div className="heatmap__header-row">
      <div className="heatmap__header-row--header">
        <div className="heatmap__main-title">
          <h2>{I18n.t("phrases.totalCountOfImpactsOrg").toUpperCase()}</h2>
        </div>
      </div>

      <ValueCheckbox toggleShowValue={toggleShowValue} />
    </div>
  );
});
