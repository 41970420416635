import { BaseModel } from "../../../../core/util/BaseModel";
import { RouteComponentProps } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../../constants";
import { ProjectsApi } from "../../../../services/api/v1/projects/Project.api";
import { IRecentProjectsService } from "../../../../services/local/recentProjectsService/IRecentProjectsService";
import { ProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { ActionsApi } from "../../../../services/api/v1/actions/Actions.api";
import { ActionVisualisationsHub } from "../../../../services/hubs/ActionVisualisationsHub/ActionVisualisations_hub";
import { ProjectAudienceVisualisationsHub } from "../../../../services/hubs/ProjectAudienceVisualisationsHub/ProjectAudienceVisualisations_hub";
import { ProjectStakeholderVisualisationsHub } from "../../../../services/hubs/ProjectStakeholderVisualisationsHub/ProjectStakeholderVisualisations_hub";
import { ImpactReportsHub } from "../../../../services/hubs/ImpactReportsHub/impactreports_hub";
import { ITimelineGroup, ITimelineItem, ITimelineMarker, TimeLineModel } from "../../../../components/widgets/timeline";
import moment from "moment";
import React from "react";
import { Tooltip } from "react-tippy";
import _ from "lodash";
import { TimelineItem } from "../../organisations/organisationDashboard/dashboardContentComponent/DashboardTimeline/components/TimelineItem";
import { SHOW_INFO_MODAL } from "./ProjectView_modals";
import { IModalService } from "../../../../core/modal/IModalService";

export class ProjectViewModel extends BaseModel {
  id: number;
  appService: AppService;
  routeProps: RouteComponentProps;
  actionsProvider: ActionsApi;
  projectsApi: ProjectsApi;
  projectTeamUserPermissionsApi: ProjectTeamUserPermissionsApi;
  @observable isLoadingProject: boolean = true;
  @observable isLoadingInsights: boolean = true;
  @observable.ref projectTeam: FP.Entities.IUserPermission[] = [];
  @observable.ref project?: FP.Entities.IProject = null;
  @observable.ref insights?: FP.Entities.IProjectInsights = null;
  @observable.ref actions: FP.Entities.IActionSummary[] = [];
  @observable.ref selectedMilestone?: FP.Entities.IMilestone;
  actionVisualisationsHub: ActionVisualisationsHub;
  stakeholdersHub: ProjectStakeholderVisualisationsHub;
  audienceHub: ProjectAudienceVisualisationsHub;
  impactHub: ImpactReportsHub;
  organisationId: number;
  timelineModel: TimeLineModel;
  @observable.ref stakeholders: FP.Entities.IProjectStakeholderSummary[] = [];
  @observable.ref audiences: FP.Entities.IProjectAudienceSummary[] = [];
  @observable.ref impacts: FP.Entities.IImpactSummary[] = [];
  modalService: IModalService;

  constructor(appService: AppService, projectId: number, orgId: number) {
    super();
    this.appService = appService;
    this.organisationId = orgId;
    this.projectsApi = this.appService.getService(Services.ProjectsApi);
    this.actionsProvider = this.appService.getService(Services.ActionsApi);
    this.id = projectId;
    this.actionVisualisationsHub = appService.getService(Services.ActionVisualisationsHub);
    this.stakeholdersHub = appService.getService(Services.ProjectStakeholderVisualisationsHub);
    this.audienceHub = appService.getService(Services.ProjectAudienceVisualisationsHub);
    this.impactHub = appService.getService(Services.ImpactReportsHub);
    this.timelineModel = new TimeLineModel();
    this.projectTeamUserPermissionsApi = this.appService.getService(Services.ProjectTeamUserPermissionsApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
  }

  @action.bound
  async load() {
    await Promise.all([this.loadProject(this.id), this.loadInsights(this.id)]);
  }

  onMount = async () => {
    await this.registerHubs();
  };

  onUnmount = () => {
    this.actionVisualisationsHub.stopConnection();
    this.stakeholdersHub.stopConnection();
    this.audienceHub.stopConnection();
    this.impactHub.stopConnection();
  };

  registerHubs = async () => {
    await this.registerActionSocketEvents();
    await this.registerStakeholderSocketEvents();
    await this.registerAudienceSocketEvents();
    await this.registerImpactSocketEvents();
  };

  registerActionSocketEvents = async () => {
    if (this.actionVisualisationsHub.isConnectionStarted === true) {
      await this.actionVisualisationsHub.stopConnection();
    }
    await this.actionVisualisationsHub.startConnection();

    this.actionVisualisationsHub.onLoadGridData(d => {
      this.setActions(d);
    });

    await this.actionVisualisationsHub.invokeLoadGridData(this.organisationId, this.id);
  };

  registerStakeholderSocketEvents = async () => {
    if (this.stakeholdersHub.isConnectionStarted === true) {
      await this.stakeholdersHub.stopConnection();
    }
    await this.stakeholdersHub.startConnection();

    this.stakeholdersHub.onData(d => {
      this.setStakeholders(d);
    });

    await this.stakeholdersHub.invokeLoadData(this.organisationId, this.id);
  };

  registerAudienceSocketEvents = async () => {
    if (this.audienceHub.isConnectionStarted === true) {
      await this.audienceHub.stopConnection();
    }
    await this.audienceHub.startConnection();

    this.audienceHub.onData(d => {
      this.setAudiences(d);
    });

    await this.audienceHub.invokeLoadData(this.organisationId, this.id);
  };

  registerImpactSocketEvents = async () => {
    if (this.impactHub.isConnectionStarted === true) {
      await this.impactHub.stopConnection();
    }
    await this.impactHub.startConnection();

    this.impactHub.onData(d => {
      this.setImpacts(d);
    });

    await this.impactHub.invokeLoadData(this.organisationId, this.id);
  };

  loadProject = async (id: number) => {
    const res = await this.projectsApi.getById(this.organisationId, id);
    if (!res || res.isError) return;
    this.setProject(res.payload);
    this.loadProjectTeam();
  };

  loadProjectTeam = async () => {
    const res = await this.projectTeamUserPermissionsApi.getAllUsersSimple(this.organisationId, this.id);
    if (!res || res.isError) return;
    this.setProjectTeam(res.payload);
  };

  loadInsights = async (projectId: number) => {
    this.isLoadingInsights = true;
    const res = await this.projectsApi.getInsights(this.organisationId, projectId);
    if (!res || res.isError) return;
    this.setInsights(res.payload);
    this.isLoadingInsights = false;
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
    const recentProjectsService = this.appService.getService<IRecentProjectsService>(Services.RecentProjectsService);
    if (project) {
      recentProjectsService.addProject({
        projectId: project.id,
        organisationId: project.organisationId,
        name: project.name
      });

      this.setTimelineDefaultDates([this.project]);
      this.setTimeLineItems([this.project]);
    }
  };

  setTimelineDefaultDates = (projects: FP.Entities.IProject[]) => {
    const proj = projects.map(e => {
      return {
        ...e,
        mStartDate: moment(e.startDate),
        mEndDate: moment(e.actualEndDate)
      };
    });
    const minDate = _.minBy(proj, e => e.mStartDate);
    const maxDate = _.maxBy(proj, e => e.mEndDate);
    const startDate = minDate.mStartDate;
    const endDate = maxDate.mEndDate;
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate.subtract(10, "days"), endDate.add(10, "days"));
  };

  setTimeLineItems = (projects: FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = projects.map(project => {
      return {
        id: project.id,
        group: project.id,
        title: project.name,
        start_time: moment(project.startDate),
        end_time: moment(project.actualEndDate),
        data: project
      };
    });

    const groups: ITimelineGroup[] = projects.map(project => {
      return {
        id: project.id,
        title:
          project.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{project.name}</small>}>
              {project.name}
            </Tooltip>
          ) : (
            project.name
          )
      };
    });

    let milestones = [];
    const markers: ITimelineMarker[] = milestones.map(milestone => {
      return {
        id: milestone.id,
        date: moment(milestone.deadline),
        title: milestone.name
      };
    });
    this.timelineModel.timelineMinRows = 1;
    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setMarkers(markers);
    this.timelineModel.sidebarWidth = 0;
    // this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = TimelineItem(this.organisationId, true);
    this.timelineModel.isLoading = false;
  };

  @action
  setProjectTeam = (projectTeam: FP.Entities.IUserPermission[]) => {
    this.projectTeam = projectTeam;
  };

  @action
  setMilestone = (milestone: FP.Entities.IMilestone) => {
    this.selectedMilestone = milestone;
  };

  @action
  setInsights = (insights: FP.Entities.IProjectInsights) => {
    this.insights = insights;
  };

  @action
  setActions = (actions: FP.Entities.IActionSummary[]) => {
    this.actions = actions;
  };

  @action
  setStakeholders = (stakeholders: FP.Entities.IProjectStakeholderSummary[]) => {
    this.stakeholders = stakeholders;
  };

  @action
  setAudiences = (audiences: FP.Entities.IProjectAudienceSummary[]) => {
    this.audiences = audiences;
  };

  @action
  setImpacts = (impacts: FP.Entities.IImpactSummary[]) => {
    this.impacts = impacts;
  };

  showInfoModal = () => {
    SHOW_INFO_MODAL(this);
  };
}
