import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IProgrammesApi } from "./IProgrammes.api";
import { IHttp } from "../../IHttp";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { AxiosRequestConfig } from "axios";

export class ProgrammesApi extends FilteredOrganisationApiModel<FP.Entities.IProgramme> implements IProgrammesApi {
  controller: string = "programmes";

  constructor(http: IHttp) {
    super(http, "programmes");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }
  //#region CRUD

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  async getDetailedByIdAsync(organisationId: number, programmeId: number, config?: AxiosRequestConfig) {
    const res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/detailed`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProgramme>;
    }
  }
  //#endregion

  //#region Projects
  getProjectsWithInsights = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/projectsInsights`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProject[]>;
    }
  };

  //#endregion

  //#region permissions

  addPermission = async (
    organisationId: number,
    programmeId: number,
    permissions: FP.Entities.IPermission,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IPermission>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/permissions`;
    let res = await this.http.post(url, permissions, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IPermission>;
    }
  };

  getPermissionUsers = async (
    organisationId: number,
    programmeId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/permissions`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
  };

  removeUserPermission = async (
    organisationId: number,
    programmeId: number,
    userId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/permissions/${userId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  getInheritUsers = async (
    organisationId: number,
    programmeId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/inherited-users`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
  };

  //#endregion

  getProgrammeDashboard = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/dashboard`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProgrammeDashboard>;
    }
  };
}
