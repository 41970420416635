import * as React from "react";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action, reaction } from "mobx";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { ImpactStakeholdersModel } from "../ImpactStakeholders/ImpactStakeholders_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { ImpactActionsModel } from "../impactActions/ImpactActions_model";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { IModalService } from "../../../../core/modal/IModalService";
import { ButtonTypes } from "../../../../components/ui/Button";
import { ICommentsApi } from "../../../../services/api/v1/comments/IComments.api";
import { ReviewModalComponentProps, ReviewModalContent, ReviewModalTitle, ReviewToastContent } from "../../../../components/ui/ReviewModal";


interface IImpactExtendedViewModelOptions {
  appService: AppService;
  projectId: number;
  impactId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}

export class ImpactExtendedViewModel extends DisposableModel {
  appService: AppService;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  projectId: number;
  commentsProvider: ICommentsApi;
  toasterService: IToasterService;
  impactId: number;
  confirmationService: IModalService;
  impactStakeholderModel: ImpactStakeholdersModel;
  impactActionsModel: ImpactActionsModel;
  httpProgress: IHttpProgressModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  returnUrl: string;
  queryStringService: IQueryStringService;
  @observable hasBeenReviewed: boolean;
  @observable reviewCommentInput: string;

  constructor({ appService, projectId, impactId, authUser, organisationId }: IImpactExtendedViewModelOptions) {
    super();
    this.appService = appService;

    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.commentsProvider = this.appService.getService<ICommentsApi>(Services.CommentsApi);
    this.confirmationService = this.appService.getService<IModalService>(Services.ConfirmationService);
    this.projectId = projectId;
    this.impactId = impactId;
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.organisationId = organisationId;
    this.impactStakeholderModel = new ImpactStakeholdersModel(
      this.appService,
      projectId,
      impactId,
      organisationId,
      false
    );
    this.impactActionsModel = new ImpactActionsModel(this.appService, organisationId, projectId, impactId, false);
    this.commentViewModel = new CommentListViewModel(appService, projectId, authUser, {
      placeholderText: I18n.t("placeholders.impactNotePlaceholder"),
      searchAttribute: "impactId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId,
    });

    this.revisionHistoryModel = new RevisionHistoryModel(appService, {
      entityId: this.impactId,
      projectId: this.projectId,
      historyType: "impacts",
      organisationId
    });

    this.queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);
    this.returnUrl = this.queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      this.queryStringService.getValue(QUERY_STRING_PARAMS.PREV_RETURN_URL) ||
      `/organisations/${organisationId}/projects/${this.projectId}/impacts`
    );

    this.installReactions();
  }

  @action.bound
  async load() {
    await this.loadImpact();
    this.impactStakeholderModel.setImpact(this.impact);
    this.commentViewModel.setConfig({
      id: this.impact.id,
      description: <h4 className="mb-0">{I18n.t("phrases.addANote")}</h4>
    });
  }

  @action
  loadImpact = async () => {
    this.isLoading = true;
    const res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, this.impactId);

    if (!res || res.isError) {
      return;
    }

    this.setImpact(res.payload);
    this.isLoading = false;
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  onUnmount = () => {
    this.dispose();
  };

  installReactions = () => {
    var d = reaction(
      () => {
        return this.impact;
      },
      (e, reaction) => {
        this.impactStakeholderModel.setImpact(e);
        // this.impactActionsModel.setImpact(e);
      }
    );

    this.addDisposer(d);
  };

  reviewImpact = async (impactId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      impactId: impactId,
      owner: null
    }
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);

    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={this.impact.name} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    if (!res || res.isError) return;

    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.loadImpact();
    this.confirmationService.hide();
  }

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  }

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewImpact(this.impactId, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };
}
