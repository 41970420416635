import React, { useState } from "react";
import I18n from "../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../singleFormModel/SingleForm_model";
import { SingleForm } from "../singleFormModel/SingleForm_view";

interface MultiFormProps {
  forms: SingleFormModel[];
  onFormSubmit: (result: any) => void;
}

export const MultiForm: React.FC<MultiFormProps> = props => {
  let [currentFormIdx, setCurrentFormIndex] = useState(0);

  let forms = props.forms.slice();
  forms = forms.map((e, i) => {
    if (forms.length - 1 > i) {
      e.actions = [
        {
          id: "action2",
          label: I18n.t("phrases.next"),
          onAction: async ev => {
            let s = await forms[i].validateFields();
            if (!s) {
              return;
            }
            setCurrentFormIndex(currentFormIdx + 1);
          },
          componentProps: {
            className: "ml-2"
          },
          rendersIn: UiActionRenderers.BUTTON
        }
      ];
    } else {
      e.actions = [
        {
          id: "action2",
          label: I18n.t("phrases.save"),
          onAction: async ev => {
            for (let i = 0; i < forms.length; i++) {
              let s = await forms[i].validateFields();
              if (!s) {
                return;
              }
            }
            let res: any = {};
            for (let i = 0; i < forms.length; i++) {
              let s = await forms[currentFormIdx].submit();
              res = { ...res, ...s };
            }

            props.onFormSubmit(res);
          },
          componentProps: {
            className: "ml-2"
          },
          rendersIn: UiActionRenderers.BUTTON
        }
      ];
    }
    return e;
  });

  let formModel: SingleFormModel = forms[currentFormIdx];
  return (
    <div className="multi-form">
      <SingleForm model={formModel} />
    </div>
  );
};
