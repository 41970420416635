import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { IMPACT_LEVEL_OPTIONS, PROGRESS_STATUS_OPTIONS, Services } from "../../../../../constants";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { EntityTypes, ImpactLevel } from "../../../../../enums";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { IProjectTeamUserPermissionsApi } from "../../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { ImpactsHub } from "../../../../../services/hubs/ImpactsHub/Impacts_hub";
import { GetImpactGridViewColumns } from "./ImpactGridView_columns";
import { getImpactGridModals } from "./modals/ImpactGridView_modals";
import { GetGridFns } from "../../../../../core/grids/GridFunctions";

export class ImpactGridViewModel extends BaseModel implements IGridModel {
  appService: AppService;
  impactsProvider: IImpactsApi;
  impactsHub: ImpactsHub;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  type: EntityTypes = EntityTypes.IMPACTS;
  organisationId: number;
  projectId: number;
  userCanViewActions: boolean;
  userCanViewStakeholders: boolean;

  @observable.ref projectTeamMembers: any[];

  constructor(appService: AppService, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    this.appService = appService;
    this.impactsProvider = appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactsHub = appService.getService<ImpactsHub>(Services.ImpactsHub);
    this.projectTeamUserPermissionsProvider = appService.getService<IProjectTeamUserPermissionsApi>(
      Services.ProjectTeamUserPermissionsApi
    );
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mapProgressStatuses();
    this.mapImpactLevels();
    this.userCanViewActions = PermissionsContext.canViewField(PermissionFields.ACTIONS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
  }

  onMount = async () => {
    await this.loadProjectTeamMembers();
    this.isLoading = false;
    // Promise.all([this.loadImpactTypes(), this.loadProjectTeamMembers()]).then(v => {
    //     this.isLoading = false;
    // })
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    const canEditImpacts = PermissionsContext.canEditField(
      PermissionFields.IMPACTS,
      this.organisationId,
      this.projectId
    );

    return GetImpactGridViewColumns({
      canEdit: canEditImpacts,
      organisationId: this.organisationId,
      projectId: this.projectId,
      projectTeamMembers: this.projectTeamMembers,
      impactLevels: this.impactLevels,
      progressStatuses: this.progressStatuses,
      gridFns: GetGridFns(this.impactsProvider, this.organisationId, this.projectId),
      modals: getImpactGridModals(this.appService, this.organisationId, this.projectId),
      userCanViewStakeholders: this.userCanViewStakeholders,
      userCanViewActions: this.userCanViewActions,
      pinned: "left"
    });
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  mapImpactLevels = () => {
    this.impactLevels = IMPACT_LEVEL_OPTIONS.filter(e => e.key !== ImpactLevel.UNKNOWN).map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.projectTeamMembers = res.payload;
  };
}
