import { observer } from "mobx-react";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IF } from "../../../../components/hoc/If";
import { Button, ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Tab, Tabs } from "../../../../components/ui/Tabs";
import { ConnectedUserAvatars } from "../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { useAppService } from "../../../../contexts/AppService";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { useIsOrganisationAdmin } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { GridView } from "../../../../core/grids/GridView_view";
import I18n from "../../../../core/localization/I18n";
import { EntityTypes, UiSizes } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../setup";
import { AudienceGridViewModel } from "./AudienceGridView/AudienceGridView_model";
import { AudiencesViewModel } from "./AudiencesView_model";
import { IconSymbols } from "../../../../components/ui/Icon";
import { ButtonIconDropdown } from "../../../../components/ui/ButtonIconDropdown";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { Services } from "../../../../constants";

export const AudiencesView: React.FC = observer(() => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId } = useParams<{ projectId: string }>();
  const wrapTextKey = `projects-${projectId}-${EntityTypes.AUDIENCES}-wrap-text`;
  const storageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
  const authUser = useFlightPathUser();
  const [hasFilters, setHasFilters] = useState(false);
  const [hasColumnChanges, setHasColumnChanges] = useState(false);
  const location = useHistory();
  const isAdmin = useIsOrganisationAdmin(organisationId);
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const filterRef = useRef(null);
  const [model, setModel] = useState(
    () => new AudiencesViewModel(appService, organisationId, +projectId, authUser, location)
  );

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (model.projectId + "" !== projectId) {
      model.stopConnection().then(() => {
        setModel(new AudiencesViewModel(appService, organisationId, +projectId, authUser, location));
      });
    }
  }, [projectId, appService, authUser, model.projectId, organisationId, location]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="audiences-view">
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={1}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount)}></Tab>
        <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
        />
      </Tabs>

      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex">
            <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
              <ButtonIcon
                type={ButtonTypes.PRIMARY}
                size={UiSizes.SM}
                onClick={model.showLinkStakeholderModal}
                symbol={IconSymbols.Plus}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.add")}
              </ButtonIcon>

              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={model.selectedAudiences.length === 0}
                symbol={IconSymbols.CommentPencil}
                onClick={model.selectedAudiences.length > 0 ? model.showConfirmReviewModal : null}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.review")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                symbol={IconSymbols.Trash}
                isDisabled={model.selectedAudiences.length === 0}
                onClick={model.selectedAudiences.length > 0 ? model.showStakeholderConfirmDeleteModal : null}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.remove")}
              </ButtonIcon>
              <ButtonIconDropdown
                iconSymbol={IconSymbols.Link}
                buttonProps={{
                  size: UiSizes.SM,
                  type: ButtonTypes.LINK,
                  className: "p-0 mr-2",
                  isDisabled: model.selectedAudiences.length === 0
                }}
                actions={model.actions}
              >
                {I18n.t("phrases.link")}
              </ButtonIconDropdown>
              {isAdmin && (
                <ButtonIcon
                  type={ButtonTypes.LINK}
                  size={UiSizes.SM}
                  isDisabled={model.selectedAudiences.length === 0}
                  onClick={model.selectedAudiences.length > 0 ? model.exportRows : null}
                  className="mr-2 align-self-start"
                  symbol={IconSymbols.Download}
                >
                  {I18n.t("phrases.export")}
                </ButtonIcon>
              )}
              <span className="vertical-line"></span>
              <ButtonIcon
                type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                size={UiSizes.SM}
                onClick={() => {
                  setIsTextWrapToggled(!isTextWrapToggled);
                  filterRef.current.toggleTextWrapper();
                }}
                symbol={IconSymbols.TextWrap}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.textWrap")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasFilters}
                onClick={() => filterRef.current.clearFilters()}
                className="mr-2 align-self-start"
                symbol={IconSymbols.ClearFilter}
              >
                {I18n.t("phrases.clearFilters")}
              </ButtonIcon>
              <ButtonIcon
                type={ButtonTypes.LINK}
                size={UiSizes.SM}
                isDisabled={!hasColumnChanges}
                symbol={IconSymbols.MonitorBack}
                onClick={() => filterRef.current.resetColumns()}
                className="mr-2 align-self-start"
              >
                {I18n.t("phrases.resetColumns")}
              </ButtonIcon>
            </CanEdit>
          </div>
          <div className="col">
            <ConnectedUserAvatars connectedUsers={model.connectedUsers} />
          </div>
        </div>
      </div>

      <IF condition={!model.isLoading}>
        <GridView
          context={{ deleteFn: model.deleteFieldData }}
          data={model.gridAudiences}
          users={model.connectedUsers}
          onCellClicked={model.updateUserSelectedCell}
          onCellEditModeChange={model.updateUserSelectedCell}
          onSelectionChanged={model.updateSelectedAudiences}
          modelClass={AudienceGridViewModel}
          onGridReady={model.onGridReady}
          filterHasChangedFn={val => {
            setHasFilters(val);
          }}
          columnOrderHasChangedFn={setHasColumnChanges}
          ref={filterRef}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});
