import { UiSizes } from "@flightpath/coreui/dist/enums";
import { PanelBackgrounds, PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { ProgressBar, ProgressBarWrapper } from "@flightpath/coreui/dist/ui/ProgressBar";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Panel } from "../../../../../../components/ui/Panel";
import { Pill, PillTypes } from "../../../../../../components/ui/Pill";
import React from "react";

export interface IUploadFileCardProps {
    stakeholderUploadFile: FP.Entities.IStakeholderUploadFile;
    onClickFn?: (id: number) => void;
    selectedFileId: number;
}

export const UploadFileCard: React.FC<IUploadFileCardProps> = observer((props:IUploadFileCardProps) => {
    const dateString = () =>{
        return moment(props.stakeholderUploadFile.createdAt).format("DD/MM/YYYY HH:mm");
    }

    const selectedClass = ():string => {
        return (props.selectedFileId === props.stakeholderUploadFile.id ? "selected": "");
    }

    const pillType = () => {
        if (props.stakeholderUploadFile.percentageUploaded === 100 ) return PillTypes.SUCCESS;
        return PillTypes.WARNING;
      };

    return <Panel.Panel
        hasShadow={true}
        background={PanelBackgrounds.BG_WHITE}
        hasBorderRadius={true}
        type={PanelTypes.OUTLINES}
        className={`p-3 mb-2 organisation-stakeholders--upload--file-list--file-card ${selectedClass()}`}
        onClick={() => props.onClickFn(props.stakeholderUploadFile.id)}
    >
        <div key={props.stakeholderUploadFile.id}>
            <h4 className="organisation-stakeholders--upload--file-list--file-card--file-name mt-0 mb-0">{props.stakeholderUploadFile.name}</h4>
            <div className="organisation-stakeholders--upload--file-list--file-card--uploaded-by">{props.stakeholderUploadFile.createdBy} - <span>{dateString()}</span></div>
            <div className="organisation-stakeholders--upload--file-list--file-card--progress-wrapper mt-3">
                <div className="organisation-stakeholders--upload--file-list--file-card--progress-wrapper--uploaded-status">
                    <Pill type={pillType()} className="text-nowrap pl-3 pr-3 pt-1 pb-1" size={UiSizes.SM}>
                        Uploaded: {props.stakeholderUploadFile.percentageUploaded}% ({props.stakeholderUploadFile.uploadedCount}/{props.stakeholderUploadFile.rowCount})
                    </Pill>
                </div>
                <div className="organisation-stakeholders--upload--file-list--file-card--progress-wrapper--progress-bar pl-1">
                    <ProgressBarWrapper>
                        <ProgressBar width={props.stakeholderUploadFile.percentageUploaded} />
                    </ProgressBarWrapper>
                </div>
                
            </div>
        </div>
        
    </Panel.Panel>
})