import { GetFilterParameterFromUrl } from "../../../../core/grids/GridQueryStringHelpers";
import * as GridFilterHelpers from "../../../../core/grids/GridFilterHelpers";

enum QueryStringParts {
  Influence = "influence",
  Impact = "impact",
  Sentiment = "sentiment",
  Commitment = "commitment",
  Receptiveness = "receptiveness"
}
const GetImpactParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Impact);
  return s ? decodeURIComponent(s) : null;
};

const GetInfluenceParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Influence);
  return s ? decodeURIComponent(s) : null;
};

const GetSentimentParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Sentiment);
  return s ? decodeURIComponent(s) : null;
};

const GetCommitmentParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Commitment);
  return s ? decodeURIComponent(s) : null;
};

const GetReceptivenessParameter = () => {
  var s = GetFilterParameterFromUrl(QueryStringParts.Receptiveness);
  return s ? decodeURIComponent(s) : null;
};

export const GetStakeholderURLFilters = () => {
  const impact = GetImpactParameter();
  const influence = GetInfluenceParameter();
  const sentiment = GetSentimentParameter();
  const commitment = GetCommitmentParameter();
  const receptiveness = GetReceptivenessParameter();

  let filter = {};
  if (impact !== null) {
    filter["impact"] = GridFilterHelpers.textEquals_Filter(impact);
  }
  if (influence !== null) {
    filter["influence"] = GridFilterHelpers.textEquals_Filter(influence);
  }
  if (sentiment !== null) {
    filter["sentiment"] = GridFilterHelpers.textEquals_Filter(sentiment);
  }
  if (commitment !== null) {
    filter["commitment"] = GridFilterHelpers.textEquals_Filter(commitment);
  }
  if (receptiveness !== null) {
    filter["receptiveness"] = GridFilterHelpers.textEquals_Filter(receptiveness);
  }

  return filter;
};
