import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../constants";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { ITreeDataItem } from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { IHttpProgressModel } from "../../../../../../../core/httpProgress/HttpProgress_model";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { IImpactTypeApi } from "../../../../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import {
  getImpactProcessesSidebarFormActions,
  getImpactProcessesSidebarFormFields
} from "./ImpactProcessesSidebar_fields";

export class ImpactProcessesSidebarModel extends BaseModel {
  appService: AppService;
  impactTypesProvider: IImpactTypeApi;
  impactsProvider: IImpactsApi;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  processes: ITreeDataItem[];
  formModel: SingleFormModel;
  canEdit: boolean;
  @observable impactId: number;

  constructor(
    appService: AppService,
    closeModalFn: () => void,
    organisationId: number,
    projectId: number,
    impactId: number
  ) {
    super();
    this.appService = appService;
    this.impactTypesProvider = this.appService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.loadSidebar(impactId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  getProcesses = async (impactId: number) => {
    let res = await this.impactsProvider.getProcessSelection(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.processes = res.payload;
  };

  @action
  loadSidebar = async (impactId: number) => {
    this.isLoading = true;
    this.impactId = impactId;
    await this.getProcesses(this.impactId);
    this.loadForm();
    this.isLoading = false;
  };

  loadForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactProcessesSidebarFormFields(this.processes);
    this.formModel.actions = getImpactProcessesSidebarFormActions(this.updateImpact, this.canEdit, this.closeModalFn);
  };

  updateImpact = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;

    await this.impactsProvider.updateProcesses(
      this.organisationId,
      this.projectId,
      this.impactId,
      res.businessProcesses
    );
  };
}
