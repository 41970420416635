import { ColDef } from "ag-grid-community";
import {
  colCreatedByName,
  colCreatedOn,
  colDescription,
  colEndDate,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colOwner,
  colProgressStatus,
  colRefNumber,
  colReviewedBy,
  colSelected,
  colStartDate,
  colTags,
  colUpdatedOn
} from "../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../core/grids/GridFunctions";
import { EntityTypes, ImpactField } from "../../../../../enums";
import {
  colActions,
  colAudiences,
  colBusinessAreas,
  colImpactGroups,
  colImpactLevel,
  colImpactTypes,
  colLocations,
  colMilestones,
  colProcesses,
  colProjectStakeholders,
  colTotalPeopleImpacted
} from "./ImpactGridView_baseColumns";
import { IImpactGridModals } from "./modals/ImpactGridView_modals";

export interface IGetImpactGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  projectTeamMembers: FP.Entities.IUser[];
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  gridFns: IGridFns;
  pinned: "left" | "right" | null;
  modals: IImpactGridModals;
  userCanViewActions: boolean;
  userCanViewStakeholders: boolean;
}

export const GetImpactGridViewColumns = (modelProps: IGetImpactGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  const modals = modelProps.modals;
  const canEdit = modelProps.canEdit;

  result.push(colSelected());

  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.IMPACTS, { pinned: modelProps.pinned })
  );
  result.push(
    colName(canEdit, canEdit && modelProps.gridFns.updateTextField, ImpactField.name, "", {
      width: 300,
      pinned: modelProps.pinned
    })
  );

  result.push(colDescription(canEdit, canEdit && modals.showDescriptionModalFn));
  result.push(
    colProgressStatus(
      canEdit,
      modelProps.progressStatuses,
      modelProps.gridFns.updateIdField,
      ImpactField.progressStatus,
      {}
    )
  );
  result.push(colStartDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ImpactField.startDate, {}));
  result.push(colEndDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ImpactField.endDate, {}));
  result.push(
    colOwner(canEdit, canEdit && modelProps.projectTeamMembers, modelProps.gridFns.updateIdField, ImpactField.owner, {})
  );
  result.push(colImpactTypes(canEdit, canEdit && modals.showTypesModalFn));
  result.push(colImpactLevel(canEdit, canEdit && modelProps.impactLevels, modelProps.gridFns.updateNumericField));
  result.push(colTotalPeopleImpacted(canEdit, canEdit && modelProps.gridFns.updateNumericField));
  result.push(colImpactGroups(canEdit, canEdit && modals.showGroupsModalFn));

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewStakeholders) {
    result.push(colProjectStakeholders(canEdit, canEdit && modals.showStakeholdersModalFn));
    result.push(colAudiences(canEdit, canEdit && modals.showAudiencesModalFn));
  }
  result.push(colBusinessAreas(canEdit, canEdit && modals.showBusinessAreasModalFN));
  result.push(colLocations(canEdit, canEdit && modals.showLocationsModalFN));
  result.push(colActions(canEdit, canEdit && modals.showActionModalFN));
  result.push(colMilestones(canEdit, canEdit && modals.showMilestonesModalFn));
  result.push(colProcesses(canEdit, canEdit && modals.showProcessesModalFn));
  result.push(colNoteCount(canEdit, canEdit && modals.showNotesModalFn));
  result.push(colTags(canEdit, canEdit && modals.showTagsModalFn));
  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());
  return result;
};
