import { Breadcrumb } from "../../../components/ui/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentOrganisation } from "../../../services/local/organisationContext/OrganisationContextModel";
import PermissionsContext from "../../../contexts/permissions/PermissionsContext";
import { useGetAllProjects, usePermissionUserOrganisations } from "../../../contexts/permissions/PermissionHooks";
import { MegaMenu, MegaMenuSections } from "./menus/MegaMenu";
import { parseUrlForProgrammeId, parseUrlForProjectId } from "../../../constants";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { ProjectNavList } from "./menus/ProjectNavList";
import { OrganisationNavList } from "./menus/OrganisationNavList";
import { UiPortalContent } from "../../../components/ui/UiPortal";
import { NAV_UI_PORTAL } from "./TopNavigationsProvider";
import AnimateHeight from "react-animate-height";
import { IF } from "../../../components/hoc/If";

export const NavBreadcrumbs: React.FC<any> = props => {
  const location = useLocation();
  const [, projectId] = parseUrlForProjectId(location.pathname);
  const [, programmeId] = parseUrlForProgrammeId(location.pathname);
  const myRef = useRef(null);
  const currentOrganisation = useCurrentOrganisation();
  const organisationId = currentOrganisation.id;
  const [section, setSection] = useState<MegaMenuSections>();
  let [isDrowpdownVisible, setIsDropdownVisible] = useState(false);
  let dropdownRef = useRef(null);

  const orgCount = Object.keys(usePermissionUserOrganisations()).length;

  const projectCount = Object.keys(useGetAllProjects(organisationId)).length;

  const orgBreadcrumbConfig = {
    ...GetOrganisationBreadcrumbConfig(currentOrganisation, orgCount),
    onClick: () => {
      setSection(null);
      if (orgCount <= 1 || orgCount < 15) return;
    }
  };

  const projectBreadcrumbConfig = {
    ...GetProjectBreadcrumbConfig(organisationId, projectId),
    onClick: () => {
      if (Object.keys(PermissionsContext.getAllProjects(organisationId)).length < 15) return;
      if (section === MegaMenuSections.PROJECT && myRef.current.isDropdownVisible) {
        setIsDropdownVisible(false);
        myRef.current.setIsDropdownVisible(false);
        setSection(null);
        return;
      }
      setIsDropdownVisible(true);
      myRef?.current.setIsDropdownVisible(true);
      setSection(MegaMenuSections.PROJECT);
    }
  };

  function handleClickOutside(event) {
    if (
      myRef.current.isDropdownVisible &&
      dropdownRef &&
      myRef &&
      myRef.current &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsDropdownVisible(false);
      myRef.current.setIsDropdownVisible(false);
    }
  }

  useEffect(() => {
    if (isDrowpdownVisible) {
      document.addEventListener("click", handleClickOutside);
    } else if (!isDrowpdownVisible) {
      document.removeEventListener("click", handleClickOutside);
    }

    return function removeListender() {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDrowpdownVisible]);

  return (
    <div className="nav-breadcrumps d-flex flex-row" tabIndex={0}>
      <UiPortalContent name={NAV_UI_PORTAL}>
        <AnimateHeight height={section && isDrowpdownVisible ? "auto" : 0}>
          <IF condition={isDrowpdownVisible}>
            <div ref={dropdownRef}>
              <MegaMenu section={section} projectId={projectId} programmeId={programmeId} />
            </div>
          </IF>
        </AnimateHeight>
      </UiPortalContent>

      <Breadcrumb
        {...orgBreadcrumbConfig}
        shouldHideOnClick={true}
        iconSymbol={section === MegaMenuSections.ORGANISATION ? IconSymbols.ChevronUp : IconSymbols.ChevronDown}
      >
        {orgCount > 1 && orgCount < 15 && <OrganisationNavList />}
      </Breadcrumb>

      <Breadcrumb
        ref={myRef}
        {...projectBreadcrumbConfig}
        hasLeftBorder={true}
        iconSymbol={
          section === MegaMenuSections.PROJECT && isDrowpdownVisible ? IconSymbols.ChevronUp : IconSymbols.ChevronDown
        }
      >
        {projectCount < 15 && <ProjectNavList projectId={projectId} />}
      </Breadcrumb>
    </div>
  );
};

const GetOrganisationBreadcrumbConfig = (organisation: FP.Entities.IOrganisation, organisationCount: number) => {
  return {
    title: "Organisation",
    className: "mr-3",
    name: organisation.name,
    shouldHideOnClick: true,
    imageSource: organisation.profileImageUrl,
    isDropdown: organisationCount > 1
  };
};

const GetProjectBreadcrumbConfig = (organisationId: number, projectId: number) => {
  const project = PermissionsContext.getProject(organisationId, projectId);
  let res = {
    className: "pl-3",
    title: "Project",
    isDropdown: true,
    shouldHideOnClick: false
  };
  if (!project)
    return {
      ...res,
      name: "Select a project"
    };
  return { ...res, name: project.name };
};
