import React from "react";
import { Link, useParams } from "react-router-dom";
import { UiPortal } from "../../../../components/ui/UiPortal";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export enum DashboardViews {
  HEATMAP,
  SUNBURST,
  TIMELINE
}

export const DASHBOARD_VIEW_RIGHT_SIDE = "dashbaord-view-right-side";

export const DashboardWrapper: React.FC<{ section: string }> = ({ children, section }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const startUrl = projectId
    ? `/organisations/${organisationId}/projects/${projectId}/organisations/visualisations`
    : `/organisations/${organisationId}/visualisations`;
  return (
    <div className="dashboard-view  pt-6">
      <div className="container-fluid">
        <div className={`organisation-dashboard__header row mb-5`}>
          <div className="d-flex align-items-end col-5">
            <div className="organisation-dashboard__title d-inline-block">
              <h1 className="mb-0">{I18n.t("phrases.organisationDashboard")}</h1>
            </div>
            <div className="organisation-dashboard__filters ml-3">
              <h4
                className={`border-right mb-0 d-inline-block pr-1 ${
                  section === "heatmap" ? "organisation-dashboard__filters--active" : ""
                } `}
              >
                <Link to={`${startUrl}/heatmap/projects`}>{I18n.t("phrases.heatmap")}</Link>
              </h4>
              <h4
                className={`border-right pr-1 pl-1 mb-0 d-inline-block ${
                  section === "sunburst" ? "organisation-dashboard__filters--active" : ""
                }`}
              >
                <Link to={`${startUrl}/sunburst`}>{I18n.t("phrases.sunburst")}</Link>
              </h4>
              <h4
                className={`pl-1 mb-0 d-inline-block ${
                  section === "timeline" ? "organisation-dashboard__filters--active" : ""
                }`}
              >
                <Link to={`${startUrl}/timeline`}>{I18n.t("phrases.timeline")}</Link>
              </h4>
            </div>
          </div>
          <div className="organisation-dashboard__controls col-7">
            <UiPortal name={DASHBOARD_VIEW_RIGHT_SIDE} />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
