export const DEFAULT_COLUMN_TYPES = {
  selectableClass: {
    cellClass: e => {
      return `ag-selectable-${e.colDef.field}-${e.data.id}`;
    }
  },
  deletableCell: {
    suppressKeyboardEvent(params) {
      let valForDeletion = params.data[params.colDef.field]
      if (params.editing || !valForDeletion) return;
      const isDeleteKey = params.event.keyCode === 46;
      const isBackspaceKey = params.event.keyCode === 8;
      if (!params.context) return;
      if (!isDeleteKey && !isBackspaceKey) return;
      params.colDef.editable = false;

      const id = params.data.id;
      params.context.deleteFn(id, params.colDef.field);
      return null;
    }
  }
};