import * as React from "react";
import { useParams } from "react-router-dom";
import I18n from "../localization/I18n";
import { useCanEditProgrammeClaim, useCanViewProgrammeClaim } from "../auth/authorise";
import { useCurrentOrganisationId } from "../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../util/Animations";
import { PermissionScope } from "../../enums";
import { PermissionFields } from "../../contexts/permissions/PermissionsTypes";
import {
  useCanContributeOrg,
  useCanEditField,
  useCanViewField,
  useOrganisationScope,
  useUserBelongsToOrgs
} from "../../contexts/permissions/PermissionHooks";

const CanEditOrganisationRoute: React.FunctionComponent<{ organisationId?: number | string }> = ({
  organisationId,
  children
}) => {
  const { organisationId: organisationIdParam } = useParams<{ organisationId?: string }>();
  const oId = organisationId || +organisationIdParam;
  const orgScope = useOrganisationScope(oId as number);
  const canEdit = orgScope >= PermissionScope.OWNER;
  return canEdit ? (children as any) : <NoAccessPage />;
};
const CanEditRoute: React.FC<{ field: PermissionFields; projectId?: number | string }> = ({
  field,
  projectId,
  children
}) => {
  const { projectId: projectIdParam } = useParams<{ projectId?: string }>();
  const organisationId = useCurrentOrganisationId();
  const pId = projectId || projectIdParam;
  const canView = useCanEditField(field, organisationId, parseInt(pId + ""));
  return canView ? (children as any) : <NoAccessPage />;
};

const CanViewRoute: React.FunctionComponent<{
  field: PermissionFields;
  projectId?: number | string;
  organisationId?: number | string;
}> = ({ projectId, field, children }) => {
  const { projectId: projectIdParam } = useParams<{ projectId?: string }>();
  const { organisationId: organisationIdParam } = useParams<{ organisationId?: string }>();

  const storedOrgId = useCurrentOrganisationId();
  const orgId = organisationIdParam || storedOrgId;

  const pId = projectId || projectIdParam;

  const canView = useCanViewField(field, +orgId, parseInt(pId + ""));
  return canView ? (children as any) : <NoAccessPage />;
};

export const HasOrganisationAccess: React.FC = props => {
  let hasOrgs: boolean = useUserBelongsToOrgs();
  if (hasOrgs === false) {
    return <NoAccessPage />;
  }

  return (props.children as any) || null;
};

const CanContributeOrgRoute: React.FunctionComponent<any> = props => {
  const currentOrganisationId = useCurrentOrganisationId();
  const canContribute = useCanContributeOrg(currentOrganisationId);
  if (canContribute) {
    return props.children;
  }

  return <NoAccessPage />;
};

const CanViewOrgRoute: React.FunctionComponent<any> = props => {
  const currentOrganisationId = useCurrentOrganisationId();
  const organisationScope = useOrganisationScope(currentOrganisationId);

  if (!organisationScope || organisationScope < PermissionScope.READER) {
    return <NoAccessPage />;
  }

  return props.children;
};

const NoAccessPage: React.FC<any> = () => {
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h3>{I18n.t("phrases.noViewAccess")}</h3>
        </div>
      </div>
    </div>
  );
};

const CanEditProgrammeRoute: React.FunctionComponent<{ programmeId?: number }> = ({ programmeId: pId, children }) => {
  const { programmeId } = useParams<{ programmeId?: string }>();
  const id = pId || programmeId;
  const canEdit = useCanEditProgrammeClaim(+id);
  return canEdit ? (children as any) : <NoAccessPage />;
};

const CanViewProgrammeRoute: React.FunctionComponent<{ programmeId?: number }> = ({ programmeId: pId, children }) => {
  const { programmeId } = useParams<{ programmeId?: string }>();
  const id = pId || programmeId;

  const canView = useCanViewProgrammeClaim(+id);

  return canView ? (children as any) : <NoAccessPage />;
};

export {
  NoAccessPage,
  CanEditProgrammeRoute,
  CanViewProgrammeRoute,
  CanViewOrgRoute,
  CanContributeOrgRoute,
  CanEditOrganisationRoute,
  CanEditRoute,
  CanViewRoute
};
