import React from "react";
import { ButtonIcon, ButtonTypes, LinkButton, LinkButtonIcon } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../components/ui/ReviewModal";
import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { Enums } from "../../../../../enums";
import { QUERY_STRING_PARAMS } from "../../../../../services/local/queryStringService/QueryStringService";
import { ActionBulkAddImpactsSidebar } from "./AcGridView/modals/actionBulkAddImpactsSidebar/ActionBulkAddImpactsSidebar_view";
import { ActionNotesSideBar } from "./AcGridView/modals/ActionNotesSidebar";
import { Animations } from "../../../../../core/util/Animations";
import { ImpactGrid } from "../ImpactGrid/ImpactGrid_view";

export const SHOW_NOTES_MODAL = (
  modalService: IModalService,
  organisationId: number,
  projectId: number,
  actionId: number
) => {
  const initUrl = `/organisations/${organisationId}/projects/${projectId}/actions`;
  modalService.show({
    showClose: false,
    title: (
      <div className="d-flex mt-6 mb-5">
        <LinkButton
          id="ViewDetailsButton"
          className="ml-auto mr-1"
          href={`${initUrl}/${actionId}`}
          onClick={modalService.hide}
        >
          {I18n.t("phrases.viewDetails")}
        </LinkButton>
        <LinkButtonIcon
          key="2"
          className="mr-1"
          type={ButtonTypes.OUTLINE_PRIMARY}
          iconSize={Enums.UiSizes.SM}
          symbol={IconSymbols.Pencil}
          onClick={modalService.hide}
          href={`${initUrl}/${actionId}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
        />
        <ButtonIcon
          key="3"
          type={ButtonTypes.OUTLINE_PRIMARY}
          iconSize={Enums.UiSizes.SM}
          symbol={IconSymbols.Close}
          onClick={modalService.hide}
        />
      </div>
    ),
    content: (
      <ActionNotesSideBar
        modalService={modalService}
        projectId={projectId}
        actionId={actionId}
        organisationId={organisationId}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_ACTION_DELETE_CONFIRM_MODAL = (
  modalService: IModalService,
  actionIds: number[],
  removeActionRange: (actionIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeActionFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmActionRangeRemove", { name: actionIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await removeActionRange(actionIds);
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_ACTION_REVIEW_CONFIRM_MODAL = (
  modalService: IModalService,
  actionIds: number[],
  reviewActionRange: (actionIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewActionRange(actionIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_CONFIRM_CREATION_MODAL = (modalService: IModalService, name): Promise<boolean> => {
  return new Promise(async resolve => {
    await modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.createSameNameAction")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        resolve(true);
      },
      () => {
        modalService.hide();
        resolve(false);
      }
    );
  });
};

export const SHOW_STAKEHOLDER_IMPACT_LINK_MODAL = (
  modalService: IModalService,
  projectId: number,
  actionsIds: number[]
) => {
  modalService.show({
    showClose: true,
    title: null,
    content: <ImpactGrid projectId={projectId} onAssignSuccess={modalService.hide} actionsIds={actionsIds} />,
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};
