import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../../constants";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { IImpactTypeApi } from "../../../../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { ILocationsApi } from "../../../../../../../services/api/v1/locations/ILocations.api";
import { IOrganisationsApi } from "../../../../../../../services/api/v1/organisations/IOrganisations.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getImpactLocationsSidebarFormActions, getImpactLocationsSidebarFormFields } from "./ImpactLocationsSidebar_fields";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";

export class ImpactLocationsSidebarModel extends BaseModel {
  appService: AppService;
  impactTypesProvider: IImpactTypeApi;
  impactsProvider: IImpactsApi;
  locationsProvider: ILocationsApi;
  organisationsProvider: IOrganisationsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  @observable locationTreeData: FP.Entities.ITreeDataItem[];
  closeModalFn: () => void;
  canEdit: boolean;
  @observable.ref formModel: SingleFormModel;


  constructor(
    appService: AppService,
    closeModalFn: () => void,
    organisationId: number,
    projectId: number,
    impactId: number
  ) {
    super();
    this.appService = appService;
    this.impactTypesProvider = this.appService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
    this.locationsProvider = this.appService.getService<ILocationsApi>(Services.LocationsApi);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.loadSidebar(impactId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(
      PermissionFields.IMPACTS,
      this.organisationId,
      this.projectId
    );
  }


  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  @action
  getOrgLocations = async (impactId: number) => {
    let res = await this.impactsProvider.getLocationSelection(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.locationTreeData = res.payload;
  }

  @action
  getImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpact(res.payload);
  }

  updateImpact = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res: any = await this.formModel.submit();
    if (!res) return;
    await this.impactsProvider.updateLocations(this.organisationId, this.projectId, this.impact.id, res.locations);
  };

  @action
  loadSidebar = async (impactId: number) => {
    this.isLoading = true;
    await this.getOrgLocations(impactId);
    await this.getImpact(impactId);
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactLocationsSidebarFormFields(this.locationTreeData);
    this.formModel.actions = getImpactLocationsSidebarFormActions(this.closeModalFn, this.updateImpact, this.canEdit)
    this.isLoading = false;
  };
}
