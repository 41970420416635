import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useParams } from "react-router-dom";
import I18n from "../../../../../../core/localization/I18n";
import { HeatmapFilters } from "../../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";

const dashboardFilters = [
  { key: HeatmapFilters.BUSINESSAREA, label: "phrases.businessAreas" },
  { key: HeatmapFilters.LOCATIONS, label: "phrases.locations" },
  { key: HeatmapFilters.PROJECTS, label: "phrases.projects" }
];

const activeFilterClassName = (element, activeFilter) => {
  let activeClass = "";
  if (element === activeFilter) {
    activeClass = "organisation-dashboard__filters--active";
  }
  return activeClass;
};

const isLastItem = index => {
  return index === dashboardFilters.length - 1;
};

const separator = index => {
  let separator = " ";
  if (!isLastItem(index)) separator = " | ";
  return separator;
};

export const HeatmapFilterMenu: React.FC = observer(() => {
  const { section, projectId } = useParams<{ section: string; projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  return (
    <ul className="organisation-dashboard__filters d-flex">
      {dashboardFilters.map((e, i) => {
        return (
          <li className="d-flex" style={{ cursor: "pointer" }}>
            <Link
              to={
                projectId
                  ? `/organisations/${organisationId}/projects/${projectId}/organisations/visualisations/heatmap/${e.key}`
                  : `/organisations/${organisationId}/visualisations/heatmap/${e.key}`
              }
            >
              <p className={`${activeFilterClassName(e.key, section)} ml-1 mb-0`}>{I18n.t(e.label)}</p>
            </Link>
            <p className="ml-1 mb-0">{separator(i)}</p>
          </li>
        );
      })}
    </ul>
  );
});
