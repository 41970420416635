import * as GridQueryStringHelpers from "../../../../../../core/grids/GridQueryStringHelpers";
import * as GridFilterHelpers from "../../../../../../core/grids/GridFilterHelpers";
import { QueryStringParts } from "../../../../../../enums";


export const getActionGridFilters = () => {
    const ragStatus: string = GridQueryStringHelpers.getParameter(QueryStringParts.RagStatus);

    let filter = {};

    if (ragStatus !== null) filter["ragStatus"] = GridFilterHelpers.textEquals_Filter(ragStatus);

    return filter;
}