import { ElementTypes } from "../../../../../../enums";

const BASE_URL = window.location.origin;

export const redirectToGrid = (datum: any, organisationId: number) => {
  const type = datum.data.elementType;
  const projectId = datum.data.parentId;
  switch (type) {
    case ElementTypes.STAKEHOLDER:
      goToStakeholders(organisationId, projectId, "stakeholders");
      break;
    case ElementTypes.AUDIENCE:
      goToStakeholders(organisationId, projectId, "audiences");
      break;
    case ElementTypes.UNKNOWN_LEVEL_IMPACT:
      goToImpacts(organisationId, projectId, "Unknown");
      break;
    case ElementTypes.LOW_LEVEL_IMPACT:
      goToImpacts(organisationId, projectId, "Low");
      break;
    case ElementTypes.MEDIUM_LEVEL_IMPACT:
      goToImpacts(organisationId, projectId, "Medium");
      break;
    case ElementTypes.HIGH_LEVEL_IMPACT:
      goToImpacts(organisationId, projectId, "High");
      break;
    case ElementTypes.UNKNOWN_RAG_ACTION:
      goToActions(organisationId, projectId, "Unknown");
      break;
    case ElementTypes.GREEN_RAG_ACTION:
      goToActions(organisationId, projectId, "Green");
      break;
    case ElementTypes.AMBER_RAG_ACTION:
      goToActions(organisationId, projectId, "Amber");
      break;
    case ElementTypes.RED_RAG_ACTION:
      goToActions(organisationId, projectId, "Red");
      break;
    case ElementTypes.COMPLETE_RAG_ACTION:
      goToActions(organisationId, projectId, "Complete");
      break;
    default:
      break;
  }
};

const goToStakeholders = (organisationId: number, projectId: number, entity: string) => {
  const organisationUrl = `/organisations/${organisationId}`;
  const projectUrl = `/projects/${projectId}`;
  const url = `${BASE_URL}${organisationUrl}${projectUrl}/${entity}`;
  window.open(url);
};

const goToImpacts = (organisationId: number, projectId: number, impactLevel: string) => {
  const organisationUrl = `/organisations/${organisationId}`;
  const projectUrl = `/projects/${projectId}`;
  const impactsQuery = `/impacts?level=${impactLevel}`;
  const url = `${BASE_URL}${organisationUrl}${projectUrl}${impactsQuery}`;
  window.open(url);
};

const goToActions = (organisationId: number, projectId: number, ragStatus: string) => {
  const organisationUrl = `/organisations/${organisationId}`;
  const projectUrl = `/projects/${projectId}`;
  const actionsQuery = `/actions?ragStatus=${ragStatus}`;
  const url = `${BASE_URL}${organisationUrl}${projectUrl}${actionsQuery}`;
  window.open(url);
};
