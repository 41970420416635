import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Panel } from "../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { PieChart } from "../../../../../../components/widgets/pie/pie";
import { useAppService } from "../../../../../../contexts/AppService";
import I18n from "../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { PieTooltip } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";
import { ReportingTitle } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingTitle";
import { SupportingDataConfig, SupportingHeatmapModel } from "./SupportingHeatmap_model";

type TotalImpactsProps = {
  totalImpactsData: number;
};

type DataConfidenceProps = {
  confidenceData: number;
  dataConfidenceDataArray: any[];
};

type ImpactLevelProps = {
  impactLevelData: any[];
};

export const SupportingHeatmapData: React.FC<SupportingDataConfig> = observer(props => {
  const organisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const { section } = useParams<{ section: string }>();
  const [model, setModel] = useState(
    () =>
      new SupportingHeatmapModel(appService, organisationId, {
        getTagFilterIdsAsString: props.getTagFilterIdsAsString,
        getSupportingData: props.getSupportingData,
        tags: props.tags
      })
  );

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    setModel(
      new SupportingHeatmapModel(appService, organisationId, {
        getTagFilterIdsAsString: props.getTagFilterIdsAsString,
        getSupportingData: props.getSupportingData,
        tags: props.tags
      })
    );
  }, [section, props.tags, appService, organisationId, props.getTagFilterIdsAsString, props.getSupportingData]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className="heatmap-support mb-5 row">
      <TotalImpacts totalImpactsData={model.supportingData.impactDataConfidence.totalOrganisationImpacts} />
      <ImpactLevelPie impactLevelData={model.impactLevelDataArray} />
      <DataConfidence
        dataConfidenceDataArray={model.dataConfidenceDataArray}
        confidenceData={model.supportingData.impactDataConfidence.dataConfidence}
      />
    </div>
  );
});

const impactLevelColors = ["#FDD886", "#FFC036", "#DC9700", "#966700"];

const noDataColor = ["#E5E5E5"];

const dataConfidenceColors = ["#58C8D1", "#E5E5E5"];
const DataConfidence: React.FC<DataConfidenceProps> = ({ confidenceData, dataConfidenceDataArray }) => {
  return (
    <div className="heatmap-support__confidence col-md-6">
      <Panel.Panel
        className="heatmap-support__confidence__panel"
        hasBorderRadius={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
      >
        <ReportingTitle className="heatmap-support__total__title" title={I18n.t("phrases.gapAnalysis")} />
        <div className="heatmap-support__confidence__flex-container">
          <div className="heatmap-support__confidence__score">
            <p className="heatmap-support__confidence__score__number">{confidenceData}</p>
            <p className="heatmap-support__confidence__score__percent">%</p>
          </div>
          <div className="heatmap-support__confidence__pie">
            <div style={{ width: "100%", height: "100%" }}>
              <PieChart
                data={dataConfidenceDataArray}
                colors={dataConfidenceColors}
                hasLegend={true}
                translateLegendX={120}
                tooltipFn={e => <PieTooltip {...e} />}
                margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
              />
            </div>
          </div>
        </div>
      </Panel.Panel>
    </div>
  );
};

const ImpactLevelPie: React.FC<ImpactLevelProps> = ({ impactLevelData }) => {
  if (!impactLevelData) return null;
  const data = parseImpactLevelData(impactLevelData);
  const hasData = data?.length > 1;
  return (
    <div className="heatmap-support__levels col-md-3">
      <Panel.Panel
        hasBorderRadius={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        className="heatmap-support__levels__panel"
      >
        <ReportingTitle title={I18n.t("phrases.impactLevel")} className="heatmap-support__total__title" />
        <div className="heatmap-support__levels__pie-container">
          <div style={{ width: "100%", height: "112px" }}>
            <PieChart
              data={data}
              colors={hasData ? impactLevelColors : noDataColor}
              hasLegend={true}
              translateLegendX={120}
              tooltipFn={e => hasData && <PieTooltip {...e} />}
              margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
            />
          </div>
        </div>
      </Panel.Panel>
    </div>
  );
};

const parseImpactLevelData = impactLevelData => {
  var isAllZero = true;
  for (let i = 0; i < impactLevelData.length; i++) {
    if (impactLevelData[i].value > 0) {
      isAllZero = false;
    }
  }
  return isAllZero ? [{ id: "noImpacts", label: "No impacts", value: 1, color: "#E5E5E5" }] : impactLevelData;
};

const TotalImpacts: React.FC<TotalImpactsProps> = ({ totalImpactsData }) => {
  return (
    <div className="heatmap-support__total col-md-3">
      <Panel.Panel
        hasBorderRadius={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        className="heatmap-support__total__panel"
      >
        <ReportingTitle className="heatmap-support__total__title" title={I18n.t("phrases.totalImpacts")} />
        <div className="heatmap-support__total__value-container">
          <div>
            <p className="heatmap-support__total__value">{totalImpactsData}</p>
          </div>
        </div>
      </Panel.Panel>
    </div>
  );
};
