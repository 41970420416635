import React from "react";
import { Services } from "../../../../../../../../constants";
import { useAppService } from "../../../../../../../../contexts/AppService";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { ITagsApi } from "../../../../../../../../services/api/v1/tags/ITags.api";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getStakeholderTagFormFields } from "./StakeholderTagsSidebar_formFields";
import { IModalService } from "../../../../../../../../core/modal/IModalService";
import { Button } from "../../../../../../../../components/ui/Button";

interface ManageStakeholderTagFormProps {
  organisationId: number;
  projectId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  closeFn: () => void;
  modalService: IModalService;
}

export const ManageStakeholderTagForm: React.FC<ManageStakeholderTagFormProps> = props => {
  const appService = useAppService();
  const tagsProvider = appService.getService<ITagsApi>(Services.TagsApi);

  const { projectStakeholder, closeFn, modalService, projectId, organisationId } = props;
  let formFields = getStakeholderTagFormFields(tagsProvider, organisationId, projectId, projectStakeholder);
  let formModel = new SingleFormModel();

  formModel.formFields = formFields;

  return (
    <div>
      {formModel.renderComponent()}
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
