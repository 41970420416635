import * as React from "react";
import { Button, ButtonTypes } from "../../../components/ui/Button";
import I18n from "../../../core/localization/I18n";
import { useIntercom } from "react-use-intercom";
import { useFlightPathUser } from "../../../setup";
import { Enums, UiSizes } from "../../../enums";
import { IIntercomLiveChatProps } from "./IntercomLiveChat";
import { Icon, IconSymbols } from "../../../components/ui/Icon";

interface IIntercomLiveChatButtonProps {
  intercomLiveChatProps: IIntercomLiveChatProps;
}

const IntercomLiveChatButton: React.FunctionComponent<IIntercomLiveChatProps> = props => {
  const { boot, show } = useIntercom();
  const user = useFlightPathUser();
  const environment = process?.env?.NODE_ENV;

  const bootWithProps = React.useCallback(() => {
    boot({
      userId: user.sub,
      name: user.firstName,
      email: user.email,
      customAttributes: {
        organisation: props.currentOrganisationName,
        organisationId: props.currentOrganisationId,
        environment: environment
      }
    });
    show();
  }, [
    boot,
    show,
    user.firstName,
    user.email,
    user.sub,
    props.currentOrganisationId,
    props.currentOrganisationName,
    environment
  ]);

  return (
    <>
      {props.showAsButton === "button" && (
        <Button id="IntercomChatTrigger" type={ButtonTypes.LINK} size={Enums.UiSizes.XS} onClick={bootWithProps}>
          {I18n.t("phrases.chatWithMember")}
        </Button>
      )}
      {props.showAsButton === "icon-label" && (
        <div className="d-flex flex-row align-items-center c-pointer" onClick={bootWithProps}>
          <Icon className="flex-column mr-1" symbol={IconSymbols.MessageSquare} size={UiSizes.MD} />{" "}
          <span className="flex-column">{I18n.t("phrases.chatWithMember")}</span>
        </div>
      )}
      {props.showAsButton === "icon" && (
        <div className="d-flex c-pointer" onClick={bootWithProps}>
          <Icon className="mx-1" symbol={IconSymbols.MessageSquare} size={UiSizes.MD} />
        </div>
      )}
    </>
  );
};

export default IntercomLiveChatButton;
