import { createBrowserHistory } from "history";
import { useCallback, useContext, useEffect, useState } from "react";
import { USER_IMAGE_PLACEHOLDER } from "./constants";
import { OktaAuth, OktaAuthOptions, Tokens } from "@okta/okta-auth-js";
import React from "react";
import { Async } from "react-async";
import { Loader } from "./components/ui/PositionedSpinner";
import { useClaim } from "./core/auth/authorise";
import { IsDev, IsStaging } from "./core/util/EnvironmentHelper";

const CLIENT_ID = window.appConfig.oauth_client_id;
export const CALLBACK_PATH = "/login_complete";
export let authService: OktaAuth;

const ISSUER = window.appConfig.oauth_authority; // `https://${OKTA_DOMAIN}/oauth2/default`;
const REDIRECT_URI = `${window.appConfig.baseUrl}${CALLBACK_PATH}`;
const SCOPES = "openid profile email groups offline_access";

const config: OktaAuthOptions = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  devMode: oktaInDevMode()
};

export const initAuthService = () =>{
  if(!authService){
    authService = new OktaAuth({
      ...config,
      tokenManager: {
        storage: "sessionStorage",
        autoRenew: true, 
        expireEarlySeconds: 1 * 60,
        autoRemove: false
      },
      storageManager: {
        token: {
          storageType: "sessionStorage",
          storageTypes: ["localStorage", "sessionStorage"],
        }
      }
    });
  }

  authService.tokenManager.on('error', (error:any) => {
    if (IsDev() || IsStaging()) {
      console.log("Token Error", error);
    }
  });

  authService.tokenManager.on('expired', (event:any) => {
    if (IsDev() || IsStaging()) {
      console.log(`Token expired ${new Date()}`);
    }
  });
}

initAuthService();

export async function getAppToken():Promise<string> {
  const idToken:Tokens = await authService.tokenManager.getTokens();
  return idToken.idToken ? idToken.idToken.idToken : idToken.accessToken ? idToken.accessToken.accessToken : null;
}

function oktaInDevMode(): boolean {
  return IsDev() || IsStaging();
}

export const appHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const UserContext = React.createContext<any>({});

export const UserProvider: React.FC = ({ children }) => {
  const getUser = useCallback(async() => {
    return authService.getUser();
  }, []);
  return (
    <Async promiseFn={getUser}>
      <Async.Loading>
        <Loader />
      </Async.Loading>
      <Async.Resolved>{user => <UserContext.Provider value={user}>{children}</UserContext.Provider>}</Async.Resolved>
      <Async.Rejected>{err => err.message}</Async.Rejected>
    </Async>
  );
};

export function useUserIdentity() {
  return useContext(UserContext);
}

function flightPathUserFromUserIdentity(userIdentity: any, profileImageUrl) {
  return {
    firstName: userIdentity.given_name,
    lastName: userIdentity.family_name,
    email: userIdentity.email,
    sub: userIdentity.sub,
    profileImageUrl:
      userIdentity.picture ||
      profileImageUrl ||
      USER_IMAGE_PLACEHOLDER(userIdentity.given_name, userIdentity.family_name)
  } as FP.Entities.IUser;
}

export function useFlightPathUser() {
  const userIdentity = useUserIdentity();
  const profileImageClaim = useClaim("profileImageUrl");
  const [flightPathUser, setFlightPathUser] = useState(() =>
    flightPathUserFromUserIdentity(userIdentity, profileImageClaim.value)
  );

  useEffect(() => {
    setFlightPathUser(flightPathUserFromUserIdentity(userIdentity, profileImageClaim.value));
  }, [userIdentity, profileImageClaim.value]);

  return flightPathUser;
}