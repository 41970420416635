import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../contexts/AppService";
import { Async } from "react-async";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectViewModel } from "./ProjectView_model";
import { ProjectMainPanel } from "./panels/ProjectMainPanel";

export interface IProjectView {
  model?: ProjectViewModel;
}

export const ProjectView: React.FunctionComponent<IProjectView> = observer(({ model: m }) => {
  const organisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const [model, setModel] = useState(() => m || new ProjectViewModel(appService, +projectId, organisationId));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (model.id !== +projectId || organisationId !== model.organisationId) {
      model.onUnmount();
      setModel(new ProjectViewModel(appService, +projectId, organisationId));
    }
  }, [model, projectId, organisationId, appService]);

  const load = useCallback(async () => {
    return await model.load();
  }, [model]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>{() => <ProjectMainPanel parentModel={model} />}</Async.Resolved>
    </Async>
  );
});
