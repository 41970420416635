import * as GridFilterHelpers from "../../../../core/grids/GridFilterHelpers";
import * as GridQueryStringHelpers from "../../../../core/grids/GridQueryStringHelpers";
import { getAllImpactLevelIndexes } from "../../../../enums";

const createDateFirstOfMonth = (month: number, year: number) => {
  return new Date(year, month - 1, 1);
};

const createDateLastOfMonth = (month: number, year: number) => {
  return new Date(year, month, 0);
};

const dateToString = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const createStartDateFilterDate = (month: number, year: number) => {
  //capture whole of month so everything below first of next month
  const nextMonth = month + 1;
  const date = createDateFirstOfMonth(nextMonth, year);
  return dateToString(date);
};

const createEndDateFilterDate = (month: number, year: number) => {
  const previousMonth = month - 1;
  const date = createDateLastOfMonth(previousMonth, year);

  return dateToString(date);
};

const isEarlierThan_Filter = (month: number, year: number) => {
  if (month > 0 && year > 0) {
    const date = createStartDateFilterDate(month, year);
    return GridFilterHelpers.dateLessThan_Filter(date);
  }
};

const isLaterThan_Filter = (month: number, year: number) => {
  if (month > 0 && year > 0) {
    const date = createEndDateFilterDate(month, year);
    return GridFilterHelpers.dateGreaterThan_Filter(date);
  }
};

export const getImpactGridFilters = () => {
  const location: string = GridQueryStringHelpers.getLocationParameter();
  const businessArea: string = GridQueryStringHelpers.getBusinessAreaParameter();
  const month: number = GridQueryStringHelpers.getWithinMonthParameter();
  const year: number = GridQueryStringHelpers.getWithinYearParameter();
  const level: string = GridQueryStringHelpers.getLevelParameter();
  const type: string = GridQueryStringHelpers.getTypeParameter();
  const owner: string = GridQueryStringHelpers.getOwnerParameter();

  let filter = {};
  if (location !== null) {
    filter["locationNames"] = GridFilterHelpers.textEquals_Filter(location);
  }

  if (businessArea !== null) {
    filter["businessAreaNames"] = GridFilterHelpers.textEquals_Filter(businessArea);
  }
  if (month > 0 && year > 0) {
    filter["startDate"] = isEarlierThan_Filter(month, year);
    filter["endDate"] = isLaterThan_Filter(month, year);
  }

  if (level !== null) {
    const levelIndexes = getAllImpactLevelIndexes(level);
    filter["impactLevel"] = GridFilterHelpers.textEquals_Filter(levelIndexes.join("|"));
  }

  if (type !== null) {
    filter["impactTypeNames"] = GridFilterHelpers.textIncludes_Filter(type);
  }

  if (owner !== null) {
    filter["ownerName"] = GridFilterHelpers.textEquals_Filter(owner);
  }

  return filter;
};
