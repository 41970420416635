import { QueryStringParts } from "../../enums";

const decodeParameter = (parameter: string) => {
  return parameter === null ? null : decodeURI(parameter);
};

export const getParameter = (query: QueryStringParts) => {
  const param = GetFilterParameterFromUrl(query);
  return decodeParameter(param);
}

const getQueryString = () => {
  return window.location.search;
};

const getQuery = (queryString: string, parameter: string) => {
  const params = new URLSearchParams(queryString);
  let result = params.get(parameter);
  return result === "null" ? null : result;
};

export const GetFilterParameterFromUrl = (parameterName: string) => {
  const queryString = getQueryString();
  return getQuery(queryString, parameterName);
};

export const getLocationParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Location);
  return decodeParameter(parameter);
};

export const getBusinessAreaParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.BusinessArea);
  return decodeParameter(parameter);
};

export const getWithinMonthParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.WithinMonth);
  return +parameter;
};

export const getWithinYearParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.WithinYear);
  return +parameter;
};

export const getGapAnalysisParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.GapAnalysis);
  return parameter;
};

export const getValueParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Value);
  return parameter;
};

export const getLevelParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Level);
  return parameter;
};

export const getTypeParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Type);
  return decodeParameter(parameter);
};

export const getOwnerParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Owner);
  return decodeParameter(parameter);
};
