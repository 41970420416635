import { IHttp } from "../../IHttp";
import { IActionTypeApi } from "./IActionType.api";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";

export class ActionTypeApi extends FilteredOrganisationApiModel<FP.Entities.IActionType> implements IActionTypeApi {
  controller: string = "action-types";

  constructor(http: IHttp) {
    super(http, "action-types");

    // this.url = `${this.http.url}/${this.version}/organisations`;
  }
  async getByOrganisationId(
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IActionType[]>> {
    const url = `${this.url}/${organisationId}/${this.controller}`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IActionType[]>;
    }
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
