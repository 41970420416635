import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { Services } from "../../../../constants";
import { IModalService } from "../../../../core/modal/IModalService";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { getImpactActionFormFields } from "./ImpactActions_data";
import I18n from "../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Animations } from "../../../../core/util/Animations";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export class ImpactActionsModel extends BaseModel {
  appService: AppService;
  impactProvider: IImpactsApi;
  isRouteView: boolean;
  impactId: number;
  projectId: number;
  modalService: IModalService;
  httpProgress: IHttpProgressModel;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable isLoading: boolean = true;
  @observable toggle: "active" | "closed" = "active";
  @observable.ref impact: FP.Entities.IImpact;
  @observable.ref impactActions: FP.Entities.IAction[] = [];
  @observable.ref filteredActions: FP.Entities.IAction[] = [];
  actionCardActions: IUiAction<FP.Entities.IAction>[] = [];
  organisationId: number;
  isFromGridView: boolean;

  constructor(
    appService: AppService,
    organisationId: number,
    projectId: number,
    impactId: number,
    isRouteView: boolean = true,
    isFromGridView: boolean = false
  ) {
    super();

    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.isRouteView = isRouteView;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.impactId = impactId;
    this.isFromGridView = isFromGridView;
    this.setActionCardActions();
  }

  onMount = (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => {
    this.resetSearch();
  };

  loadActions = async (impactId: number) => {
    this.impactId = impactId;
    this.isLoading = true;
    let res = await this.impactProvider.getActions(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  setActionCardActions = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );
    this.actionCardActions.push({
      id: "action1",
      label: I18n.t("phrases.view"),
      rendersIn: UiActionRenderers.HTML_ANCHOR,
      componentProps: {
        type: ButtonTypes.LINK
      },
      hrefFn: action => `/organisations/${this.organisationId}/projects/${action.projectId}/actions/${action.id}`
    });
    if (canEditActions) {
      this.actionCardActions.push({
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: async (ev, action: FP.Entities.IAction) => {
          await this.removeAction(this.impactId, action.id);
          this.loadActions(this.impactId);
        },
        componentProps: {
          type: ButtonTypes.LINK
        },
        rendersIn: UiActionRenderers.BUTTON
      });
    }
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.impactActions = actions;
    this.filterActions();
    this.isLoading = false;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterActions();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterActions();
    this.hideSearchMode();
  };

  @action
  filterActions = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredActions = this.impactActions.filter(action => {
        const lowerName = `${action.owner.firstName} ${action.owner.lastName}`.toLowerCase();
        const lowerContent = action.name.toLowerCase();
        const lowerRefNo = action.refNumber.toLowerCase();

        return (
          lowerName.includes(lowerSearch) || lowerContent.includes(lowerSearch) || lowerRefNo.includes(lowerSearch)
        );
      });
    } else {
      this.filteredActions = this.impactActions;
    }
  };

  removeAction = async (impactId: number, actionId: number) => {
    this.httpProgress.showOverlay();
    const res = await this.impactProvider.removeAction(this.organisationId, this.projectId, impactId, actionId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  showAddActionModal = () => {
    let formFields = getImpactActionFormFields(this.impactProvider, this.organisationId, this.projectId, this.impactId);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;

          this.httpProgress.showTopProgressBarVisible();
          await this.impactProvider.addActions(this.organisationId, this.projectId, this.impactId, res.actions);
          this.httpProgress.hideTopProgressBarVisible();

          this.loadActions(this.impactId)

          !this.isFromGridView && this.loadActions(this.impactId);
          this.modalService.hide();

        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: (
          <div className="pt-6">
            <h1 className="mt-6">
              {I18n.t("phrases.addActions")}
              {!this.isFromGridView && <LinkButton
                type={ButtonTypes.PRIMARY}
                className="float-right"
                onClick={() => {
                  this.modalService.hideAll();
                }}
                href={`/organisations/${this.organisationId}/projects/${this.projectId}/actions/create?impactId=${this.impactId}`}
              >
                {I18n.t("phrases.createNewAction")}
              </LinkButton>}
            </h1>
          </div>
        ),
        content: <div className="container-fluid pt-2">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };
}
