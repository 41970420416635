import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { PositionedSpinner } from '../../../../../../../components/ui/PositionedSpinner';
import { SingleForm } from '../../../../../../change/forms/singleFormModel/SingleForm_view';
import { ImpactDescriptionSidebarModel } from './impactDescriptionSidebar_model';

interface ImpactDescriptionSidebarProps {
    model: ImpactDescriptionSidebarModel;
}

export const ImpactDescriptionSidebar: React.FC<ImpactDescriptionSidebarProps> = observer(({ model }) => {
    const { impact } = model;

    if (typeof (impact) === undefined) return;

    useEffect(() => {
        model.onMount();
    }, [model]);

    if (model.isLoading) return <PositionedSpinner />

    return <div className="container-fluid">
        <SingleForm model={model.formModel} />
    </div>
})