import { Panel } from "@flightpath/coreui";
import { LinkButton } from "../../../../../components/ui/Button";
import React from "react";
import { Link } from "react-router-dom";
import { IF } from "../../../../../components/hoc/If";
import I18n from "../../../../../core/localization/I18n";

interface CountDisplayProps {
  count: number;
  label: string;
  subtitle: string;
  url: string;
  parmissionLabel?: string;
  canView?: boolean;
}

export const CountDisplay: React.FC<CountDisplayProps> = props => {
  return (
    <Panel.Panel className={`count-display p-2 ${!props.canView ? "count-display--disabled" : ""}`}>
      <h2 className="mb-4 text-center">{props.label}</h2>
      <IF condition={!props.canView}>
        <h4 className="text-center px-3">{props.parmissionLabel}</h4>
      </IF>
      <IF condition={props.count === 0 && props.canView}>
        <LinkButton href={props.url}>{I18n.t("phrases.start")}</LinkButton>
      </IF>
      <IF condition={props.count > 0 && props.canView}>
        <Link to={props.url}>
          <div className="display-xxl text-center">{props.count}</div>
          <h4 className="text-center">{props.subtitle}</h4>
        </Link>
      </IF>
    </Panel.Panel>
  );
};
