import { action, observable } from 'mobx';
import { AppService } from 'strikejs-app-service';
import { Services } from '../../../../../../../constants';
import { IHttpProgressModel } from '../../../../../../../core/httpProgress/HttpProgress_model';
import { BaseModel } from '../../../../../../../core/util/BaseModel';
import { IImpactsApi } from '../../../../../../../services/api/v1/impacts/IImpacts.api';
import { IImpactTypeApi } from '../../../../../../../services/api/v1/impactTypes/IImpactTypes.api';

export class ImpactTypesSidebarModel extends BaseModel {
    appService: AppService;
    impactTypesProvider: IImpactTypeApi;
    impactsProvider: IImpactsApi;
    httpProgress: IHttpProgressModel;
    @observable isLoading: boolean = true;
    @observable.ref impact: FP.Entities.IImpact;
    organisationId: number;
    projectId: number;
    closeModalFn: () => void;

    constructor(appService: AppService, closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
        super();
        this.appService = appService;
        this.impactTypesProvider = this.appService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
        this.organisationId = organisationId;
        this.projectId = projectId;
        this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi)
        this.loadImpact(impactId);
        this.closeModalFn = closeModalFn;
    }

    @action
    setImpact = (impact: FP.Entities.IImpact) => {
        this.impact = impact;
        this.isLoading = false;
    };

    @action
    loadImpact = async (impactId: number) => {
        this.isLoading = true;
        let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
        if (!res || res.isError) return;

        this.setImpact(res.payload);
    };
}