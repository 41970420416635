import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { DashboardWrapper } from "../DashboardWrapper";
import { ReportingBar } from "./cards/ReportingBar";
import { DashboardSunburstChart } from "./dashboardSunburst/DashboardSunburstChart";
import { SunburstModel } from "./Sunburst_model";

export const DashboardSunburstContent: React.FC<any> = observer(({ sectionView, setSectionView }) => {
  const organisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const [model] = useState(() => new SunburstModel(appService, organisationId));

  const projectCount = model.sunburst?.children.length;

  return (
    <DashboardWrapper section="sunburst">
      <div className="container-fluid pb-4">
        <ReportingBar
          actionRagCounts={model.actionRagCounts}
          impactLevelCounts={model.impactLevelCounts}
          projectCount={projectCount}
          isLoading={model.isLoading}
        />

        <div className="row pt-4">
          <div className="col">
            <DashboardSunburstChart sunburst={model.sunburst} organisationId={organisationId} />
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
});
