import { AppService } from "strikejs-app-service";
import { ImpactsHub } from "../../../../../services/hubs/ImpactsHub/Impacts_hub";
import { Services } from "../../../../../constants";
import { action, observable } from "mobx";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";

export class ImpactGridModel {
  appService: AppService;
  projectId: number;
  impactsHub: ImpactsHub;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable gridImpacts: FP.Entities.IImpactSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedImpacts: number[] = [];
  gridApi: GridApi;
  impactsProvider: IImpactsApi;
  actionIds: number[];
  searchText: string;

  /**
   *
   */
  constructor(
    appService: AppService,
    projectId: number,
    organisationId: number,
    authUser: FP.Entities.IUser,
    actionIds: number[]
  ) {
    this.appService = appService;
    this.projectId = projectId;
    this.impactsHub = this.appService.getService<ImpactsHub>(Services.ImpactsHub);
    this.impactsProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.organisationId = organisationId;
    this.actionIds = actionIds;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.impactsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.impactsHub.isConnectionStarted === true) {
      await this.impactsHub.stopConnection();
    }
    await this.impactsHub.startConnection();

    this.impactsHub.onData(d => {
      this.setImpactRowData(d);
    });

    await this.impactsHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  @action
  setImpactRowData = impacts => {
    this.gridImpacts = impacts;
    this.isLoading = false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setQuickFilter(this.searchText);
    }
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedImpacts = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  assignImpacts = async () => {
    return await this.impactsProvider.addBulkActionsToImpacts(
      this.organisationId,
      this.projectId,
      this.selectedImpacts,
      this.actionIds
    );
  };
}
