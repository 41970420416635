// export { ProgrammeNavElement } from "@flightpath/coreui/dist/ui/ProgrammeNavElement";
import React from "react";
import { Link } from "react-router-dom";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";
import { Icon, IconSymbols } from "../../ui/Icon";

export interface ProgrammeNavElementProps {
  name: string;
  url: string;
  isActive: boolean;
}

const ActiveIcon: React.FC<any> = ({ className, url, icon }) => {
  return (
    <Link className={className} to={url}>
      {icon}
    </Link>
  );
};

const InactiveIcon: React.FC<any> = ({ className, icon }) => {
  return <div className={`${className}`}>{icon}</div>;
};

export const NavIcon: React.FC<any> = ({ isActive, className, url, icon }) => {
  if (isActive) return <ActiveIcon className={className} url={url} icon={icon} />;
  return <InactiveIcon className={className} icon={icon} />;
};

export const ProgrammeNavElement: React.FC<ProgrammeNavElementProps> = ({ isActive, name, url }) => {
  const cls: string = B().bl("programme-nav-element").bem;
  return (
    <div className={`${cls} ${!isActive ? "programme-nav-element--disabled" : ""}`}>
      <div className="programme-nav-element__name">
        {isActive ? (
          <Link to={url}>
            <small className="mb-0">{name}</small>
          </Link>
        ) : (
          <small className="mb-0">{name}</small>
        )}
      </div>
      <div className="programme-nav-element__icons">
        <NavIcon
          className="programme-nav-element__icons__settings mr-2"
          isActive={isActive}
          url={url}
          icon={<Icon symbol={IconSymbols.Programmes} size={UiSizes.SM} />}
        />
      </div>
    </div>
  );
};
