import { UiActionRenderers } from '@flightpath/coreui/dist/uiAction/IUiAction';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { PositionedSpinner } from '../../../../../../../components/ui/PositionedSpinner';
import PermissionsContext from '../../../../../../../contexts/permissions/PermissionsContext';
import { PermissionFields } from '../../../../../../../contexts/permissions/PermissionsTypes';
import I18n from '../../../../../../../core/localization/I18n';
import { SingleFormModel } from '../../../../../../change/forms/singleFormModel/SingleForm_model';
import { SingleForm } from '../../../../../../change/forms/singleFormModel/SingleForm_view';
import { getImpactTypesSidebarFormFields } from './ImpactTypesSidebar_formFields';
import { ImpactTypesSidebarModel } from './ImpactTypesSidebar_model';

export interface ImpactTagsSidebarProps {
  model: ImpactTypesSidebarModel;
}

export const ImpactTypesSidebar: React.FC<ImpactTagsSidebarProps> = observer(({ model }) => {
  const { impact } = model;
  const canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, model.organisationId, model.projectId);

  const [formModel] = useState(new SingleFormModel())

  useEffect(() => {
    formModel.formFields = getImpactTypesSidebarFormFields
      (model.impactTypesProvider,
        canEdit,
        model.organisationId,
        model.projectId,
        impact);
  }, [formModel, impact, model.impactTypesProvider, model.organisationId, model.projectId, canEdit])

  formModel.actions = [
    {
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => model.closeModalFn(),
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ]

  if (model.isLoading) return <PositionedSpinner />;



  return <div className="container-fluid">
    <SingleForm model={formModel} />
  </div>
})