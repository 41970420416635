import React from "react";
import AppService from "../../../../../../contexts/AppService";
import { ErrorMessage } from "../../../../../../components/ui/ErrorMessage";
import { FORM_COL, ORGANISATION_PERMISSION_SCOPE_OPTIONS, Services } from "../../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { ITextFieldModel } from "../../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../../core/forms/controls/textField/TextField_init";
import { TextFieldModel } from "../../../../../../core/forms/controls/textField/TextField_model";
import { generateFormFieldsFromJson } from "../../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../../core/localization/I18n";
import { IUsersApi } from "../../../../../../services/api/v1/users/IUsers.api";

export const GetEmailFormFields = (org: FP.Entities.IOrganisation) => {
  const usersProvider = AppService.getService<IUsersApi>(Services.UsersApi);
  const fields = [];
  let orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: org && org.id + "",
    defaultValue: org && org.id + ""
  };

  let email: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "EmailAddress",
    label: <label htmlFor="EmailAddress">{I18n.t("forms.email")} *</label>,
    placeholder: I18n.t("placeholders.searchByEmailAddress"),
    validate: async function () {
      let self: TextFieldModel = this;
      let errorMessages: React.ReactNode[] = [];
      let res = true;

      if (Validations.IS_EMPTY(self.value)) {
        errorMessages.push(<ErrorMessage key="1">{I18n.t("validations.email")}</ErrorMessage>);
        res = false;
      }

      if (!Validations.IS_EMAIL(self.value)) {
        errorMessages.push(<ErrorMessage key="2">{I18n.t("validations.validEmail")}</ErrorMessage>);
        res = false;
      }

      const authoriseEmail = await usersProvider.getUserByEmail(org.id, {
        EmailAddress: self.value,
        organisationId: ""
      });

      if (authoriseEmail.payload.email.length === 0) {
        errorMessages.push(<ErrorMessage key="3">{I18n.t("validations.unauthorisedEmailDomain")}</ErrorMessage>);
        res = false;
      }

      self.errorMessage = errorMessages.length ? errorMessages : null;
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  fields.push(email);

  fields.push(orgId);

  const models = generateFormFieldsFromJson(fields);

  return models;
};

export const GetInviteUserFormFields = (org: FP.Entities.IOrganisation) => {
  let orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: org && org.id + "",
    defaultValue: org && org.id + ""
  };

  const firstName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "firstName",
    placeholder: I18n.t("placeholders.firstName"),
    label: <label htmlFor="name">{I18n.t("forms.firstName")} *</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="3">{I18n.t("validations.firstName")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  const lastName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "lastName",
    placeholder: I18n.t("placeholders.lastName"),
    label: <label htmlFor="lastName">{I18n.t("forms.lastName")} *</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.lastName")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  const permissionScope: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "permissionScope",
    label: <label htmlFor="permissionScope">{I18n.t("forms.permissionScope")} *</label>,
    placeholder: I18n.t("placeholders.permissionScope"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      className: "form-control"
    },
    options: ORGANISATION_PERMISSION_SCOPE_OPTIONS.map(option => ({ ...option, label: I18n.t(option.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IAutocompleteModel = this;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.permissionScope")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    extractValue: function () {
      return this.value?.key;
    }
  };
  const fields = [];
  fields.push(firstName);
  fields.push(lastName);
  fields.push(permissionScope);
  fields.push(orgId);

  const models = generateFormFieldsFromJson(fields);

  return models;
};

export const GetEditUserFormFields = (org: FP.Entities.IOrganisation, user: FP.Entities.IUser) => {
  let orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: org && org.id + "",
    defaultValue: org && org.id + ""
  };

  let userId = {
    ...INIT_TEXT_FIELD,
    key: "id",
    inputType: "hidden",
    value: user && user.sub + "",
    defaultValue: user && user.sub + ""
  };

  const permissionScope: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "permissionScope",
    label: <label htmlFor="permissionScope">{I18n.t("forms.permissionScope")} *</label>,
    placeholder: I18n.t("placeholders.permissionScope"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      className: "form-control"
    },
    options: ORGANISATION_PERMISSION_SCOPE_OPTIONS.map(option => ({ ...option, label: I18n.t(option.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IAutocompleteModel = this;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.permissionScope")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    extractValue: function () {
      return this.value?.key;
    }
  };

  const fields = [];

  fields.push(userId);

  fields.push(permissionScope);
  fields.push(orgId);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
