import React from "react";
import { AppService } from "strikejs-app-service";
import { ButtonTypes } from "../../../../../../../components/ui/Button";
import { Services } from "../../../../../../../constants";
import I18n from "../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { StakeholderType } from "../../../../../../../enums";
import { IImpactsApi } from "../../../../../../../services/api/v1/impacts/IImpacts.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { getImpactStakeholderFormFields } from "./getStakeholderFormFields";

interface AddStakeholderFormProps {
  appService: AppService;
  organisationId: number;
  projectId: number;
  impactId: number;
  stakeholderType: StakeholderType;
  setIsActive: (e?) => void;
  reloadImpactFn: (id: number) => void;
}

export const AddStakeholderForm: React.FC<AddStakeholderFormProps> = ({
  appService,
  reloadImpactFn,
  setIsActive,
  organisationId,
  projectId,
  impactId,
  stakeholderType
}) => {
  const impactsProvider = appService.getService<IImpactsApi>(Services.ImpactsApi);

  let formFields = getImpactStakeholderFormFields(
    impactsProvider,
    projectId,
    impactId,
    organisationId,
    stakeholderType
  );
  let formModel = new SingleFormModel();

  let actions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.cancel"),
      onAction: ev => {
        setIsActive(false);
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("phrases.save"),
      onAction: async ev => {
        let res = await formModel.submit();
        if (!res) return;
        await impactsProvider.addProjectStakeholder(organisationId, projectId, impactId, res.stakeholders);
        reloadImpactFn(impactId);
      },
      componentProps: {
        className: "ml-2"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  formModel.formFields = formFields;
  formModel.actions = actions;

  return (
    <div className="mt-2">
      <SingleForm model={formModel} />
    </div>
  );
};
