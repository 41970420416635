export class ImpactReportingViewChartProps {
  constructor(organisationId: number, projectId: number) {
    this.OrganisationId = organisationId;
    this.ProjectId = projectId;
  }

  OrganisationId: number;
  ProjectId: number;
  Data: any;
  DataConfidence: number = 0;
  IsLoading: boolean = true;

  setData = chartData => {
    this.Data = chartData.data;
    this.DataConfidence = chartData.dataConfidence;
    this.IsLoading = false;
  };

  gapAnalysisOnClickFunction = (gapAnalysisType: string) => (node, event) => {
    let url = `/organisations/${this.OrganisationId}/projects/${this.ProjectId}/impacts/?ga=${gapAnalysisType}&value=${node.id}`;
    window.open(url, "_blank");
  };

  drillThroughOnClickFunction = (drillThroughType: string) => (data, event) => {
    const url = `/organisations/${this.OrganisationId}/projects/${this.ProjectId}/impacts/?level=${
      data.id
    }&${drillThroughType}=${encodeURIComponent(data.indexValue)}`;
    window.open(url, "_blank");
  };
}
