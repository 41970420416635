import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import React, { useCallback, useEffect, useState } from "react";
import { Async } from "react-async";
import { useAppService } from "../../../../../contexts/AppService";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCanEditOrganisationClaim } from "../../../../../core/auth/authorise";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../core/localization/I18n";
import { TableView } from "../../../../../core/table/Table_view";
import { Animations } from "../../../../../core/util/Animations";
import { CustomPropertiesModel } from "./CustomProperties_model";
import { LinkButton, ButtonTypes } from "../../../../../components/ui/Button";
import { ShowIfOrganisationAdmin } from "../../../../../contexts/permissions/PermissionHelpers";

export const CustomProperties: React.FC<any> = props => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const canEditOrgnisation = useCanEditOrganisationClaim(organisation.id);

  const [model] = useState(new CustomPropertiesModel(appService, organisation.id));

  useEffect(() => {
    model.onMount(canEditOrgnisation);
    return model.onUnmount;
  }, [model, canEditOrgnisation]);

  const load = useCallback(async () => {
    await model.loadCustomProperties(organisation.id);
  }, [model, organisation]);

  return (
    <ShowIfOrganisationAdmin>
      <div
        className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}
        style={{ height: "100%", minHeight: "100vh" }}
      >
        <div className="row mb-4">
          <div className="col-lg-8">
            <h1>
              {organisation.name} | {I18n.t("phrases.manageCustomProperties")}
            </h1>
            <p>{I18n.t("phrases.manageCustomPropertiesDescription")}</p>
          </div>
          <div className="col-lg-4">
            <div className="d-flex justify-content-end">
              <LinkButton
                type={ButtonTypes.OUTLINE_PRIMARY}
                href={`/organisations/${organisation.id}`}
                className="float-right"
              >
                {I18n.t("phrases.closeSettings")}
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <LinkButton
              href={`/organisations/${organisation.id}/settings/customProperties/create`}
              id="ShowCustomPropertyFormButton"
            >
              {I18n.t("phrases.createCustomProperty")}
            </LinkButton>
          </div>
        </div>
        <Async promiseFn={load}>
          <Async.Loading>
            <PositionedSpinner />
          </Async.Loading>
          <Async.Resolved>
            <TableView model={model.tableModel} />
            <Pagination model={model.paginationModel} />
          </Async.Resolved>
        </Async>
      </div>
    </ShowIfOrganisationAdmin>
  );
};
