import { IProjectInfoCardModel } from "./ProjectInfoCard_model";
import { Panel } from "../../../ui/Panel";
import { Pill, PillTypes } from "../../../ui/Pill";
import { observer } from "mobx-react-lite";
import { B } from "../../../../core/util/BootstrapHelper";
import I18n from "../../../../core/localization/I18n";
import { Enums, ProgressStatus } from "../../../../enums";
import { Tooltip } from "react-tippy";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useCanViewField } from "../../../../contexts/permissions/PermissionHooks";
import { Timeline } from "../../TimelineWidget";
import { AvatarContent } from "../../../ui/AvatarContent";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { Link } from "react-router-dom";
import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import StakeholderMap from "./assets/Stakeholder_map.svg";
import ActionTimeline from "./assets/Action_timeline.svg";
import AudienceMap from "./assets/Audience_map.svg";
import ImpactAssessment from "./assets/Impact_assessment.svg";
import NoAccess from "./assets/NoAccess.svg";
import React from "react";

export interface ProjectInfoCardProps {
  model?: IProjectInfoCardModel;
  projectData: FP.Entities.IProgrammeProject;
}

type IProjectMetric = {
  key: string;
  label: string;
  value: string;
  tooltip?: React.ReactElement<any>;
};

const ProjectInfoCardView: React.FunctionComponent<ProjectInfoCardProps> = observer(({ model, projectData }: any) => {
  let projectTeam = projectData.projectTeam === null ? [] : projectData.projectTeam.split('|');
  //const { project } = model;

  //const projectInsights: FP.Entities.IProjectInsight | any = project;
  const cls = B().bl("project-info-card");
  let pillType = projectData.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;

  const organisationId = useCurrentOrganisationId();
  const canViewActions = useCanViewField(PermissionFields.ACTIONS, organisationId, projectData.id);
  const canViewImpacts = useCanViewField(PermissionFields.IMPACTS, organisationId, projectData.id);
  const canViewStakeholders = useCanViewField(PermissionFields.STAKEHOLDERS, organisationId, projectData.id);

  // const projectMetrics: IProjectMetric[] = [
  //   {
  //     key: "stakeholders",
  //     label: I18n.t("phrases.stakeholders"),
  //     value:
  //       projectInsights.stakeholderAudienceCount + projectInsights.stakeholderIndividualCount === 0 ||
  //         !canViewStakeholders
  //         ? "-"
  //         : `${projectInsights.stakeholderAudienceCount + projectInsights.stakeholderIndividualCount}`,
  //     tooltip: canViewStakeholders ? (
  //       <div>
  //         {projectInsights.stakeholderAudienceCount > 0 && (
  //           <p>
  //             {projectInsights.stakeholderAudienceCount} - {I18n.t("phrases.audience")}
  //           </p>
  //         )}
  //         {projectInsights.stakeholderIndividualCount > 0 && (
  //           <p className="mb-0">
  //             {projectInsights.stakeholderIndividualCount} - {I18n.t("phrases.individual")}
  //           </p>
  //         )}
  //       </div>
  //     ) : null
  //   },
  //   {
  //     key: "impacts",
  //     label: I18n.t("phrases.completedImpacts"),
  //     value:
  //       projectInsights.impactsTotal === 0 || !canViewImpacts
  //         ? "-"
  //         : `${projectInsights.impactsMitigated}/${projectInsights.impactsTotal}`
  //   },
  //   {
  //     key: "actions",
  //     label: I18n.t("phrases.completedActions"),
  //     value:
  //       projectInsights.actionsTotal === 0 || !canViewActions
  //         ? "-"
  //         : `${projectInsights.actionsClosed}/${projectInsights.actionsTotal}`
  //   }
  // ];

  return (
    <Panel.Panel
      type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      className={`${cls.bem}`}
    >
      <div className="row">
        <div className="col-1 pt-2">
          <Link to={`/organisations/${organisationId}/projects/${projectData.id}`}>
            <h1 className={`${cls.el("title").bem} text-truncate`}>{projectData.name}</h1>
            <Pill type={pillType} className="text-nowrap">
              {Enums.Translator.ProgressStatus(projectData.progressStatus)}
            </Pill>
          </Link>
        </div>
        <div className="col-2 pt-6">
          <Timeline
            startDate={new Date(projectData.startDate)}
            initialEndDate={new Date(projectData.endDate)}
            actualEndDate={new Date(projectData.endDate)}
          />
        </div>
        <div className="col-7">
          <div className="row">
            <div className="col-3">
              <Panel.Panel hasShadow={false} hasBorderRadius={true} background={PanelBackgrounds.BG_LIGHT} className="p-2 metric-card">
                <div className="metric-card--data">
                  {canViewStakeholders && (
                    <Link to={`/organisations/${organisationId}/projects/${projectData.id}/stakeholders`}>
                      <h2>Stakeholders</h2>
                      <div className="metric-card--data--count">{projectData.stakeholderCount}</div>
                    </Link>
                  )}
                  {canViewStakeholders === false && (
                    <div>
                      <h2>Stakeholders</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewStakeholders && (
                  <Link to={`/organisations/${organisationId}/projects/${projectData.id}/stakeholders/visualisations/1/0`}>
                    <img src={StakeholderMap} alt="Stakeholder Map" />
                  </Link>
                  )}
                  {canViewStakeholders === false && (
                    <img src={NoAccess} alt="No Access" />
                  )}
                </div>
              </ Panel.Panel>
            </div>
            <div className="col-3">
              <Panel.Panel hasShadow={false} hasBorderRadius={true} background={PanelBackgrounds.BG_LIGHT} className="p-2 metric-card">
                <div className="metric-card--data">
                  {canViewStakeholders && (
                    <Link to={`/organisations/${organisationId}/projects/${projectData.id}/audiences`}>
                      <h2>Audiences</h2>
                      <div className="metric-card--data--count">{projectData.audienceCount}</div>
                    </Link>
                  )}
                  {canViewStakeholders === false && (
                    <div>
                      <h2>Audiences</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewStakeholders && (
                  <Link to={`/organisations/${organisationId}/projects/${projectData.id}/stakeholders/visualisations/2/0`}>
                    <img src={AudienceMap} alt="Audience Map" />
                  </Link>
                  )}
                  {canViewStakeholders === false && (
                    <img src={NoAccess} alt="No Access" />
                  )}
                </div>
              </ Panel.Panel>
            </div>
            <div className="col-3">
              <Panel.Panel hasShadow={false} hasBorderRadius={true} background={PanelBackgrounds.BG_LIGHT} className="p-2 metric-card">
                <div className="metric-card--data">
                  {canViewImpacts && (
                    <Link to={`/organisations/${organisationId}/projects/${projectData.id}/impacts`}>
                      <h2>Impacts</h2>
                      <div className="metric-card--data--count">{projectData.impactCount}</div>
                    </Link>
                  )}
                  {canViewImpacts === false && (
                    <div>
                      <h2>Impacts</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewImpacts && (
                  <Link to={`/organisations/${organisationId}/projects/${projectData.id}/impacts/impact-assessment`}>
                    <img src={ImpactAssessment} alt="Audience Map" />
                  </Link>
                  )}
                  {canViewImpacts === false && (
                    <img src={NoAccess} alt="No Access" />
                  )}
                </div>
              </ Panel.Panel>
            </div>
            <div className="col-3">
              <Panel.Panel hasShadow={false} hasBorderRadius={true} background={PanelBackgrounds.BG_LIGHT} className="p-2 metric-card">
                <div className="metric-card--data">
                  {canViewActions && (
                    <Link to={`/organisations/${organisationId}/projects/${projectData.id}/actions`}>
                      <h2>Actions</h2>
                      <div className="metric-card--data--count">{projectData.actionCount}</div>
                    </Link>
                  )}
                  {canViewActions === false && (
                    <div>
                      <h2>Actions</h2>
                      <div className="metric-card--data--count">-</div>
                    </div>
                  )}
                </div>
                <div className="metric-card--chart">
                  {canViewActions && (
                  <Link to={`/organisations/${organisationId}/projects/${projectData.id}/actions/visualisations`}>
                    <img src={ActionTimeline} alt="Audience Map" />
                  </Link>
                  )}
                  {canViewActions === false && (
                    <img src={NoAccess} alt="No Access" />
                  )}
                </div>
              </ Panel.Panel>
            </div>
          </div>
        </div>
        <div className="col-2 project-team">
          <h4 className="pb-2">Project Team</h4>
          {projectTeam.map(x=>(
            <AvatarContent
            type="primary"
            avatarProps={{
              size: Enums.UiSizes.MD,
              imgSrc:
                x.split('::')[1] ||
                USER_IMAGE_PLACEHOLDER(
                  x.split('::')[0].split(' ')[0],
                  x.split('::')[0].split(' ')[1],
                )
            }}
          >
            
          </AvatarContent>
          ))}
        </div>
        
        {/* <div className="d-flex flex-wrap ml-auto">
          {projectMetrics.map(metric => {
            return (
              <Tooltip theme="light" html={metric.tooltip} key={metric.key}>
                <div key={metric.key} className={cls.el("metric").bem}>
                  <small className={`${cls.el("metric-heading").bem} d-block text-truncate mb-2`}>{metric.label}</small>
                  <div className="display-s strong">{metric.value}</div>
                </div>
              </Tooltip>
            );
          })}
        </div> */}
      </div>
    </Panel.Panel>
  );
});

export { ProjectInfoCardView };
