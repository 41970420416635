import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getStakeholderFormFields } from "../../forms/stakeholder/StakeholderFormSection_data";
import { Enums } from "../../../../enums";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Services } from "../../../../constants";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { ButtonTypes } from "../../../../components/ui/Button";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";

export class CreateStakeholderViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  organisationsProvider: IOrganisationsApi;
  stakeholdersProvider: IStakeholdersApi;
  localStorageService: ILocalStorageService;
  businessAreaProvider: IBusinessAreasApi;
  orgId: number;
  formModel: SingleFormModel;
  httpProgress: IHttpProgressModel;
  toasterService: IToasterService;
  stakeholderGroupProvider: IStakeholderGroupsApi;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps<any, any, any>) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.organisationsProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.stakeholdersProvider = appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.stakeholderGroupProvider = appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.formModel = this.getStakeholderFormModel();
  }

  onMount = () => {};

  onUnmount = () => {};

  getStakeholderFormModel = () => {
    const stakeholderFormModel = new SingleFormModel();
    const formFields = getStakeholderFormFields(
      this.orgId,
      this.organisationsProvider,
      this.businessAreaProvider,
      this.stakeholdersProvider
    );

    const actions: IUiAction<any>[] = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: async () => {
          await this.createStakeholder(true);
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        }
      },
      {
        id: "create",
        label: I18n.t("phrases.createStakeholder"),
        onAction: async ev => {
          await this.createStakeholder();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    stakeholderFormModel.formFields = formFields;
    stakeholderFormModel.actions = actions;
    return stakeholderFormModel;
  };

  createStakeholder = async (createAnother: boolean = false) => {
    const formRes = await this.formModel.submit();
    if (!formRes) return;

    formRes.audienceCount = -1;

    this.httpProgress.showOverlay();
    const res = await this.stakeholdersProvider.create(this.orgId, formRes as FP.Entities.IStakeholder);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    if (createAnother) {
      this.toasterService
        .showSuccessToast()
        .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.stakeholder") })}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      this.formModel.resetFields();
      return;
    }

    this.routeProps.history.push(`/organisations/${this.orgId}/stakeholders/${res.payload.id}`);
  };
}
