import { action, observable } from "mobx";
import { StakeholderUploadFileHub } from "../../../../../../services/hubs/StakeholderUploadFileHub/StakeholderUploadFile_hub";
import { Services } from "../../../../../../constants";
import { AppService } from "strikejs-app-service";

export class UploadFileListModel {
    appService: AppService;
    organisationId: number;
    @observable stakeholderUploadFiles: FP.Entities.IStakeholderUploadFile[];
    @observable isLoading: boolean = true;
    stakeholderUploadFileHub: StakeholderUploadFileHub;
    @observable connectedUsers: any[] = [];
    @observable selectedFileId: number = 0;
    authUser: FP.Entities.IUser;
    @observable isComponentMounted: boolean = false;

    constructor(appService: AppService, organisationId: number, authUser: FP.Entities.IUser) {
        this.appService = appService;
        this.organisationId = organisationId;
        this.stakeholderUploadFileHub = this.appService.getService<StakeholderUploadFileHub>(Services.StakeholderUploadFileHub);
        this.authUser = authUser;
    }

    onMount = async () => {
        await this.registerSocketEvents();
    };

    onUnmount = () => {
        this.stopConnection();
    };

    registerSocketEvents = async () => {
        if (this.stakeholderUploadFileHub.isConnectionStarted === true) {
            await this.stakeholderUploadFileHub.stopConnection();
        }

        await this.stakeholderUploadFileHub.startConnection();

        this.stakeholderUploadFileHub.onUserJoined((d) => {
            this.setConnectedUsers(d);
        });

        this.stakeholderUploadFileHub.onData((d) => {
            this.setStakeholderUploadFileData(d);
        });

        this.stakeholderUploadFileHub.onUserCellSelected((d) => {
            this.setConnectedUsers(d);
        });

        await this.stakeholderUploadFileHub.invokeUserJoined(
            this.organisationId,
            0,
            this.authUser.sub
        );
        await this.stakeholderUploadFileHub.invokeLoadData(
            this.organisationId
        );
        this.isComponentMounted = true;
    };

    stopConnection = async () => {
        await this.stakeholderUploadFileHub.stopConnection();
    };

    @action
    setConnectedUsers = (users) => {
        this.connectedUsers = [...users];
    };

    @action
    setStakeholderUploadFileData = (data) => {
        this.stakeholderUploadFiles = data;
        this.isLoading = false;
    }
}