import * as React from "react";
import { IModel, BaseModel } from "../util/BaseModel";
import { IFilteredApiModel, IFilteredOptions } from "../../services/api/filteredApi/FilteredApiModel";
import { IFormFieldModel } from "../forms/formField/IFormField";
import { IModalService } from "../modal/IModalService";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services, DEBOUNCE_DELAY } from "../../constants";
import { SingleFormModel } from "../../pages/change/forms/singleFormModel/SingleForm_model";
import I18n from "../localization/I18n";
import { IPaginationModel } from "../../components/widgets/pagination/Pagination_model";
import * as _ from "lodash";
import { Animations } from "../util/Animations";
import { Panel } from "../../components/ui/Panel";
import { ILocalStorageFilterService } from "../../services/local/localStorageFilterService/ILocalStorageFilterService";
import { Button, ButtonIcon, ButtonTypes } from "../../components/ui/Button";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { IInfiniteLoaderModel } from "./InfiniteLoader_model";

export interface IFilterAttribute {
  key: string;
  label?: string;
  value?: string[];
  operator?: FilterOperator;

  /**
   * indicates that the filter doesn't has a value and can be used as a flag in the url
   */
  useWithoutValue?: boolean;

  /**
   * Indicated that the value list can have only one value
   */
  isMultiValue?: boolean;

  isHidden?: boolean;

  /**
   * Callback function to populate filter pill content
   */
  valueRenderer?: (value: any, obj?: IFilterAttribute) => React.ReactNode;

  extractFilterValue?: (value: any) => any[];

  /**
   * if true then the filter will be tracked in the url
   */
  shouldUpdateUrlOnChange?: boolean;

  /**
   * if true the it will not reload data on value change
   */
  shouldIgnoreRefetchOnChange?: boolean;
  localStorageValue?: (value: any) => any[];
}

export interface ISortAttribute {
  key: string;
  isAsc: boolean;
}

export enum FilterOperator {
  EQUALS = "%3D%3D",
  NOT_EQUALS = "%21%3D",
  CONTAINS = "%40%3D",
  GREATER_THAN = ">", //	Greater than
  LESS_THAN = "<", //Less than
  GREATER_EQUAL_THAN = ">=", //Greater than or equal to
  LESS_EQUAL_THAN = "<=", //Less than or equal to
  STARTS_WITH = "_=", //Starts with
  NOT_CONTAINS = "!@=", //Does not Contains
  NOT_STARTS_WITH = "!_=", //Does not Starts with
  CS_CONTAINS = "@=*", //Case-insensitive string Contains
  CS_STARTS_WITH = "_=*", //Case-insensitive string Starts with
  CS_EQUALS = "==*", //Case-insensitive string Equals
  CS_NOT_EQUALS = "!=*", //Case-insensitive string Not equals
  CS_NOT_CONTAINS = "!@=*", //Case-insensitive string does not Contains
  CS_NOT_STARTS_WITH = "!_=*" //Case-insensitive string does not Starts with
  // EQUALS = "==",
  // NOT_EQUALS = "!=",
  // CONTAINS = "@="
}

export interface IFilterModel<T> extends IModel {
  currentFilters: IFilterAttribute[];
  currentOrder: ISortAttribute;
  addFilter: (filter: IFilterAttribute) => void;
  loadData: () => Promise<any>;
  searchData: any;
  // filter actions
  removeFilter: (key: string) => void;
  setFilter: (filter: IFilterAttribute) => void;
  getFilter: (key: string) => IFilterAttribute | undefined;
  filterExists: (key: string) => boolean;
  toggleFilter: (filter: IFilterAttribute) => void;
  setFilterValue: (key: string, value: string, ignoreReload?: boolean) => void;
  setFilterValueList: (key: string, valueList: string[]) => void;
  removeFilterValue: (key: string, value: string) => void;
  resetFilterValues: (key: string) => void;
  resetAllFilters: () => void;
  getFilterOptions: () => IFilteredOptions;
  setFromQueryString: (queryStr: string) => boolean;
  setFromLocalStorage: () => void;
  refreshPageDataAfterChange: () => Promise<void>;
  isFilterFree: boolean;
  // sort actions
  addSort: (sortBy: ISortAttribute) => void;
  removeSort: (sortBy: ISortAttribute) => void;
  toggleSort: () => void;
  setSortByKey: (key: string) => void;
  isSortEqualTo: (sort: string) => boolean;
  httpProvider?: IFilteredApiModel<T>;
  filterCb?: (filterOptions: Partial<IFilteredOptions>) => Promise<any>;
  formFields: (filterModel: IFilterModel<T>) => IFormFieldModel<any, any>[];
  showFilterFormModal: () => void;
  setConfig: (config: Partial<IFilterConfig<T>>) => void;
  data: T[];
  uniqueIdentifier: number;
  hasStoredFilters: boolean;
  isModalOpen: boolean;
}
export interface IFilterConfig<T> {
  httpProvider?: IFilteredApiModel<T>;
  filterCb?: (filterOptions: Partial<IFilteredOptions>) => Promise<any>;
  formFields: (filterModel: IFilterModel<T>) => IFormFieldModel<any, any>[];
  currentOrder: ISortAttribute | null;
  onDataLoaded: (data: T[]) => void;
  uniqueIdentifier: number;
  pageSize: number;
  page: number;
}

export class FilterModel<T> extends BaseModel implements IFilterModel<T> {
  appService: AppService;
  uniqueIdentifier: number | any;
  httpProvider?: IFilteredApiModel<T> | any;
  filterCb?: (filterOptions: Partial<IFilteredOptions>) => Promise<any>;
  formFields: any | ((filterModel: IFilterModel<T>) => IFormFieldModel<any, any>[]);
  modalService: IModalService;
  pageSize: number = 10;
  dataOffset: number = 0;
  page: number = 1;
  onDataLoaded = (data: T[]) => { };
  paginationModel: IPaginationModel;
  @observable isLoadingData: boolean = false;
  @observable errorMessage: string = "";
  @observable currentOrder: ISortAttribute | any = null;
  @observable currentFilters: IFilterAttribute[] = [];
  @observable.ref data: T[] = [];
  @observable hasStoredFilters: boolean;
  @observable isFilterFree: boolean;
  @observable isModalOpen: boolean = false;
  infiniteModel: IInfiniteLoaderModel;
  onInfiniteLoadReset: () => void;
  localStorageFilterService: ILocalStorageFilterService;

  constructor(config: {
    appService: AppService;
    paginationModel?: IPaginationModel;
    initOpts?: Partial<IFilterConfig<T>>;
    localStorageName?: string;
    infiniteLoaderModel?: IInfiniteLoaderModel;
    onInfiniteLoadReset?: () => void;
  }) {
    super();
    this.appService = config.appService;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.paginationModel = config.paginationModel;
    this.infiniteModel = config.infiniteLoaderModel;
    this.onInfiniteLoadReset = config.onInfiniteLoadReset;
    if (config.initOpts) {
      this.setConfig(config.initOpts);
      if (config.initOpts.formFields) {
        config.initOpts.formFields(this as any);
      }
    }

    if (config.localStorageName) {
      this.localStorageFilterService = this.appService.getService<ILocalStorageFilterService>(
        Services.LocalStorageFilterService
      );
      this.localStorageFilterService.setLocalStorageName(`${this.uniqueIdentifier}.${config.localStorageName}`);
      this.hasStoredFilters = this.localStorageFilterService.hasStoredFilters;
      this.isModalOpen = false;
    }
  }

  @action
  setConfig = (config: Partial<IFilterConfig<T>>) => {
    this.formFields = config.formFields || this.formFields;
    this.httpProvider = config.httpProvider || this.httpProvider;
    this.filterCb = config.filterCb || this.filterCb;
    this.currentOrder = config.currentOrder || this.currentOrder;
    this.onDataLoaded = config.onDataLoaded || this.onDataLoaded;
    this.uniqueIdentifier = config.uniqueIdentifier || this.uniqueIdentifier;
    this.pageSize =
      config.pageSize === null || typeof config.pageSize === "undefined" ? this.pageSize : config.pageSize;
    this.page = config.page || this.page;
    if (!this.filterCb && !this.httpProvider) {
      console.error(
        `FilterModel has no source of retrieving data \n 
                Please specify an httpProvider or a filterCb`
      );
    }
  };

  @action
  addFilter = (filter: IFilterAttribute) => {
    this.currentFilters.push(filter);
  };

  @action
  removeFilter = (key: string) => {
    let idx = this.currentFilters.findIndex(e => e.key === key);
    this.currentFilters.splice(idx, 1);
  };

  @action
  refreshPageDataAfterChange = async () => {
    this.resetInfiniteLoader();
    await this.loadData();
  }

  @action
  toggleFilter = (filter: IFilterAttribute) => {
    let idx = this.currentFilters.findIndex(e => e.key === filter.key);
    if (idx >= 0) {
      this.currentFilters.splice(idx, 1);
    } else {
      this.currentFilters.push(filter);
    }
  };

  filterExists = (key: string) => {
    return this.currentFilters.findIndex(e => e.key === key) >= 0;
  };

  setFilter = (filter: IFilterAttribute) => {
    let idx = this.currentFilters.findIndex(e => e.key === filter.key);
    this.resetInfiniteLoader();
    if (idx >= 0) {
      this.currentFilters[idx] = filter;
    } else {
      this.addFilter(filter);
    }
    if (filter.shouldIgnoreRefetchOnChange) return;
    this.page = 1;
    this.searchData();
  };

  getFilter = (key: string): IFilterAttribute | undefined => {
    return this.currentFilters.find(e => e.key === key);
  };

  @action
  resetAllFilters = () => {
    for (const filter of this.currentFilters) {
      if (!filter.isHidden) {
        filter.value = [];
      }
    }
    this.updateUrlQueryString();
    if (this.localStorageFilterService) {
      this.resetInfiniteLoader();
    }
    this.hasStoredFilters = this.localStorageFilterService.removeLocallyStoredFilters();
    this.searchData();
    this.isFilterFree = true;
  };

  @action
  resetFilterValues = (key: string) => {
    const filter = this.currentFilters.find(e => e.key === key);

    this.resetInfiniteLoader();
    if (!filter) return;

    if (!filter.isHidden) {
      filter.value = [];
      this.updateUrlQueryString();
      this.searchData();
    }
  };

  @action
  removeFilterValue = (key: string, value: string) => {
    const filter = this.currentFilters.find(e => e.key === key) as any;
    if (!filter) return;
    const removeValue = () => {
      if (!filter.isMultiValue) {
        filter.value = [];
        return;
      }

      const valueList = filter.value.slice();
      const foundValueIndex = valueList.findIndex(e => e === value);

      if (foundValueIndex < 0) return;

      valueList.splice(foundValueIndex, 1);
      filter.value = valueList;
    };
    removeValue();
    this.resetInfiniteLoader();
    if (filter.shouldIgnoreRefetchOnChange) return;
    this.updateUrlQueryString();
    this.updateLocalStorage(key);
    this.searchData();
  };

  @action
  setFilterValue = (key: string, value: string, ignoreReload?: boolean) => {
    const filter = this.getFilter(key);
    if (!filter) return;
    this.resetInfiniteLoader();
    const updateFilter = () => {
      if (!filter.isMultiValue) {
        filter.value = [value];
        if (filter.value[0] === "") this.removeFilterValue(key, value);
        this.searchData();
        return;
      }

      if (this.hasStoredFilters === false) this.isFilterFree = true;

      if (value === "") return;

      this.isFilterFree = false;
      if (this.isModalOpen) this.showFilterFormModal();

      let filterList: any = filter.value;
      let kIdx = filterList.findIndex(e => e === value);
      if (kIdx >= 0) {
        filterList.splice(kIdx, 1);
      } else {
        filterList.push(value);
      }
      filter.value = filterList;
    };
    updateFilter();
    if (!ignoreReload && !filter.shouldIgnoreRefetchOnChange) {
      this.searchData();
    }
    this.updateUrlQueryString();
    this.updateLocalStorage(key);
  };

  @action
  setFilterValueList = (key: string, valueList: any[]) => {
    const filter = this.getFilter(key);
    if (!filter) {
      return;
    }

    this.isFilterFree = false;
    if (this.isModalOpen) this.showFilterFormModal();

    this.resetInfiniteLoader();
    filter.value = valueList;
    if (filter.shouldIgnoreRefetchOnChange) return;

    this.updateUrlQueryString();
    this.updateLocalStorage(key);
    this.searchData();
  };

  addSort = (sortBy: ISortAttribute) => {
    this.setSort(sortBy);
  };

  removeSort = (sortBy: ISortAttribute) => {
    this.setSort(null);
  };

  @action
  toggleSort = () => {
    this.setSort({ ...this.currentOrder, isAsc: !this.currentOrder.isAsc });
  };

  @action
  setSort = (value: ISortAttribute) => {
    this.resetInfiniteLoader();
    this.currentOrder = value;
  };

  @action
  resetInfiniteLoader = () => {
    if (this.infiniteModel) {
      this.data = [];
      this.dataOffset = 0;
      this.infiniteModel.setTotal(-1);
      this.infiniteModel.setConfig({ totalItems: 0 });
      this.onInfiniteLoadReset && this.onInfiniteLoadReset();
    }
  };

  setSortByKey = (key: string) => {
    if (this.currentOrder?.key === key) {
      this.toggleSort();
    } else {
      this.addSort({ key, isAsc: true });
    }
    this.loadData();
  };

  isSortEqualTo = (sort: string) => {
    return this.currentOrder && this.currentOrder.key === sort;
  };

  getFilterOptions = (): IFilteredOptions => {
    let k: IFilteredOptions = {
      page: this.page,
      pageSize: this.pageSize,
      filters: this.getFilterString(),
      sorts: this.getSortsString()
    };
    return k;
  };

  getFilterString = (): string => {
    if (this.currentFilters.length === 0) return null as any;
    const list: any[] = [];
    const filtersThatNeedsDataReload = this.currentFilters.filter(
      (e: IFilterAttribute | any) => !e.shouldIgnoreRefetchOnChange
    );
    filtersThatNeedsDataReload.forEach((e: IFilterAttribute) => {
      if (e.useWithoutValue) {
        list.push(e.key);
        return;
      }
      if (e.value.length && e.extractFilterValue) {
        const val = e.extractFilterValue(e.value);
        list.push(e.key + e.operator + val.join("|"));
      } else if (e.value.length) {
        list.push(e.key + e.operator + e.value.join("|"));
      }
    });
    return list.join(",");
  };

  getSortsString = (): string => {
    return this.currentOrder ? (this.currentOrder.isAsc ? this.currentOrder.key : "-" + this.currentOrder.key) : "";
  };

  @action
  loadData = async () => {
    this.isLoadingData = true;
    if (this.infiniteModel) {
      this.infiniteModel.setIsLoading(true);
      this.dataOffset++;
      this.setConfig({ page: this.dataOffset });
    }

    try {
      let s = this.getFilterOptions();
      let res = this.filterCb ? await this.filterCb(s) : await this.httpProvider.getFilteredAsync(s);
      if (!res || res.isError) return;
      this.data = res.payload;
      this.paginationModel && this.paginationModel.setConfig(res.pagination);

      if (this.infiniteModel) {
        this.infiniteModel.setConfig(res.pagination);
        this.infiniteModel.setIsLoading(false);
      }

      this.onDataLoaded(this.data);
      return this.data;
    } catch {
      this.errorMessage = I18n.t("errors.loadData");
    }

    this.infiniteModel && this.infiniteModel.setIsLoading(false);
    this.isLoadingData = false;
  };

  searchData = _.debounce(() => {
    this.page = 1;
    this.paginationModel && this.paginationModel.config.onPageClick(1);
    this.loadData();
  }, DEBOUNCE_DELAY.NORMAL);

  showFilterFormModal = () => {
    if (this.isFilterFree === undefined) this.isFilterFree = !this.hasStoredFilters;
    this.isModalOpen = true;
    let formModel = new SingleFormModel();
    formModel.formFields = this.formFields(this);
    let searchPhrase = "";
    if (this.filterExists("searchPhrase")) {
      searchPhrase = this.getFilter("searchPhrase").value[0];
    }

    return new Promise(resolve => {
      /** todo: change this code, this is potentially a memory leak, a never ending promise */
      this.modalService.show({
        showClose: false,
        title: (
          <div>
            <h1 className="float-left testme filter-modal__title-text">{I18n.t("phrases.applyFilters")}</h1>
            <div className="float-right mt-4">
              <ButtonIcon
                className="mr-2 float-right"
                type={ButtonTypes.OUTLINE_PRIMARY}
                symbol={IconSymbols.Close}
                onClick={() => {
                  this.modalService.hide();
                  this.isModalOpen = false;
                }}
              />
              <Button
                className="mr-2 float-right"
                onClick={() => {
                  if (this.isFilterFree && !!!searchPhrase) return;
                  formModel.resetFields();
                  this.resetAllFilters();
                  this.showFilterFormModal();
                }}
                isDisabled={this.isFilterFree && !!!searchPhrase}
                type={ButtonTypes.OUTLINE_PRIMARY}
              >
                {I18n.t("phrases.resetFilters")}
              </Button>
            </div>
          </div>
        ),
        content: <div className="container-fluid filter-modal__content">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  setFromQueryString = (queryStr: string): boolean => {
    let hasQuerystringFilters = false;

    if (queryStr) {
      const filterArr = queryStr.slice(1).split("&");

      for (const filter of filterArr) {
        const [key, value] = filter.split("=");
        const parsedValue = decodeURIComponent(value);
        const filterObj = this.getFilter(key);
        if (!!filterObj) {
          filterObj.shouldUpdateUrlOnChange = true;
          this.setFilterValue(key, parsedValue);
          continue;
        }

        hasQuerystringFilters = true;

        this.addFilter({
          key,
          operator: FilterOperator.EQUALS,
          value: [parsedValue],
          shouldUpdateUrlOnChange: true
        });
      }
    }

    return hasQuerystringFilters;
  };

  updateUrlQueryString = () => {
    const baseUrl = window.location.href.split("?")[0];
    const syncFilter = _.filter(this.currentFilters, (e: any) => e.shouldUpdateUrlOnChange && e.value.length > 0);
    if (syncFilter.length === 0) {
      window.history.replaceState("", "", baseUrl);
      return;
    }
    const query = _.map(syncFilter, (filter: IFilterAttribute) => {
      if (!filter.shouldUpdateUrlOnChange) return "";
      let value = filter.value;

      if (filter.extractFilterValue) {
        value = filter.extractFilterValue(value);
      }

      return `${filter.key}=${encodeURIComponent(value.join("|"))}`;
    }).join("&");
    const url = `${baseUrl}?${query}`;
    window.history.replaceState("", "", url);
  };

  setFromLocalStorage = () => {
    if (!this.localStorageFilterService) return;

    var existingFilters = this.localStorageFilterService.getFromLocalStorage();
    if (!existingFilters) return;

    let hasStoredFilters: boolean = false;

    for (var existingFilter of Object.keys(existingFilters)) {
      const filterObj = this.getFilter(existingFilter);
      if (filterObj) {
        filterObj.value = existingFilters[existingFilter];
        hasStoredFilters = true;
      }
    }
    this.hasStoredFilters = hasStoredFilters;
  };

  @action
  updateLocalStorage(key: string) {
    const filter = this.getFilter(key);
    if (this.localStorageFilterService && this.isFilterWithValue(filter)) {
      this.hasStoredFilters = this.localStorageFilterService.addToLocalStorage(filter);
      if (this.hasStoredFilters === undefined) this.isFilterFree = true;
      if (this.isModalOpen) this.showFilterFormModal();
    }
  }

  isFilterWithValue = (filter: IFilterAttribute) => {
    if (!filter && !filter.value && (filter.value.length === 0 || filter.value[0] === "")) return false;

    let res = false;
    res = filter.value.every(e => {
      return e && true;
    });
    return res;
  };
}
