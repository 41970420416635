import { HubConnectionBuilder, HubConnection, LogLevel } from "@microsoft/signalr";
import { getAppToken } from "../../../setup";
import { HubEvents } from "./HubEventsEnum";

export interface ISignalRBaseModel { }

export abstract class SignalRBaseModel implements ISignalRBaseModel {
  url: string = `${window.appConfig.flightPathCoreApiUrl}/hubs/`;
  hubName: string;
  connection: HubConnection;
  isConnectionStarted: boolean = false;

  constructor(hubName: string) {
    this.url += hubName;
    this.createHub();
  }

  getConnectionGroupName = (organisationId: number, projectId: number, hubName: string) => {
    return `${hubName}-${organisationId}-${projectId}`;
  };

  createHub = (): HubConnection => {
    this.connection = new HubConnectionBuilder()
      .withUrl(this.url, {
        accessTokenFactory: () => {
          return getAppToken();
        }
      })
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect()
      .build();
    return this.connection;
  };

  loadData = async (connection: HubConnection, event: string, callback: (data) => void) => {
    if (connection) {
      connection
        .start()
        .then(result => {
          if (connection.connectionId) {
            console.info(`SignalR Connected (${event})!`);
          }

          connection.on(event, data => {
            if (callback) {
              callback(data);
            }
          });
        })
        .catch(e => console.error(`SignalR Connection failed ${event}: `, e));
    }
  };

  startConnection = async () => {
    try {
      if (this.connection && !this.isConnectionStarted) {
        await this.connection.start();
        if (this.connection.connectionId) {
          this.isConnectionStarted = true;
          console.info(`SignalR Connected \n ConnectionId: ${this.connection.connectionId}!`);
        }
      }
    } catch (error) {
      console.error(`SignalR Connection failed: `, error);
    }
  };

  stopConnection = async () => {
    try {
      await this.connection.stop();
      this.isConnectionStarted = false;
    } catch (e) {
      console.error(e);
    }
  };

  onUserJoined = callback => this.registerIncomingEvent(HubEvents.ON_USER_JOIN, callback);


  registerIncomingEvent = (event, callback: (data) => void) => {
    try {
      if (!this.isConnectionStarted) return;
      this.connection.on(event, data => {
        if (!callback) return;

        callback(data);
      });
      return this;
    } catch (error) {
      console.error(`SignalR Connection failed ${event}: `, error);
    }
  };
}
