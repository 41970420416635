import * as React from 'react';
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IActionsApi } from '../../../../services/api/v1/actions/IActions.api';
import I18n from '../../../../core/localization/I18n';
import _ from 'lodash';
import { FORM_COL } from '../../../../constants';
import { IconSymbols } from '../../../../components/ui/Icon';


export const getActionImpactFormFields = (
    actionProvider: IActionsApi, projectId: number, organisationId: number, action: FP.Entities.IAction) => {


    let impactSearch: Partial<IAutocompleteModel> = {
        ...INIT_AUTOCOMPLETE,
        key: "impactSearch",
        label: (<label htmlFor={"impactSearch"}>{I18n.t("forms.actionImpact")}</label>),
        placeholder: I18n.t("placeholders.searchImpacts"),
        optionElement: (<AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
        />),
        componentProps: {
            className: "form-control",
            icon: IconSymbols.Search,
        },
        onFocus: async function () {
            const self: IAutocompleteModel = this;
            const res = await actionProvider.getUnassignedImpactsAsync(organisationId, projectId, action.id);

            if (res?.payload) {
                const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
                self.setOptions(sortedImpacts);
            }
        },
        shouldClearOnBlur: true,
        filterFn: (items: FP.Entities.IImpact[], query) => {
            const lowerQuery = query.toLowerCase();
            return _.filter(items, item => {
                const lowerName = item.name.toLowerCase();
                const lowerRef = item.refNumber.toLowerCase();
                return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
            })
        },
        fieldClassName: FORM_COL.FULL_WIDTH,
        valueLabelFn: (e) => e.name
    }


    let listing: Partial<IListingModel> = {
        ...INIT_LISTING_FIELD,
        key: "impacts",
        label: (<label htmlFor={"impacts"}>{I18n.t("forms.selectedDetailedImpacts")}</label>),
        placeholder: I18n.t("placeholders.selectDetailedImpact"),
        fieldClassName: FORM_COL.FULL_WIDTH,
        subscribeTo: ["impactSearch"],
        onChannelFieldChanged: function (field) {
            let val = field.value;
            if (val) {
                this.addItem(val);
                field.reset();
            }
        },
        extractValue: function () {
            return this.value && this.value.map((e) => e.id)
        },
        selector: (e: FP.Entities.IImpact) => <p className="mb-0 d-inline-block">{e.refNumber} {e.name}</p>

    }

    const fields = [];

    fields.push(impactSearch);
    fields.push(listing);
    const models = generateFormFieldsFromJson(fields);
    return models;
}