import React, { useEffect, useMemo, useState } from "react";
import { StakeholderProfileMap } from "@flightpath/coreui/dist/widgets/stakeholderProfileMap/StakeholderProfileMap_view";
import _ from "lodash";
import { IRadioButtonOption, RadioButtonList } from "../../../../../../../components/ui/_forms/RadioButtons";
import ColorScale from "../../../../../../../core/colorScale/ColorScale";
import { Link, useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { StakeholderType } from "../../../../../../../enums";
import I18n from "../../../../../../../core/localization/I18n";

const _radioOptions = profilingType => [
  { key: "all", label: "All", inputProps: { value: "all" } },
  { key: "advocate", label: "Advocate", inputProps: { value: "advocate" } },
  { key: "neutral", label: "Neutral", inputProps: { value: "neutral" } },
  {
    key: "concerned",
    label: profilingType === "commitment" ? I18n.t("phrases.cautious") : I18n.t("phrases.concerned"),
    inputProps: { value: "concerned" }
  },
  { key: "unknown", label: "Unknown", inputProps: { value: "unknown" } }
];

const _colorScheme = {
  all: ["#f8f8f8", "#3d00c1"],
  advocate: ["#f8f8f8", "#1AC541"],
  concerned: ["#f8f8f8", "#E00606"],
  neutral: ["#f8f8f8", "#2AE1EE"],
  unknown: ["#f8f8f8", "#999999"]
};

export const StakeholderHeatmap: React.FC<{
  data: FP.Entities.IProjectStakeholderSummary[];
  profilingType: "0" | "commitment" | "sentiment" | "receptiveness";
  stakeholderType: any;
}> = ({ data, profilingType, stakeholderType }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [filter, setFilter] = useState(profilingType === "0" ? "all" : "concerned");
  const radioOptions = _radioOptions(profilingType);
  useEffect(() => {
    setFilter(profilingType === "0" ? "all" : "concerned");
  }, [profilingType]);

  const stakeholders = useMemo(
    () =>
      _.groupBy(
        data
          .filter(e => {
            return e.influence > 0 && e.impact > 0;
          })
          .filter(e => {
            if (profilingType === "0") {
              return true;
            } else if (filter === radioOptions[4].key) {
              return e[profilingType] <= 0;
            } else if (filter === radioOptions[1].key) {
              return e[profilingType] <= 10 && e[profilingType] > 7;
            } else if (filter === radioOptions[2].key) {
              return e[profilingType] <= 7 && e[profilingType] > 4;
            } else if (filter === radioOptions[0].key) {
              return e[profilingType] >= -1;
            } else {
              return e[profilingType] <= 4 && e[profilingType] > 0;
            }
          }),
        // xAxis is first param so map impact first and the yAxis influence
        e => `${e.impact}-${e.influence}`
      ),
    [data, profilingType, filter, radioOptions]
  );

  const maxVal = useMemo(
    () =>
      _.maxBy(
        _.map(stakeholders, e => e),
        e => e.length
      )?.length || 0,
    [stakeholders]
  );

  const colourScale = new ColorScale(0, maxVal === 0 ? maxVal + 1 : maxVal, _colorScheme[filter], 1);
  return (
    <div className="stakeholder-heatmap">
      {profilingType !== "0" && (
        <div className="stakeholder-heatmap__toggles mb-3">
          <RadioButtonList
            onItemClick={(item: IRadioButtonOption) => setFilter(item.inputProps.value as string)}
            options={radioOptions}
            value={filter}
          />
        </div>
      )}
      <StakeholderProfileMap
        labels={{
          topLeft: I18n.t("visualisations.keepSatisfied"),
          topRight: I18n.t("visualisations.manageClosely"),
          bottomLeft: I18n.t("visualisations.monitor"),
          bottomRight: I18n.t("visualisations.keepInformed"),
          xAxis: I18n.t("visualisations.levelOfImpact"),
          yAxis: I18n.t("visualisations.levelOfInfluence")
        }}
        getCellContent={(x: number, y: number) => {
          let item = stakeholders[`${x}-${y}`];
          return (
            <Link
              to={`/organisations/${organisationId}/projects/${projectId}/${
                stakeholderType === StakeholderType.INDIVIDUAL ? "stakeholders" : "audiences"
              }/${GetLinkUrl(x, y, profilingType, filter)}`}
              className="stakeholder-heatmap__cell"
              style={{
                backgroundColor: item ? colourScale.getColor(item.length).toHexString() : "#f8f8f8",
                color: item && (colourScale.getColor(item.length).isDarkColor() ? "#fff" : "#000")
              }}
            >
              {item ? item.length : ""}
            </Link>
          );
        }}
      />
    </div>
  );
};

const GetLinkUrl = (impact, influence, profilingType, filter) => {
  let res = `?impact=${impact}&influence=${influence}`;
  if (filter !== "all") {
    res += `&${profilingType}=${GetFilterValues(filter)}`;
  }

  return res;
};

const GetFilterValues = filter => {
  const s = {
    advocate: "8|9|10",
    concerned: "1|2|3|4",
    neutral: "5|6|7",
    unknown: "0|-1"
  };
  return s[filter];
};
