import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { ExportableOrganisationApi } from "../../exportableApi/ExportableOrganisationApiModel";
import { IHttp } from "../../IHttp";
import { ICustomPropertyTypesApi } from "./ICustomPropertyTypes";

export class CustomPropertyTypeApi
  extends ExportableOrganisationApi<FP.Entities.ICustomPropertyType>
  implements ICustomPropertyTypesApi
{
  controller: string = "custom-property-types";

  constructor(http: IHttp) {
    super(http, "custom-property-types");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }
  getAll = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ICustomPropertyType[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.ICustomPropertyType[]>;
    }
  };
}
