import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../../constants";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { GetGridFns } from "../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../core/grids/IGridModel";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { EntityTypes } from "../../../../../../enums";
import { IBenefitApi } from "../../../../../../services/api/v1/benefits/IBenefit.api";
import { BenefitsHub } from "../../../../../../services/hubs/BenefitsHub/Benefits_hub";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { GetBenefitsGridColumns } from "./BenefitsGrid_columns";

export class BenefitsGridModel implements IGridModel {
  appService: AppService;
  modalService: IModalService;
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = false;
  authUser: FP.Entities.IUser;
  benefitsHub: BenefitsHub;
  gridToastService: IGridToastService;
  type: EntityTypes;
  benefitsProvider: IBenefitApi;

  constructor(appService: AppService, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    this.appService = appService;
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.benefitsHub = appService.getService<BenefitsHub>(Services.BenefitsHub);
    this.gridToastService = this.appService.getService<IGridToastService>(Services.GridToastService);
    this.benefitsProvider = this.appService.getService<IBenefitApi>(Services.BenefitApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.type = EntityTypes.BENEFITS;
  }
  onUnmount: () => void;

  onMount = async () => {};

  getColumnConfig = () => {
    const canEditOrg = PermissionsContext.canEditOrganisation(this.organisationId);

    return GetBenefitsGridColumns({
      canEdit: canEditOrg,
      organisationId: this.organisationId,
      projectId: this.projectId,
      modalService: this.modalService,
      gridFns: GetGridFns(this.benefitsProvider as any, this.organisationId, this.projectId)
    });
  };
}
