import { AxiosRequestConfig } from "axios";
import { BaseApiModel } from "../../BaseApiModel";
import { IHttp } from "../../IHttp";
import { ISearchApi } from "./ISearch.api";

export class SearchApi extends BaseApiModel<any> implements ISearchApi {
  controller: string = "search";

  constructor(http: IHttp) {
    super(http, "search");
    this.url = `${this.http.url}/${this.version}/search`;
  }

  globalSearch = async (data: any, config?: AxiosRequestConfig) => {
    const res = await this.http.post(`${this.url}`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getFacets = async (config?: AxiosRequestConfig) => {
    const res = await this.http.get(`${this.url}/facets`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
