import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { ISliderFieldModel } from "../../../../core/forms/controls/slider/ISliderFieldModel";
import { Enums, Translator } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import { FORM_COL, IMPACT_STATUS_OPTIONS, REMOVE_UNSAFE_CHARACTERS, Services } from "../../../../constants";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import moment from "moment";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { convertStakeholderToName, systemUserOrUnassigned } from "../../../../core/util/Helpers";
import AppService from "../../../../contexts/AppService";
import { IImpactTypeApi } from "../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { IconSymbols } from "../../../../components/ui/Icon";
import { FormRequiredFieldIndicator } from "../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { ICheckboxSliderModel } from "../../../../core/forms/controls/checkboxslider/ICheckboxSliderModel";
import { INIT_CHECKBOX_SLIDER_FIELD } from "../../../../core/forms/controls/checkboxslider/CheckboxSlider_init";
import { getMitigationConfidencePhrase } from "../../impacts/impactDisplay";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { INIT_CHECKBOX_FIELD } from "../../../../core/forms/controls/checkbox/Checkbox_init";
import { IProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { IProjectStakeholderGroupsApi } from "../../../../services/api/v1/projectStakeholdersGroups/IProjectStakeholderGroups.api";

export const getProjectFormField = (
  orgProvider: IOrganisationsApi,
  organisationId: number,
  onChange: (val) => void,
  projectId?: number
) => {
  const projectIdHidden = projectId
    ? {
        ...INIT_TEXT_FIELD,
        key: "projectId",
        inputType: "hidden",
        value: projectId,
        defaultValue: projectId
      }
    : {
        ...INIT_AUTOCOMPLETE,
        key: "projectId",
        label: (
          <label htmlFor={"projectId"}>
            {I18n.t("forms.chooseProject")} <FormRequiredFieldIndicator />
          </label>
        ),
        placeholder: I18n.t("placeholders.searchProject"),
        optionElement: (
          <AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={e => {
              return e.name;
            }}
          />
        ),
        onFocus: async function (model: IAutocompleteModel, event) {
          if (model.options.length === 0) {
            const res = await orgProvider.getProjects(organisationId);

            if (res?.payload) {
              const sortedProjects = _.orderBy(res.payload, [project => project.name.toLowerCase()]);
              model.setOptions(sortedProjects);
            }
          }
        },
        componentProps: {
          className: "form-control",
          icon: "search"
        },
        onItemSelected: function () {
          onChange(this.extractValue());
        },
        searchAttribute: "name",
        validate: function () {
          const self: IAutocompleteModel = this;
          let res = true;
          if (Validations.IS_EMPTY(self.extractValue())) {
            self.errorMessage = <ErrorMessage>{I18n.t("validations.impactProject")}</ErrorMessage>;
            res = false;
          }
          return res;
        },
        fieldClassName: `${FORM_COL.FULL_WIDTH} mb-0`,
        extractValue: function () {
          return this.value?.id;
        }
      };

  const fields = [];
  fields.push(projectIdHidden);
  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getImpactFormFields = (
  organisationId: number,
  projectId: number,
  isTestUser: boolean,
  projectTeam: IProjectTeamUserPermissionsApi,
  impact?: FP.Entities.IImpact
) => {
  const impactTypeProvider = AppService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
  const orgProvider = AppService.getService<IOrganisationsApi>(Services.OrganisationsApi);
  const projectStakeholderProvider = AppService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
  const projectStakeholderGroupsProvider = AppService.getService<IProjectStakeholderGroupsApi>(
    Services.ProjectStakeholderGroupsApi
  );
  const impactGroupsProvider = AppService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
  const tagsProvider = AppService.getService<ITagsApi>(Services.TagsApi);

  const projectIdHidden = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId,
    defaultValue: projectId
  };

  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      min: 1,
      max: 10,
      marks: true
    }
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.detailedImpactName"),
    label: (
      <label htmlFor="name">
        {I18n.t("forms.detailedImpactName")} <FormRequiredFieldIndicator />
      </label>
    ),
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.detailedImpactName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: impact?.name
  };

  const description = isTestUser
    ? {
        ...INIT_TEXT_FIELD,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
        fieldClassName: FORM_COL.FULL_WIDTH,
        value: impact?.description
      }
    : {
        ...INIT_RTEDITOR,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
        fieldClassName: FORM_COL.FULL_WIDTH,
        value: impact?.description
      };

  const impactOwner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactOwnerId",
    label: <label htmlFor={"impactOwnerId"}>{I18n.t("forms.detailedImpactOwner")}</label>,
    placeholder: I18n.t("placeholders.selectDetailedImpactOwner"),
    onFocus: async function (model: IAutocompleteModel) {
      const res = await projectTeam.getAllUsersSimple(organisationId, projectId);
      if (!res.payload) return;
      const impactOwners = _.orderBy(res.payload, [
        owner => owner.lastName.toLowerCase(),
        owner => owner.firstName.toLowerCase()
      ]);
      model.setOptions(impactOwners);
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.detailedImpactOwner")}</p>
      </FormTooltip>
    ),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete_chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    searchAttribute: "name",
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: impact?.impactOwner,
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: e => systemUserOrUnassigned(e)
  };

  const impactLevel: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impactLevel",
    label: <label htmlFor="impactLevel">{I18n.t("forms.detailedImpactLevel")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: ISliderFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.detailedImpactLevel")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      ...baseCheckboxSlider.componentProps
    },
    value: impact?.impactLevel,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.detailedImpactLevel")}</p>
      </FormTooltip>
    ),
    testId: "impact-level"
  };

  const mitigationConfidence: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "mitigationConfidence",
    fieldClassName: FORM_COL.FULL_WIDTH,
    label: <label htmlFor={"mitigationConfidence"}>{I18n.t("forms.mitigationConfidence")}</label>,
    value: impact?.mitigationConfidence,
    componentProps: {
      ...baseCheckboxSlider.componentProps
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.mitigationConfidence")}</p>
      </FormTooltip>
    ),
    adviceText: function (value: number) {
      return getMitigationConfidencePhrase(value);
    },
    testId: "mitigation-confidence"
  };

  let shouldAddToImpactGroup: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "controlQuestion",
    label: <label htmlFor="controlQuestion">{I18n.t("forms.shouldAddImpactToHighLevelImpacts")}</label>,
    fieldClassName: impact?.impactGroups?.length > 0 ? FORM_COL.FULL_WIDTH : "col-12 col-space-lg-6",
    options: [
      {
        key: "controlQuestionYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "controlQuestion"
        }
      },
      {
        key: "controlQuestionNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "controlQuestion"
        }
      }
    ],
    onValueChange: function (val) {
      if (val === "yes") {
        this.fieldClassName = FORM_COL.FULL_WIDTH;
        return;
      }
      this.fieldClassName = FORM_COL.FULL_WIDTH;
    },
    defaultValue: "no",
    value: impact?.impactGroups?.length > 0 ? "yes" : "no"
  };

  const filterOptions: IFilteredOptions = {
    page: 0,
    pageSize: 0
  };

  const impactGroup: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroups",
    label: <label htmlFor={"impactGroups"}>{I18n.t("forms.highLevelImpacts")}</label>,
    placeholder: I18n.t("placeholders.searchHighLevelImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpactGroup) => `${e.refNumber} - ${e.name}`}
      />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${organisationId}/projects/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await impactGroupsProvider.getFiltered(organisationId, projectId, filterOptions);

      if (res?.payload) {
        const sortedImpactGroups = _.orderBy(res.payload, [impactGroup => impactGroup.name.toLowerCase()]);
        self.setOptions(sortedImpactGroups);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.isHidden ? null : this.selectedItems.map(e => e.id);
    },
    value: impact?.impactGroups,
    isHidden: _.isEmpty(impact) || impact?.impactGroups?.length === 0
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    placeholder: I18n.t("placeholders.date"),
    componentProps: {
      icon: IconSymbols.Calendar,
      showClearDate: true,
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    extractValue: function () {
      return this.value?.format(this.exportedDateFormat);
    },
    value: impact?.startDate ? moment(impact.startDate) : null
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "actualEndDate",
    label: <label htmlFor="actualEndDate">{I18n.t("forms.endDate")}</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["startDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      const sd = field.value;
      self.setDatepickerProps({
        isOutsideRange: day => {
          return day < sd;
        },
        initialVisibleMonth: () => (sd != null ? sd : moment())
      });
    },
    validate: function () {
      const self: IDatePickerModel = this;
      const startDate = this.channels.startDate.value;
      let res = true;

      if (
        (!_.isEmpty(startDate) && startDate > this.value && !this.endDate === null) ||
        !this.endDate === null ||
        !this.endDate === undefined
      ) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    componentProps: {
      icon: IconSymbols.Calendar,
      showClearDate: true,
      datePickerProps: impact && {
        isOutsideRange: day => {
          return day < moment(impact.startDate);
        }
      }
    },
    value: impact?.actualEndDate ? moment(impact.actualEndDate) : null,
    extractValue: function () {
      return this.value?.format(this.exportedDateFormat);
    }
  };

  const totalPeopleImpacted: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "totalPeopleImpacted",
    placeholder: I18n.t("placeholders.totalPeopleImpacted"),
    label: <label htmlFor="totalPeopleImpacted">{I18n.t("forms.individualsImpacted")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: impact?.totalPeopleImpacted || impact?.totalPeopleImpacted === 0 ? impact.totalPeopleImpacted + "" : "",
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (self.extractValue()) {
        if (!Validations.IS_NUMERIC(self.extractValue())) {
          self.errorMessage = <ErrorMessage>{I18n.t("validations.notNumber")}</ErrorMessage>;
        }
      }
      if (self.extractValue() > 0 && !Validations.IS_NUMERIC(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.notNumber")}</ErrorMessage>;
        res = false;
      }
      if ((self.extractValue() + "").length > 9) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.numberToLarge")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    extractValue: function () {
      return this.value;
    }
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor="progressStatus">{I18n.t("forms.progressStatus")}</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: IMPACT_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    reset: function () {
      this.value = this.defaultValue;
    },
    defaultValue: IMPACT_STATUS_OPTIONS[0],
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    },
    value: getProgressStatusValue()
  };

  function getProgressStatusValue() {
    let options = IMPACT_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) }));
    let statusValue = _.isEmpty(impact) ? options[0] : options.find(e => e.key === impact?.progressStatus);

    return statusValue;
  }

  const businessAreas: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "businessAreas",
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    manageLink: `/organisations/${organisationId}/settings/businessAreas`,
    label: <label htmlFor="businessAreas">{I18n.t("forms.businessAreas")}</label>,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await orgProvider.getBusinessAreas(organisationId);

      if (res?.payload) {
        const sortedBusinessAreas = _.orderBy(res.payload, [businessArea => businessArea.name.toLowerCase()]);
        self.setOptions(sortedBusinessAreas);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.businessAreas,
    valueLabelFn: e => e?.name
  };

  const locations: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "locations",
    placeholder: I18n.t("placeholders.searchLocation"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    label: <label htmlFor="locations">{I18n.t("forms.locations")}</label>,
    manageLink: `/organisations/${organisationId}/settings/locations`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await orgProvider.getLocations(organisationId);

      if (res?.payload) {
        const sortedLocations = _.orderBy(res.payload, [location => location.name.toLowerCase()]);
        self.setOptions(sortedLocations);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.locations,
    valueLabelFn: e => e?.name
  };

  const canEditStakeholders = PermissionsContext.canEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);

  const stakeholderGroups: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "stakeholderGroups",
    label: <label htmlFor="stakeholderGroups">{I18n.t("forms.projectStakeholderGroups")}</label>,
    manageLink: `/organisations/${organisationId}/settings/stakeholderGroups`,
    placeholder: I18n.t("placeholders.searchStakeholderGroup"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await projectStakeholderGroupsProvider.getStakeholderGroups(organisationId, projectId);

      if (!res) return;

      if (res?.payload) {
        const sortedStakeholderGroups = _.orderBy(res.payload, [
          stakeholderGroup => stakeholderGroup.name.toLowerCase()
        ]);
        self.setOptions(sortedStakeholderGroups);
      }
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.stakeholderGroups,
    valueLabelFn: e => e?.name,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.stakGrpExp")}</p>
      </FormTooltip>
    ),
    componentProps: {
      icon: IconSymbols.User
    },
    disabled: !canEditStakeholders
  };

  const stakeholderSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderSearch",
    label: <label htmlFor={"stakeholderSearch"}>{I18n.t("forms.projectStakeholders")}</label>,
    manageLink: `/organisations/${organisationId}/settings/stakeholders`,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectStakeholderProvider.getStakeholdersOnly(organisationId, projectId);
      if (res?.payload) {
        const sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        self.setOptions(sortedStakeholders);
      }
    },
    subscribeTo: ["projectStakeholders"],
    onItemSelected: async function () {
      const self: IAutocompleteModel = this;
      const listingModel: IListingModel = self.channels.projectStakeholders as IListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === Enums.StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p>{I18n.t("phrases.stakIndExp")}</p>
        <p className="mb-0">{I18n.t("phrases.stakAudExp")}</p>
      </FormTooltip>
    ),
    disabled: !canEditStakeholders
  };

  const stakeholderListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "projectStakeholders",
    placeholder: I18n.t("placeholders.selectStakeholder"),
    uniqueAttr: "email",
    label: <label htmlFor={"projectStakeholders"}>{I18n.t("forms.selectedProjectStakeholders")} </label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      var projectStakeholderIds =
        this.value &&
        this.value.map(e => (e.projectStakeholders ? e.projectStakeholders[0].id : e.projectStakeholderId));
      return projectStakeholderIds;
    },
    selector: (pst: FP.Entities.IStakeholder) => {
      const e = pst;
      return <p className="mb-0 d-inline-block">{convertStakeholderToName(e)}</p>;
    },
    value: impact?.projectStakeholders?.map(e => e.stakeholder),
    disabled: !canEditStakeholders
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => `${e.text}`} />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${organisationId}/tags/search/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await tagsProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(sortedTags);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: impact?.tags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: <p className="mb-0 pl-3 pb-1 pt-2">{I18n.t("forms.tagsSearchResultHint")}</p>
  };

  const impactTypes: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactTypes",
    placeholder: I18n.t("placeholders.searchImpactTypes"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    manageLink: `/organisations/${organisationId}/settings/impactTypes`,
    label: <label htmlFor="impactTypes">{I18n.t("forms.impactTypes")}</label>,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      let values = this.extractValue();

      const res = await impactTypeProvider.getUnselectedByOrganisation(organisationId, values);

      if (res?.payload) {
        const sortedImpactTypes = _.orderBy(res.payload, [impactType => impactType.name.toLowerCase()]);
        self.setOptions(sortedImpactTypes);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.impactTypes,
    valueLabelFn: e => e?.name
  };

  const markAsReviewed = {
    ...INIT_CHECKBOX_FIELD,
    key: "markAsReviewed",
    inputType: "hidden",
    value: false,
    defaultValue: false,
    isHidden: true
  };

  const reviewNotes: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "reviewNotes",
    inputType: "hidden",
    placeholder: I18n.t("placeholders.reviewNotes"),
    label: <label htmlFor="reviewNotes"></label>,
    value: ""
  };

  const fields = [];
  fields.push(name);
  fields.push(projectIdHidden);
  fields.push(description);
  fields.push(impactTypes);
  fields.push(impactOwner);
  fields.push(impactLevel);
  fields.push(mitigationConfidence);
  fields.push(shouldAddToImpactGroup);
  fields.push(impactGroup);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(totalPeopleImpacted);
  fields.push(progressStatus);
  fields.push(businessAreas);
  fields.push(locations);
  fields.push(stakeholderGroups);
  fields.push(stakeholderSearch);
  fields.push(stakeholderListing);
  fields.push(tags);
  fields.push(markAsReviewed);
  fields.push(reviewNotes);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
