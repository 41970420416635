import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IModalService } from "../../../../core/modal/IModalService";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums, ProjectStakeholderField, StakeholderType } from "../../../../enums";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ProjectAudiencesHub } from "../../../../services/hubs/ProjectAudiencesHub/ProjectAudiences_hub";
import { IGridToastService } from "../../../../services/local/gridToastService/IGridToastService";
import { SingleFormModel } from "../../../change/forms/singleFormModel/SingleForm_model";
import { getEntityNameMicroFormFields } from "./AudiencesView_fields";
import {
  SHOW_AUDIENCE_DELETE_CONFIRM_MODAL,
  SHOW_AUDIENCE_EXISTS_MODAL,
  SHOW_AUDIENCE_REVIEW_CONFIRM_MODAL
} from "./AudiencesView_modal";
import { GetStakeholderURLFilters } from "./AudienceView_filters";
import {
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL,
  SHOW_STAKEHOLDER_LINK_MODAL
} from "../Stakeholders/StakeholdersListView/StakeholdersView_modal";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import I18n from "../../../../core/localization/I18n";

export class AudiencesViewModel extends BaseModel {
  appService: AppService;
  projectId: number;
  organisationId: number;
  projectAudiencesHub: ProjectAudiencesHub;
  @observable.ref connectedUsers: FP.Entities.IUser[] = [];
  @observable isLoading: boolean = true;
  @observable.ref gridAudiences: FP.Entities.IProjectAudienceSummary[];
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  authUser: FP.Entities.IUser;
  httpProgress: IHttpProgressModel;
  gridToastService: IGridToastService;
  microFormModel: SingleFormModel;
  toasterService: IToasterService;
  gridApi: any;
  @observable.ref selectedAudiences: number[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalService;
  location: any;
  filterModel: {};
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.impacts"),
      onAction: ev => {
        this.showLinkToImpact();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(
    appService: AppService,
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    location: any
  ) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.gridToastService = this.appService.getService(Services.GridToastService);
    this.setMicroAddForm();
    this.projectAudiencesHub = this.appService.getService<ProjectAudiencesHub>(Services.ProjectAudiencesHub);
    this.location = location;
  }

  onMount = async () => {
    await this.registerSocketEvents();
    await this.loadStakeholderAndAudienceCounts();
  };

  onUnmount = () => {
    this.stopConnection();
  };

  stopConnection = async () => {
    await this.projectAudiencesHub.stopConnection();
  };

  registerSocketEvents = async () => {
    await this.projectAudiencesHub.startConnection();

    this.projectAudiencesHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.projectAudiencesHub.onLoadGridData(d => {
      this.setAudienceRowData(d);
    });

    this.projectAudiencesHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.projectAudiencesHub.invokeUserJoined(this.organisationId, this.projectId, this.authUser.sub);
    await this.projectAudiencesHub.invokeLoadGridData(this.organisationId, this.projectId);
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setAudienceRowData = audiences => {
    this.gridAudiences = audiences;
    this.isLoading = false;
  };

  @action
  onGridReady(gridReadyEvent: GridReadyEvent): void {
    this.filterModel = {
      ...GetStakeholderURLFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  }

  updateUserSelectedCell = async (cell: string, isEditMode?: boolean) => {
    await this.projectAudiencesHub.invokeUserClickedCell(this.organisationId, this.projectId, cell, isEditMode);
  };

  updateSelectedAudiences = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedAudiences = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setMicroAddForm = () => {
    this.microFormModel = new SingleFormModel();
    this.microFormModel.className = "mr-2";
    this.microFormModel.formFields = getEntityNameMicroFormFields(
      this.organisationId,
      this.projectId,
      this.addAudienceToProject
    );
  };

  addAudienceToProject = async audience => {
    this.showBringAudienceModal(audience);
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.location.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/visualisations/1/0`
      );
      return;
    }
    if (newTabIndex === 0) {
      this.location.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.location.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };

  showConfirmReviewModal = () => {
    return SHOW_AUDIENCE_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedAudiences,
      this.reviewStakeholderRange,
      this.toasterService,
      this.deselectRows
    );
  };

  showLinkStakeholderModal = () => {
    return SHOW_STAKEHOLDER_LINK_MODAL(this.modalService, this.projectId, StakeholderType.AUDIENCE);
  };

  showLinkToImpact = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedAudiences);
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  showBringAudienceModal = async (audience: FP.Entities.IStakeholder) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectStakeholderProvider.getDeletedStakeholder(
      this.organisationId,
      this.projectId,
      audience.id
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (res.payload) {
      SHOW_AUDIENCE_EXISTS_MODAL(
        this.modalService,
        res,
        audience,
        this.activateExistingAudience,
        this.addProjectAudience
      );
      return;
    } else {
      this.addProjectAudience(audience);
    }
  };

  @action
  deleteFieldData = async (stakeholderId: number, actionField: ProjectStakeholderField) => {
    const res = await this.projectStakeholderProvider.deleteField(
      this.organisationId,
      this.projectId,
      stakeholderId,
      actionField
    );
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  activateExistingAudience = async (projectAudience: FP.Entities.IProjectStakeholder) => {
    this.httpProgress.showTopProgressBarVisible();
    projectAudience.lifecycleStatus = Enums.LifecycleStatus.Active;
    let res = await this.projectStakeholderProvider.update(
      this.organisationId,
      projectAudience.project.id,
      projectAudience.stakeholder.id,
      projectAudience
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) {
      return;
    }
  };

  addProjectAudience = async s => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.projectStakeholderProvider.create(this.organisationId, this.projectId, {
      stakeholderId: s.id,
      projectId: this.projectId
    } as any);
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;
  };

  showStakeholderConfirmDeleteModal = () => {
    return SHOW_AUDIENCE_DELETE_CONFIRM_MODAL(this.modalService, this.selectedAudiences, this.removeAudiences);
  };

  removeAudiences = async () => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectStakeholderProvider.deleteRange(
      this.organisationId,
      this.projectId,
      this.selectedAudiences
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;
    return res.payload;
  };

  reviewStakeholderRange = async (projectAudienceIds: number[], comment: string) => {
    let res = await this.projectStakeholderProvider.reviewRange(
      this.organisationId,
      this.projectId,
      projectAudienceIds,
      comment
    );

    if (!res || res.isError) return;
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );
    if (!res || res.isError) return;
    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-audiences-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedAudiences && this.selectedAudiences.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };
}
