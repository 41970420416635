import { action, observable } from 'mobx';
import { AppService } from 'strikejs-app-service';
import { Services } from '../../../../../../constants';
import { IHttpProgressModel } from '../../../../../../core/httpProgress/HttpProgress_model';
import I18n from '../../../../../../core/localization/I18n';
import { IToasterService } from '../../../../../../core/toaster/ToasterService';
import { TOASTER_TOAST_TIME } from '../../../../../../core/toaster/Toaster_model';
import { BaseModel } from '../../../../../../core/util/BaseModel';
import { ITagsApi } from '../../../../../../services/api/v1/tags/ITags.api';
import { SingleFormModel } from '../../../../forms/singleFormModel/SingleForm_model';
import { TagSettingsModel } from '../TagSettings_model';
import { getTagMiniFormFields } from './TagSettingsEditModal_fields';

export class TagSettingsEditModalModel extends BaseModel {
    organisationId: number;
    appService: AppService;
    httpProgress: IHttpProgressModel;
    @observable isLoading: boolean = true;
    tagForm: SingleFormModel;
    tagProvider: ITagsApi;
    tag: FP.Entities.ITag;
    parentModel: TagSettingsModel;
    toasterService: IToasterService;

    constructor(parentModel: TagSettingsModel, tag: FP.Entities.ITag) {
        super();
        this.parentModel = parentModel;
        this.appService = parentModel.appService;
        this.organisationId = parentModel.organisationId;
        this.tagProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
        this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
        this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
        this.tag = tag;
        this.setForms();
    }

    onMount() {
        this.isLoading = false;
    }

    @action
    setForms = () => {
        this.tagForm = new SingleFormModel();
        this.tagForm.formFields = getTagMiniFormFields(this.parentModel.tags, this.tag);
    }

    @action
    updateTag = async () => {
        let tagFormRes = await this.tagForm.submit();
        this.tagForm.isSaving = true;
        if (!tagFormRes) return;


        tagFormRes = { ...tagFormRes, organisationId: this.organisationId }
        const res = {
            ...tagFormRes,
        }
        this.httpProgress.showOverlay();
        const result = await this.tagProvider.update(this.organisationId, this.tag.id, res as FP.Entities.ITag);
        this.httpProgress.hideOverlay();

        if (!result || result.isError) {
            this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.updateTag"));
            return;
        }


        const tag = result.payload;
        this.parentModel.tags = this.parentModel.tags.map(t => t.id === tag.id ? tag : t)
        return tag;
    }
}