import { IHttp } from "../../IHttp";
import { ITagsApi } from "./ITags.api";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { gEntities } from "../../../../FlightPathEntities";

export class TagsApi extends FilteredOrganisationApiModel<FP.Entities.ITag> implements ITagsApi {
  controller: string = "tags";

  constructor(http: IHttp) {
    super(http, "tags");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  searchByNameAsync = async (
    organisationId: number,
    searchPhrase: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let encodedSearchPhrase = encodeURI(searchPhrase);
    let res = await this.http.get(`${this.url}/${organisationId}/tags/search/${encodedSearchPhrase}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getDetailedAsync = async (
    organisationId: number,
    tagId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/tags/${tagId}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  addActionTagAssoc = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/actionTags/${actionId}`,
      tag,
      config
    );
    if (res && res.status === 200) return res.data;
  };

  addImpactTagAssoc = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/impactTags/${impactId}`,
      tag,
      config
    );
    if (res && res.status === 200) return res.data;
  };

  addProjectTagAssoc = async (
    organisationId: number,
    projectId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/projectTags`,
      tag,
      config
    );
    if (res && res.status === 200) return res.data;
  };

  getAllAsync = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/tags`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getFilteredWithUsageCount = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let query = this.getRequestQuery(filterOptions);
    let res = await this.http.get(`${this.url}/${organisationId}/tags/usageCount` + query, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  removeActionTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    actionId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/actionTags/${actionId}`,
      config
    );
    if (res && res.status === 200) return res.data;
  };

  removeImpactTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/impactTags/${impactId}`,
      config
    );
    if (res && res.status === 200) return res.data;
  };

  removeProjectStakeholderTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    pStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/projectStakeholderTags/${pStakeholderId}`,
      config
    );
    if (res && res.status === 200) return res.data;
  };

  addProjectStakeholderTagAssoc = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    tag: gEntities.ITag,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/projectStakeholderTags/${projectStakeholderId}`,
      tag,
      config
    );
    if (res && res.status === 200) return res.data;
  };
}
