import React from "react";
import _ from "lodash";
import { observable, action } from "mobx";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IModalService } from "../../../../../core/modal/IModalService";
import { Enums, PermissionScope } from "../../../../../enums";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { IFilteredOptions } from "../../../../../services/api/filteredApi/FilteredApiModel";
import I18n from "../../../../../core/localization/I18n";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../../core/filter/Filter_model";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { IOrganisationContextModel } from "../../../../../services/local/organisationContext/IOrganisationContextModel";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IExportService, ExportService } from "../../../../../services/local/export/ExportService";
import moment from "moment";
import { Animations } from "../../../../../core/util/Animations";
import { Panel } from "../../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { IUsersApi } from "../../../../../services/api/v1/users/IUsers.api";
import { UserCompactView } from "./UserCompact_view";
import { GetUserSettingsFilters } from "./UserSettingsFilter_fields";
import { UserInviteForm } from "./forms/UserInviteForm_view";

export class UsersSettingsModel extends BaseModel {
  appService: AppService;
  organisationsProvider: IOrganisationsApi;
  exportService: IExportService<FP.Entities.IUser>;
  modalService: IModalService;
  httpProgress: IHttpProgressModel;
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IUser>;
  orgContext: IOrganisationContextModel;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable searchQuery: string = "";
  @observable.ref organisation: FP.Entities.IOrganisation;
  @observable.ref users: FP.Entities.IUser[];
  usersProvider: IUsersApi;

  constructor(appService: AppService, organisation: FP.Entities.IOrganisation) {
    super();
    this.appService = appService;
    this.organisation = organisation;
    this.organisationId = this.organisation.id;
    this.usersProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.orgContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);

    this.installPagination();
    this.installFilter();
    this.installExportService();
    this.filterModel.setFromLocalStorage();
  }

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      paginationModel: this.paginationModel,
      initOpts: {
        filterCb: async (filters: Partial<IFilteredOptions>) => {
          return await this.organisationsProvider.getAssociateUsers(this.organisationId, filters);
        }
      }
    };
    this.filterModel = new FilterModel(config);

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const permissionLevel: IFilterAttribute = {
      key: "permissionScope",
      label: I18n.t("filters.permissionLevel"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return I18n.t(Enums.Translator.PermissionScope(parseInt(k)));
      }
    };

    const nameFilter: IFilterAttribute = {
      key: "SearchPhrase",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.filterModel.addSort({
      key: "systemUser.FirstName",
      isAsc: true
    });
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(permissionLevel);
    this.filterModel.setConfig({
      formFields: s => GetUserSettingsFilters(s, this.organisationId),
      onDataLoaded: d => this.setUsers(d)
    });
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IUser>(this.appService, this.filterModel, {
      filename: `${_.kebabCase(I18n.t("entities.users"))}-${I18n.t("phrases.export").toLowerCase()}-${moment().format(
        "L"
      )}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.organisationsProvider.exportUsers(columns, filterOptions, null, this.organisationId);
      },
      fields: [
        {
          key: "name",
          label: I18n.t("table.name"),
          selector: i => `${i.firstName} ${i.lastName}`
        },
        {
          key: "email",
          label: I18n.t("table.email")
        },
        {
          key: "permissionLevel",
          label: I18n.t("table.permissionLevel"),
          selector: (i: any) => {
            return I18n.t(Enums.Translator.PermissionScope(i.permissionScope));
          }
        },
        {
          key: "status",
          label: I18n.t("table.status"),
          selector: i => {
            return I18n.t(i.isActive ? "phrases.active" : "phrases.notActive");
          }
        }
      ]
    });
  };

  onMount = (organisationId: number) => {
    this.loadUsers();
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  onUnmount = () => {};

  @action
  setUsers = users => {
    this.users = users;
  };

  @action
  loadUsers = async () => {
    this.isLoading = true;
    await this.filterModel.loadData();
    this.setUsers(this.filterModel.data);
    this.isLoading = false;
  };

  updateUserPermission = async (organisationId: number, userId: string, permission: PermissionScope) => {
    let res = await this.organisationsProvider.editUserPermission(organisationId, userId, permission);
    if (!res) return;
    this.loadUsers();
    this.modalService.hide();
  };

  searchUsers = (ev: React.SyntheticEvent) => {
    const e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("SearchPhrase", e.value);
  };

  resetQuery = () => {
    this.filterModel.setFilterValue("SearchPhrase", "");
  };

  showUserConfirmDeleteModal = (user: FP.Entities.IUser) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeUser")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: `${user.firstName} ${user.lastName}` })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          const res = await this.organisationsProvider.removeUser(this.organisation.id, user.sub);
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.loadUsers();
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  showUserFormModal = () => {
    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.inviteUser")}</h1>,
        content: <UserInviteForm loadUsers={this.loadUsers} />,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showUserEditForm = row => {
    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: null,
        content: (
          <div className="container-fluid mt-6">
            {/* adds empty space */}
            <div className="row mb-4"></div>
            <UserCompactView
              onCancelClick={() => {
                this.modalService.hide();
              }}
              onSaveClick={formFields => {
                this.updateUserPermission(
                  parseInt(formFields.organisationId),
                  formFields.id,
                  formFields.permissionScope
                );
              }}
              user={row}
            />
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };
}
