
const textConditionObject = (value: string, type: string) => {
  return {
    filterType: "text",
    type: type,
    filter: value
  }
}

const operativeTextFilter = (operatorName: string, conditions) => {
  return {
    filterType: "text",
    operator: operatorName,
    ...conditions
  }
}

export const notBlank_Filter = () => {
  const filter = {
    type: "notBlank"
  };
  return filter;
}

export const blank_Filter = () => {
  const filter = {
    type: "blank"
  };
  return filter;
}

export const textEquals_Filter = (value: string) => {
  const filter = {
    filterType: "text",
    type: "equals",
    filter: value
  };
  return filter;
};

export const textIncludes_Filter = (value: string) => {
  const filter = {
    filterType: "text",
    type: "includes",
    filter: value
  }
  return filter;
}

export const dateGreaterThan_Filter = (date: string) => {
  const filter = {
    type: "greaterThan",
    dateFrom: date,
    dateTo: null
  };
  return filter;
}

export const dateLessThan_Filter = (date: string) => {
  const filter = {
    type: "lessThan",
    dateFrom: date,
    dateTo: null
  };
  return filter;
}

export const textEqualsOr_Filter = (values: string[]) => {
  const conditions = {};

  values.forEach((e, i) => {
    conditions[`condition${i + 1}`] = textConditionObject(e, "equals")
  });
  const result = operativeTextFilter("OR", conditions);
  return result;
}

