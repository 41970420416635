import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional them
import { useParams } from "react-router-dom";
import { useAppService } from "../../contexts/AppService";
import { PositionedSpinner } from "../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { useFlightPathUser } from "../../setup";
import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { ActiveUsersHandler } from "./utils";
import { DEFAULT_COLUMN_TYPES } from "./GridDefaultColumnTypes";
import { useCurrentOrganisationId } from "../../services/local/organisationContext/OrganisationContextModel";
import { GridFilterModel } from "./GridFilterModel";
import { progressStatusTextMatcher } from "./filters/TextMatcher";

interface GridViewProps {
  data: any[];
  onCellClicked: (cell: string, isEditMode?: boolean) => void;
  onCellEditModeChange: (cell: string, isEditMode: boolean) => void;
  onSelectionChanged: (event: SelectionChangedEvent) => void;
  users: any[];
  modelClass: any;
  context?: any;
  onGridReady?: (event: GridReadyEvent) => void;
  ref?: any;
  filterHasChangedFn?: (e?) => void;
  columnOrderHasChangedFn?: (e?) => void;
  isExternalFilterPresent?: () => boolean;
  doesExternalFilterPass?: (node) => boolean;
  overlayNoRowsTemplate: string;
  isRowSelectableFn?: (params) => boolean;
}

const GridViewContent: React.FC<GridViewProps> = (
  { data, users, modelClass, onCellClicked, filterHasChangedFn, columnOrderHasChangedFn, ...props },
  ref
) => {
  const appService = useAppService();
  const myRef = useRef();
  const element = document.getElementsByClassName("top-navigation");
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(() => new modelClass(appService, organisationId, +projectId, authUser));
  const [offsetTop, setOffsetTop] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const gridRef = useRef();
  const [connectedUsers, setConnectedUsers] = useState(users);
  const [rowData, setRowData] = useState(data);
  const [columnDefs, setColumnDefs] = useState(model.getColumnConfig());
  const [gridFilterModel] = useState(
    filterHasChangedFn
      ? new GridFilterModel(appService, +projectId, filterHasChangedFn, model.type, columnOrderHasChangedFn, columnDefs)
      : null
  );

  useEffect(() => {
    setConnectedUsers(users);
  }, [users, gridRef]);

  useImperativeHandle(ref, () => ({
    isWrappedText: () => gridFilterModel && gridFilterModel.isWrappedText,
    clearFilters: () => gridFilterModel && gridFilterModel.clearFilters(gridRef as any),
    toggleTextWrapper: () => gridFilterModel && gridFilterModel.toggleTextWrap(),
    resetColumns: () => gridFilterModel && gridFilterModel.resetColumns(gridRef as any)
  }));

  useEffect(() => {
    ActiveUsersHandler.removeAllClassNames();
    ActiveUsersHandler.styleSelectedCells(connectedUsers);
  }, [connectedUsers]);

  useEffect(() => {
    if (model.projectId !== projectId) {
      setModel(new (modelClass as any)(appService, organisationId, projectId, authUser));
    }
  }, [projectId, appService, authUser, model.projectId, organisationId, modelClass]);

  const calculateHeight = useCallback(() => {
    setTimeout(() => {
      const getOffsetTop = () => {
        if (!myRef || !myRef.current) return;
        if (offsetTop !== (myRef.current as any).getBoundingClientRect().top) {
          return (myRef.current as any).getBoundingClientRect().top;
        }
        return 0;
      };
      const ot = getOffsetTop();
      if (offsetTop !== ot) {
        setOffsetTop(ot);
      }
    }, 400);
  }, [offsetTop]);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight), false);
    if (element && element.length > 0) {
      element[0].addEventListener("click", calculateHeight);
    }
    return () => {
      if (element && element.length > 0) {
        element[0].removeEventListener("click", calculateHeight);
      }
    };
  }, [calculateHeight, element]);

  useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  // useEffect(() => {
  //   if (`${modelProjectId}` !== projectId) {
  //     setModel(new (modelClass as any)(appService, organisationId, projectId, authUser));

  //     setColumnDefs(model.getColumnConfig());
  //     setRowData(data);
  //   }
  // }, [projectId, calculateHeight, modelProjectId, organisationId, appService, data, authUser]);

  useEffect(() => {
    if (!model.isLoading) {
      setRowData(data);
    }
  }, [model, data, model.isLoading]);

  useEffect(() => {
    if (!model.isLoading) {
      setColumnDefs(model.getColumnConfig());
    }
  }, [model, model.isLoading]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true
    }),
    []
  );

  // Example of consuming Grid Event
  const cellClickedListener = useCallback(
    event => {
      onCellClicked(`${event.colDef.field}-${event.data.id}`);
    },
    [onCellClicked]
  );

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  const getExternalOffset = () => {
    return myRef && myRef.current ? (myRef.current as any).getBoundingClientRect().top : 0;
  };
  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        height: windowHeight - (offsetTop ? offsetTop : getExternalOffset())
      }}
      ref={myRef}
    >
      <AgGridReact
        gridOptions={{
          context: {
            ...props.context
          }
        }}
        ref={gridRef} // Ref for accessing Grid's API
        rowData={rowData} // Row Data for Rows
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
        onFilterChanged={gridFilterModel && gridFilterModel.onFilterChanged}
        onColumnResized={gridFilterModel && gridFilterModel.onSaveGridColumnState}
        onColumnPinned={gridFilterModel && gridFilterModel.onSaveGridColumnState}
        onColumnMoved={gridFilterModel && gridFilterModel.onSaveGridColumnState}
        onSortChanged={gridFilterModel && gridFilterModel.onSaveGridColumnState}
        onFirstDataRendered={gridFilterModel && gridFilterModel.onFirstDataRendered}
        onSelectionChanged={props.onSelectionChanged}
        onCellEditingStarted={event => {
          props.onCellEditModeChange(`${event.colDef.field}-${event.data.id}`, true);
        }}
        onCellEditingStopped={event => {
          props.onCellEditModeChange(`${event.colDef.field}-${event.data.id}`, false);
        }}
        columnTypes={DEFAULT_COLUMN_TYPES}
        suppressRowClickSelection={true}
        getRowId={params => params.data.id}
        stopEditingWhenCellsLoseFocus={true}
        onGridReady={s => {
          gridFilterModel && gridFilterModel.onGridReady(s);
          props.onGridReady(s);
        }}
        isExternalFilterPresent={props.isExternalFilterPresent}
        doesExternalFilterPass={props.doesExternalFilterPass}
        cacheQuickFilter={true}
        overlayNoRowsTemplate={props.overlayNoRowsTemplate}
        isRowSelectable={props.isRowSelectableFn}
      />
    </div>
  );
};

export const GridView = observer(GridViewContent, { forwardRef: true });
