import * as React from "react";
import { UsersSettingsModel } from "./UsersSettings_model";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Animations } from "../../../../../core/util/Animations";
import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { GetUserTableConfig } from "./UsersSettings_columns";
import { TopActionBar } from "../../../../../components/widgets/TopActionBar/TopActionBar";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { FilterPills } from "../../../../../components/widgets/filterPills/FilterPills_view";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useState } from "react";
import { CanEditOrg } from "../../../../../contexts/permissions/PermissionHelpers";

export interface UsersProps {
  // model: UsersSettingsModel;
}

const UsersSettings: React.FunctionComponent<UsersProps> = observer(() => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const organisationId = organisation.id;
  const [model, setModel] = useState(() => {
    return new UsersSettingsModel(appService, organisation);
  });
  React.useEffect(() => {
    model.onMount(+organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  React.useEffect(() => {
    setModel(new UsersSettingsModel(appService, organisation));
  }, [appService, organisation]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  const smartTableConfig = GetUserTableConfig(model, organisationId);

  let searchValue = model.filterModel.getFilter("SearchPhrase").value;
  return (
    <div className={` pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8">
            <h1>
              {organisation.name} | {I18n.t("phrases.manageUsers")}
            </h1>
            <p>{I18n.t("phrases.manageUsersDescription")}</p>
          </div>
          <div className="col-lg-4">
            <div className="d-flex justify-content-end">
              <LinkButton
                type={ButtonTypes.OUTLINE_PRIMARY}
                href={`/organisations/${model.organisation?.id}`}
                className="float-right"
              >
                {I18n.t("phrases.closeSettings")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>

      <TopActionBar
        searchButtonProps={{
          onChange: model.searchUsers,
          placeholder: I18n.t("placeholders.searchTableData"),
          value: searchValue.length > 0 ? searchValue[0] : "",
          className: "float-right ml-2",
          onClose: model.resetQuery,
          buttonType: ButtonTypes.LINK
        }}
        actions={[
          {
            id: "filter",
            label: (
              <>
                <Icon className="mr-2" symbol={IconSymbols.FilterLines} /> {I18n.t("phrases.filters")}
              </>
            ),
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.LINK
            },
            onAction: model.filterModel.showFilterFormModal
          }
        ]}
      >
        <CanEditOrg>
          <Button
            id="InviteUserButton"
            className={"float-left"}
            onClick={() => {
              model.showUserFormModal();
            }}
          >
            {I18n.t("phrases.inviteUsers")}
          </Button>
        </CanEditOrg>
        <Button className={"float-left ml-2"} type={ButtonTypes.LINK} onClick={model.exportService.showSelectionModal}>
          <Icon symbol={IconSymbols.Download} className="mr-2" />
          {I18n.t("phrases.export")}
        </Button>
      </TopActionBar>

      <div className="container-fluid pt-4">
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-2">
        <div className="row mb-4">
          <div className="col">
            {model.isLoading ? (
              <PositionedSpinner />
            ) : (
              <>
                <SmartTable
                  colHeaders={smartTableConfig.colHeaders}
                  actions={smartTableConfig.actions}
                  onRowClick={smartTableConfig.onRowClick}
                  data={model.users || []}
                />
                <Pagination model={model.paginationModel} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { UsersSettings };
